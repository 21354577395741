import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(private http: HttpClient) {}

  public getFeatureFlags(): Observable<FeatureFlagsStateModel> {
    const requestUrl = `/feature_flags`;
    return this.http.get<FeatureFlagsStateModel>(`${requestUrl}`);
  }
}
