import { UserProject } from '@theme/interfaces';
import { ProjectRequestHeaders } from './project-request-header-model';

export enum BoxPickerTypeEnum {
  FILE = 'file',
  FOLDER = 'folder',
}

export interface BoxPickerDialogModel {
  actionType: string;
  isFile: boolean;
  options: BoxPickerOptionsModal;
  pickerType: BoxPickerTypeEnum;
  prefix?: string;
  projectRequestHeaders?: ProjectRequestHeaders;
  selectedProject?: UserProject;
  showDefaultProjectBucket?: boolean;
}

export interface BoxPickerOptionsModal {
  autoFocus: boolean;
  canCreateNewFolder?: boolean;
  canSetShareAccess?: boolean;
  canUpload?: boolean;
  container: string;
  logoUrl?: string;
  maxSelectable?: number;
  size: string;
  sortBy?: string;
  sortDirection?: string;
}
