import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponseModel } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UpdateRVersionService {
  constructor(private http: HttpClient) {}

  public updateRVersion(
    instanceId: string,
    rVersion: string
  ): Observable<CommonResponseModel> {
    return this.http.post<CommonResponseModel>(`/r_version`, {
      instanceId,
      rVersion,
    });
  }

}
