/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';

export class UpdateEc2InstanceType {
  public static type = '[UpdateInstance] Ec2 ';
  constructor(
    public machineSize: string,
    public userId: string,
    public projectId: string,
    public requestHeaders: MprHttpHeaderModal
  ) {}
}

export class ResetpdateEc2InstanceType {
  public static type = '[ResetInstance] Ec2';
}
