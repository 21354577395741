import { ValueTitleModel } from '@shared';

export const hoursOfTheDay: ValueTitleModel[] = [
    { title: '00:00', value: '00' },
    { title: '01:00', value: '01' },
    { title: '02:00', value: '02' },
    { title: '03:00', value: '03' },
    { title: '04:00', value: '04' },
    { title: '05:00', value: '05' },
    { title: '06:00', value: '06' },
    { title: '07:00', value: '07' },
    { title: '08:00', value: '08' },
    { title: '09:00', value: '09' },
    { title: '10:00', value: '10' },
    { title: '11:00', value: '11' },
    { title: '12:00', value: '12' },
    { title: '13:00', value: '13' },
    { title: '14:00', value: '14' },
    { title: '15:00', value: '15' },
    { title: '16:00', value: '16' },
    { title: '17:00', value: '17' },
    { title: '18:00', value: '18' },
    { title: '19:00', value: '19' },
    { title: '20:00', value: '20' },
    { title: '21:00', value: '21' },
    { title: '22:00', value: '22' },
    { title: '23:00', value: '23' }
];

export const daysOfTheWeek: ValueTitleModel[] = [
    { title: 'Monday', value: '0' },
    { title: 'Tuesday', value: '1' },
    { title: 'Wednesday', value: '2' },
    { title: 'Thursday', value: '3' },
    { title: 'Friday', value: '4' },
    { title: 'Saturday', value: '5' },
    { title: 'Sunday', value: '6' },
];

export const daysOfTheMonth = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

