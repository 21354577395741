<ng-container *ngIf="listDetails | async as listData">
  <div *ngIf="listData['userCount'] && listData['userCount'] > -1">
    <div class="mpr-list-container">
      <div class="mpr-list">List of Users ({{ listData['userCount'] }})</div>
      <span class="mpr-list-container">
        <span #skipper>
          <mpr-export-to-csv
            [spinner]="exportButtonSpinner"
            *ngIf="listData"
            (exportMethod)="exportToCSV()"
          ></mpr-export-to-csv>
        </span>
        <button
          class="mpr-action-button"
          (click)="navigateToRoute('platformAdmin/users/add')"
        >
          Add New User(s)
        </button>
      </span>
    </div>
    <div class="mpr-all-users-container m-t-16">
      <div class="p-b-16" *ngFor="let list of listData['userInfo']; index as i">
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <span class="mpr-username">{{ list.userName }}</span>
            <span class="mpr-email">({{ list.emailId }})</span>
            <span *ngIf="isDisabled(list)" class="d-flex m-l-8">
              <span class="mpr-danger-text m-t-2"> DISABLED </span>
              <span>
                <button
                  class="mpr-btn-transparency m-t-0"
                  [mtxTooltip]="disableReason"
                  [mprMtxTooltipAriaDescribedBy]="disableReason"
                  #tooltip="mtxTooltip"
                  [attr.aria-label]="list.reason"
                >
                  <mat-icon
                    role="img"
                    class="mat-icon notranslate mpr-info-icon mpr-info-big-icon material-icons mat-icon-no-color mpr-icon-info"
                    aria-hidden="true"
                    >info
                  </mat-icon>
                </button>
                <ng-template #disableReason>
                  <div class="mpr-popover-width" id="mpr-file-note">
                    {{ list.reason }}
                  </div>
                </ng-template>
              </span>
            </span>
          </div>
          <div class="d-flex align-items-center">
            <button
              class="d-flex align-items-center p-r-16 link-like-button"
              (click)="
                navigateToRoute('platformAdmin/users/manage', list.emailId)
              "
            >
              <img src="assets/images/Manage.svg" alt="" class="p-r-8" />
              <span class="icon-text">Manage</span>
            </button>
            <button
              class="d-flex align-items-center p-l-16 link-like-button"
              [ngClass]="{'mpr-disabled': isDisabled(list)}"
              (click)="navigateAddToProject(list)"
            >
              <img src="assets/images/AddToProject.svg" alt="" class="p-r-8" />
              <span class="icon-text">Add To Project</span>
            </button>
          </div>
        </div>
        <div
          *ngIf="listData['userCount'] && i < listData['userCount'] - 1"
          class="mpr-divider"
        ></div>
      </div>
    </div>

    <div
      *ngIf="
        listData['userInfo'] &&
        listData['userCount'] > listData['userInfo'].length
      "
      class="mpr-load-more-wrapper"
    >
      <mpr-load-more [show]="!showSpinner" (loadMoreMethod)="loadMoreUsers()">
      </mpr-load-more>
    </div>
  </div>
</ng-container>
<div class="mpr-noresult-container" *ngIf="showSpinner">
  <div class="mpr-noresutl-error">
    <span>
      <mat-spinner diameter="45" color="accent"></mat-spinner>
    </span>
  </div>
</div>
