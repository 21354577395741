import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { LoadRecentUploadsdata } from './recent-uploads.actions';
import { UploadHistoryService } from '@shared';
import {
  RecentUploadStateModel,
  RecentUploadDetails,
} from '@shared/interfaces';

const stateDefaults: RecentUploadStateModel = {
  recentUpload: [],
};
@State<RecentUploadStateModel>({
  defaults: stateDefaults,
  name: 'RecentUploadData',
})
@Injectable()
export class RecentUploadsState {
  constructor(private uploadService: UploadHistoryService) {}

  @Action(LoadRecentUploadsdata)
  public LoadRecentUploadsdata({
    patchState,
  }: StateContext<RecentUploadStateModel>): Observable<
    Array<RecentUploadDetails>
  > {
    return this.uploadService.getRecentUploadsDeatils().pipe(
      tap((res: Array<RecentUploadDetails>) => {
        const recentUpload = res;
        patchState({ recentUpload });
      })
    );
  }

  @Selector()
  public static getRecentUploads(
    state: RecentUploadStateModel
  ): RecentUploadStateModel {
    return state;
  }
}
