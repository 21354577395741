import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GlobalAttributesService } from '@theme/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalAttributesStateModel, GlobalAttributesModel } from './global-attributes.model';
import { GetGobalAttributes, ResetGobalAttributes } from './global-attributes.actions';


const stateDefaults: GlobalAttributesStateModel = {
    global_attributes: [],
    loader: false
}

@State<GlobalAttributesStateModel>({
  defaults: stateDefaults,
  name: 'globalAttributes'
})
@Injectable()
export class GlobalAttributesState {
  constructor(private globalAttributesService :GlobalAttributesService ) {}

  @Selector()
  public static getGloablAttributesLoader(state : GlobalAttributesStateModel) : boolean {
    return state.loader;
  }

  @Action(GetGobalAttributes)
  public getGobalAttributes(
    { patchState }: StateContext<GlobalAttributesStateModel>,
    {requestHeaders}: GetGobalAttributes): Observable<any> {
      const loader = true;
      patchState({loader})
    return this.globalAttributesService.getGlobalAttributes(requestHeaders).pipe(
      tap((result) => {
        const global_attributes = result
        const isLoaderActive = false;
        patchState({global_attributes, loader: isLoaderActive});
      })
    );
  }

  @Action(ResetGobalAttributes)
  public resetGobalAttributes({
        patchState
  }: StateContext<GlobalAttributesStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Selector()
  public static returnGlobalAttributes(state: GlobalAttributesStateModel): GlobalAttributesModel[] {
    return state.global_attributes;
  }
}