<div>
  <div class="mpr-dashboard-loader" *ngIf="showSpinner; else showUserForm">
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showUserForm>
    <div *ngIf="selectedUserId">
      <mat-label for="SELECT PROJECT" class="mpr-select-project" role="none"
        >SELECT PROJECT</mat-label
      >
      <div>
        <mat-form-field
          class="mpr-mat-select-field mpr-select-field-width mpr-add-to-project-select-project"
          floatLabel="never"
          appearance="outline"
        >
          <mat-select
            placeholder="Select your project"
            #selectedProject
            (selectionChange)="changeProject(selectedProject.value)"
          >
            <mat-option
              *ngFor="let project of sortedValue"
              [value]="project.projectId"
            >
              {{ getProjectName(project) }}
            </mat-option>
          </mat-select>
          
        </mat-form-field>
        <mat-error
          class="padding"
          *ngIf="isSelectedUserUnsupportedForSelectedProject"
        >
          <div>User's domain is not supported for the selected project.</div>
        </mat-error>
        <mat-error
          class="padding"
          *ngIf="!isSelectedUserUnsupportedForSelectedProject"
        >
          <div>&nbsp;</div>
        </mat-error>
      </div>
    </div>
    <form #userForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="mpr-margin-top mpr-padding-top mat-expansion-panel mat-expanded">
        <div class="mat-expansion-panel-content">
          <div class="mat-expansion-panel-body">
            <mpr-user-form
              [data]="config"
              [group]="''"
              [projectId]="selectedProjectId"
              [action]="'addToProject'"
              [canChangePowerUserStatus]="canChangePowerUserStatus"
              [canChangeBedrockAccessStatus]="canChangeBedrockAccessStatus"
              [restrictedGroups]="allRestrictedGroupsForProject"
              [projectPoweruser]="isPowerUserApplicable"
              [allowedExternalDomains]="allowedExternalDomains"
            ></mpr-user-form>
          </div>
        </div>
      </div>
      <div class="mpr-actions">
        <div></div>
        <div>
          <button type="button" (click)="navigateBack()" class="mpr-cancel">
            Cancel
          </button>
          <button
            type="submit"
            class="mpr-submit-user"
            [ngClass]="!userForm.form.valid || isSelectedUserUnsupportedForSelectedProject || !selectedProjectId ? 'mpr-disabled' : ''"
          >
            Confirm
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</div>
