import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LaunchEc2Service } from '@shared/services';
import { Observable, tap } from 'rxjs';
import {
  LaunchWorkspaceEc2,
  StopWorkspaceEc2,
  ProvisionWorkspaceEc2,
  ResetWorkspaceEc2,
} from './launch-ec2.actions';
import { CommonResponseModel } from '@shared/interfaces';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};
@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'Launchec2data',
})
@Injectable()
export class LaunchEc2State {
  constructor(private launchEc2Service: LaunchEc2Service) {}

  @Selector()
  public static getLaunchEc2(state: CommonResponseModel): CommonResponseModel {
    return state;
  }

  @Action(LaunchWorkspaceEc2)
  public launchWorkspaceEc2(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { userId, projectId }: LaunchWorkspaceEc2
  ): Observable<CommonResponseModel> {
    return this.launchEc2Service.launchOrProvisionEc2(userId, projectId).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Action(ProvisionWorkspaceEc2)
  public provisionWorkspaceEc2(
    { patchState }: StateContext<CommonResponseModel>,
    { userId, projectId, action }: LaunchWorkspaceEc2
  ): Observable<CommonResponseModel> {
    action = action ?? 'provision';
    return this.launchEc2Service
      .launchOrProvisionEc2(userId, projectId, action)
      .pipe(
        tap((results) => {
          patchState({ ...results });
        })
      );
  }

  @Action(ResetWorkspaceEc2)
  public resetProjectState({ patchState }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(StopWorkspaceEc2)
  public stopWorkspaceEc2(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { userId, projectId, action, requestHeaders }: StopWorkspaceEc2
  ): Observable<CommonResponseModel> {
    return this.launchEc2Service.stopEc2(userId, projectId, action, requestHeaders).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }
  
}
