import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SkipMainContentService } from '@shared';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-platform-users',
  templateUrl: './platform-users.component.html',
})
export class PlatformUsersComponent implements OnInit {
  @ViewChild('skipper') public skipper!: ElementRef;
  public selectedTabIndex = 0;

  constructor(
    public skipMainContentService: SkipMainContentService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.children[0].children[0].children[1].children[0].children[0].children[0].focus();
    });
  }

  public selectedTabChange(tabChange: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChange.index;
  }
}
