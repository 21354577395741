import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserProjectRoleEnum } from '@core';
import { HeaderParams } from '@core/interfaces';
import { UserEnableDisableRequestModel } from '@shared';
import { CommonResponseModel } from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserEnableDisableService {
  constructor(private http: HttpClient) {}

  public enableOrDisableUser(
    action: string,
    userId: string,
    isPlatformAdmin: boolean
  ): Observable<CommonResponseModel> {
    const payload: UserEnableDisableRequestModel = {
      action,
      isPlatformAdmin
    };

    if (userId.includes('@')) {
      payload['emailId'] = userId;
    } else {
      payload['userId'] = userId;
    }

    const headers = new HttpHeaders().append(
      HeaderParams.ROLENAME,
      UserProjectRoleEnum.PLATFORM_ADMIN
    );
    return this.http.put<CommonResponseModel>(`/user/disable`, payload, {
      headers,
    });
  }
}
