/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */

// eslint-disable-next-line max-classes-per-file
import { MprHttpHeaderModal } from '@core/interfaces';
import { ProjectUserList } from './project-user-list-model';

/* eslint-disable max-classes-per-file */
export class AddUserToProject {
  public static type = '[Project] Add Users ';
  constructor(
    public userFormDetails: Array<ProjectUserList>,
    public roleName?: string
  ) {}
}

export class EditProjectUser {
  public static type = '[Project] Edit User ';
  constructor(
    public userFormDetails: ProjectUserList,
    public requestHeaders?: MprHttpHeaderModal
  ) {}
}

export class GetCountOfUsers {
  public static type = '[Project] Get Users ';
  constructor(public isCount: string) {}
}

export class GetUserDetails {
  public static type = '[Project] Get User Details';
  constructor(
    public userId: string,
    public requestHeaders?: MprHttpHeaderModal
  ) {}
}

export class GetProjectUserList {
  public static type = '[Project] Users List ';
  constructor(
    public isCount: string,
    public requestHeaders?: MprHttpHeaderModal
  ) {}
}

export class ResetUserListState {
  public static type = '[Project] Users List Reset ';
}

export class ResetEditUserState {
  public static type = '[Project] Edit User Reset ';
}

export class DeleteUserFromProject {
  public static type = '[UserProject] Delete User From Current Project ';
  constructor(
    public userId: string,
    public requestHeaders?: MprHttpHeaderModal,
    public projectIds?: string[]
  ) {}
}
