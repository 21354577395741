import { Injectable } from '@angular/core';
import { AuthConstants } from '@core/enums';
import * as dayjs from 'dayjs';
import { CookieService, CookieOptions } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class MprCookieService {
  // Current Domain with a . prefix. Same as set by Backend else its 2 cookies
  private domain: CookieOptions['domain'] = `.${window.location.host}`;

  constructor(private cookieService: CookieService) {}

  public delete(
    cookieName: string,
    path: CookieOptions['path'] = '/',
    domain: CookieOptions['domain'] = this.domain
  ): void {
    // The library seems to need these three minimum to delete on non-localhost domains
    this.cookieService.delete(cookieName, path, domain);
  }

  public getContent(cookie: string): string {
    return this.cookieService.get(cookie);
  }

  public isCookieSet(cookieName: string): boolean {
    return this.cookieService.check(cookieName);
  }

  public refreshTokenExpiry(): void {
    // Current time + 1 hour
    const idTokenExpiry = new Date(
      dayjs()
        .add(
          AuthConstants.ID_TOKEN_LIFETIME,
          AuthConstants.ID_TOKEN_LIFETIME_UNIT
        )
        .format()
    );
    // Current time + 30 days
    const refreshTokenExpiry = new Date(
      dayjs()
        .add(
          AuthConstants.REFRESH_TOKEN_LIFETIME,
          AuthConstants.REFRESH_TOKEN_LIFETIME_UNIT
        )
        .format()
    );
    this.resetCookieExpiry(AuthConstants.ID_TOKEN, idTokenExpiry);
    this.resetCookieExpiry(AuthConstants.REFRESH_TOKEN, refreshTokenExpiry);
  }

  public set(
    cookieName: string,
    cookieValue: string,
    options: CookieOptions
  ): void {
    options.domain = this.domain;
    this.cookieService.set(cookieName, cookieValue, options);
  }

  private resetCookieExpiry(
    cookieName: string,
    expires: CookieOptions['expires']
  ): void {
    if (!this.cookieService.check(cookieName)) return;
    const cookieValue = this.cookieService.get(cookieName);
    this.set(cookieName, cookieValue, { expires });
  }
}
