import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ResetUploadsState,
  SelectFileForUploads,
  SetExistingFileNames,
  SetUploadsMetaData,
} from './uploads.actions';
import { ConnectionState } from '../connection';
import { Injectable } from '@angular/core';
import { UploadsFilesFormModel } from './upload-files-form-model';
import { UploadsMetadataState } from './uploads-metadata-state';
import { UploadsStateModel } from './uploads-state-model';
import { MprFile } from 'app/uploads/interfaces/mpr-file-model';
import { JobOperationTypeEnums } from '@core/enums';

const stateDefaults: UploadsStateModel = {
  uploadFilesForm: {
    connectionId: 'local',
    fileNameSizeMapping: [],
    jobOperationType:
      JobOperationTypeEnums.JOB_OPERATION_TYPE_UPLOAD_AND_CATALOG,
    supportingFileNameSizeMapping: [],
    noOfFiles: 0,
    noOfSupportingFiles: 0,
    urls: [],
    stagingTargetPath: '',
    noOfUrls: 0,
    surveyId: '',
  },
  selectedFiles: [],
  selectedSupportingFiles: [],
  existingFileNames: []
};

@State<UploadsStateModel>({
  defaults: stateDefaults,
  name: 'UploadsState',
  children: [UploadsMetadataState],
})
@Injectable()
export class UploadsState {
  constructor(private store: Store) {}

  @Selector()
  public static getExistingFileNames(state: UploadsStateModel): string[] {
    return state.existingFileNames ?? [];
  }

  @Selector()
  public static getSelectedLocalFiles(state: UploadsStateModel): MprFile[] {
    return state.selectedFiles;
  }


  @Action(ResetUploadsState)
  public resetUploadsState({
    patchState,
  }: StateContext<UploadsStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetExistingFileNames)
  public setExistingFileNames(
    ctx: StateContext<UploadsMetadataState>,
    existingFileNames: string[]
  ): void {
    ctx.patchState({ ...existingFileNames });
  }

  @Action(SelectFileForUploads)
  public setSelectedFiles(
    ctx: StateContext<UploadsStateModel>,
    {
      selectedFiles,
      selectedSupportingFiles,
      jobId,
      datasetName,
      urls,
      jobOperationType,
      stagingTargetPath,
      surveyId,
    }: SelectFileForUploads
  ): void {
    // DELETE WHEN BE STARTS SUPPORTING THE SUPPORTING DOCUMENTS -- START --
    selectedFiles = selectedFiles.concat(selectedSupportingFiles);
    // DELETE WHEN BE STARTS SUPPORTING THE SUPPORTING DOCUMENTS -- END --

    const existingState = ctx.getState();
    const uploadFilesForm: UploadsFilesFormModel = {
      ...existingState.uploadFilesForm,

      fileNameSizeMapping: selectedFiles.map((file: MprFile) =>
        file.id
          ? {
              fileName: file.name,
              fileSize: `${file.size}`,
              fileId: file.id,
            }
          : {
              fileName: file.name,
              fileSize: `${file.size}`,
              filePath: file.pickerPath,
            }
      ),
      supportingFileNameSizeMapping: selectedSupportingFiles.map(
        (file: MprFile) =>
          file.id
            ? {
                fileName: file.name,
                fileSize: `${file.size}`,
                fileId: file.id,
              }
            : {
                fileName: file.name,
                fileSize: `${file.size}`,
                filePath: file.pickerPath,
              }
      ),
      noOfFiles: selectedFiles.length,
      noOfSupportingFiles: selectedSupportingFiles.length,
      jobOperationType,
      urls,
      noOfUrls: urls.length,
      connectionId: this.store.selectSnapshot(
        ConnectionState.getSelectedConnectionId
      ),
      surveyId
    };

    // If these selected files are for an existing job
    if (jobId) uploadFilesForm.jobId = jobId;

    // if its staging upload
    if (
      jobOperationType ===
      JobOperationTypeEnums.JOB_OPERATION_TYPE_UPLOAD_TO_STAGING
    ) {
      uploadFilesForm.jobOperationType = jobOperationType;
      uploadFilesForm.stagingTargetPath = stagingTargetPath;
    }

    if (datasetName) uploadFilesForm.datasetName = datasetName;
    ctx.patchState({ selectedFiles, selectedSupportingFiles, uploadFilesForm });
  }

  @Action(SetUploadsMetaData)
  public setUploadsMetadataValues(
    ctx: StateContext<UploadsMetadataState>,
    fileMetadata: UploadsMetadataState
  ): void {
    ctx.patchState({ ...fileMetadata });
  }


}
