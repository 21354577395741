import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  Renderer2,
  TemplateRef,
} from '@angular/core';

@Directive({
  selector: '[mprMtxTooltipAriaDescribedBy]',
})
export class mtxTooltipAriaDescribedByDirective implements AfterViewInit {
  @Input() public data: any = {};
  @Input() public mprMtxTooltipAriaDescribedBy!: TemplateRef<any>;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const embeddedViewRef =
        this.mprMtxTooltipAriaDescribedBy.createEmbeddedView(this.data);
      embeddedViewRef.detectChanges();
      const describedBy =
        embeddedViewRef.rootNodes[0].textContent ||
        embeddedViewRef.rootNodes[0].innerText ||
        '';
      const ariaDescribedBy =
        this.el.nativeElement.getAttribute('aria-describedby');

      const child = this.document.createElement('div');
      child.setAttribute('hidden', '');
      child.setAttribute('id', ariaDescribedBy);
      child.innerText = describedBy;
      this.renderer.appendChild(this.el.nativeElement, child);
    }, 0);
  }
}
