import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@core/services';
import { Store } from '@ngxs/store';
import { GetFeatureFlags } from 'app/state';
import {
  FeatureFlagsState,
  FeatureFlagsStateModel,
} from 'app/state/feature-flags';
import { Observable, map, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store, private route: Router, private auth: AuthService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | UrlTree {
    const isRedirectURLPresent = localStorage.getItem('redirectURL');
    if(!isRedirectURLPresent)
    localStorage.setItem('redirectURL', state.url);
  
    return this.isFlagNeededForRouteNavigation(route);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | UrlTree {
    return this.isFlagNeededForRouteNavigation(route);
  }

  private isFlagNeededForRouteNavigation(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | UrlTree {
    if (!route['data'] || !route['data']['allowWhenFeatureFlagTrue'])
      return of(true);
    const flagChecks = route['data']['allowWhenFeatureFlagTrue'];
    return this.store
      .dispatch(new GetFeatureFlags())
      .pipe(map((featureFlagsFetched: boolean) => this.isValid(flagChecks)));
  }

  private isValid(flagChecks: string[]): boolean {
    if (!flagChecks || flagChecks[0] === 'any') return true;

    const featureFlags = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );

    let returnVal: boolean | UrlTree = true;
    flagChecks.forEach((featureFlag: string) => {
      returnVal =
        featureFlags[featureFlag].toLowerCase() !== 'true'
          ? this.route.parseUrl('/dashboard')
          : true;
    });

    return returnVal;
  }
}
