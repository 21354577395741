import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store } from '@ngxs/store';
import { LoadAllMetadataOptions } from 'app/state';
import { ResetScheduleState } from 'app/state/uploads/schedule/schedule.action';
import { InitiateCreateScheduleComponent } from '@shared/components';
import { OneTimeScheduleEnum } from '@core/enums';
import { LoadSelectedProjectMetadata } from 'app/state/project/project.actions';
@Component({
  selector: 'mpr-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.scss'],
})
export class CreateScheduleComponent implements OnInit, OnDestroy {
  public createScheduleFormStatus = false;
  public isLinear = true;
  public metaDataFormStatus = false;
  public schedule = OneTimeScheduleEnum.SCHEDULE;

  private dialogSize: MatDialogConfig = {
    minHeight: '250px ',
    width: '70%',
  };
  constructor(private dialog: MatDialog, private store: Store) {}

  public createSchedule(): void {
    this.metaDataFormStatus = true;
    this.dialog.open(InitiateCreateScheduleComponent, {
      ...this.dialogSize,
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    // Ensure we have clean slate when user goes off with this.
    this.store.dispatch(new ResetScheduleState());
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadAllMetadataOptions());
    this.store.dispatch(new LoadSelectedProjectMetadata());
  }

  public proceedToMetaDataScreen(stepper: MatStepper): void {
    setTimeout(() => stepper.next(), 0);
    this.createScheduleFormStatus = true;
  }
}
