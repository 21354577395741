import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  HydrateUserProjectStateByProjectId,
  UserProjectState,
} from 'app/state';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectSelectGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store: Store) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean> {
      const projectFromURL = route.queryParams['projectId'] || '';
    return this.hasSelectedProject(projectFromURL);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const projectFromURL = route.queryParams['projectId'] || '';
    return this.hasSelectedProject(projectFromURL);
  }

  private hasSelectedProject(projectFromURL : string): Observable<boolean> {
    return this.store.dispatch(new HydrateUserProjectStateByProjectId(projectFromURL)).pipe(
      map((canNavigate: boolean) => {
        const selectedProjectId = this.store.selectSnapshot(
          UserProjectState.getSelectedProjectId
        );

        // If user has no project now or If the user no longer has access to this last selected project
        if (selectedProjectId === '') this.router.navigate(['/']);

        // All good.
        return true;
      })
    );
  }
}
