import { Action,Selector,State,StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';

import SelectedUserProjectInfoModel from './selected-user-project-info.model';
import { Injectable } from '@angular/core';
import { LoadSelectedUserProjects } from './selected-user-project-info.action';
import { UserProject } from '@theme/interfaces';
import { UserProjectService } from '@theme/services';


const stateDefaults: SelectedUserProjectInfoModel = {
    projects : []
  };

  @State<SelectedUserProjectInfoModel>({
    defaults: stateDefaults,
    name: 'SelectedUserProjects',
  })
  @Injectable()
  export class SelectedUserProjectInfoState {
    constructor(private userProjectService: UserProjectService) {}

    @Selector()
    public static getSelectedUserProjects(
    state: SelectedUserProjectInfoModel
    ): UserProject[] {
        return state.projects;
    }

    @Action(LoadSelectedUserProjects)
    public loadSelectedUserProjects(
    ctx: StateContext<SelectedUserProjectInfoModel>,
    { emailId }: LoadSelectedUserProjects
    ): Observable<UserProject[]> {
    return this.userProjectService.getAllUserProjects(emailId).pipe(
        tap((projects) => {
        ctx.patchState({ projects });
        })
    );
    }
}