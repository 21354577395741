import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import {
  ConnectionState,
  GetAllConnections,
  ResetConnectionState,
  SetSelectedConnection,
} from 'app/state/connection';
import {
  BoxContentPickerComponent,
  MprConfirmComponent,
  S3ContentPickerComponent,
} from '@shared/components';
import { ConnectionModel } from '@shared/interfaces';
import {
  ConnectionDataStore,
  ConnectionType,
  JobOperationTypeEnums,
  SelectFileTypeEnum,
  UserProjectRoleEnum,
} from '@core/enums';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import {
  LoadSelectedProjectMetadata,
  LoadUserdata,
  ProjectState,
  SelectFileForUploads,
  UserProjectState,
  UserState,
} from 'app/state';
import { Select, Store } from '@ngxs/store';
import { AlertMessageService } from '@core/services';
import { SkipMainContentService } from '@shared/services';
import { MprFile } from '../interfaces/mpr-file-model';
import { Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserProject } from '@theme/interfaces';
import { DatasetDetailsModel, URLListModel } from '../interfaces';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import * as constants from '@core/constants';
import {
  FeatureFlagsState,
  FeatureFlagsStateModel,
} from 'app/state/feature-flags';
import { formatOptions } from '@core/constants';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-select-files',
  templateUrl: './select-files.component.html',
  styleUrls: ['./select-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectFilesComponent implements OnInit, OnDestroy {
  @Select(UserProjectState.getAllUserProjects)
  public allProject$?: Observable<UserProject[]>;
  @Select(ConnectionState.getConnectionLoading)
  public connectionLoading$?: Observable<boolean>;
  @Select(ConnectionState.getConnectionsLoading)
  public connectionsLoading$?: Observable<boolean>;
  @Input() public datasetDetails: DatasetDetailsModel = {
    datasetId: '',
    projectID: '',
    datasetName: '',
  };
  @Select(UserState.getDWFeatureFlag)
  public dwFeatureFlag$!: Observable<string>;
  @ViewChild('fileUpload', { static: false }) public fileUpload?: ElementRef;
  @Input() public jobId = '';
  @Output() public proceedToMetaData = new EventEmitter<any>();
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @ViewChild('typeOfDataSource') public skipper!: MatSelect;
  @Select(ProjectState.getSnowflakeProvisioningStatus)
  public snowflakeProvisioningStatus$!: Observable<string>;
  @ViewChild('filesTable') public table!: MatTable<any>;
  @Output() public uploadData = new EventEmitter<any>();

  @Select(ConnectionState.getAllActiveIngestionConnections)
  private connections$?: Observable<ConnectionModel[]>;
  public areAllURLSectionsValid = false;
  public connectionList: ConnectionModel[] = [];
  public defaultConnectionValue: ConnectionModel = {
    connectionStatus: '',
    projectId: '',
    dataStore: ConnectionDataStore.LOCAL,
    createdDate: '',
    connectionName: '',
    connectionType: ConnectionType.INGESTION,
    emailId: '',
    connectionId: '',
    modifiedDate: '',
    createdBy: '',
    createdByName: '',
    modifiedBy: '',
  };
  public defaultValue = '';
  public displayedColumns = ['serialNum', 'fileName', 'fileSize', 'removeFile'];
  public fileCount = 0;
  public fileList: MprFile[] = [];
  public fileType = SelectFileTypeEnum.FILES;
  public formatOptions = formatOptions;
  public requestHeaders: MprHttpHeaderModal = {};
  public returnPath: string;
  public selectFileTypeEnum = SelectFileTypeEnum;
  public selectedConnection: ConnectionModel = this.defaultConnectionValue;
  public showSnowflakeOption = false;
  public supportingFileCount = 0;
  public supportingFileList: MprFile[] = [];
  public totalFileSize = 0;
  public totalSupportingFileSize = 0;
  public unsupportedFileTypes = ['EXE', 'COM', 'MSI', 'BAT', 'CGI']; // TODO: Will soon move to a externally loaded API / JSON
  public urlArray: URLListModel[] = [];
  public urlList: URLListModel[] = [{ url: '', name: '', format: '' }];
  private destroyed$ = new Subject<boolean>();
  constructor(
    private alertMsgService: AlertMessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private skipMainContentService: SkipMainContentService
  ) {
    this.returnPath = route.snapshot.data['back'];
  }

  public addURLRow(): void {
    this.urlList.push({ url: '', name: '', format: '' });
    this.onURLChange();
  }

  public checkSnowflakeStatusAndDisplay(
    requestHeaders?: MprHttpHeaderModal
  ): void {
    this.showSnowflakeOption = false;
    this.store
      .dispatch(new LoadSelectedProjectMetadata(requestHeaders))
      .pipe(withLatestFrom(this.snowflakeProvisioningStatus$))
      .subscribe(([_, snowflakeProvisioningResponse]) => {
        const featureFlags: FeatureFlagsStateModel =
          this.store.selectSnapshot(FeatureFlagsState);
        const dwResponse: string = featureFlags.dwFeatureFlag;
        this.showSnowflakeOption =
          snowflakeProvisioningResponse === 'active' &&
          dwResponse.toLowerCase() === 'true';
        if (!this.showSnowflakeOption) {
          this.areAllURLSectionsValid =
            this.fileList.length !== 0 || this.supportingFileList.length !== 0;
        }
      });
  }

  public getFiles(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const selectedFiles: MprFile[] = Array.from(target.files);
      this.handleFileSelections(selectedFiles, this.fileType);
    }

    // Clear the input
    target.value = '';
  }
  public navigateBack(): void {
    if (this.datasetDetails.datasetName !== '') {
      window.history.back();
    } else {
      this.router.navigate([this.returnPath]);
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  ngOnInit(): void {
    // add files to Dataset flow
    if (this.datasetDetails.datasetName !== '') {
      this.allProject$?.subscribe((allProjects: UserProject[]) => {
        this.store.dispatch(new ResetConnectionState());
        const selectedProject = allProjects.find(
          (project: UserProject) =>
            project.projectId === this.datasetDetails.projectID
        );
        this.requestHeaders = {};
        this.connectionList = [];
        if (selectedProject) {
          this.requestHeaders[HeaderParams.ROLENAME] =
            selectedProject?.roleName || UserProjectRoleEnum.BLANK;
          this.requestHeaders[HeaderParams.PROJECTID] =
            this.datasetDetails.projectID || '';
          this.checkSnowflakeStatusAndDisplay(this.requestHeaders);
          if (selectedProject?.roleName !== UserProjectRoleEnum.RESEARCHER && selectedProject?.roleName !== UserProjectRoleEnum.EXTERNAL_RESEARCHER)
            this.store.dispatch(new GetAllConnections(this.requestHeaders));
          else this.loadPremiseConnection();
        } else {
          this.requestHeaders[HeaderParams.ROLENAME] =
            UserProjectRoleEnum.GENERAL_RESEARCHER;
          this.requestHeaders[HeaderParams.PROJECTID] = '';
          this.loadPremiseConnection();
        }
        this.connections$?.subscribe((connections: ConnectionModel[]) => {
          this.connectionList = [...connections];
          this.loadPremiseConnection();
        });
      });
    }
    // one time upload and add files to job flow
    else {
      this.project$
        ?.pipe(takeUntil(this.destroyed$))
        .subscribe((project: UserProject) => {
          this.checkSnowflakeStatusAndDisplay();
          if (project.roleName === UserProjectRoleEnum.RESEARCHER || project.roleName === UserProjectRoleEnum.EXTERNAL_RESEARCHER) {
            this.loadPremiseConnection();
          } else {
            this.store.dispatch(new GetAllConnections());
            this.connectionList = [];
            this.connections$?.subscribe((connections: ConnectionModel[]) => {
              this.connectionList = [...connections];
              this.loadPremiseConnection();
            });
          }
        });
    }
    this.skipMainContentService.skip$.subscribe(() => {
      this.skipper.focus();
    });
  }

  public onURLChange(): void {
    this.urlArray = this.urlList.filter(
      (obj) => obj.url !== '' && obj.name !== '' && obj.format !== ''
    );
    const partialValuesEntered = this.urlList.filter(
      (obj) =>
        (obj.url === '' || obj.name === '' || obj.format === '') &&
        !(obj.url === '' && obj.name === '' && obj.format === '')
    ).length;
    this.areAllURLSectionsValid =
      (this.urlArray.length === 0 && partialValuesEntered === 0) ||
      this.urlList.length === 0
        ? this.fileList.length !== 0 || this.supportingFileList.length !== 0
        : partialValuesEntered === 0 && this.urlArray.length > 0;
  }

  public proceedFlow(isAddFilesFlow?: string): void {
    if (!this.hasDuplicateDocuments()) {
      // Put in state for later use and emit event to trigger next
      if (this.fileList.length === 0 && this.supportingFileList.length === 0) {
        this.setSelectedConnection(this.defaultConnectionValue.connectionId);
      }
      this.alertMsgService.dismmissAll();
      this.setSelectedFileToState();
      if (isAddFilesFlow === 'addFiles')
        this.uploadData.emit(this.requestHeaders);
      else this.proceedToMetaData.emit();
    }
  }

  public removeFilesFromList(file: MprFile, fileType: string): void {
    if (fileType === SelectFileTypeEnum.FILES) {
      this.fileList = this.fileList.filter((f) => f.name !== file.name);
    } else {
      this.supportingFileList = this.supportingFileList.filter(
        (f) => f.name !== file.name
      );
    }

    if (fileType === SelectFileTypeEnum.FILES) {
      // Reduce the counters
      this.fileCount--;
      this.totalFileSize -= file.size;
    } else {
      // Reduce the counters
      this.supportingFileCount--;
      this.totalSupportingFileSize -= file.size;
    }
    this.onURLChange();
    this.table.dataSource = this.fileList;
    this.table.renderRows();
  }

  public removeURL(index: number): void {
    this.urlList.splice(index, 1);
    this.onURLChange();
  }

  public setSelectedConnection(selectedConnectionId: string): void {
    const connection: ConnectionModel =
      this.connectionList.find(
        (connectionObject) =>
          connectionObject.connectionId === selectedConnectionId
      ) ?? this.defaultConnectionValue;
    this.selectedConnection = connection;
    if (Object.keys(this.requestHeaders).length > 0)
      this.store.dispatch(
        new SetSelectedConnection(connection, this.requestHeaders)
      );
    else this.store.dispatch(new SetSelectedConnection(connection));
  }

  public showConfirmOnChangeIfApplicable(selectedConnectionId: string): void {
    // If user has no file selections to loose let it go.
    if (this.fileList.length === 0 && this.supportingFileList.length === 0) {
      this.setSelectedConnection(selectedConnectionId);
      return;
    }

    // There are some files so best we confirm with user
    this.dialog
      .open(MprConfirmComponent, {
        data: {
          confirmTitle: 'Change Data Source',
          confirmMessage:
            'Are you sure you want to change the data source? Selecting a different data source will clear the currently selected files as upload from only a single source is supported.',
          confirmData: selectedConnectionId,
        },
      })
      .afterClosed()
      .subscribe((connectionId: string): void => {
        // If user clicked cancel revert to earlier value.
        if (!connectionId) {
          this.defaultValue = this.selectedConnection.connectionName;
          return;
        } else {
          // User confirmed lets overwrite
          this.fileList = [];
          this.supportingFileList = [];
          this.fileCount = 0;
          this.supportingFileCount = 0;
          this.totalFileSize = 0;
          this.totalSupportingFileSize = 0;
        }
        this.setSelectedConnection(connectionId);
        this.onURLChange();
      });
  }

  public showFilePicker(fileType: SelectFileTypeEnum): void {
    this.fileType = fileType;
    switch (this.selectedConnection.dataStore) {
      case ConnectionDataStore.BOX:
        this.showPickerDialog(BoxContentPickerComponent, this.fileType);
        break;
      case ConnectionDataStore.S3:
      case ConnectionDataStore.DL_S3:
        this.showPickerDialog(S3ContentPickerComponent, this.fileType);
        break;
      default:
        this.fileUpload?.nativeElement.click();
        break;
    }
  }

  public showReplaceFilesWarning(fileType: SelectFileTypeEnum): void {
    this.dialog
      .open(MprConfirmComponent, {
        data: {
          confirmMessage:
            'Adding more files will completely overwrite the currently selected files. Are you sure you want to continue?',
          confirmData: 'clearFileSelection',
        },
      })
      .afterClosed()
      .subscribe((clearFiles: string): void => {
        if (clearFiles === 'clearFileSelection') {
          if (fileType === SelectFileTypeEnum.FILES) {
            this.fileCount = 0;
            this.fileList = [];
            this.totalFileSize = 0;
          } else {
            this.supportingFileCount = 0;
            this.supportingFileList = [];
            this.totalSupportingFileSize = 0;
          }
          this.showFilePicker(fileType);
        }
      });
  }

  public showReplaceWarningOrFilePicker(fileType: SelectFileTypeEnum): void {
    switch (fileType) {
      case SelectFileTypeEnum.FILES:
        if (this.fileCount > 0) {
          this.showReplaceFilesWarning(fileType);
        } else {
          this.showFilePicker(fileType);
        }
        break;
      case SelectFileTypeEnum.SUPPORTING:
        if (this.supportingFileCount > 0) {
          this.showReplaceFilesWarning(fileType);
        } else {
          this.showFilePicker(fileType);
        }
        break;
    }
  }

  private getSupportedFileAndHandleErrorDisplay(
    files: MprFile[],
    fileType: string
  ): MprFile[] {
    let unsupportedFileCount = 0;
    const userSelectedUnsupportedTypes: string[] = [];
    const supportedFiles = files.filter((file) => {
      const fileExt = file.name.split('.').pop();
      if (
        fileExt &&
        this.unsupportedFileTypes.includes(fileExt.toUpperCase())
      ) {
        unsupportedFileCount++;
        const fileExtLowercase = fileExt.toUpperCase();
        if (!userSelectedUnsupportedTypes.includes(fileExtLowercase))
          userSelectedUnsupportedTypes.push(fileExtLowercase);
        return false;
      }
      // Excluding unsupported.
      if (fileType === SelectFileTypeEnum.FILES) {
        this.fileCount++;
        this.totalFileSize += file.size;
      } else {
        this.supportingFileCount++;
        this.totalSupportingFileSize += file.size;
      }

      return true;
    });

    // Only if user has selected unsupported files: below is just a "if" shortened
    if (unsupportedFileCount > 0)
      this.handleErrorDisplay(
        unsupportedFileCount,
        userSelectedUnsupportedTypes
      );
    return supportedFiles;
  }

  private handleErrorDisplay(
    unsupportedFileCount: number,
    userSelectedUnsupportedTypes: string[]
  ): void {
    const extFileText = `${userSelectedUnsupportedTypes.join(',')}`;
    this.alertMsgService.error({
      title: `The file format [${extFileText}] you are trying to upload is not supported.`,
      body: `${unsupportedFileCount} of your file(s) are in [${extFileText}] file format, so they cannot be uploaded.`,
      autoDismiss: false,
    });
  }

  private handleFileSelections(files: MprFile[], fileType: string): void {
    // Remove alerts if any
    this.alertMsgService.dismmissAll();

    if (
      Object.keys(this.selectedConnection).length !== 0 &&
      files &&
      files.length
    ) {
      if (fileType === SelectFileTypeEnum.FILES) {
        // Reduce the counters
        this.fileCount = 0;
        this.totalFileSize = 0;
        this.fileList = this.getSupportedFileAndHandleErrorDisplay(
          files,
          fileType
        );
      } else {
        // Reduce the counters
        this.supportingFileCount = 0;
        this.totalSupportingFileSize = 0;
        this.supportingFileList = this.getSupportedFileAndHandleErrorDisplay(
          files,
          fileType
        );
      }
    }
    this.onURLChange();
  }

  private hasDuplicateDocuments(): boolean {
    let hasDuplicateFile = false;
    switch (this.selectedConnection.dataStore) {
      case ConnectionDataStore.LOCAL:
        hasDuplicateFile = this.fileList.some((file) =>
          this.supportingFileList.find(
            (supportingFile) => supportingFile.name === file.name
          )
        );
        break;
      case ConnectionDataStore.BOX:
        hasDuplicateFile = this.fileList.some((file) =>
          this.supportingFileList.find(
            (supportingFile) => supportingFile.id === file.id
          )
        );
        break;
      case ConnectionDataStore.S3:
      case ConnectionDataStore.DATA_LIBRARY_S3:
        hasDuplicateFile = this.fileList.some((file) =>
          this.supportingFileList.find(
            (supportingFile) => supportingFile.pickerPath === file.pickerPath
          )
        );
        break;
      case ConnectionDataStore.DL_S3:
        hasDuplicateFile = this.fileList.some((file) =>
          this.supportingFileList.find(
            (supportingFile) => supportingFile.pickerPath === file.pickerPath
          )
        );
        break;
    }
    if (hasDuplicateFile) {
      this.alertMsgService.error({
        body: `Please remove all the duplicate files from your selection to proceed further.`,
      });
      return true;
    }
    return false;
  }

  private loadPremiseConnection(): void {
    this.connectionList.unshift(constants.DL_S3_CONNECTION);
    this.connectionList.unshift(constants.LOCAL_DRIVE_SOURCE);
  }

  private setSelectedFileToState(): void {
    const jobOperationType =
      this.fileList.length === 0 && this.supportingFileList.length === 0
        ? JobOperationTypeEnums.JOB_OPERATION_TYPE_URL_CATALOG
        : JobOperationTypeEnums.JOB_OPERATION_TYPE_UPLOAD_AND_CATALOG;
    // Put to state
    this.store.dispatch(
      new SelectFileForUploads(
        this.fileList,
        this.supportingFileList,
        this.jobId,
        this.datasetDetails.datasetName,
        this.urlArray,
        jobOperationType
      )
    );
  }

  private showPickerDialog(openerComponent: any, fileType: string): void {
    this.dialog
      .open(openerComponent, {
        data: {
          pickerType: 'file',
        },
      })
      .afterClosed()
      .subscribe((selectedItems) => {
        if (selectedItems)
          this.handleFileSelections(selectedItems, this.fileType);
      });
  }
}
