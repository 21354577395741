import { NgModule } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { SharedModule } from '@shared';
import { MatPaginatorIntlCro } from 'app/uploads/customPaginator';
import { OneTimeUploadStagingComponent } from './one-time-upload-staging/one-time-upload-staging.component';
import { StagingJobDetailsComponent } from './staging-job-details/staging-job-details.component';
import { StagingRoutingModule } from './staging-routing.module';
import { StagingUploadHistoryComponent } from './staging-upload-history/staging-upload-history.component';
import { StagingComponent } from './staging/staging.component';
@NgModule({
  imports: [SharedModule, StagingRoutingModule],
  declarations: [
    StagingComponent,
    OneTimeUploadStagingComponent,
    StagingUploadHistoryComponent,
    StagingJobDetailsComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlCro,
    },
  ],
})
export class StagingModule {}
