import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RoleLabelsService } from '@theme/services';
import { Observable, tap } from 'rxjs';
import { GetAllRoleLabels } from './role-labels.actions';
import {
  RoleLabelsMapModel,
  RoleLabelsModel,
  RoleLabelsStateModel,
} from './role-labels.model';

const stateDefaults: RoleLabelsStateModel = {
  roleLabels: null,
};

@State<RoleLabelsStateModel>({
  defaults: stateDefaults,
  name: 'RoleLabels',
})
@Injectable()
export class RoleLabelsState {
  constructor(private roleLabelsService: RoleLabelsService) {}

  @Action(GetAllRoleLabels)
  public GetAllRoleLabels({
    patchState,
  }: StateContext<RoleLabelsStateModel>): Observable<RoleLabelsModel[]> {
    return this.roleLabelsService.getAllRoleLabels().pipe(
      tap((roleLabels: RoleLabelsModel[]): any => {
        patchState({ roleLabels });
      })
    );
  }

  @Selector()
  public static getRoleLabelsArray(
    state: RoleLabelsStateModel
  ): Array<RoleLabelsModel> | null {
    return state.roleLabels;
  }

  @Selector()
  public static getRoleLabelsMapping(
    state: RoleLabelsStateModel
  ): RoleLabelsMapModel | null {
    if (!state.roleLabels) return null;

    const roleLabelsMap = state.roleLabels.reduce(
      (accumulator: RoleLabelsMapModel, currentValue) => {
        accumulator[currentValue?.roleName] = currentValue.roleLabel;
        return accumulator;
      },
      {}
    );

    return roleLabelsMap;
  }

  @Selector()
  public static isRoleLabelsDataLoaded(state: RoleLabelsStateModel): boolean {
    return (
      state.roleLabels !== null &&
      state.roleLabels &&
      state.roleLabels.length > 0
    );
  }
}
