/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class ChangeSFDefaultRole {
    public static type = '[ChangeSFRole] change SF role ';
    constructor(
      public projectId: string,
      public roleName: string
    ) {}
  }

  export class ResetSFRoleResponseState {
    public static type = '[ChangeSFRole] Reset to Default';
  }