import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { SkipMainContentService } from '@shared/services';
import { UserProject } from '@theme/interfaces';
import { UserProjectState } from 'app/state';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-staging',
  templateUrl: './staging.component.html',
  styleUrls: ['./staging.component.scss'],
})
export class StagingComponent implements OnInit {
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  public selectedProject!: UserProject;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('skipper') public skipper!: ElementRef;

  constructor(private skipMainContentService: SkipMainContentService) {}

  ngOnInit(): void {
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.focus();
    });
  }
}
