<div class="mpr-load-more-wrapper" *ngIf="show">
  <button
    class="mpr-link mpr-btn-transparency mpr-load-more"
    [class.mpr-disabled]="spinner"
    (click)="loadMore()"
    aria-label="Load more"
  >
    <span class="mpr-load-more-text">Load More</span>
  </button>
  <mat-spinner
    diameter="20"
    color="accent"
    [class]="spinner ? 'mpr-visibility-visible' : 'mpr-visibility-hidden'"
  ></mat-spinner>
</div>
