<ng-container
  *ngIf="{
    connections: (connectionsLoading$ | async),
    connection: (connectionLoading$ | async)
  } as loaders"
>
  <div class="mpr-onetime-upload-form">
    <!-- File upload section : Starts-->
    <div class="mpr-file-upload-section">
      <!-- dropdown and browse row : Starts-->
      <div
        class="mpr-dropdown-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>
          <div fxLayout="row">
            <span class="mpr-data-source-label"
              >Select Data Source<sup>*</sup></span
            >
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="dataSourePopover"
              [mprMtxTooltipAriaDescribedBy]="dataSourePopover"
              #tooltip="mtxTooltip"
              aria-label="Select Data Source Information"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>
          </div>

          <mat-form-field
            class="mpr-mat-select-field mpr-custom-view"
            floatLabel="never"
            appearance="outline"
            aria-label="select data source"
          >
            <mat-select
              disableOptionCentering
              #typeOfDataSource
              (selectionChange)="
                showConfirmOnChangeIfApplicable(typeOfDataSource.value)
              "
              panelClass="mpr-select-option-panel mpr-mat-arrow"
              [(value)]="defaultValue"
              placeholder="Select Data Source"
            >
              <mat-option
                *ngFor="let connection of connectionList"
                [value]="connection.connectionId"
              >
                {{ connection.connectionName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="flex-item m-l-12 m-r-12"
          fxFlex
          [fxLayout]="loaders.connection ? 'row-reverse' : 'row'"
          *ngIf="loaders.connection || loaders.connections"
        >
          <mat-spinner diameter="30" color="accent"></mat-spinner>
        </div>
      </div>
      <!-- dropdown and browse row : Ends-->

      <!-- messaging div : Starts -->
      <div fxLayout="row" fxLayoutAlign="space-between center ">
        <div>
          <span class="mpr-data-source-label">Add New Files</span>
          <div class="select-file-dropdrop-info">
            NOTE: These file formats are not supported: [{{
              unsupportedFileTypes.join(', ')
            }}] When ingesting zip files, you must make sure that they do not
            contain any of these unsupported file formats.
          </div>
        </div>
        <div>
          <label for="addFiles" class="file-input-native">Add New Files</label
          ><input
            id="addFiles"
            type="file"
            class="file-input-native"
            multiple
            (change)="getFiles($event)"
            #fileUpload
          />
          <button
            mat-stroked-button
            [disabled]="loaders.connection || typeOfDataSource.value === ''"
            class="mpr-button mpr-green-button"
            (click)="showReplaceWarningOrFilePicker(selectFileTypeEnum.FILES)"
          >
            Add File(S)
          </button>
        </div>
      </div>
      <!-- messaging div : Ends -->

      <!-- file table : Starts-->
      <div class="mpr-list-files">
        <div class="mpr-no-files-text" *ngIf="!fileList.length">
          No files are uploaded yet
        </div>
        <div class="mpr-file-list-table" *ngIf="fileList.length">
          <mpr-selected-file-table
            [dataSource]="fileList"
            (removeFiles)="removeFilesFromList($event, 'Files')"
          >
          </mpr-selected-file-table>
        </div>
      </div>
      <!-- file table : Ends-->

      <!-- messaging div : Starts -->
      <div fxLayout="row" fxLayoutAlign="space-between center ">
        <div>
          <span class="mpr-data-source-label">Add Supporting Documents</span>
          <div class="select-file-dropdrop-info">
            <b>This is an optional step.</b> Add supporting documents (example:
            codebooks, data layouts, dictionaries, readme files) that provide
            additional information about the data. <br />
            If you have a supporting document on a different data source, you
            can add it later through the Upload History view.
          </div>
        </div>
        <div>
          <label for="addSupportingDocuments" class="file-input-native"
            >Add Supporting Documents</label
          ><input
            id="addSupportingDocuments"
            type="file"
            class="file-input-native"
            multiple
            (change)="getFiles($event)"
            #fileUpload
          />
          <button
            mat-stroked-button
            [disabled]="loaders.connection || typeOfDataSource.value === ''"
            class="mpr-button mpr-green-button"
            (click)="
              showReplaceWarningOrFilePicker(selectFileTypeEnum.SUPPORTING)
            "
          >
            Add File(S)
          </button>
        </div>
      </div>
      <!-- messaging div : Ends -->

      <!-- file table : Starts-->
      <div class="mpr-list-files">
        <div class="mpr-no-files-text" *ngIf="!supportingFileList.length">
          No files are uploaded yet
        </div>
        <div class="mpr-file-list-table" *ngIf="supportingFileList.length">
          <mpr-selected-file-table
            [dataSource]="supportingFileList"
            (removeFiles)="removeFilesFromList($event, 'SupportingFiles')"
          ></mpr-selected-file-table>
        </div>
      </div>
      <!-- file table : Ends-->
    </div>
    <div class="select-file-stats" *ngIf="fileCount + supportingFileCount > 0">
      <div>Files Selected: {{ fileCount + supportingFileCount }}</div>
      <div>
        Total File Size:
        {{ totalFileSize + totalSupportingFileSize | fileSize }}
      </div>
    </div>
    <!-- File upload section : Ends-->
    <!-- URL Section :  Starts-->
    <div class="mpr-snowflake-url-section">
      <div>
        <div>
          <span class="mpr-data-source-label">DATA URL(S)</span>
          <div class="select-file-dropdrop-info">
            <b>This is an optional step.</b>
          </div>
          <div class="mpr-snowflake-url-wrapper">
            <ng-container *ngFor="let list of urlList; let index = index">
              <div class="mpr-snowflake-url-field-wrapper">
                <div class="mpr-url-input-field mpr-form-fields-row-items">
                  <div for="resourceURL" class="mpr-form-block-field-label"
                    >URL*</div
                  >
                  <mat-form-field
                    floatLabel="never"
                    class="mpr-mat-input-field mpr-width-percentage-100"
                    [hideRequiredMarker]="true"
                    appearance="outline"
                  >
                    <input
                      id="resourceURL"
                      class="mpr-metadata-form-block-field-input"
                      matInput
                      placeholder="Enter URL"
                      [(ngModel)]="list.url"
                      (keyup)="onURLChange()"
                      required
                      #url="ngModel"
                      aria-label="Resource URL"
                    />
                    <mat-error
                      *ngIf="
                        url.invalid &&
                        (url.dirty || url.touched) &&
                        url.errors?.['required']
                      "
                      class="mpr-error-padding-top-4px"
                      >Please enter URL
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="mpr-resource-details-wrapper d-flex">
                  <div
                    class="mpr-url-input-field mpr-form-fields-row-items mpr-resource-name"
                  >
                    <div for="resourceName" class="mpr-form-block-field-label"
                      >Resource Name*</div
                    >
                    <mat-form-field
                      floatLabel="never"
                      class="mpr-mat-input-field mpr-width-percentage-100"
                      [hideRequiredMarker]="true"
                      appearance="outline"
                    >
                      <input
                        id="resourceName"
                        class="mpr-form-block-field-input"
                        matInput
                        placeholder="Enter resource name"
                        [(ngModel)]="list.name"
                        (keyup)="onURLChange()"
                        required
                        maxlength="100"
                        #name="ngModel"
                        aria-label="Resource Name"
                      />
                      <mat-error
                        *ngIf="
                          name.invalid &&
                          (name.dirty || name.touched) &&
                          name.errors?.['required']
                        "
                        class="mpr-error-padding-top-4px"
                        >Please enter resource name
                      </mat-error>
                    </mat-form-field>
                    <div
                      class="mpr-form-block-field-wordcount"
                      [ngClass]="
                        list.name.length === 100 ? 'mpr-error-color' : ''
                      "
                    >
                      {{ list.name.length }}/100 characters
                    </div>
                  </div>
                  <div
                    class="mpr-url-input-field mpr-form-fields-row-items mpr-resource-format"
                  >
                    <div
                      for="resourceFormat"
                      class="mpr-form-block-field-label"
                    >
                      Resource Format*
                    </div>
                    <mat-form-field
                      floatLabel="never"
                      class="mpr-mat-select-field mpr-width-percentage-100"
                      [hideRequiredMarker]="true"
                      appearance="outline"
                    >
                      <mat-select
                        id="resourceFormat"
                        class="mpr-form-block-field-input"
                        matInput
                        placeholder="Choose Format Type"
                        [(ngModel)]="list.format"
                        (selectionChange)="onURLChange()"
                        required
                        #format="ngModel"
                        panelClass="mpr-select-option-panel mpr-mat-arrow"
                      >
                        <mat-option value="" disabled
                          >Choose Resource Format</mat-option
                        >
                        <ng-container *ngFor="let option of formatOptions">
                          <mat-option
                            *ngIf="
                              showSnowflakeOption ||
                              option.value !== 'Snowflake'
                            "
                            [value]="option.value"
                          >
                            {{ option.displayName }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          format.invalid &&
                          (format.dirty || format.touched) &&
                          format.errors?.['required']
                        "
                        class="mpr-error-padding-top-4px"
                        >Please choose resource format
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="mpr-url-remove">
                  <button
                    class="mpr-btn-transparency"
                    (click)="removeURL(index)"
                    aria-label="Delete URL"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </ng-container>
            <div class="mpr-add-url-button-row">
              <button
                (click)="addURLRow()"
                mat-stroked-button
                class="mpr-button mpr-green-button mpr-cancel-button"
                aria-label="Add URL Row"
              >
                Add URL(s)
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- URL Section :  Ends-->
    <!--cancel and preceed row: Starts-->
    <div [fxLayoutAlign]="urlArray.length > 0 ? 'space-between' : 'flex-end'">
      <div class="select-file-stats" *ngIf="urlArray.length > 0">
        <div>URL(s) Entered: {{ urlArray.length }}</div>
      </div>
      <div>
        <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
          <div>
            <button
              mat-stroked-button
              class="mpr-button mpr-blue-button mpr-cancel-button"
              (click)="navigateBack()"
            >
              Cancel
            </button>
          </div>
          <div
            *ngIf="
              jobId || datasetDetails.datasetName !== '';
              else proceedToMetaData
            "
          >
            <button
              [disabled]="
                (fileList.length === 0 &&
                  supportingFileList.length === 0 &&
                  !areAllURLSectionsValid) ||
                ((fileList.length !== 0 || supportingFileList.length !== 0) &&
                  !areAllURLSectionsValid)
              "
              mat-button
              class="mpr-button mpr-yellow-button"
              (click)="proceedFlow('addFiles')"
            >
              Upload
            </button>
          </div>
          <ng-template #proceedToMetaData>
            <button
              [disabled]="
                (fileList.length === 0 &&
                  supportingFileList.length === 0 &&
                  !areAllURLSectionsValid) ||
                ((fileList.length !== 0 || supportingFileList.length !== 0) &&
                  !areAllURLSectionsValid)
              "
              mat-button
              class="mpr-button mpr-yellow-button"
              (click)="proceedFlow()"
            >
              Proceed And Enter Metadata
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <!--cancel and preceed row: Ends-->
  </div>
</ng-container>

<ng-template #dataSourePopover>
  <div class="mpr-width-px-300" id="mpr-data-class">
    Select Data Source from where you want to upload the file.
  </div>
</ng-template>
