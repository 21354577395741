<div fxLayout="column" class="mpr-mat-dialog-confirm mpr-mat-dialog-small">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div
    mat-dialog-content
    [innerHTML]="data.contents"
    class="mpr-margin-buttom"
  ></div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
    <div>
      <button
        mat-button
        mat-dialog-close
        class="mpr-button mpr-cancel-button"
        id="mpr-cancel"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        mat-button
        class="mpr-button"
        [ngClass]="data.isFile ? 'mpr-cancel-button' : 'mpr-yellow-button'"
        id="mpr-cancel"
        (click)="replace(true)"
      >
        Keep a Copy
      </button>
    </div>
    <div *ngIf="data.isFile">
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        id="mpr-confirm"
        (click)="replace(false, 'replace')"
      >
        Replace
      </button>
    </div>
  </div>
</div>
