import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared';
import { AddRestrictedGroupComponent } from './add-restricted-group/add-restricted-group.component';
import { AllProjectsComponent } from './all-projects/all-projects.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { CreateMappingComponent } from './create-mapping/create-mapping.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { PlatformAdminRoutingModule } from './platform-admin-routing.module';
import { PlatformAdminUsersComponent } from './platform-admin-users/platform-admin-users.component';
import { PlatformAdminComponent } from './platform-admin/platform-admin.component';
import { PlatformUsersComponent } from './platform-users/platform-users.component';
import { ProjectSurveyComponent } from './project-survey/project-survey.component';
import { ProjectViewComponent } from './project-view/project-view.component';

@NgModule({
  imports: [SharedModule, PlatformAdminRoutingModule, FormsModule],
  declarations: [
    ProjectViewComponent,
    PlatformAdminComponent,
    PlatformAdminUsersComponent,
    PlatformUsersComponent,
    AllUsersComponent,
    ManageUsersComponent,
    AllProjectsComponent,
    CreateProjectComponent,
    EditProjectComponent,
    AddRestrictedGroupComponent,
    ProjectSurveyComponent,
    CreateMappingComponent,
  ],
})
export class PlatformAdminModule {}
