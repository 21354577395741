import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserProjectRoleEnum } from '@core/enums';
import { Select } from '@ngxs/store';
import { TileConfig, UserProject } from '@theme/interfaces';
import { UserProjectState } from 'app/state';
import { Observable } from 'rxjs';
import { SkipMainContentService } from '@shared/services';


@Component({
  selector: 'mpr-upload-option',
  templateUrl: './upload-option.component.html',
  styleUrls: ['./upload-option.component.scss'],
})
export class UploadOptionComponent implements OnInit {
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  public selectedProject!: UserProject;
  public tileConfigs: TileConfig[] = [
    {
      img: 'assets/images/Search_Data_Library.svg',
      title: 'ONE TIME UPLOAD',
      tag: '',
      desc: 'Use this option for one time data ingestion along with metadata and supporting documents (code books, data dictionaries, layouts and README files).',
      roleSetting: [
        UserProjectRoleEnum.RESEARCHER,
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER,
        UserProjectRoleEnum.EXTERNAL_RESEARCHER
      ],
      isVisible: true,
      isDisabled: false,
      path: '/uploads/onetime',
    },
    {
      img: 'assets/images/Upload_File.svg',
      title: 'MANAGE RECURRING SCHEDULES',
      tag: '',
      desc: 'Use this option to schedule recurring data file transfers from external data sources such as AWS S3, Box.com.',
      roleSetting: [
        UserProjectRoleEnum.DEVELOPER,
        UserProjectRoleEnum.ADMIN,
        UserProjectRoleEnum.EXTERNAL_DEVELOPER
        ],
      isVisible: true,
      isDisabled: false,
      path: '/uploads/schedule',
    },
  ];
  
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('skipper') public skipper!: ElementRef;

  constructor(
    private skipMainContentService: SkipMainContentService, 
  ) {}

  ngOnInit(): void {
    this.project$?.subscribe((project: UserProject) => {
      this.selectedProject = project;
      if (this.selectedProject.roleName !== '') this.setRoleVisibility();
    });
    this.skipMainContentService.skip$.subscribe((res) =>{
      this.skipper.nativeElement.focus();
    })
  }
  private setRoleVisibility(): void {
    this.tileConfigs = this.tileConfigs.map((tile: TileConfig) => ({
      ...tile,
      isVisible: tile.roleSetting.includes(this.selectedProject.roleName),
    }));
  }
}
