<div *ngIf="!showSpinner; else showLoader">
  <div class="mpr-projects-header mb-10">
    <div class="mpr-list">List of Projects ({{ projectCount$ | async }})</div>
    <span class="mpr-list-container">
      <span #skipper>
        <mpr-export-to-csv
          [spinner]="exportButtonSpinner"
          (exportMethod)="exportToCSV()"
        ></mpr-export-to-csv>
      </span>
      <button
        class="mpr-action-button btn-lg"
        (click)="navigateToRoute('/platformAdmin/projects/create')"
      >
        Create New Project
      </button>
    </span>
  </div>

  <div class="mpr-tiles-container d-flex mpr-platform-admin-width">
    <a
      tabindex="0"
      *ngFor="let project of projectList; index as i"
      (keydown.enter)="
        navigateToRoute('/platformAdmin/projects/view/' + project.projectId)
      "
    >
      <div
        class="mpr-tiles"
        (click)="
          navigateToRoute('/platformAdmin/projects/view/' + project.projectId)
        "
      >
        <div class="mpr-tiles-title">
          <div title="{{ project.projectId }}">
            {{
              project.projectId.length > 20
                ? (project.projectId | slice: 0:25) + '...'
                : project.projectId
            }}
          </div>
          <div class="mpr-tiles-date">
            Onboarded On: {{ project.createdDate | utcDate: 'MM/dd/yyyy' }}
          </div>
        </div>
        <div class="mpr-tiles-summary">
          <div class="mpr-tiles-dataset-count">
            {{
              project.datasetCount > 0
                ? ('0' + project.datasetCount).slice(-2)
                : project.datasetCount
            }}
            Published Datasets
          </div>
          <span class="mpr-divider-line">|</span>
          <div class="mpr-tiles-users-count">
            {{
              project.userCount > 0
                ? ('0' + project.userCount).slice(-2)
                : project.userCount
            }}
            Users
          </div>
        </div>
        <div class="mpr-tiles-desc">
          {{ project.projectName }}
        </div>
        <div class="mpr-tiles-footer">
          <span class="mpr-tiles-tag blue" *ngIf="project.dwAccess">
            Data Warehouse Access
          </span>
          <span class="mpr-tiles-tag green" *ngIf="project.hasPowerUsers">
            Heavy Lifting
          </span>
          <span class="mpr-tiles-tag yellow" *ngIf="project.externalProjectAccountsEnabled">
            Powered by Mquiry
          </span>
          <span class="mpr-tiles-tag lblue" *ngIf="project.rdsEnabled">
            Relational Database Service
          </span>
        </div>
      </div>
    </a>
  </div>
  <div
    class="mpr-load-more-wrapper m-t-8"
    *ngIf="projectCount$ | async as count"
  >
    <mpr-load-more
      [spinner]="showLoadMoreSpinner"
      [show]="projectList.length < count"
      (loadMoreMethod)="loadMoreProjects()"
    >
    </mpr-load-more>
  </div>
</div>

<ng-template #showLoader>
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</ng-template>
