<div class="mpr-tiles-container d-flex">
  <div
    class="mpr-dashboard-loader"
    *ngIf="showSpinner$ | async; else showTiles"
  >
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>

  <ng-template #showTiles>
    <ng-container *ngFor="let tile of tileConfigs">
      <a
        *ngIf="tile.isVisible"
        (click)="
          navigateToRoute(tile.path, tile.externalUrl, tile.target, tile.title)
        "
        (keydown.enter)="
          navigateToRoute(tile.path, tile.externalUrl, tile.target, tile.title)
        "
        #skipper
        tabindex="0"
      >
        <div class="mpr-tiles">
          <div class="mpr-tiles-img">
            <img [src]="tile.img" alt="">
          </div>
          <div class="mpr-tiles-title">
            {{ tile.title }}
            <span class="mpr-tiles-tag" *ngIf="tile.tag !== ''">{{
              tile.tag
            }}</span>
          </div>
          <div class="mpr-tiles-desc">{{ tile.desc }}</div>
        </div>
      </a>
      <!-- It will be use in future  -->
      <!-- <a
      href="{{ endPointUrl }}"
      target="_blank"
      *ngIf="tile.isVisible && tile.title === 'Search data Library'"
      ><div class="mpr-tiles">
        <div class="mpr-tiles-img">
          <img [src]="tile.img" [alt]="tile.title" role="img"/>
        </div>
        <div class="mpr-tiles-title">
          {{ tile.title }}
          <span class="mpr-tiles-tag" *ngIf="tile.tag !== ''">{{
            tile.tag
          }}</span>
        </div>
        <div class="mpr-tiles-desc">{{ tile.desc }}</div>
      </div></a
    > -->
    </ng-container>
  </ng-template>
</div>
