/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { GenericStatus } from '@core';
import { EditScheduleFormModel } from '@shared/interfaces';
import { UploadsMetadataState } from '../uploads-metadata-state';
import { ScheduleFormModel } from './schedule-form.model';

export class DeleteSchedule {
  public static type = '[Schedule] Delete ';
  constructor(public scheduleId: string) {}
}

export class EnableDisableSchedule {
  public static type = '[Schedule] Enable Disable ';
  constructor(public scheduleId: string, public status: GenericStatus) {}
}

export class GetAllSchedule {
  public static type = '[ScheduleState] Get All Schedule';
  constructor(public scheduleType: string) {}
}

export class SetScheduleFormData {
  public static type = '[ScheduleState] Set Schedule Form Data';

  constructor(public scheduleFormData: ScheduleFormModel) {}
}
export class ResetScheduleState {
  public static type = '[ScheduleState] Reset to Default';
}
export class SetScheduleMetaData {
  public static type = '[ScheduleState] Set File Metadata';

  constructor(public fileMetadata: UploadsMetadataState) {}
}

export class EditSchedule {
  public static type = '[Schedule] Update';
  constructor(public scheduleId: string, public scheduleUpdateDetails: EditScheduleFormModel){}
}
