import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { FailedLoginScenarios } from '@core/enums';
import { FeatureFlagsState, LoggedinUserState } from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Select(FeatureFlagsState.returnFeatureFlags)
  public featureFlags$!: Observable<FeatureFlagsStateModel>;
  public contactUs = '';
  public externalTermsOfUseURL = environment.externalTermsOfUsePageURL;
  public isFooterLinksHided = false;
  public loggedinUserEmailDomain = '';
  public termsOfUse = '';
  public termsOfUseUrl = environment.termsOfUsePageURL;
  constructor(private route: ActivatedRoute, public store: Store, public router: Router) { }

  public getFullYear(): number {
    const d = new Date();
    const year = d.getFullYear();
    return year;
  }

  ngOnInit(): void {
    if (this.router.url.includes('failed-login')) {
      this.route.queryParams.subscribe((params: any) => {
        if (params.msg !== FailedLoginScenarios.LOGIN_FAILED_TECHNICAL ) {
          this.isFooterLinksHided = true;
        }
        this.contactUs = params.contact_us ? params.contact_us : this.store.selectSnapshot(FeatureFlagsState.returnFeatureFlags).externalContactUsUrl;
        this.termsOfUse = params.is_external === 'True' ? this.externalTermsOfUseURL : this.termsOfUseUrl;
      });
    } else {
      this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
        this.loggedinUserEmailDomain = this.store.selectSnapshot(
          LoggedinUserState.getLoggedInUserEmail
        ).split('@')[1];
        const isLoggedInUserInternal = featureFlags.internalDomainList.findIndex((x) => x === this.loggedinUserEmailDomain) > -1;
        this.contactUs = isLoggedInUserInternal ? featureFlags.internalContactUsUrl : featureFlags.externalContactUsUrl;
        this.termsOfUse = isLoggedInUserInternal ? this.termsOfUseUrl : this.externalTermsOfUseURL;
      });
    }
  }
}