import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import ProjectModel from 'app/state/project/project-state-model';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProjectMetadataService {
  constructor(private http: HttpClient) {}

  public getProjectMetadata(
    optionalHeaders?: MprHttpHeaderModal,
    getProjectAdminEmailList? : string
  ): Observable<ProjectModel> {
    let requestUrl = '/projects/metadata';
    if(getProjectAdminEmailList){
      requestUrl = `${requestUrl}${getProjectAdminEmailList}`
    }
    if (optionalHeaders && Object.keys(optionalHeaders).length > 0) {
      const headers = this.getRequestHeaders(optionalHeaders);
      return this.http.get<ProjectModel>(requestUrl, { headers });
    }
    return this.http.get<ProjectModel>(requestUrl);
  }
  private getRequestHeaders(headers: MprHttpHeaderModal): HttpHeaders {
    const requestHeaders = new HttpHeaders()
      .set(HeaderParams.PROJECTID, headers[HeaderParams.PROJECTID])
      .append(HeaderParams.ROLENAME, headers[HeaderParams.ROLENAME]);
    return requestHeaders;
  }
}
