<div class="mpr-tabs-container" #skipper>
  <mat-tab-group
    animationDuration="0ms"
    class="workspace-wrapper-container"
    [selectedIndex]="selectedTabIndex"
    (selectedTabChange)="selectedTabChange($event)"
  >
    <mat-tab label="PROJECT USERS" id="projectUsers"
      ><app-all-users></app-all-users
    ></mat-tab>
    <mat-tab label="PLATFORM ADMINS" id="platformAdmins"
      ><app-platform-admin-users></app-platform-admin-users
    ></mat-tab>
  </mat-tab-group>
</div>
