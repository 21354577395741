<ng-container *ngIf="!showLoader; else loaderConatiner">
  <div fxLayout="column" class="mpr-mat-dialog-small">
    <div>
      <div
        fxLayout="row"
        fxLayoutGap="30px"
        *ngIf="selectedUploadType === uploadTypeEnum.RECURRING"
      >
        <div
          class="mpr-job-details m-t-4"
          *ngFor="let detail of detailsToDisplay.recurring"
        >
          <ng-container [ngSwitch]="detail">
            <ng-container *ngSwitchCase="'scheduleName'"
              ><div class="mpr-form-block-field-label text-label">
                Schedule Name
              </div>
              <span>{{ recurringJobDetails.scheduleName }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'dataStore'"
              ><div class="mpr-form-block-field-label text-label">
                Data Source
              </div>
              {{ recurringJobDetails.dataStore | appDetails }}
            </ng-container>
            <ng-container *ngSwitchCase="'uploadType'"
              ><div class="mpr-form-block-field-label text-label">
                Upload Type
              </div>
              <span>Recurring</span>
            </ng-container>
            <ng-container *ngSwitchCase="'totalNoOfFiles'"
              ><div class="mpr-form-block-field-label text-label">
                # Of Files
              </div>
              <span>{{ recurringJobDetails.totalNoOfFiles }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'runDate'"
              ><div class="mpr-form-block-field-label text-label">
                Date Uploaded
              </div>
              <span>{{ recurringJobDetails.runDate | utcDate }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'description'"
              ><div class="mpr-form-block-field-label text-label">
                Dataset Name
              </div>
              <span>{{ recurringJobDetails.description }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'jobStatus'">
              <div class="mpr-form-block-field-label text-label">
                Upload Status
              </div>
              <ng-container
                [ngTemplateOutlet]="jobStatusTemplate"
                [ngTemplateOutletContext]="{ $implicit: jobStatus }"
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="30px"
        *ngIf="selectedUploadType === uploadTypeEnum.ONETIME"
      >
        <div
          class="mpr-job-details m-t-4"
          *ngFor="let detail of detailsToDisplay.onetime"
        >
          <ng-container [ngSwitch]="detail">
            <ng-container *ngSwitchCase="'userName'"
              ><div class="mpr-form-block-field-label text-label">
                Uploaded By
              </div>
              <span>{{ oneTimeJobDetails.userName }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'dataStore'"
              ><div class="mpr-form-block-field-label text-label">
                Data Source
              </div>
              <ng-container *ngIf="oneTimeJobDetails.displaySource">
                <ng-container
                  *ngFor="let source of oneTimeJobDetails.displaySource"
                >
                  <ng-container
                    [ngSwitch]="source"
                    class="data-source-container"
                  >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.BOX"
                      class="source-value"
                      >Box.com</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.LOCAL"
                      class="source-value"
                      >Local Drive</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.S3"
                      class="source-value"
                      >External S3</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.DATA_LIBRARY_S3"
                      class="source-value"
                      >{{ 'internalS3' | appDetails }}</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.DL_S3"
                      class="source-value"
                      >{{ 'internalS3' | appDetails }}</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.SNOWFLAKE"
                      class="source-value"
                      >Snowflake</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.CHART"
                      class="source-value"
                      >Chart</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.WEB_URL"
                      class="source-value"
                      >Web URL</span
                    >
                    <span
                      *ngSwitchCase="connectionDataStoreEnum.API"
                      class="source-value"
                      >API</span
                    >
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container
                *ngIf="
                  !oneTimeJobDetails.displaySource ||
                  oneTimeJobDetails.displaySource.length === 0
                "
              >
                <ng-container [ngSwitch]="oneTimeJobDetails.dataStore">
                  <span *ngSwitchCase="connectionDataStoreEnum.BOX"
                    >Box.com</span
                  >
                  <span *ngSwitchCase="connectionDataStoreEnum.LOCAL"
                    >Local Drive</span
                  >
                  <span *ngSwitchCase="connectionDataStoreEnum.S3"
                    >External S3</span
                  >
                  <span *ngSwitchCase="connectionDataStoreEnum.DATA_LIBRARY_S3">
                    {{ 'internalS3' | appDetails }}</span
                  >
                  <span *ngSwitchCase="connectionDataStoreEnum.DL_S3">
                    {{ 'internalS3' | appDetails }}</span
                  >
                  <span *ngSwitchCase="connectionDataStoreEnum.SNOWFLAKE"
                    >Snowflake</span
                  >
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'uploadType'"
              ><div class="mpr-form-block-field-label text-label">
                Upload Type
              </div>
              <span>One Time</span>
            </ng-container>
            <ng-container *ngSwitchCase="'totalNoOfFiles'"
              ><div class="mpr-form-block-field-label text-label">
                # Of Files
              </div>
              <span>{{ oneTimeJobDetails.totalNoOfFiles }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'uploadDate'"
              ><div class="mpr-form-block-field-label text-label">
                Date Uploaded
              </div>
              <span>{{ oneTimeJobDetails.uploadDate | utcDate }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'description'"
              ><div class="mpr-form-block-field-label text-label">
                Dataset Name
              </div>
              <span>{{ oneTimeJobDetails.description }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'jobStatus'">
              <div class="mpr-form-block-field-label text-label">
                Upload Status
              </div>
              <ng-container
                [ngTemplateOutlet]="jobStatusTemplate"
                [ngTemplateOutletContext]="{ $implicit: jobStatus }"
              >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex add-details">
    <h1>File Details</h1>
    <div class="btn-left">
      <button
        #skipper
        mat-stroked-button
        mat-dialog-close
        class="mpr-button mpr-blue-button mpr-cancel-button"
        *ngIf="isCompletedJob(jobStatus)"
        (click)="checkAndAddFiles()"
      >
        Add File(s)
      </button>
    </div>
  </div>
  <div class="d-flex mpr-file-details-wrapper">
    <div
      class="mpr-metadata-link-row"
      *ngIf="
        isCompletedJob(jobStatus) &&
        (recurringJobDetails.datasetName || oneTimeJobDetails.datasetName)
      "
    >
      <span class="mpr-metadata-link-title">DATASET LOCATION : </span
      ><a class="mpr-metadata-link" [href]="generateCkanURL()">
        {{ generateCkanURL() }}</a
      >
    </div>
    <div
      class="mpr-file-count-zero-job"
      *ngIf="isCompletedJob(jobStatus) && isTotalNoOfFilesZero"
    >
      <div class="mpr-noresult-container">
        No files were available for upload when this job was scheduled to run.
      </div>
    </div>
    <div
      class="mpr-completed-job mpr-files-listing-row m-x-16 m-t-16"
      fxLayoutGap="30px"
      *ngIf="
        (isCompletedJob(jobStatus) && !isTotalNoOfFilesZero) ||
        (!showFilesGridOrError(jobStatus, selectedUploadType) &&
          isFailedJob(jobStatus))
      "
    >
      <div class="mpr-vertical-stack-wrapper" *ngIf="chartData.length > 0">
        <div
          class="mpr-table-title mpr-skipped-files-legend"
          *ngIf="skippedFiles.length !== 0"
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.SKIPPED_FILES }} :
          {{
            recurringJobDetails.noOfSkippedFiles ||
              oneTimeJobDetails.noOfSkippedFiles
          }}
        </div>
        <div
          class="mpr-table-title mpr-quarantine-files-legend"
          *ngIf="quarantinedFiles.length !== 0"
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.QUARANTINED_FILES }} :
          {{
            recurringJobDetails.noOfQuarantinedFiles ||
              oneTimeJobDetails.noOfQuarantinedFiles
          }}
        </div>
        <div
          class="mpr-table-title mpr-ready-files-legend"
          *ngIf="filesReadyTableDataSource.length !== 0"
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.FILES_READY_FOR_SEARCH }} :
          {{
            recurringJobDetails.noOfFilesReadyForSearch ||
              oneTimeJobDetails.noOfFilesReadyForSearch
          }}
        </div>
        <div
          class="mpr-table-title mpr-snowflake-urls-legend"
          *ngIf="resourceNames.length !== 0"
        >
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.URLS }} :
          {{ resourceNames.length }}
        </div>
      </div>
      <div class="mpr-skipped-files-wrapper" *ngIf="skippedFiles.length !== 0">
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.SKIPPED_FILES }}
          <button
            class="mpr-btn-transparency"
            [mtxTooltip]="skippedFilesInfo"
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="skippedFilesInfo"
            aria-label="Skipped Files Information"
          >
            <mat-icon class="mpr-info-icon">info </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="skippedFiles"
          class="skipped-files-table mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef scope="col">
              File Name
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
              <span class="mpr-ellipsis" matTooltip="{{ file.fileName }}">
                {{ file.fileName }}</span
              >
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="row"
            *matHeaderRowDef="skippedFilesColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: skippedFilesColumns"></mat-row>
        </mat-table>
        <div class="mpr-table-note-text" *ngIf="showNote('skippedFiles')">
          NOTE: Showing 30 of
          {{
            recurringJobDetails.noOfSkippedFiles ||
              oneTimeJobDetails.noOfSkippedFiles
          }}
          files. To view remaining files, refer to file upload email
          notification sent to user who uploaded the files.
        </div>
      </div>
      <div
        class="mpr-quarantine-files-wrapper"
        *ngIf="quarantinedFiles.length !== 0"
      >
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.QUARANTINED_FILES }}
          <button
            class="mpr-btn-transparency"
            [mtxTooltip]="quarantineFilesInfo"
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="quarantineFilesInfo"
            aria-label="Quarantine Files Information"
          >
            <mat-icon class="mpr-info-icon">info </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="quarantinedFiles"
          class="mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef scope="col">
              File Name
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
              <span class="mpr-ellipsis" matTooltip="{{ file.fileName }}">
                {{ file.fileName }}</span
              ><button
                *ngIf="isQuarantineSupportingDoc(file.s3Location)"
                class="mpr-support-doc-indicator"
              >
                <img
                  src="assets/images/Supporting_Document.svg"
                  alt="Supporting Document"
                  class="img-margin"
                  matTooltip="Supporting Documents"
                />
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="row"
            *matHeaderRowDef="quarantineFilesColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: quarantineFilesColumns"
          ></mat-row>
        </mat-table>
        <div class="mpr-table-note-text" *ngIf="showNote('quarantinedFiles')">
          NOTE: Showing 30 of
          {{
            recurringJobDetails.noOfQuarantinedFiles ||
              oneTimeJobDetails.noOfQuarantinedFiles
          }}
          files. To view remaining files, refer to file upload email
          notification sent to user who uploaded the files.
        </div>
      </div>
      <div
        class="mpr-ready-files-wrapper"
        *ngIf="filesReadyTableDataSource.length !== 0"
      >
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span
          >{{ commonConstants.FILES_READY_FOR_SEARCH }}
          <button
            class="mpr-btn-transparency"
            [mtxTooltip]="readyFilesInfo"
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="readyFilesInfo"
            aria-label="Ready Files Information"
          >
            <mat-icon class="mpr-info-icon">info </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="filesReadyTableDataSource"
          class="ready-files-table mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef scope="col">
              File Name
            </mat-header-cell>
            <mat-cell *matCellDef="let readyFile">
              <span class="mpr-ellipsis" matTooltip="{{ readyFile.fileName }}">
                {{ readyFile.fileName }}</span
              ><button
                *ngIf="isReadyFileSupportingDoc(readyFile.s3Location)"
                class="mpr-support-doc-indicator"
              >
                <img
                  src="assets/images/Supporting_Document.svg"
                  alt="Supporting Document"
                  class="img-margin"
                  matTooltip="Supporting Document"
                />
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="row"
            *matHeaderRowDef="readyFilesColumns"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: readyFilesColumns"></mat-row>
        </mat-table>
        <div
          class="mpr-table-note-text"
          *ngIf="showNote('filesReadyForSearch')"
        >
          NOTE: Showing 30 of
          {{
            recurringJobDetails.noOfFilesReadyForSearch ||
              oneTimeJobDetails.noOfFilesReadyForSearch
          }}
          files. To view remaining files,
          <button
            class="mpr-metadata-link mpr-btn-transparency"
            (click)="redirectToCkan()"
          >
            click here
          </button>
        </div>
      </div>
      <div
        class="mpr-snowflake-urls-wrapper"
        *ngIf="resourceNames.length !== 0"
      >
        <div class="mpr-table-title">
          <span class="mpr-color-square">&nbsp;</span>{{ commonConstants.URLS }}
          <button
            class="mpr-btn-transparency"
            [mtxTooltip]="snowflakeUrlsInfo"
            #tooltip="mtxTooltip"
            [mprMtxTooltipAriaDescribedBy]="snowflakeUrlsInfo"
            aria-label="Snowflake Urls Information"
          >
            <mat-icon class="mpr-info-icon">info </mat-icon>
          </button>
        </div>
        <mat-table
          [dataSource]="resourceNames"
          class="snowflake-urls-table mpr-tables scrollbar-thin"
          role="table"
          aria-rowcount="-1"
        >
          <ng-container matColumnDef="filename">
            <mat-header-cell *matHeaderCellDef scope="col">
              Resource Name
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
              <span class="mpr-ellipsis" matTooltip="{{ file }}">
                {{ file }}</span
              >
            </mat-cell>
          </ng-container>
          <mat-header-row
            role="row"
            *matHeaderRowDef="snowflakeUrlsColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: snowflakeUrlsColumns"
          ></mat-row>
        </mat-table>
        <div class="mpr-table-note-text" *ngIf="showNote('snowflakeUrls')">
          URL(S): Showing 30 of
          {{ resourceNames.length }}
          URL(S).To view remaining resources, click the VIEW LATEST METADATA
          link above.
        </div>
      </div>
    </div>
    <div class="mpr-inprogress-job" *ngIf="isInProgressJob(jobStatus)">
      <div class="mpr-inprogress-step-wrapper">
        <div class="mpr-custom-step-header">
          <div class="mpr-custom-step-number">
            <div class="mpr-custom-step-content">1</div>
          </div>
          <div class="mpr-custom-step-label">
            <div class="mpr-custom-step-text-label">Upload Files</div>
            <ng-container
              [ngTemplateOutlet]="mprStepper"
              [ngTemplateOutletContext]="{ $implicit: 1 }"
            ></ng-container>
          </div>
        </div>
        <div class="mpr-custom-step-horizontal-line"></div>
        <div class="mpr-custom-step-header">
          <div class="mpr-custom-step-number">
            <div class="mpr-custom-step-content">2</div>
          </div>
          <div class="mpr-custom-step-label">
            <div class="mpr-custom-step-text-label">Cataloging</div>
            <ng-container
              [ngTemplateOutlet]="mprStepper"
              [ngTemplateOutletContext]="{ $implicit: 2 }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mpr-error-job"
      *ngIf="
        showFilesGridOrError(jobStatus, selectedUploadType) &&
        isFailedJob(jobStatus)
      "
    >
      <div class="mpr-noresult-container">
        <div>
          <div class="mpr-failed-status-error-wrapper">
            <img src="assets/images/Error.svg" alt="" /><span
              class="mpr-failed-status-error-text"
              >Error</span
            >
          </div>
          <div class="mpr-note-dashed-divider"></div>
          <div class="mpr-failed-status-reason-label">Reason For Error</div>
          <div class="mpr-failed-status-reason">
            File upload failed. Please try again or contact
            <a class="mpr-service-now-link" href="{{ contactUs }}"
              >“{{ 'appName' | appDetails }} – Support”</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loaderConatiner>
  <div class="mpr-content-loader">
    <h1 class="mpr-content-loader-text">Retrieving Details. Please Wait...</h1>
  </div>
</ng-template>
<ng-template #skippedFilesInfo>
  <div class="mpr-width-px-300" id="mpr-skipped-class">
    These files are skipped, because they are not a supported format. These file
    extension(s) [{{ unsupportedFileTypes.join(',') }}] are not supported by
    {{ 'appName' | appDetails }}.
  </div>
</ng-template>
<ng-template #quarantineFilesInfo>
  <div class="mpr-width-px-300" id="mpr-quarantine-class">
    The data file scan detected the presence of PII/PHI data. Please re-upload
    the data files and select the correct PII/PHI metadata attribute indicator.
  </div>
</ng-template>
<ng-template #readyFilesInfo>
  <div class="mpr-width-px-300" id="mpr-ready-class">
    These files are uploaded and available to access from the workspace and
    ready for search in the data catalog.
  </div>
</ng-template>
<ng-template #snowflakeUrlsInfo>
  <div class="mpr-width-px-300" id="mpr-snowflake-class">
    These URL(S) are uploaded and are accessible from the workspace accordingly.
  </div>
</ng-template>

<ng-template #jobStatusTemplate let-jobStatus>
  <ng-container [ngSwitch]="jobStatus">
    <span *ngSwitchCase="uploadStatusesEnum.COMPLETED">
      <mat-icon><img src="assets/images/completed.svg" alt="" /></mat-icon
      >Completed
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.COMPLETED_WITH_ERRORS">
      <mat-icon><img src="assets/images/completed.svg" alt="" /></mat-icon
      >Completed with errors
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.UPLOAD_FAILED">
      <mat-icon><img src="assets/images/red-dot-error.svg" alt="" /></mat-icon
      >Error
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.SCAN_FAILED">
      <mat-icon><img src="assets/images/red-dot-error.svg" alt="" /></mat-icon
      >Error
    </span>
    <span *ngSwitchCase="uploadStatusesEnum.CATALOGING_FAILED">
      <mat-icon><img src="assets/images/red-dot-error.svg" alt="" /></mat-icon
      >Error
    </span>
    <span *ngSwitchDefault>
      <mat-icon><img src="assets/images/in-progress.svg" alt="" /></mat-icon
      >In-Progress
    </span>
  </ng-container>
</ng-template>

<ng-template #mprStepper let-mprStepperValue>
  <div class="mpr-custom-step-text-status">
    <mat-icon
      *ngIf="
        getStepStatus(mprStepperValue, jobStatus) === stepValueEnum.INPROGRESS
      "
      ><img src="assets/images/in-progress.svg" alt=""
    /></mat-icon>
    <mat-icon
      *ngIf="
        getStepStatus(mprStepperValue, jobStatus) === stepValueEnum.PENDING
      "
      ><img src="assets/images/hourglass-svgrepo-com.svg" alt=""
    /></mat-icon>
    <mat-icon
      *ngIf="
        getStepStatus(mprStepperValue, jobStatus) === stepValueEnum.COMPLETED
      "
      ><img src="assets/images/green-tick.svg" alt=""
    /></mat-icon>
    {{ getStepStatus(mprStepperValue, jobStatus) }}
  </div>
</ng-template>
<ng-template #datasetDeletedDialog>
  <div class="mpr-mat-dialog-container mpr-mat-dialog-small container-size">
    <div class="mpr-delete-dataset-close" (click)="closeDeletedDatasetDialog()">
      <img src="assets/images/cancel-popup.svg" alt="" />
    </div>
    <h1 mat-dialog-title class="mpr-delete-dataset-leftmargin">
      Dataset Unavailable
    </h1>
    <div mat-dialog-content fxLayout="row">
      <div class="mpr-delete-dataset-leftmargin">
        <p>
          This dataset is no longer available and files cannot be added to it.
        </p>
        <p
          *ngIf="
            this.selectedDatasetResponse.actionType === 'deleted';
            else purged
          "
        >
          It was removed by
          <b>{{ this.selectedDatasetResponse.userName }}</b> on
          <b>{{ this.selectedDatasetResponse.actionDate! | utcDate }}</b>
        </p>
        <ng-template #purged>
          <p>
            It was purged on
            <b>{{ this.selectedDatasetResponse.actionDate! | utcDate }}</b>
          </p>
        </ng-template>
      </div>
      <br />
    </div>
  </div>
</ng-template>
