import { Component } from '@angular/core';
import { SettingsService } from '@core';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
})
export class BrandingComponent {
  public brandName = '';
  constructor(settings: SettingsService) {
    this.brandName = settings.brandName;
  }
  
}
