<div
  fxLayout="row"
  fxLayoutAlign="flex-start center"
  class="dashboard-sub-header"
>
  <mat-label
    for="YOUR PROJECTS"
    class="dashboard-sub-header-project-title"
    role="none"
    >YOUR PROJECTS</mat-label
  >

  <div
    class="dashboard-sub-header-project-items dashboard-sub-header-project-select"
  >
    <mat-form-field appearance="outline">
      <mat-select
        panelClass="dashboard-sub-header-panel"
        [(value)]="defaultValue"
        disableOptionCentering
        data-test="dashboard-sub-header-project-select"
        #projectSelect
        (selectionChange)="changeProject(projectSelect.value)"
      >
        <mat-select-trigger>
          <div
            class="dashboard-sub-header-mat-option"
            [matTooltip]="
              isSelectProjectValue
                ? projectSelect.value.projectNumber
                : (projectSelect.value | projectShortName) +
                  ' - ' +
                  (projectSelect.value.roleName | replaceRoleName)
            "
            matTooltipClass="dashboard-subheader-project-name-tooltip"
          >
            <span
              class="dashboard-sub-header-project-name"
              [ngClass]="
                isSelectProjectValue
                  ? 'dashboard-sub-header-project-name-unselected'
                  : ''
              "
              >{{ projectSelect.value.projectNumber
              }}<span *ngIf="!isSelectProjectValue"
                >_{{ projectSelect.value.projectNickname }}</span
              ></span
            ><span class="dashboard-sub-header-role-name p-r-4">{{
              projectSelect.value.roleName | replaceRoleName
            }}</span>
          </div>
        </mat-select-trigger>
        <mat-option
          [value]="defaultValue"
          disabled
          matTooltip="{{ selectProjectString }}"
        >
          <div class="dashboard-sub-header-mat-option">
            <span
              class="dashboard-sub-header-project-name dashboard-sub-header-project-name-unselected"
              >{{ selectProjectString }}</span
            ><span class="dashboard-sub-header-role-name"></span>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let project of projects$ | async"
          [value]="project"
          matTooltip="{{ project | projectShortName }}  -  {{
            project.roleName | replaceRoleName
          }}"
          matTooltipClass="dashboard-subheader-project-name-tooltip"
        >
          <div class="dashboard-sub-header-mat-option">
            <span class="dashboard-sub-header-project-name">{{
              project | projectShortName
            }}</span
            ><span class="dashboard-sub-header-role-name">{{
              project.roleName | replaceRoleName
            }}</span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
