import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[mprCopyToClipboard]',
})
export class mprCopyToClipboardDirective implements AfterViewInit {
  @Input() public mprCopyToClipboard = '';

  constructor(private el: ElementRef, private clipboard: Clipboard) {}

  @HostListener('click') public onClick(): void {
    // Handle click event
    this.clipboard.copy(this.mprCopyToClipboard);
  }

  @HostListener('keydown.enter') public onEnter(): void {
    // Handle Enter key press
    this.clipboard.copy(this.mprCopyToClipboard);
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute(
      'class',
      'mpr-copy-icon-button mpr-copy-to-clipboard-icon mpr-skipper'
    );
  }
}
