import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  public transform(bytes: number = 0, decimals: number = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const kiloBytes = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const index = Math.floor(Math.log(bytes) / Math.log(kiloBytes));
    return `${parseFloat(
      (bytes / Math.pow(kiloBytes, index)).toFixed(decimals)
    )} ${sizes[index]}`;
  }
}
