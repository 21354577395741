/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';
import { DownloadJobRequest } from '@download/interface';

export class CreateDownloadJob {
  public static type = '[download] Create downlosd job and get details';
  constructor(
    public downloadJobRequest: DownloadJobRequest,
    public requestHeaders?: MprHttpHeaderModal
  ) {}
}

export class CreateViewPresignedUrl {
  public static type = '[view] Create view request and get presignedurl';
  constructor(public s3Path: string) {}
}

export class ResetDownloadJobState {
  public static type = '[download] reset download state';
}
