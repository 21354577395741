<div class="dialog-container">
  <mat-dialog-content>
    <mat-toolbar class="mpr-mat-toolBar">
      <span *ngIf="isInternalPicker"
        ><a mat-button (click)="mainBreadCrumb()">All Files</a></span
      >

      <span *ngFor="let m of breadCrumbMenuList; let indx = index">
        <a mat-button (click)="subBreadCrumb(indx)">
          <mat-icon *ngIf="isInternalPicker || indx !== 0">{{
            m.icon
          }}</mat-icon
          >{{ getFormattedFolderName(m.displayName || m.name) }}
        </a>
      </span>
    </mat-toolbar>
    <div class="mpr-name-and-checkbox" *ngIf="showSelectAllCheckbox && pickerType === boxPickerTypeEnum.FILE">
      <span class="select-all-text" >Select All</span>
      <span class="checkbox-wrapper">
        <mat-checkbox 
          class="select-all-checkbox"  
          #selectAllCheckbox 
          [(ngModel)]="selectAllFiles" 
          (change)="toggleSelectAllFiles()"
          aria-label="Select All Check Box"
        ><span class="mpr-hidden-labels">Select All</span>
      </mat-checkbox>
      </span>
    </div>
    <div *ngIf="contentPickerList.length || showSpinner; else noResult">
      <div class="mpr-dataconnector-table-noresult" *ngIf="showSpinner">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span>
              <mat-spinner diameter="45" color="accent"></mat-spinner>
            </span>
            <span>Please wait while the items load....</span>
          </div>
        </div>
      </div>
      <mat-table
        [dataSource]="dataSource"
        class="connector-list-table mpr-tables"
        *ngIf="!showSpinner"
        role="table"
        aria-rowcount="-1"
      > 
        <ng-container matColumnDef="columnName">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let contentPickerDetails">
            <ng-container
              *ngIf="contentPickerDetails.type !== boxPickerTypeEnum.FILE"
            >
              <img
                [src]="folderPath"
                alt="Folder Icon"
                class="imgClass"
              /><span
                class="text-padding"
                (click)="folderClick(contentPickerDetails)"
                >{{
                  getFormattedFolderName(
                    contentPickerDetails.displayName ||
                      contentPickerDetails.name
                  )
                }}</span
              >
              <mat-radio-button
                *ngIf="pickerType === boxPickerTypeEnum.FOLDER"
                class="mat-radio-button mat-accent checkBox-margin"
                color="primary"
                [checked]="contentPickerDetails.checked"
                (change)="radioClick(contentPickerDetails)"
                aria-label="Radio Button"
              ><span class="mpr-hidden-labels">Select</span>
              </mat-radio-button>
            </ng-container>
            <ng-container
              *ngIf="contentPickerDetails.type === boxPickerTypeEnum.FILE"
            >
              <img
                src="{{ getImagePath(contentPickerDetails) }}"
                [alt]="getFileIconAltText(contentPickerDetails)"
                class="imgClass"
              /><span class="text-padding"
                >{{ contentPickerDetails.name }}
              </span>
              <mat-checkbox
                *ngIf="pickerType === boxPickerTypeEnum.FILE"
                [checked]="contentPickerDetails.checked"
                (change)="checkBoxClick($event, contentPickerDetails)"
                class="checkBox-margin mpr-s3-file-selection-checkbox"
                aria-label="Select Check Box"
              ><span class="mpr-hidden-labels">Select</span></mat-checkbox>
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row
          role="row"
          *matHeaderRowDef="displayedColumns"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <ng-template #noResult>
      <div class="mpr-dataconnector-table-noresult">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span><img src="assets/images/Error.svg" alt="Error" /> </span>
            <span>There are no items in this folder</span>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div *ngIf="actionType === ''">
      <button
        *ngIf="pickerType === boxPickerTypeEnum.FILE"
        type="button"
        class="standard-button dialog-button mpr-selected-button btnSelected"
        mat-raised-button
      >
        {{ this.fileDetailsList.length }} Selected
      </button>
      <button
        class="standard-button dialog-button mpr-no-button"
        mat-raised-button
        [mat-dialog-close]="false"
        cdkFocusInitial
        aria-label="Close Info"
      >
        <svg
          class="icon-close"
          height="16"
          viewBox="0 0 24 24"
          width="16"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <path
            class="fill-color"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            fill="#000000"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <span class="mpr-hidden-labels">Close</span>
      </button>
      <button
        *ngIf="pickerType === boxPickerTypeEnum.FILE"
        mat-raised-button
        aria-label="Confirm Info"
        color="primary"
        (click)="chooseFiles()"
        [disabled]="!this.fileDetailsList.length"
      >
        <svg
          height="16"
          viewBox="0 0 24 24"
          width="16"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            class="fill-color"
            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
            fill="##0b2949"
          ></path>
        </svg>
        <span class="mpr-hidden-labels">Confirm</span>
      </button>
      <button
        *ngIf="pickerType === boxPickerTypeEnum.FOLDER"
        mat-raised-button
        aria-label="Confirm Info"
        color="primary"
        (click)="chooseFolder()"
        [disabled]="!this.folderDetailsList.length"
      >
        <svg
          height="16"
          viewBox="0 0 24 24"
          width="16"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            class="fill-color"
            d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
            fill="##0b2949"
          ></path>
        </svg>
        <span class="mpr-hidden-labels">Confirm</span>
      </button>
    </div>
    <div *ngIf="actionType === 'move'">
      <button class="mpr-cancel" [mat-dialog-close]="false">Cancel</button>
      <button
        class="mpr-movehere"
        (click)="moveToTargetLocation()"
        [ngClass]="
          !folderDetailsList.length || disableAction ? 'mpr-disabled' : ''
        "
      >
        Move Here
      </button>
    </div>
  </mat-dialog-actions>
</div>
