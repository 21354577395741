import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SkipMainContentService {
  public showHideChangeSFRole$ = new Subject<boolean>();
  public skip$ = new Subject<boolean>();
  public uploadRoute$ = new Subject<boolean>();
  constructor(private http: HttpClient) {}

  public fetchValueToOpenUploadPopup(): void {
    this.uploadRoute$.next(true);
  }

  public showHideLoaderForOpeningDataWareHouse(display: boolean): void {
    this.showHideChangeSFRole$.next(display);
  }

  public skiptomain(): void {
    this.skip$.next(true);
  }
}
