import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { ApiInProgressStateDefaults, SetApiInProgressAction } from 'app/state';
import { finalize, Observable } from 'rxjs';

@Injectable()
export class ApiInProgressInterceptor implements HttpInterceptor {
  constructor(public store: Store) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Check if this is our BE URL
    const isBackendUrl = request.url.includes(environment.apiBaseUrl);

    if (!isBackendUrl || request.method !== 'GET') {
      return next.handle(request);
    }

    const urlStateKey = new URL(request.url);
    const urlKey =
      this.toTitleCase(request.method) +
      this.titleCaseAPIPath(urlStateKey.pathname);

    if (!(urlKey in ApiInProgressStateDefaults)) return next.handle(request);
    // Set the Action to be in progress
    this.store.dispatch(new SetApiInProgressAction(urlKey, true));
    return next.handle(request).pipe(
      finalize(() => {
        this.store.dispatch(new SetApiInProgressAction(urlKey, false));
      }),
    );
  }

  private titleCaseAPIPath(path: string): string {
    const parts = path.split(/[/_]/);
    let finalPath = '';
    parts.forEach((urlPath: string) => {
      finalPath += this.toTitleCase(urlPath);
    });
    return finalPath;
  }

  private toTitleCase(word: string): string {
    return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
  }
}
