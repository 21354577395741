import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { MetadataOptionsPayloadModel, UploadsState } from 'app/state';
import { ScheduleState } from 'app/state/uploads/schedule/schedule.state';
import {
  CreateJobResponseModel,
  CreateSchedulePayloadModel,
  CreateScheduleResponseModel,
  PresignedUrlPayloadModel,
} from 'app/uploads/interfaces';
import { Observable } from 'rxjs';
import { OnetimeUploadPayloadModel } from '../interfaces';
@Injectable({
  providedIn: 'root',
})
export class UploadsService {
  constructor(private http: HttpClient, public store: Store) {}

  public checkDatasetStatus(datasetId: string): Observable<any> {
    return this.http.get(`/validate_dataset/${datasetId}`);
  }

  public createJob(
    requestHeaders: MprHttpHeaderModal
  ): Observable<CreateJobResponseModel> {
    // TODO: Might be best to put in state Action, but need to handle errors
    const stateSnapshot = this.store.selectSnapshot(UploadsState);
    const payload: OnetimeUploadPayloadModel = {
      ...stateSnapshot.uploadFilesForm,
    };
    // if its not for a job id add metadata
    if (
      !stateSnapshot.uploadFilesForm.jobId &&
      !stateSnapshot.uploadFilesForm.datasetName
    )
      payload.fileMetadata = { ...stateSnapshot.fileMetadata };
    payload.existingFileNames = [...stateSnapshot.existingFileNames];
    if (requestHeaders && Object.keys(requestHeaders).length !== 0) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, requestHeaders[HeaderParams.PROJECTID])
        .append(HeaderParams.ROLENAME, requestHeaders[HeaderParams.ROLENAME]);
      return this.http.post<CreateJobResponseModel>('/job', payload, {
        headers,
      });
    }
    return this.http.post<CreateJobResponseModel>('/job', payload);
  }

  public createSchedule(scheduleType: string): Observable<CreateScheduleResponseModel> {
    const stateSnapshot = this.store.selectSnapshot(ScheduleState);
    let payload = {};
    payload = scheduleType === 'staging' ? 
    {
      ...stateSnapshot.scheduleFormData
    } : 
    {
      emailId: stateSnapshot.emailId,
      projectId: stateSnapshot.projectId,
      userId: stateSnapshot.emailId,
      ...stateSnapshot.scheduleFormData,
      fileMetadata: { ...stateSnapshot.fileMetadata },
    }
    return this.http.post<CreateScheduleResponseModel>('/schedule', payload);
  }

  public getAllLookupValues(): Observable<MetadataOptionsPayloadModel> {
    return this.http.get<MetadataOptionsPayloadModel>(
      '/projects/metadata/lookup'
    );
  }

  public s3FileUploadFromLocal(
    file: File,
    presignedUrlJson: PresignedUrlPayloadModel,
    newFileName?: string
  ): Observable<HttpEvent<any>> {
    const presignedUrl = environment.useAcceleratedS3Endpoint
      ? presignedUrlJson.s3PresignedUrlWithTransferAcceleration
      : presignedUrlJson.s3PresignedUrl;
    const formData: FormData = new FormData();
    const url: string = presignedUrl.url;
    let key = presignedUrl.fields.key;
    if (newFileName)
      // eslint-disable-next-line no-template-curly-in-string
      key = presignedUrl.fields.key.replace('${filename}', newFileName);
    formData.append('key', key);
    formData.append('AWSAccessKeyId', presignedUrl.fields.AWSAccessKeyId);
    formData.append(
      'x-amz-security-token',
      presignedUrl.fields['x-amz-security-token']
    );
    formData.append('signature', presignedUrl.fields.signature);
    formData.append('policy', presignedUrl.fields.policy);
    formData.append('file', file);
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  public updateJobUploadStatus(
    jobId: string,
    jobStatus: string,
    requestHeaders: MprHttpHeaderModal,
    jobOperationType: string
  ): Observable<any> {
    if (requestHeaders && Object.keys(requestHeaders).length !== 0) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, requestHeaders[HeaderParams.PROJECTID])
        .append(HeaderParams.ROLENAME, requestHeaders[HeaderParams.ROLENAME]);
      return this.http.put(
        `/job/${jobId}`,
        { jobStatus, jobOperationType },
        { headers }
      );
    }
    return this.http.put(`/job/${jobId}`, { jobStatus, jobOperationType });
  }
}
