import {
  MetadataOptionsStateModel,
  ResetMetadataOptionsState,
  LoadAllMetadataOptions,
  MetadataOptionsModel,
  MetadataOptionsPayloadModel,
} from 'app/state';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UploadsService } from 'app/uploads/services';

const stateDefaults: MetadataOptionsModel = {
  aggregationLevels: [],
  dataAccessClassification: [],
  focusAreas: [],
  geographicRegion: [],
};

@State<MetadataOptionsStateModel>({
  defaults: stateDefaults,
  name: 'MetadataOptionsState',
})
@Injectable()
export class MetadataOptionsState {
  constructor(private uploadsService: UploadsService) {}

  @Selector()
  public static getAllAggregationLevels(
    state: MetadataOptionsStateModel
  ): string[] {
    return state.aggregationLevels;
  }

  @Selector()
  public static getAllDataAccessClassification(
    state: MetadataOptionsStateModel
  ): string[] {
    return state.dataAccessClassification;
  }

  @Selector()
  public static getAllFocusAreas(state: MetadataOptionsStateModel): string[] {
    return state.focusAreas;
  }

  @Selector()
  public static getAllGeographicRegion(
    state: MetadataOptionsStateModel
  ): string[] {
    return state.geographicRegion;
  }

  @Selector()
  public static getAllLookupValues(
    state: MetadataOptionsStateModel
  ): MetadataOptionsModel {
    return { ...state };
  }

  @Action(LoadAllMetadataOptions)
  public loadAllMetadataOptions({
    patchState,
  }: StateContext<MetadataOptionsStateModel>): Observable<MetadataOptionsPayloadModel> {
    return this.uploadsService.getAllLookupValues().pipe(
      tap((response) => {
        const metaOptions = response.message;
        // BE payloads inconsistent : Mapping needed as we need to stick to storing camelCase and not kebab case
        const stateValues: MetadataOptionsModel = {
          aggregationLevels: metaOptions.aggregation_levels,
          dataAccessClassification:
            metaOptions.data_access_classification.reverse(),
          focusAreas: metaOptions.focus_areas,
          geographicRegion: metaOptions.geographic_region,
        };
        patchState({ ...stateValues });
      })
    );
  }

  @Action(ResetMetadataOptionsState)
  public resetMetaOptionsState({
    patchState,
  }: StateContext<MetadataOptionsStateModel>): void {
    patchState({ ...stateDefaults });
  }
}
