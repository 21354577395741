<div class="mpr-manage-recurring-schedule">
  <div class="mpr-recurring-top-section">
    <div class="field-container">
      <div class="field-label mpr-project-name">PROJECT : {{ projectId }}</div>
    </div>
    <div *ngIf="projectSurveys.length > 0">
      <button
        #skipper
        mat-button
        class="mpr-button mpr-yellow-button"
        (click)="openCreateMappingDialog()"
      >
        CREATE NEW MAPPING
      </button>
    </div>
  </div>

  <div class="mpr-schedule-table-container" 
       *ngIf="(fetchProjectSurveyInProgress$ | async) === false; else loader">
    <div
      class="mpr-schedule-table-withdata"
      *ngIf="projectSurveys.length; else noResult"
    >
      <mat-table
        [dataSource]="dataSource"
        class="schedule-list-table mpr-tables"
        aria-rowcount="-1"
      >
        <ng-container matColumnDef="surveyId">
          <mat-header-cell *matHeaderCellDef scope="col">
            Survey Id
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="surveyIdInfo"
              [mprMtxTooltipAriaDescribedBy]="surveyIdInfo"
              #tooltip="mtxTooltip"
              aria-label="SurveyId Information"
            >
              <img
                class="mpr-file-info-icon"
                src="assets/images/info.svg"
                alt="Note"
              />
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            <span class="mpr-ellipsis" matTooltip="{{ survey.surveyId }}">{{
              survey.surveyId
            }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef scope="col">
            Created On
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            {{ survey.createdDate | utcDate: 'MM/dd/yy' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="surveyName">
          <mat-header-cell *matHeaderCellDef scope="col">
            Survey Name
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="surveyNameInfo"
              [mprMtxTooltipAriaDescribedBy]="surveyNameInfo"
              #tooltip="mtxTooltip"
              aria-label="SurveyName Information"
            >
              <img
                class="mpr-file-info-icon"
                src="assets/images/info.svg"
                alt="Note"
              />
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            {{ survey.surveyName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef scope="col">
            Edit
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            <button
              class="mpr-btn-transparency"
              title="Edit Mapping"
              (click)="openEditMappingDialog(survey)"
            >
              <mat-icon
                class="mpr-edit-schedule"
                title="Edit schedule"
                aria-hidden="false"
              >
                <img src="assets/images/EditIcon.svg" alt="Edit" />
              </mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef scope="col"
            >Delete</mat-header-cell
          >
          <mat-cell *matCellDef="let survey">
            <button
              class="mpr-btn-transparency"
              (click)="showDeleteSurveyDialog(survey)"
              aria-label="Delete Survey"
              [ngClass]="isDeleteInProgress ? 'mpr-disabled' : ''"
            >
              <mat-icon class="mpr-delete-schedule" title="Delete Survey"
                >cancel</mat-icon
              >
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <ng-template #noResult>
      <div class="mpr-schedule-table-noresult">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span><img src="assets/images/Error.svg" alt="Error" /> </span>
            <span
              >You do not have any mappings created for the current
              project</span
            >
          </div>
          <div class="mpr-noresult-btn">
            <button
              #skipper
              mat-button
              class="mpr-button mpr-yellow-button"
              (click)="openCreateMappingDialog()"
            >
              CREATE NEW MAPPING
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #surveyIdInfo>
  <div class="mpr-popover-width" id="mpr-survey-id-info">
    The Forsta project ID (PID) that is used to identify the survey in Forsta
  </div>
</ng-template>

<ng-template #surveyNameInfo>
  <div class="mpr-popover-width" id="mpr-survey-Name-info">
    The name of the specific data collection instrument associated with the
    Survey
  </div>
</ng-template>

<ng-template #loader>
  <div class="d-flex mpr-justify-content-center mpr-table-loader">
    <span>
      <mat-spinner diameter="45" color="accent"></mat-spinner>
    </span>
  </div>
</ng-template>