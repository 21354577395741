import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserService } from '@theme/services';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { LoadUserInfodata } from './user-info.actions';
import { UserProjectRoleEnum } from '@core/enums';
import UserInfoStateModel from './user-info-state-model';
import { User } from '@theme/interfaces';
import UserInfoModel from './user-model';
import { HttpErrorResponse } from '@angular/common/http';

const stateDefaults: UserInfoStateModel = {
  message: {
    roleName: UserProjectRoleEnum.DEFAULT,
  },
};
@State<UserInfoStateModel>({
  defaults: stateDefaults,
  name: 'Userinfodata',
})
@Injectable()
export class UserInfoState {
  constructor(private userService: UserService) {}

  @Action(LoadUserInfodata)
  public LoadUserInfodata(
    { patchState }: StateContext<UserInfoStateModel>,
    { userId }: LoadUserInfodata
  ): Observable<User> {
    return this.userService.getUserInfo(userId).pipe(
      // For a non-platform-admin this is a error so handle this.
      catchError((error: HttpErrorResponse): Observable<any> => {
        const message: UserInfoModel = { roleName: UserProjectRoleEnum.BLANK };
        patchState({ message });
        // other errors we don't know how to handle and throw them further.
        return throwError(() => error);
      }),
      tap((result: any) => {
        patchState({ ...result });
      })
    );
  }

  @Selector()
  public static getCurrentUserRole(state: UserInfoStateModel): string {
    return state.message.roleName;
  }

  @Selector()
  public static getPlatformAdminTermsAccepted(
    state: UserInfoStateModel
  ): boolean | undefined {
    return state.message.isTermsAccepted;
  }
}
