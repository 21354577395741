import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { LoadAllMetadataOptions } from 'app/state/metadata-options';
import { OnPremiseUploadComponent } from '../on-premise-upload/on-premise-upload.component';
import { ResetUploadsState } from 'app/state';
import { Store } from '@ngxs/store';
import { MatStepper } from '@angular/material/stepper';
import { ConnectionState } from 'app/state/connection';
import { CloudTransferComponent } from '../cloud-transfer/cloud-transfer.component';
import { ConnectionDataStore, OneTimeScheduleEnum } from '@core/enums';
import { ComponentType } from '@angular/cdk/portal';
import { LoadSelectedProjectMetadata } from 'app/state/project/project.actions';
import { ActivatedRoute } from '@angular/router';
import { DatasetDetailsModel } from '../interfaces';
import { UploadsService } from '../services';

@Component({
  selector: 'mpr-one-time-uploads',
  templateUrl: './one-time-uploads.component.html',
  styleUrls: ['./one-time-uploads.component.scss'],
})
export class OneTimeUploadsComponent implements OnInit, OnDestroy {
  public datasetDetails: DatasetDetailsModel = {
    datasetId: '',
    projectID: '',
    datasetName: '',
  };
  public isLinear = true;
  public jobId = '';
  public metaDataFormStatus = false;
  public oneTime = OneTimeScheduleEnum.ONETIME;
  public projectDetails: any;
  public selectFilesFormStatus = false;
  private dialogSize: MatDialogConfig = {
    minHeight: '250px ',
    width: '70%',
  };

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private route: ActivatedRoute,
    private uploadsService: UploadsService
  ) {}
  public fetchRouting(): void {
    const jobId = this.route.snapshot.paramMap.get('id');
    this.projectDetails = this.route.snapshot.paramMap.get('datasetDetails');
    if (jobId) {
      this.jobId = jobId;
    } else if (this.projectDetails) {
      this.datasetDetails = JSON.parse(atob(this.projectDetails));
    }
  }
  ngOnDestroy(): void {
    // Ensure we have clean slate when user goes off with this.
    this.store.dispatch(new ResetUploadsState());
  }

  ngOnInit(): void {
    //Getting routing details
    this.fetchRouting();
    // Ensure we have clean slate when user staarts off with this.
    this.store.dispatch(new ResetUploadsState());
    this.store.dispatch(new LoadAllMetadataOptions());
    this.store.dispatch(new LoadSelectedProjectMetadata());
  }

  public proceedToMetaDataScreen(stepper: MatStepper): void {
    setTimeout(() => stepper.next(), 0);
    this.selectFilesFormStatus = true;
  }

  public startFileUpload(requestHeaders?: any): void {
    const connectionId = this.store.selectSnapshot(
      ConnectionState.getSelectedConnectionId
    );
    this.metaDataFormStatus = true;

    const useComponent: ComponentType<unknown> =
      connectionId === ConnectionDataStore.LOCAL
        ? OnPremiseUploadComponent
        : CloudTransferComponent;
    this.dialog.open(useComponent, {
      ...this.dialogSize,
      disableClose: true,
      data: {
        requestHeader: requestHeaders,
        datasetDetails: this.projectDetails,
      },
    });
  }
}
