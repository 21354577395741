import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '@core';

@Component({
  selector: 'mpr-app-name',
  templateUrl: './mpr-app-name.component.html',
})
export class MprAppNameComponent {
  @Input() public dataTestId = '';
  public appLogoText;

  constructor(private settings: SettingsService) {
    this.appLogoText = this.settings.appLogoText;
  }
}
