import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserProject } from '@theme/interfaces';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { Store } from '@ngxs/store';
import { LoggedinUserState, UserProjectState } from 'app/state';
import { UserList } from 'app/state/project-user/user-list-model';
import { UserProjectRoleEnum, userStatus } from '@core';
import { DeleteUserList } from 'app/state/project-user/delete-user-list-model';
import ManageProjectModel, {
  projectDetails,
} from 'app/state/manage-project/manage-project-state-model';

@Injectable({
  providedIn: 'root',
})
export class UserProjectService {
  constructor(private http: HttpClient, private store: Store) {}
  public createAProject(
    projectDetail: projectDetails,
    roleName: string
  ): Observable<ManageProjectModel> {
    const headers = new HttpHeaders().set(HeaderParams.ROLENAME, roleName);
    return this.http.post<any>(`/project`, projectDetail, { headers });
  }

  public createUser(
    userDetails: any,
    roleName?: string
  ): Observable<Array<UserList>> {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < userDetails.length; i++) {
      userDetails[i].userRestrictedDataUseGroups =
        userDetails[i]['projectRestrictedDataUseGroups'];
      delete userDetails[i].projectRestrictedDataUseGroups;
    }
    const payload = userDetails;
    if (roleName) {
      const headers = new HttpHeaders().set(HeaderParams.ROLENAME, roleName);
      return this.http.post<any>(`/user`, payload, { headers });
    } else {
      return this.http.post<any>(`/user`, payload);
    }
  }

  public deleteUserFromProject(
    userId: string,
    requestHeaders?: MprHttpHeaderModal,
    projectIdList?: string[]
  ): Observable<Array<DeleteUserList>> {
    if (!projectIdList) {
      projectIdList = [
        this.store.selectSnapshot(UserProjectState.getSelectedProjectId),
      ];
    }

    if (requestHeaders) {
      const headers = new HttpHeaders().set(
        HeaderParams.ROLENAME,
        requestHeaders['roleName'] ?? ''
      );
      return this.http.delete<Array<DeleteUserList>>(`/user/${userId}`, {
        headers,
        body: { projectIdList },
      });
    }

    return this.http.delete<Array<DeleteUserList>>(`/user/${userId}`, {
      body: { projectIdList },
    });
  }

  public editAProject(
    projectDetail: projectDetails,
    roleName: string,
    projectId: string
  ): Observable<ManageProjectModel> {
    const url = `/project/${projectId}`;
    const headers = new HttpHeaders().set(HeaderParams.ROLENAME, roleName);
    return this.http.put<any>(url, projectDetail, { headers });
  }

  public editUser(
    userDetails: any,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<any> {
    const loggedinUserEmail = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserEmail
    );

    const isPlatformAdmin =
      requestHeaders &&
      requestHeaders['roleName'] === UserProjectRoleEnum.PLATFORM_ADMIN;

    // This is because BE need this for payload while it sends back projectRestrictedDataUseGroups in /GET
    const payload = { ...userDetails };
    payload.userRestrictedDataUseGroups = [
      ...payload.projectRestrictedDataUseGroups,
    ];
    delete payload.projectRestrictedDataUseGroups;

    // Logged in user cannot change his role.
    if (loggedinUserEmail === payload.emailId && !isPlatformAdmin)
      delete payload.roleName;

    // A user cannot be demoted to non-power user as requirement
    if (!payload.isPowerUser) delete payload.isPowerUser;

    const apiUrl =
      payload.userStatus === userStatus.INVITED
        ? `/user/${payload.emailId}`
        : `/user/${payload.userId}`;

    if (requestHeaders) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, requestHeaders['projectId'] ?? '')
        .append(HeaderParams.ROLENAME, requestHeaders['roleName'] ?? '');
      return this.http.put<any>(apiUrl, payload, { headers });
    }

    return this.http.put<any>(apiUrl, payload);
  }

  public getAllUserProjects(emailId?: string): Observable<UserProject[]> {
    if (emailId) {
      const url = `/projects?userId=${emailId}`;
      return this.http.get<UserProject[]>(url);
    }
    return this.http.get<UserProject[]>('/projects');
  }

  public getProjectDetails(
    requestHeaders: MprHttpHeaderModal
  ): Observable<projectDetails> {
    const requestUrl = `projects/metadata`;
    const headers = new HttpHeaders()
      .set(HeaderParams.PROJECTID, requestHeaders['projectId'] ?? '')
      .append(HeaderParams.ROLENAME, requestHeaders['roleName'] ?? '');
    return this.http.get<projectDetails>(`${requestUrl}`, { headers });
  }

  public getProjectUserCount(
    isCount: string,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<Array<ProjectUserList>> {
    if (
      requestHeaders &&
      requestHeaders['projectId'] &&
      requestHeaders['roleName']
    ) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, requestHeaders['projectId'] ?? '')
        .append(HeaderParams.ROLENAME, requestHeaders['roleName'] ?? '');

      return this.http.get<Array<ProjectUserList>>(
        `/projects/user?isCount=${isCount}`,
        { headers }
      );
    }
    return this.http.get<Array<ProjectUserList>>(
      `/projects/user?isCount=${isCount}`
    );
  }

  public getUserProjectDetails(
    userId: string,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<ProjectUserList> {
    const requestUrl = `/user/${userId}`;
    if (requestHeaders) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, requestHeaders['projectId'] ?? '')
        .append(HeaderParams.ROLENAME, requestHeaders['roleName'] ?? '');
      return this.http.get<any>(`${requestUrl}`, { headers });
    }

    return this.http.get<ProjectUserList>(requestUrl);
  }
}
