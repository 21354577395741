<div
  class="mpr-workspace-top-section"
  *ngIf="fetchUserApiInProgress$ | async; else showEc2WorkspaceStatus"
>
  <div class="mpr-workspace-loader-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</div>
<ng-template #showEc2WorkspaceStatus>
  <div
    *ngIf="showMessage"
    class="mpr-message-box"
    [ngClass]="
      ec2Data.status_code === 200 ? 'header-success-note' : 'header-error-note'
    "
  >
    <div
      class="mpr-message"
      [ngClass]="
        ec2Data.status_code === 200
          ? 'header-success-message'
          : 'header-error-message'
      "
    >
      {{ ec2Data.message }}
    </div>
    <div class="mpr-close-icon" (click)="closemessage()">X</div>
  </div>

  <div class="mpr-top-section">
    <div class="mpr-workspace-top-section">
      <div class="field-container">
        <div class="field-label" *ngIf="ec2Status === launchEc2Enum.FAILED">
          <div class="mpr-bold-text">Failure in provisioning EC2 Instance</div>
          <div class="mpr-ec2-failure-text">
            <img
              class="mpr-warning-icon"
              src="assets/images/warning-icon-red-triangle.svg"
              alt=" EC2 provision failed warning"
            />
            Your EC2 Instance setup has failed due to an error. Please try again
            in a few minutes. If the problem continues, contact
            <a
              class="mpr-service-now-link f-s-16"
              href="{{ inputs.commonSupportLink }}"
              target="_blank"
              >{{ 'appName' | appDetails }} Support</a
            >.
          </div>
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status === launchEc2Enum.PROVISIONING"
        >
          Your EC2 Instance is being setup. It can take upto 5 minutes to
          complete. Click <b>REFRESH</b> to check the status.
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status === launchEc2Enum.STOPPED_STATUS"
        >
          Your EC2 Instance is <b>OFF</b>. Click <b>LAUNCH EC2 INSTANCE</b> to
          turn it on.
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status === launchEc2Enum.STOPPING_STATUS"
        >
          Your EC2 Instance is <b>STOPPING</b>. Click <b>REFRESH</b> to check
          the status
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status === launchEc2Enum.RUNNING_STATUS"
        >
          Your EC2 Instance is <b>ON</b>. Click <b>STOP EC2 INSTANCE</b> to turn
          it off.
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status === launchEc2Enum.INITIALIZING_STATUS"
        >
          Your EC2 Instance is <b>INITIALIZING</b>. Click <b>REFRESH</b> to
          check the status.
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status.toUpperCase().startsWith(launchEc2Enum.REPROVISION)"
        >
          Your EC2 Instance is <b>REPROVISIONING</b>. It can take upto 5 minutes. Click <b>REFRESH</b> to
          check the status.
        </div>
        <div
          class="field-label"
          *ngIf="ec2Status === launchEc2Enum.SHUTTING_DOWN"
        >
          Your EC2 Instance is <b>TERMINATING</b>. Click <b>REFRESH</b> to check
          the status.
        </div>
        <div
          class="field-label"
          *ngIf="
            ec2Status === launchEc2Enum.NOT_PROVISIONED ||
            ec2Status === launchEc2Enum.TERMINATED_STATUS
          "
        >
          Click on the button below to setup your EC2 Instance. Please note, it
          may take up to 5 minutes to complete.
        </div>
      </div>
      <div class="f-right">
        <button
          mat-button
          class="mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          [ngClass]="disableStopButton ? 'mpr-button-disabled' : ''"
          (click)="stopWorkspace()"
          #skipper
          *ngIf="ec2Status === launchEc2Enum.RUNNING_STATUS"
        >
          <mat-icon>power_settings_new</mat-icon> STOP EC2 INSTANCE
        </button>
        <button
          mat-button
          class="mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          [ngClass]="
            ec2Status !== launchEc2Enum.STOPPED_STATUS
              ? 'mpr-button-disabled'
              : ''
          "
          (click)="launchWorkspace()"
          *ngIf="
            ec2Status !== launchEc2Enum.RUNNING_STATUS &&
            ec2Status !== launchEc2Enum.NOT_PROVISIONED &&
            ec2Status !== launchEc2Enum.TERMINATED_STATUS &&
            ec2Status !== launchEc2Enum.FAILED
          "
          #skipper
        >
          <mat-icon>power_settings_new</mat-icon> LAUNCH EC2 INSTANCE
        </button>

        <button
          mat-button
          class="mat-focus-indicator mpr-button mpr-yellow-button mat-button mat-button-base ng-star-inserted"
          (click)="provisionWorkspace()"
          *ngIf="
            ec2Status === launchEc2Enum.NOT_PROVISIONED ||
            ec2Status === launchEc2Enum.TERMINATED_STATUS ||
            ec2Status === launchEc2Enum.FAILED
          "
          #skipper
        >
          <mat-icon>power_settings_new</mat-icon> SETUP EC2 INSTANCE
        </button>

        <button
          mat-button
          #skipper
          class="mpr-button mpr-aws-workspace-second-btn"
          *ngIf="isRefresh"
          (click)="doRefresh()"
        >
          <span class="mpr-refresh-img">
            <img src="assets/images/New_Refresh.svg" alt="" role="img" />
          </span>
          <span class="mpr-refresh"> Refresh </span>
        </button>
      </div>
    </div>
    <div class="mpr-workspace-top-section1" *ngIf="showUpdateEc2Section()">
      <div class="mpr-font-style-normal">
        A new version of the EC2 instance is available
      </div>
      <span>
        <button
          (click)="showReprovisionConfimation()"
          class="mpr-reprovision-link mpr-cursor-pointer mpr-button mat-button mat-button-base"
          [ngClass]="
            disableUpdateEC2Instance(ec2Status) ? 'mpr-button-disabled' : ''
          "
        >
          <img src="assets/images/reprovision.svg" alt="Reprovision" />
          <div class="mpr-reprovison-icon-name">Update EC2 Instance</div>
        </button>
      </span>
    </div>
  </div>

  <div id="ec2_details">
    <ng-container *ngIf="displayQuickLinks(ec2Status)">
      <div class="mpr-quick-links">QUICK LINKS</div>
      <div class="mpr-workspace-quick-links">
        <ng-container *ngIf="isEc2Provisioned(ec2Status)">
          <a
            class="mpr-data-processing-guide"
            href="{{ dataProcessingUserGuideUrl }}"
            target="_blank"
            >Data Processing User Guide
            <img
              class="mpr-link-new-window-icon"
              src="assets/images/new-window.svg "
              alt=""
            />
          </a>
        </ng-container>
        <ng-container *ngIf="showAWSCredentialsLink">
          <span
            class="mpr-divide-links-symbol"
            *ngIf="showAWSCredentialsLink && isEc2Provisioned(ec2Status)"
            >|</span
          >
          <a class="mpr-modify-workspace">
            <button
              class="mpr-workspace-link mpr-workspace-pointer mpr-btn-transparency"
              (click)="openTempUrlAndCredModal()"
              [disabled]="disableAWSCredentialsButton"
            >
              aws url & credentials
            </button>
          </a>
        </ng-container>
        <ng-container *ngIf="showConnectingToSFLink">
          <span
            class="mpr-divide-links-symbol"
            *ngIf="showAWSCredentialsLink || isEc2Provisioned(ec2Status)"
            >|</span
          >
          <a class="mpr-modify-workspace">
            <button
              class="mpr-workspace-link mpr-workspace-pointer mpr-btn-transparency"
              (click)="navigateConnectingToSnowflake()"
            >
              connecting to snowflake
            </button>
          </a>
        </ng-container>
        <ng-container *ngIf="showConnectingToRDSLink">
          <span
            class="mpr-divide-links-symbol"
            *ngIf="showAWSCredentialsLink || isEc2Provisioned(ec2Status) || showConnectingToSFLink"
          >|</span
          >
          <a class="mpr-modify-workspace">
          <button
            class="mpr-workspace-link mpr-workspace-pointer mpr-btn-transparency"
            (click)="navigateConnectingToRDS()"
          >
            connecting to rds
          </button>
          </a>
        </ng-container>
        <ng-container *ngIf="isEc2Provisioned(ec2Status)">
          <span class="mpr-divide-links-symbol">|</span>
          <a class="mpr-modify-workspace">
            <button
              class="mpr-workspace-link mpr-workspace-pointer mpr-btn-transparency"
              (click)="openWorkSpaceModal()"
            >
              Modify ec2 instance
            </button>
          </a>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isEc2Provisioned(ec2Status)">
      <div>
        <div class="mpr-workspace-container">
          <div class="mpr-workspace-header mpr-upppercase-transform">
            EC2 Instance info
          </div>
        </div>
        <div class="mpr-workspace-border-box mpr-no-padding">
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="mpr-workspace-header">
                  <span class="mpr-upper-case">Instance Id</span> :
                  {{ selectedUser.instanceId }}
                  <span
                    class="mpr-private-space-copy"
                    *ngIf="selectedUser?.instanceId"
                  >
                    <button
                      class="mpr-icon-button mpr-copy-to-clipboard-icon"
                      (click)="
                        copyToClipBoard(selectedUser.instanceId, $event)
                      "
                      matTooltip="Copy Instance Id"
                      aria-label="Copy EC2 Instance Id"
                    ></button>
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex">
              <div class="mpr-workspace-value">
                <div class="mpr-workspace-details">TYPE</div>
                <div class="mpr-workspace-details-value">
                  {{ selectedUser.machineSize || 'NA' }}
                </div>
              </div>
              <div class="mpr-workspace-value">
                <div class="mpr-workspace-details">MEMORY</div>
                <div class="mpr-workspace-details-value">
                  {{ convertToGiB(selectedUser.memory || 'NA') }}
                </div>
              </div>
              <div class="mpr-workspace-value">
                <div class="mpr-workspace-details">CPU</div>
                <div class="mpr-workspace-details-value">
                  {{ selectedUser.vCPU || 'NA' }}
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <div class="mpr-workspace">
        <div class="mpr-tools-box">
          <div class="mpr-workspace-header mpr-upppercase-transform">Tools</div>
          <div class="mpr-tools-border-box mpr-tools-height">
            <!-- RStudio Tile -->
            <div
              class="mpr-tools-container"
              *ngIf="rstudioTile?.isVisible"
              [ngClass]="rstudioTile?.isDisabled ? 'disabled' : ''"
            >
              <div class="mpr-workspace-tiles">
                <div class="mpr-workspace-tiles-header">
                  <div class="mpr-workspace-tiles-img rStudio">
                    <img
                      [src]="rstudioTile?.img"
                      [alt]="rstudioTile?.title + 'Icon'"
                      role="img"
                    />
                  </div>
                  <div class="mpr-workspace-tiles-header-title">
                    {{ rstudioTile?.title }}
                  </div>
                </div>
                <div
                  *ngIf="
                    rstudioTile?.version !== null &&
                    rstudioTile?.version !== undefined &&
                    rstudioTile?.version !== ''
                  "
                  class="mpr-rstudio-version"
                >
                  R Version - {{ rstudioTile?.version }}
                  <button
                    class="mpr-workspace-link mpr-workspace-pointer mpr-btn-transparency"
                    (click)="tileClick(rstudioTile, true)"
                    [disabled]="rstudioTile?.isDisabled"
                  >
                    MODIFY
                  </button>
                </div>
                <div
                  *ngIf="!rstudioTile?.version || rstudioTile?.version === null"
                  class="mpr-rstudio-version"
                >
                  Version- not selected
                </div>
                <div class="mpr-workspace-tile-launch-container">
                  <button
                    class="mpr-workspace-tile-launch-button mpr-btn-transparency mat-button mpr-button rStudio"
                    (click)="tileClick(rstudioTile)"
                    [disabled]="rstudioTile?.isDisabled"
                  >
                    <img
                      src="assets/images/new-window.svg "
                      alt="Launch R Studio"
                      class="mpr-link-new-window-icon"
                    />
                    Launch
                  </button>
                </div>
              </div>
            </div>

            <!-- Jupyter Lab Tile -->
            <div
              class="mpr-tools-container"
              *ngIf="jupyterLabTile?.isVisible"
              [ngClass]="jupyterLabTile?.isDisabled ? 'disabled' : ''"
            >
              <div class="mpr-workspace-tiles">
                <div class="mpr-workspace-tiles-header">
                  <div class="mpr-workspace-tiles-img jupyter">
                    <img
                      [src]="jupyterLabTile?.img"
                      [alt]="jupyterLabTile?.title + 'Icon'"
                      role="img"
                    />
                  </div>
                  <div class="mpr-workspace-tiles-header-title">
                    {{ jupyterLabTile?.title }}
                  </div>
                </div>
                <div class="mpr-workspace-tile-launch-container">
                  <button
                    class="mpr-workspace-tile-launch-button mpr-btn-transparency mat-button mpr-button"
                    (click)="tileClick(jupyterLabTile)"
                    [disabled]="jupyterLabTile?.isDisabled"
                  >
                    <img
                      src="assets/images/new-window.svg "
                      alt="Launch JUPYTER LAB"
                      class="mpr-link-new-window-icon"
                    />
                    Launch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #WorkSpaceDialog>
      <div
        class="mpr-mat-dialog-container mpr-mat-dialog-small container-size mpr-workspace-types-dialog-container"
      >
        <div class="mpr-workspace-close" (click)="closeWorkspaceDialog()">
          <img src="assets/images/cancel-popup.svg" alt="Close icon" />
        </div>
        <h1 mat-dialog-title class="mpr-workspace-leftmargin">
          How to modify your EC2 Instance?
        </h1>
        <div mat-dialog-content fxLayout="row">
          <div class="mpr-workspace-leftmargin">
            The following types of EC2 instances are supported, by default:
          </div>
        </div>
        <div class="workspacetypes-container d-flex">
          <div *ngFor="let item of workSpaceTypes">
            <div class="mpr-workspace-types">
              <div class="mpr-workspace-details-center">
                <div>{{ item.category }}</div>
                <div>- {{ item.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mpr-workspace-leftmargin" *ngIf="isLoggedInUserInternal">
          To modify your <b>EC2</b>, please submit a
          <a
            class="mpr-workspace-link-service-now mpr-workspace-new-window-icon"
            href="{{ workspaceServiceNowUrl }}"
            target="_blank"
            >ServiceNow ticket <img src="assets/images/new-window.svg" alt=""
          /></a>
          . For the EC2 Types, you can select one of the above options or choose
          “Other” and pick from a wide selection of EC2
          <a
            class="mpr-workspace-link-service-now mpr-workspace-new-window-icon"
            href="https://aws.amazon.com/ec2/instance-types/"
            target="_blank"
            >types
            <img src="assets/images/new-window.svg" alt="ServiceNow Ticket"
          /></a>
          based on your processing needs.
        </div>
        <div class="mpr-workspace-leftmargin" *ngIf="!isLoggedInUserInternal">
          To modify your EC2, please send us an
          <a
            class="mpr-workspace-link-service-now mpr-workspace-new-window-icon"
            href="{{ contactUs }}"
            target="_blank"
            >email
          </a>
          with the 5-digit project number and the requested EC2 type. You can
          select one of the above options or choose from a wide selection of EC2
          <a
            class="mpr-workspace-link-service-now mpr-workspace-new-window-icon"
            href="https://aws.amazon.com/ec2/instance-types/"
            target="_blank"
            >types
            <img src="assets/images/new-window.svg" alt="ServiceNow Ticket"
          /></a>
          based on your processing needs.
        </div>
      </div>
    </ng-template>

    <ng-template #StataAndVscodeDialog let-data>
      <div class="mpr-mat-dialog-container mpr-mat-dialog-small container-size">
        <div class="mpr-stata-close" (click)="closeStataAndVscodeDialog()">
          <img src="assets/images/cancel-popup.svg" alt="Close icon" />
        </div>
        <h1 mat-dialog-title class="mpr-stata-dialog-title">
          How to connect to {{ data.name }}?
        </h1>
        <div mat-dialog-content fxLayout="row">
          <div class="mpr-stata-dialog-content">
            <p>
              <strong>1</strong> : To connect to the {{ data.name }}, start a
              Remote Desktop Connection.
            </p>
            <p>
              <strong>2</strong> : On the Remote Desktop Connection, for the
              Computer, enter :
              <strong>{{ stataAndVscodeDetails.public_dns_name }}</strong>
              <span
                class="mpr-private-space-copy"
                *ngIf="stataAndVscodeDetails.public_dns_name !== ''"
              >
                <button
                  class="mpr-icon-button mpr-copy-to-clipboard-icon"
                  (click)="
                    copyToClipBoard(stataAndVscodeDetails.public_dns_name)
                  "
                  matTooltip="Copy To Clipboard"
                  aria-label="Copy To Clipboard"
                ></button> </span
              ><br />
              <span class="mpr-workspace-note"
                >It is important to note, your computer name changes every time
                you turn your EC2 Instance OFF and ON.
              </span>
            </p>
            <p>
              <strong>3</strong> : Once connected, enter the username
              <strong>{{ data.username }}</strong>
              and the password you received on EC2 Instance provisioning.
            </p>
            <p>
              <strong>4</strong> : To start {{ data.name }} IDE, double click
              the {{ data.name }} icon on the desktop.
            </p>
            <div class="line-with-space">
              When you want to log out of the machine, click the menu in the top
              right ><strong> Log Out.</strong>
            </div>
            <div class="mpr-stata-lastline">
              <p class="mpr-stata-space">
                <strong>For more instructions, visit </strong>
                <a
                  class="mpr-workspace-link-service-now"
                  href="{{ data.link }}"
                  target="_blank"
                  ><span>Help</span>
                  <img
                    class="mpr-stata-new-window-icon"
                    src="assets/images/new-window.svg "
                    alt="Help"
                /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #TempUrlAndCredDialog>
      <div
        class="mpr-cred-dialog-container mpr-mat-dialog-small container-size"
      >
        <div class="mpr-workspace-close" (click)="closeTempUrlAndCredDialog()">
          <img src="assets/images/cancel-popup.svg" alt="Close icon" />
        </div>
        <div class="mpr-cred-dialog-content">
          <h1 mat-dialog-title class="mpr-cred-dialog-title">
            AWS URL & Credentials
          </h1>
          <div mat-dialog-content fxLayout="row">
            <div class="mpr-workspace-margin">
              <a
                class="mpr-workspace-link-console"
                href="{{ awsCredentials.consoleUrl }}"
                target="_blank"
                >AWS CONSOLE
                <img
                  class="mpr-link-new-window-icon"
                  src="assets/images/new-window.svg "
                  alt="Help"
                />
              </a>
            </div>
          </div>
          <p class="mpr-workspace-cred-line">
            While provisioning new resources, please tag them using ProjectID
            and UserID parameters.
          </p>
          <div class="mpr-credentials">
            <div class="mpr-credentials-values">
              <p>
                DL_user_id =
                <span
                  >{{ userID }}
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(userID)"
                    matTooltip="Copy To Clipboard"
                    aria-label="Copy To Clipboard"
                  ></button>
                </span>
              </p>
              <p>
                DL_project_id =
                <span
                  >{{ projectID }}
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(projectID)"
                    matTooltip="Copy To Clipboard"
                    aria-label="Copy To Clipboard"
                  ></button>
                </span>
              </p>
            </div>
          </div>
          <div class="mpr-workspace-credentials">
            <p class="mpr-workspace-link-console">CREDENTIALS</p>
          </div>
          <p class="mpr-workspace-cred-line">
            Use the following credentials to execute your workloads on
            {{ 'appName' | appDetails }} AWS account.
          </p>
          <p class="mpr-workspace-cred-line">
            These credentials expire after 8 hours.
          </p>
          <div class="mpr-credentials">
            <div class="mpr-credentials-values">
              <p>
                aws_access_key_id =
                <span>{{ awsCredentials.aws_access_key_id }}</span>
              </p>
              <p>
                aws_secret_access_key =
                <span>{{ awsCredentials.aws_secret_access_key }}</span>
                <button
                  class="mpr-copy-icon-button mpr-cred-copy-to-clipboard-icon"
                  (click)="copyAwsValues()"
                  matTooltip="Copy To Clipboard"
                  aria-label="Copy To Clipboard"
                ></button>
              </p>
              <p>
                aws_session_token =
                <span>{{ awsCredentials.aws_session_token }}</span>
              </p>
            </div>
          </div>
          <div class="mpr-workspace-credentials">
            <p class="mpr-workspace-link-console">ADDITIONAL DETAILS</p>
          </div>
          <p class="mpr-workspace-cred-line">
            Use one of the following subnets while creating resources from AWS
            console.
          </p>
          <div class="mpr-credentials">
            <div class="mpr-additional-values">
              <p>
                <span
                  >{{ awsCredentials.sm_subnet_1 }}
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(awsCredentials.sm_subnet_1)"
                    matTooltip="Copy To Clipboard"
                    aria-label="Copy To Clipboard"
                  ></button>
                </span>
              </p>
              <span class="mpr-divide-links-symbol">|</span>
              <p>
                <span
                  >{{ awsCredentials.sm_subnet_2 }}
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(awsCredentials.sm_subnet_2)"
                    matTooltip="Copy To Clipboard"
                    aria-label="Copy To Clipboard"
                  ></button>
                </span>
              </p>
            </div>
          </div>
          <div class="mpr-margin-top">
            <p class="mpr-workspace-cred-line">
              Use the following security group while creating resources from AWS
              console.
            </p>
          </div>
          <div class="mpr-credentials">
            <div class="mpr-additional-values">
              <p>
                <span
                  >{{ awsCredentials.sm_securitygroup }}
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="copyToClipBoard(awsCredentials.sm_securitygroup)"
                    matTooltip="Copy To Clipboard"
                    aria-label="Copy To Clipboard"
                  ></button>
                </span>
              </p>
            </div>
          </div>
          <div class="mpr-workspace-margin mpr-aws-text">
            <mat-icon class="mpr-aws-info-icon">info </mat-icon>
            Max length allowed for a SageMaker training job name is 63
            characters, the first 37 characters are taken by the DL user id. Use
            the remaining space to name your job.<br />
            Naming Convention: DL_user_id-<<strong
              >Enter a custom job name</strong
            >><br />
            E.g., 279c7d80-9075-4bce-a4df-84c1274f94d9-<strong>mynewjob</strong>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #ReprovisionConfirmInnerContent let-data>
      <div class="f-w-700 m-b-16">
        A newer version of the EC2 Instance is now available. Updating to the
        latest version provides the latest features, security updates, and bug
        fixes.
      </div>
      <div class="m-b-16">
        <span class="f-w-700">Note:</span> By choosing to update, your current
        EC2 instance will be terminated. To avoid losing work, please move any
        data from your current EC2 instance to your project staging location and
        push all your code to GitHub. Once the instance is updated, you can pull
        your code from GitHub and access the data from your project's staging
        location to continue working as before.
      </div>
      <div>Would you like to update your EC2 Instance now?</div>
    </ng-template>
  </div>
  <div
    class="use-workspace-box"
    id="ec2_workspaceDetails_container"
    *ngIf="
      ec2Status === launchEc2Enum.PROVISIONING ||
      ec2Status === launchEc2Enum.NOT_PROVISIONED ||
      ec2Status === launchEc2Enum.TERMINATED_STATUS ||
      ec2Status === launchEc2Enum.FAILED
    "
  >
    <div class="use-workspace-image">
      <img src="./assets/images/workspace-man-with-computer.png" alt="" />
    </div>
    <div class="use-workspace-content">
      <div class="use-workspace-heading">USE EC2 INSTANCE FOR:</div>
      <div class="use-workspace-list">
        <ul>
          <li>
            Write and execute code for data analytics in R (RStudio) or Python
            (Jupyter).
          </li>
          <li>Access project data and public datasets.</li>
          <li>
            Collaborate with other developers via GitHub and share reusable
            code.
          </li>
          <li>
            Leverage cloud computing to automate large, compute intensive
            workloads.
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
