import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent {
  public isTermsPage = false;
  public showTitle = false;

  constructor(private route: ActivatedRoute, private router: Router) {
    // FirstChild always has the data even in case of the / route
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showTitle = route.snapshot.firstChild?.data['title'] ?? false;
        this.isTermsPage = this.router.url.includes('/accept-terms');
      }
    });
  }
}
