import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { SkipMainContentService } from '@shared';
import { UserProject } from '@theme/interfaces';
import {
  FeatureFlagsState,
  LoadProjectSurveys,
  LoadSelectedProjectMetadata,
  ProjectState,
  ProjectSurveyModel,
  ProjectSurveyState,
  ResetProjectSurveys,
  ResetSelectedProjectMetadata,
  UserProjectState,
  GetGobalAttributes,
  GlobalAttributesModel,
  GlobalAttributesState,
  ResetGobalAttributes,
  ManageRDSClusterState,
  RDSClusters,
  LoadRDSClusters,
  ResetManageRDSClusters
} from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import ProjectModel from 'app/state/project/project-state-model';
import { catchError, Observable, throwError, withLatestFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss'],
})
export class ProjectViewComponent implements OnInit, OnDestroy {
  @Select(GlobalAttributesState.returnGlobalAttributes)
  public globalAttributes$?: Observable<GlobalAttributesModel[]>;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectState.getSelectedProjectData)
  public projectData$!: Observable<ProjectModel>;
  @Select(ProjectSurveyState.GetProjectSurveyLoader)
  public projectSurveyLoader$?: Observable<boolean>;
  @Select(ProjectSurveyState.GetProjectSurveys)
  public projectSurveys$?: Observable<ProjectSurveyModel[] | []>;
  @Select(ManageRDSClusterState.GetRDSClusters)
  public rdsClusters$?: Observable<RDSClusters[] | []>;
  @Select(ManageRDSClusterState.GetManageRDSLoader)
  public rdsClustersLoader$?: Observable<boolean>;

  @ViewChild('skipper') public skipper!: ElementRef;
  public encodedProjectId: string;
  public isPlatformAdmin = true;
  public panelOpenState = true;
  public projectId: string;
  public showSpinner = true;
  public userRole = UserProjectRoleEnum.PLATFORM_ADMIN;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public store: Store,
    private skipMainContentService: SkipMainContentService,
  ) {
    this.projectId = this.route.snapshot.params['projectId'];
    this.encodedProjectId = this.projectId
      ? btoa(this.route.snapshot.params['projectId'])
      : '';
  }
  public getAttributeValue(projectData : ProjectModel, attribute : GlobalAttributesModel) : string {
    if(projectData.projectAttributes){
      const filteredAttribute = projectData.projectAttributes.filter((attr: any) => attr.attributeId === attribute.attributeId);
      if(filteredAttribute.length > 0)
        return filteredAttribute[0]['attributeValue']
    }
    return 'N/A'
  }
  public navigateToRoute(routeStr?: string): void {
    if (!routeStr) {
      routeStr =
        this.userRole === UserProjectRoleEnum.ADMIN
          ? `/admin/project/edit`
          : `/platformAdmin/projects/edit/${this.projectId}`;
    } else if (routeStr === 'view-all') {
      routeStr = this.encodedProjectId
        ? `/platformAdmin/users/list/${this.encodedProjectId}`
        : `/admin/users`;
    } else if (routeStr === 'project-survey') {
      routeStr = `platformAdmin/projects/project-survey/${this.projectId}`;
    } else if (routeStr === 'manage-rds') {
      routeStr = this.isPlatformAdmin? `rds/platform-admin/manage-rds/${this.projectId}` : `rds/manage-rds`;
    }
    this.router.navigate([routeStr]);
  }

  ngOnDestroy(): void {
    // Ensure we have clean slate when user goes off with this.
    if (this.userRole !== UserProjectRoleEnum.ADMIN) {
      this.store.dispatch(new ResetSelectedProjectMetadata());
    }
    if (this.showProjectSurveys()) {
      this.store.dispatch(new ResetProjectSurveys());
    }
    if(this.showAccountingDetails()) {
      this.store.dispatch(new ResetGobalAttributes())
    }
    this.store.dispatch(new ResetManageRDSClusters());
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetSelectedProjectMetadata());
    if (!this.projectId) {
      this.project$?.subscribe((project: UserProject) => {
        this.projectId = project.projectId;
        if (project.roleName === UserProjectRoleEnum.ADMIN) {
          this.userRole = UserProjectRoleEnum.ADMIN;
          this.isPlatformAdmin = false;
        }
      });
    }
    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.PROJECTID] = this.projectId;
    requestHeaders[HeaderParams.ROLENAME] = this.userRole;
    this.store.dispatch(new LoadSelectedProjectMetadata(requestHeaders))
    .pipe(
      withLatestFrom(this.projectData$),
      catchError((err) => throwError(() => new Error(''))
      )
    )
    .subscribe(([_, res]) => {
      if(this.showRDS(res)){
        this.store.dispatch(new LoadRDSClusters(requestHeaders))
      }
    });
    if (this.showProjectSurveys())
      this.store.dispatch(new LoadProjectSurveys(requestHeaders));
    if(this.showAccountingDetails())
      this.store.dispatch(new GetGobalAttributes(requestHeaders))
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.nativeElement.focus();
    });
  }

  public showAccountingDetails(): boolean {
    return this.store
              .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
              .isAccountingFeatureFlagEnabled.toLowerCase() === 'true';
  }

  public showProjectSurveys(): boolean {
    return (
      this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .isForstaFeatureFlagEnabled.toLowerCase() === 'true'
    );
  }

  public showRDS(projectData : ProjectModel): boolean {
    const featureFlags: FeatureFlagsStateModel = this.store
    .selectSnapshot(FeatureFlagsState.returnFeatureFlags);
    return (
      featureFlags.rdsFeatureFlag.toLowerCase() === 'true' && 
      featureFlags.serviceAccountFeatureFlag.toLowerCase() === 'true' && 
      projectData.rdsEnabled && projectData.serviceAccountEnabled

    );
  }
}
