import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { DashboardLoaderStateModel } from './dashboard-loader-state-model';
import { SetDashboardLoaderState } from './dashboard-loader.actions';

const stateDefaults: DashboardLoaderStateModel = {
  showLoader: false,
};

@State<DashboardLoaderStateModel>({
  defaults: stateDefaults,
  name: 'DashboardLoaderState',
})
@Injectable()
export class DashboardLoaderState {
  constructor() {}

  @Selector()
  public static getDashboardLoaderState(state: DashboardLoaderStateModel): any {
    return state.showLoader;
  }

  @Action(SetDashboardLoaderState)
  public setDashboardLoader(
    ctx: StateContext<DashboardLoaderStateModel>,
    { showLoader }: DashboardLoaderStateModel
  ): Observable<any> {
    ctx.patchState({ showLoader });
    return of(showLoader);
  }
}
