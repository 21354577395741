import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ResetScheduleState } from 'app/state/uploads/schedule/schedule.action';
import { take } from 'rxjs';
import { UploadsService } from '../../../uploads/services';
import { Router } from '@angular/router';

@Component({
  selector: 'mpr-initiate-create-schedule',
  templateUrl: './initiate-create-schedule.component.html',
  styleUrls: ['./initiate-create-schedule.component.scss'],
})
export class InitiateCreateScheduleComponent implements OnInit, OnDestroy {
  public description: string;
  public scheduleCreated = false;
  public scheduleCreationPending = true;
  public title: string;
  private scheduleType = '';

  constructor(private store: Store, private uploadsService: UploadsService, private router : Router) {
    this.title = 'Preparing Schedule Request';
    this.description =
      'Please wait while we register your request for the creating a schedule.';
      this.scheduleType = this.router.url.includes('staging') ? 'staging' : '';
  }

  public navigateToRecurringSchedule(): void {
    const routePath = this.scheduleType==='staging' ? 'staging/schedule':'uploads/schedule';
    this.router.navigate([routePath]);
  }

  ngOnDestroy(): void {
    // Ensure we have clean slate when user starts off with this.
    this.store.dispatch(new ResetScheduleState());
  }

  ngOnInit(): void {
    this.initialiseScheduleCreation();
  }

  private initialiseScheduleCreation(): void {
    // To make Async possibly move to the State and just trigger from here.
    // Currently this info is not needed elsewhere and sync is fine.
    // take(1) last emitted value needs no unsubscribe
    this.uploadsService
      .createSchedule(this.scheduleType)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.scheduleCreated = true;
          this.scheduleCreationPending = false;
          this.title = 'Recurring upload created';
          this.description =
            'Data files will be uploaded per the recurring schedule. You will receive an email notification after every job completion. You can also check the upload status for the recurring job from the Upload History.';
        },
        error: (err: any) => {
          this.scheduleCreationPending = false;
          this.title = 'Recurring upload creation failed';
          this.description = 'Schedule creation failed';
        },
      });
  }
}
