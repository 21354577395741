import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core/enums';
import { Store } from '@ngxs/store';
import { LoggedinUserState } from 'app/state';
import { LoadUserInfodata } from 'app/state/user-info/user-info.actions';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserInfoGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private store: Store) {}

  public canActivate(): Observable<boolean> {
    return this.hasUserInfo();
  }

  public canActivateChild(): Observable<boolean> {
    return this.hasUserInfo();
  }

  private hasUserInfo(): Observable<boolean> {
    const roleName = this.store.selectSnapshot(
      UserInfoState.getCurrentUserRole
    );
    if (roleName !== UserProjectRoleEnum.DEFAULT) return of(true);
    const userId = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserId
    );
    return this.store
      .dispatch(new LoadUserInfodata(userId))
      .pipe(map(() => true));
  }
}
