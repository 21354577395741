import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { JobOperationTypeEnums } from '@core/enums';
import { Store } from '@ngxs/store';
import { ResetUploadsState } from 'app/state';
import { Subject, take, takeUntil } from 'rxjs';
import {
  CreateJobResponseModel,
  PresignedUrlPayloadModel,
} from '../interfaces';
import { UploadsService } from '../services';

@Component({
  selector: 'mpr-onpremise-upload',
  templateUrl: './on-premise-upload.component.html',
  styleUrls: ['../../../styles/custom/_mpr-dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OnPremiseUploadComponent implements OnInit, OnDestroy {
  public jobOperationType = '';
  public jobOperationTypeStaging =
    JobOperationTypeEnums.JOB_OPERATION_TYPE_STAGING;
  public maxSizeforWarning = 1073741824;
  // Object to maintain file upload stats
  public progressInfo = {
    attempted: 0,
    completedFiles: 0,
    completedAll: false,
    failedFiles: 0,
    jobId: '',
    noOfUrls: 0,
    totalFiles: 0,
    totalPercentCompleted: 0,
    totalUploadSize: 0,
    uploadTitle: 'Preparing Upload..',
    uploadFailed: false,
  };
  public warningSizeReached = false;

  // Private
  private destroyed$ = new Subject<boolean>();
  private totalContentSizePerFile: number[] = [];
  private totalSize = 0;
  private uploadCompleted: number[] = [];

  constructor(
    private store: Store,
    private uploadsService: UploadsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // Confirm if User is on staging page then set jobOperationType
    if (this.router.url.includes('/staging'))
      this.jobOperationType =
        JobOperationTypeEnums.JOB_OPERATION_TYPE_UPLOAD_TO_STAGING;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    sessionStorage.removeItem('long-operation-in-progress');

    // Ensure we have clean slate when user starts off with this.
    this.store.dispatch(new ResetUploadsState());
  }

  ngOnInit(): void {
    sessionStorage.removeItem('long-operation-in-progress');
    // We just take the local files.
    const selectedFiles: File[] =
      this.store.snapshot().UploadsState.selectedFiles;

    this.progressInfo.totalFiles = selectedFiles.length;
    this.progressInfo.totalUploadSize = selectedFiles.reduce<number>(
      (sum, file: File) => sum + file.size,
      0,
    );
    this.progressInfo.noOfUrls =
      this.store.snapshot().UploadsState.uploadFilesForm.noOfUrls;
    if (this.progressInfo.totalUploadSize > this.maxSizeforWarning) {
      this.warningSizeReached = true;
    }
    this.initialiseFileUploads(selectedFiles);
  }
  public proceedToOneTimeJobDetails(jobOperationType = 'uploads'): void {
    const projectDetails = this.data.datasetDetails;
    if (projectDetails) {
      this.router.navigate([
        `/${jobOperationType}/job-details/onetime/dataset/${this.progressInfo.jobId}/${projectDetails}`,
      ]);
    } else {
      this.router.navigate([
        `/${jobOperationType}/job-details/onetime/${this.progressInfo.jobId}/`,
      ]);
    }
  }

  // Update Backend that we are done.
  private completeUploadFlow(status: string): void {
    sessionStorage.removeItem('long-operation-in-progress');
    this.uploadsService
      .updateJobUploadStatus(
        this.progressInfo.jobId,
        status,
        this.data.requestHeader,
        this.jobOperationType,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: any) => {
          // Do something with response
          // Only when final call goes consider upload as successful.
          // If upload has failed then its not complete
          this.progressInfo.completedAll = !this.progressInfo.uploadFailed;
        },
        error: (err: any) => {
          this.failedUpload();
        },
      });
  }

  private failedUpload(): void {
    sessionStorage.removeItem('long-operation-in-progress');
    this.progressInfo.uploadTitle = 'Upload Failed';
    this.progressInfo.uploadFailed = true;
  }

  // Handle Completions
  private handleCompletedResponse(type: string): void {
    if (type === 'error') {
      this.progressInfo.failedFiles += 1;
    } else {
      this.progressInfo.completedFiles += 1;
    }

    this.progressInfo.attempted += 1;

    // In case we are done attempting all lets trigger completion API call
    if (this.progressInfo.attempted === this.progressInfo.totalFiles) {
      // By default all is well
      let status = 'successful';
      this.progressInfo.uploadTitle = 'Upload Completed';

      // Even if 1 file failed the job is failed.
      if (this.progressInfo.failedFiles > 0) {
        this.failedUpload();
        status = 'failed';
      }

      this.completeUploadFlow(status);
    }
  }

  // Handle the Progress Event
  private handleUploadProgressEvent(
    loadedValue: number,
    totalContentSize: number,
    fileIndex: number,
  ): void {
    // Track each file upload :
    // Upload Progess gets called multiple times for the same file keep oveeriding latest total.
    this.uploadCompleted[fileIndex] = loadedValue;
    // Track total : override earlier total
    this.totalContentSizePerFile[fileIndex] = totalContentSize;
    // Now go over all the file upload progress since this is async
    const totalSoFar = this.uploadCompleted.reduce(
      (accumulator, current) => accumulator + current,
      0,
    );
    // Total File sizes so far
    const totalSize = this.totalContentSizePerFile.reduce(
      (sum, currentFileTotalSize) => sum + currentFileTotalSize,
      0,
    );
    // Calculate percentage for overall progress bar
    this.progressInfo.totalPercentCompleted = 100 * (totalSoFar / totalSize);
  }

  // Start off upload flow : Perhaps Separate Service for file-uploads and when we revisit
  private initialiseFileUploads(selectedFiles: File[]): void {
    // To make Async possibly move to the State and just trigger from here.
    // Currently this info is not needed elsewhere and sync is fine.
    // take(1) last emitted value needs no unsubscribe
    this.uploadsService
      .createJob(this.data.requestHeader)
      .pipe(take(1))
      .subscribe({
        next: (s3Parms: CreateJobResponseModel) => {
          const s3Payload = s3Parms.message;
          this.progressInfo.jobId = s3Payload.jobId;
          this.progressInfo.uploadTitle = 'Upload In-progress';
          sessionStorage.setItem('long-operation-in-progress', '1');
          selectedFiles.map((file: File, fileIndex) => {
            let newFileName;
            if (s3Payload.oldNewFileNameList) {
              const oldNewFile = s3Payload.oldNewFileNameList.filter(
                (entry) => entry.oldFile === file.name,
              );
              if (oldNewFile.length > 0) newFileName = oldNewFile[0].newFile;
            }

            // Check how to best optimise this code: possibily forkJoin is better instead of mapping.
            this.uploadFileToS3(file, s3Payload, fileIndex, newFileName);
          });
        },
        error: (err: any) => {
          this.failedUpload();
        },
      });
  }

  // Handle upload of one file
  private uploadFileToS3(
    file: File,
    s3Payload: PresignedUrlPayloadModel,
    fileIndex: number,
    newFileName?: string | undefined,
  ): void {
    this.uploadsService
      .s3FileUploadFromLocal(file, s3Payload, newFileName)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (event: any) => {
          // If browser is telling about the upload progress
          if (event.type === HttpEventType.UploadProgress) {
            this.handleUploadProgressEvent(
              event.loaded,
              event.total,
              fileIndex,
            );
            // If its trying to denote a request response
          } else if (event instanceof HttpResponse) {
            this.handleCompletedResponse('success');
          }
        },
        error: (err: any) => {
          this.handleCompletedResponse('error');
        },
      });
  }
}
