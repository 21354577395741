import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { BrandingComponent } from './widgets/branding.component';
import { HeaderComponent } from './header/header.component';
import { MprAppNameComponent } from './widgets/mpr-app-name.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { SubheaderProjectSelectComponent } from './sideheader-project-select/subheader-project-select.component';
import { UserComponent } from './widgets/user.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  declarations: [
    AdminLayoutComponent,
    AlertMessageComponent,
    BrandingComponent,
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
    MprAppNameComponent,
    SidebarComponent,
    SidemenuComponent,
    SubheaderComponent,
    SubheaderProjectSelectComponent,
    UserComponent,
  ],
  imports: [SharedModule],
})
export class ThemeModule {}
