<div
  class="mpr-dashboard-loader"
  *ngIf="showSpinner$ | async; else showProjectUsers"
>
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</div>
<ng-template #showProjectUsers>
  <mpr-list-details
    [listType]="listType"
    [listDetails]="projectUsersList$"
    [isFromListPage]="isFromListPage"
  ></mpr-list-details>
</ng-template>
