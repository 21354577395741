/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
import { MprHttpHeaderModal } from '@core/interfaces';
export class LoadProjectSurveys {
  public static type = '[ProjectSurvey] List ';
  constructor(public requestHeaders: MprHttpHeaderModal) {}
}
 
export class ResetProjectSurveys {
  public static type = '[ProjectSurvey] Reset List ';
}
 
export class AddNewMapping {
  public static type = '[Project Survey] New Mapping';
  constructor(public headers : MprHttpHeaderModal, public surveyId : string, public surveyName :  string, public projectId : string) {}
}

export class EditMapping {
  public static type = '[Project Survey] Edit Mapping';
  constructor(public headers : MprHttpHeaderModal, public surveyId : string, public surveyName :  string, public projectSurveyId : string ) {}
}
 
export class DeleteProjectSurvey {
  public static type = '[ProjectSurvey] Delete Project Survey ';
  constructor(
    public projectSurveyId: string,
    public requestHeaders: MprHttpHeaderModal,
  ) {}
}
