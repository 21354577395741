export const environment = {
  production: false,
  prodEnvUrl: 'https://mdataplatform.com',
  apiBaseUrl: 'https://api.test.mdataplatform.com/',
  ckanUrl: 'https://catalog.test.mdataplatform.com', //base path // new CKAN instance
  ckanLoginPath: '/user/login', //user login path
  snowflakeUrl: 'https://ac82321-wjb06002.snowflakecomputing.com',
  useHash: false,
  plugins: [],
  appendEmailToBaseUrl: 'UI_User@mathematica-mpr.com',
  appName: 'Mquiry',
  appLogoUrl: 'assets/images/application-logo.png',
  useAcceleratedS3Endpoint: true,
  auth0ClientId: 'MoFqMCdF7j800ZA9bNh5hx3tbR8sAO73',
  auth0Domain:'https://auth.test.mdataplatform.com/',
  auth0ClientSecret: 'GXZ5Xeorg86l6IgAfMWsQ_cPDxe1euci33NA3RctF_gDufiDhuDnOudh0wvMDLSf',
  maxLocalFileDownloads: 30,
  ec2idleTime: '1 hour',
  helpPageNowUrl: 'https://help.test.mdataplatform.com/#/',
  termsOfUsePageURL: 'https://help.test.mdataplatform.com/#/terms-of-use-and-privacy-policy',
  externalTermsOfUsePageURL: 'https://help.test.mdataplatform.com/#/external-terms-of-use-and-privacy-policy',
  supportEmailId:
    'MquirySupport@mathematica-mpr.com',
  workspaceChangeServiceNowUrl:
    'https://mathematica.service-now.com/mpr/?id=sc_cat_item&sys_id=0900b3001ba2911040eec801604bcb7d&sysparm_category=faef17711b1e595040eec801604bcbd8',
  projectS3BucketName: 'mm-project-data-psl-qa',
  env_shortName: 'TEST'
};
