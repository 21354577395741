import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const noSpaceInBetweenStringValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const isSpaceBeweenString =
      ((control && control.value) || '').indexOf(' ') >= 0;
    const isValid = !isSpaceBeweenString;
    return isValid ? null : { spaceInBetween: true };
  };
