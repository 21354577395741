/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetAWSDetails {
    public static type = '[AWSDetails] Get AWS Details ';
  }

export class ResetAWSDetails {
    public static type = '[AWSDetails] Reset AWS Details ';
  }

