import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams } from '@core/interfaces';
import { environment } from '@env/environment';
import {
  ContentPickerDetails,
  CommonResponseModel,
  ProjectRequestHeaders,
} from '@shared/interfaces';
import { UserProject } from '@theme/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class S3ContentPickerService {
  constructor(private http: HttpClient) {}

  public addS3Folder(prefix: any): Observable<CommonResponseModel> {
    return this.http.post<CommonResponseModel>(
      '/file_manager',
      prefix
    );
  }

  public deleteS3Folder(
    prefix: any
  ): Observable<CommonResponseModel> {
    return this.http.delete<CommonResponseModel>('/file_manager', {
      body: prefix,
    });
  }

  public getS3ContentPicker(
    contentPickerDetails?: ContentPickerDetails,
    optionalHeaders?: ProjectRequestHeaders,
    selectedProject?: UserProject,
    showDefaultProjectBucket?: boolean,
    pageNo?: number | undefined
  ): Observable<any> {
    let requestUrl = `/file_manager?`;
    if (
      contentPickerDetails &&
      contentPickerDetails.name &&
      !contentPickerDetails.path
    ) {
      requestUrl += `bucketName=${contentPickerDetails.name}`;
    } else if (
      contentPickerDetails &&
      contentPickerDetails.name &&
      contentPickerDetails.path &&
      selectedProject
    ) {
      const bucketName = environment.projectS3BucketName;
      let prefixName = selectedProject?.projectId + '_staging';
      if (contentPickerDetails.path.includes('/')) {
        const path = contentPickerDetails.path.split('/');
        let subPath = '';
        for (let i = 1; i < path.length - 1; i++) {
          subPath += `${path[i]}/`;
        }
        prefixName = subPath
          ? `${subPath}${contentPickerDetails.name}`
          : contentPickerDetails.name;
      }
      requestUrl += `bucketName=${bucketName}&prefixName=${prefixName}`;
    } else if (
      contentPickerDetails &&
      contentPickerDetails.name &&
      contentPickerDetails.path
    ) {
      let bucketName = contentPickerDetails.path;
      let prefixName = contentPickerDetails.name;
      if (contentPickerDetails.path.includes('/')) {
        const path = contentPickerDetails.path.split('/');
        let subPath = '';
        for (let i = 1; i < path.length - 1; i++) {
          subPath += `${path[i]}/`;
        }
        bucketName = path[0];
        prefixName = subPath
          ? `${subPath}${contentPickerDetails.name}`
          : contentPickerDetails.name;
      }
      requestUrl += `bucketName=${bucketName}&prefixName=${prefixName}`;
    } else if (selectedProject && showDefaultProjectBucket) {
      // In case the user is picking up staging and he has a project.
      const bucketName = environment.projectS3BucketName;
      const prefixName = selectedProject?.projectId + '_staging/';
      requestUrl += `bucketName=${bucketName}`;
    } else if (selectedProject) {
      const bucketName = environment.projectS3BucketName;
      const prefixName = selectedProject?.projectId + '_staging/';
      requestUrl += `bucketName=${bucketName}&prefixName=${prefixName}`;
    }

    if (pageNo) requestUrl += `&pageNumber=${pageNo}`;

    if (optionalHeaders) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, optionalHeaders.projectId ?? '')
        .append(HeaderParams.ROLENAME, optionalHeaders.roleName ?? '');
      return this.http.get<any>(`${requestUrl}`, { headers });
    }
    return this.http.get(`${requestUrl}`);
  }

  public renameS3Folder(
    data: any
  ): Observable<CommonResponseModel> {
    return this.http.put<CommonResponseModel>(
      '/file_manager',
      data
    );
  }
}
