<div fxLayout="column" class="mpr-mat-dialog-confirm mpr-mat-dialog-small">
  <h1 mat-dialog-title *ngIf="data.confirmTitle">{{ data.confirmTitle }}</h1>
  <div mat-dialog-content>
    <ng-container
      *ngIf="data.innerContent; else innerContent"
      [ngTemplateOutlet]="data.innerContent"
    ></ng-container>
    <ng-template #innerContent>
      <div>
        <span [innerHTML]="data.confirmMessage"></span>
        <span
          class="f-w-900"
          *ngIf="data.confirmAction"
          [innerHTML]="data.confirmAction"
        ></span>
      </div>
      <div *ngIf = "data.showCheckboxWithConfirmationMessage && data.showCheckboxWithConfirmationMessage !== ''">
        <label class="checkbox-label-container">
        <mat-checkbox class= "mpr-confrimation-checkbox" [(ngModel)] = "confirmationMessage" aria-label="Confirmation Checkbox">
        </mat-checkbox>
        <span class="mpr-confrimation-checkbox-label">{{data.showCheckboxWithConfirmationMessage}}</span>
      </label>
      </div>
    </ng-template>
  </div>
  <div
    [class.mpr-custom-innercontent]="data.innerContent"
    class="mpr-progressbar-actions"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="flex-end center"
  >
    <div *ngIf="disableConfirmShowLoader">
      <mat-spinner diameter="30" color="accent"></mat-spinner>
    </div>
    <div>
      <button
        mat-button
        mat-dialog-close
        class="mpr-button mpr-cancel-button"
        id="mpr-cancel"
      >
        {{ data.cancelButtonText || 'Cancel' }}
      </button>
    </div>
    <div>
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        id="mpr-confirm"
        [class]="disableConfirmShowLoader || (data.showCheckboxWithConfirmationMessage && !confirmationMessage) ? 'mpr-disabled' : ''"
        (click)="confirm()"
      >
        {{ data.confirmButtonText || 'Confirm' }}
      </button>
    </div>
  </div>
</div>
