/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetBillingList {
    public static type = '[BillingState] Get Billing State ';
  }

  export class ResetBillingList {
    public static type = '[BillingState] Reset Billing State ';
  }

  export class GetMonthlyBilling {
    public static type = '[BillingState] Get Monthly Billing';
    constructor(public statementPeriod: string) {}
  }

  export class ResetMonthlyBilling {
    public static type = '[BillingState] Reset Monthly Billing';
  }