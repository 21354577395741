<div class="mpr-metadata-container">
  <div class="mpr-form">
    <form [formGroup]="metaDataForm">
      <div class="mpr-form-block">
        <div class="mpr-form-block-field">
          <label for="datasetName" class="mpr-form-block-field-label"
            >DATASET NAME<sup>*</sup>
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="datasetNameInfo"
              [mprMtxTooltipAriaDescribedBy]="datasetNameInfo"
              #tooltip="mtxTooltip"
              aria-label="Dataset Name Information"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>

            <span class="mpr-info-text mpr-textarea-info-text">
              This will appear as the dataset name on the catalog search
              results.</span
            ></label
          >
          <mat-form-field class="mpr-width-percentage-100" appearance="outline">
            <textarea
              id="datasetName"
              formControlName="datasetName"
              matInput
              class="mpr-form-block-field-textarea"
              required
              maxlength="100"
              rows="4"
              placeholder="e.g. “Pregnant women enrolled in Medicaid in 2017”; “High Schools in Alabama, Mississippi and Georgia”; “Quality Measures for all hospitals accredited by JCAHO”"
            ></textarea>
          </mat-form-field>
          <div
            class="mpr-form-block-field-wordcount"
            [ngClass]="
              metaDataForm.controls['datasetName'].value.length === 100
                ? 'mpr-error-color'
                : ''
            "
          >
            {{ metaDataForm.controls['datasetName'].value.length }}/100
            characters
          </div>
          <mat-error
            *ngIf="
              metaDataForm.controls['datasetName'].touched &&
              metaDataForm.controls['datasetName'].errors?.['required']
            "
            >Please enter dataset name.</mat-error
          >
          <mat-error
            *ngIf="
              metaDataForm.controls['datasetName'].touched &&
              !metaDataForm.controls['datasetName'].errors?.['required'] &&
              metaDataForm.controls['datasetName'].errors?.['whitespace']
            "
            >Please enter a valid dataset name.</mat-error
          >
        </div>
        <div class="mpr-form-block-field">
          <label for="shortDescription" class="mpr-form-block-field-label"
            >SHORT DESCRIPTION
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="shortDescInfo"
              [mprMtxTooltipAriaDescribedBy]="shortDescInfo"
              #tooltip="mtxTooltip"
              aria-label="Short Description Information"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>
          </label>
          <mat-form-field class="mpr-width-percentage-100" appearance="outline">
            <textarea
              id="shortDescription"
              formControlName="shortDescription"
              matInput
              class="mpr-form-block-field-textarea"
              maxlength="500"
              placeholder="Additional Data, e.g. Original File, Added Column X"
            ></textarea>
          </mat-form-field>
          <div
            class="mpr-form-block-field-wordcount"
            [ngClass]="
              metaDataForm.controls['shortDescription'].value.length === 500
                ? 'mpr-error-color'
                : ''
            "
          >
            {{ metaDataForm.controls['shortDescription'].value.length }}/500
            characters
          </div>
        </div>
      </div>
      <div class="mpr-form-block">
        <div class="mpr-form-block-field">
          <div class="mpr-form-block-field-label">
            AGGREGATION LEVEL<sup>*</sup>
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="aggLevelInfo"
              #tooltip="mtxTooltip"
              [mprMtxTooltipAriaDescribedBy]="aggLevelInfo"
              aria-label="Aggregation Level Information"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>
          </div>
          <mat-form-field
            class="mpr-mat-select-field mpr-width-percentage-100"
            floatLabel="never"
            [hideRequiredMarker]="hideRequiredMarker"
            appearance="outline"
          >
            <mat-select
              formControlName="aggregationLevel"
              multiple
              panelClass="mpr-select-option-panel"
              placeholder="Select Aggregation Level"
              #aggregations
              (selectionChange)="matSelectChange('aggregationLevels')"
            >
              <mat-select-trigger>
                <mat-chip-list
                  aria-label="AGGREGATION level"
                  role="grid"
                  data-ally="aggregationLevels"
                >
                  <mat-chip
                    *ngFor="
                      let agg of metaDataForm.controls['aggregationLevel'].value
                    "
                    (removed)="removeAggregation(agg)"
                    role="row"
                  >
                    <div role="gridcell">
                      {{ agg }}
                    </div>
                    <div role="gridcell">
                      <button matChipRemove aria-label="Cancel Info">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option
                *ngFor="let level of aggregationLevelsList$ | async"
                [value]="level"
              >
                {{ level }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            class="mpr-error-padding-top-4px"
            *ngIf="
              metaDataForm.controls['aggregationLevel'].touched &&
              metaDataForm.controls['aggregationLevel'].value.length === 0
            "
            >Please select aggregation level.</mat-error
          >
        </div>
        <div class="mpr-form-block-field">
          <div class="mpr-form-block-field-label">
            FOCUS AREA<sup>*</sup>
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="focusAreaInfo"
              #tooltip="mtxTooltip"
              [mprMtxTooltipAriaDescribedBy]="focusAreaInfo"
              aria-label="Foucs Area Information"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>
          </div>

          <mat-form-field
            class="mpr-mat-select-field mpr-width-percentage-100"
            floatLabel="never"
            [hideRequiredMarker]="hideRequiredMarker"
            appearance="outline"
          >
            <mat-select
              formControlName="focusArea"
              multiple
              panelClass="mpr-select-option-panel"
              placeholder="Select Focus Area"
              (selectionChange)="matSelectChange('focusAreas')"
            >
              <mat-select-trigger>
                <mat-chip-list
                  role="grid"
                  #chipList
                  aria-label="Focus Area"
                  data-ally="focusAreas"
                >
                  <mat-chip
                    *ngFor="
                      let focusArea of metaDataForm.controls['focusArea'].value
                    "
                    (removed)="removeFocusArea(focusArea)"
                    role="row"
                  >
                    <div role="gridcell">
                      {{ focusArea }}
                    </div>
                    <div role="gridcell">
                      <button matChipRemove aria-label="Cancel Info">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <mat-option
                *ngFor="let focusArea of focusAreas$ | async"
                [value]="focusArea"
              >
                {{ focusArea }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            class="mpr-error-padding-top-4px"
            *ngIf="
              metaDataForm.controls['focusArea'].touched &&
              metaDataForm.controls['focusArea'].errors?.['required']
            "
            >Please select focus area.</mat-error
          >
        </div>
      </div>
      <div class="mpr-form-block">
        <div class="mpr-form-block-field">
          <div class="mpr-form-block-field-label mpr-width-percentage-100">
            DATA ACCESS CLASSIFICATION
            <sup>*</sup>
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="dataAccessInfo"
              [mprMtxTooltipAriaDescribedBy]="dataAccessInfo"
              #tooltip="mtxTooltip"
              aria-label="Data Access Classification"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>
          </div>

          <div class="d-flex mpr-form-fields-row">
            <div class="mpr-form-fields-row-items">
              <mat-form-field
                class="mpr-mat-select-field"
                floatLabel="never"
                [ngClass]="
                  showDataAccessGroupsDropdown
                    ? 'mpr-width-percentage-95'
                    : 'mpr-width-percentage-100'
                "
                [hideRequiredMarker]="hideRequiredMarker"
                appearance="outline"
              >
                <mat-select
                  formControlName="dataAccessClassification"
                  panelClass="mpr-select-option-panel"
                  (selectionChange)="dataAccessClassificationChange($event)"
                  placeholder="Select Data Access Classification"
                >
                  <mat-select-trigger>{{
                    dataAccessClassificationDisplayText
                  }}</mat-select-trigger>
                  <mat-option
                    *ngFor="let access of dataAccessClassificationList"
                    [value]="access"
                  >
                    {{ access }}
                  </mat-option>
                  <mat-option
                    *ngFor="let grp of projectRestrictedGroups"
                    [value]="grp"
                    >{{ projectRestrictedUseDataText }} - {{ grp }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                class="mpr-error-padding-top-4px"
                *ngIf="
                  metaDataForm.controls['dataAccessClassification'].touched &&
                  metaDataForm.controls['dataAccessClassification'].errors?.[
                    'required'
                  ]
                "
                >Please select data access classification.</mat-error
              >
              <mat-error
                *ngIf="
                  metaDataForm.controls['dataAccessClassification'].errors?.[
                    'showNoDefaultGrpError'
                  ]
                "
              >
                You do not have access to the selected group. Please select
                another option.
              </mat-error>
              <mat-error
                *ngIf="
                  metaDataForm.controls['dataAccessClassification'].errors?.[
                    'publicDataError'
                  ] || metaDataForm.controls['pii'].errors?.['publicDataError']
                "
                >You have selected Public Use Data and also selected Yes for
                PII/PHI. Please correct it.
              </mat-error>
            </div>
          </div>
        </div>

        <div class="mpr-form-block-field">
          <label for="dataTags" class="mpr-form-block-field-label"
            >DATA TAGS
            <button
              class="mpr-btn-transparency"
              [mtxTooltip]="dataTagsInfo"
              #tooltip="mtxTooltip"
              [mprMtxTooltipAriaDescribedBy]="dataTagsInfo"
              aria-label="Data Tags Information"
            >
              <mat-icon class="mpr-info-icon">info </mat-icon>
            </button>
          </label>
          <mat-form-field
            class="mpr-metadata-dataTags-chip-list"
            appearance="outline"
          >
            <mat-chip-list
              role="grid"
              mprMatChipA11y
              #chipListMetaData
              aria-label="Data Tags"
              data-ally="metaTagsList"
            >
              <mat-chip
                *ngFor="let meta of metaTagsList"
                (removed)="removeChip(meta)"
                role="row"
              >
                <div role="gridcell">
                  {{ meta }}
                </div>
                <div role="gridcell">
                  <button matChipRemove aria-label="Cancel Info">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </div>
              </mat-chip>
              <span role="row">
                <input
                  id="dataTags"
                  class="mpr-mat-chip-data-tags"
                  #metaTagsInput
                  placeholder="Start typing and add comma to separate tags"
                  formControlName="dataTags"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipListMetaData"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addChip($event)"
                  [matChipInputAddOnBlur]="addOnBlur"
                  role="gridcell"
                />
              </span>
            </mat-chip-list>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="changeSelection($event)"
            ></mat-autocomplete>
          </mat-form-field>
          <mat-error
            class="mpr-error-padding-top-4px"
            *ngIf="metaDataForm.controls['dataTags'].errors?.['maxlength']"
            >Max 100 characters per tag. Please use comma to create different
            tags</mat-error
          >
          <div class="mpr-form-block-field-wordcount">
            Max 100 characters per tag
          </div>
        </div>
      </div>

      <div class="mpr-form-block">
        <div class="mpr-form-block-field">
          <div class="mpr-form-block-field-label">GEOGRAPHIC REGION</div>
          <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
            <div class="mpr-form-fields-row-items">
              <mat-form-field
                class="mpr-mat-select-field"
                [ngClass]="
                  showGeoRegionCustomValue
                    ? 'mpr-width-percentage-95'
                    : 'mpr-width-percentage-100'
                "
                floatLabel="never"
                appearance="outline"
              >
                <mat-select
                  disableOptionCentering
                  #geographicRegion
                  panelClass="mpr-select-option-panel"
                  formControlName="geographicRegion"
                  (selectionChange)="geographicRegionChanged()"
                  placeholder="Select State or Territory"
                >
                  <mat-option [value]=""
                    ><span class="mpr-placeholder-option"
                      >Select State or Territory</span
                    ></mat-option
                  >
                  <mat-option [value]="'Others'"
                    >Others (Add Custom Value)</mat-option
                  >
                  <mat-option
                    *ngFor="let region of geoRegions$ | async"
                    [value]="region"
                  >
                    {{ region }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="mpr-form-fields-row-items"
              *ngIf="showGeoRegionCustomValue"
            >
              <mat-form-field
                floatLabel="never"
                class="mpr-width-percentage-100"
                [hideRequiredMarker]="hideRequiredMarker"
                appearance="outline"
              >
                <input
                  id="customGeographicRegion"
                  formControlName="geoRegionCustomValue"
                  class="mpr-form-block-field-input"
                  matInput
                  placeholder="Add Custom Value"
                  maxlength="100"
                />
              </mat-form-field>
              <mat-error
                *ngIf="
                  metaDataForm.controls['geoRegionCustomValue'].touched &&
                  metaDataForm.controls['geoRegionCustomValue'].errors?.[
                    'required'
                  ]
                "
              >
                Please enter custom value for geographic region.</mat-error
              >
              <mat-error
                *ngIf="
                  metaDataForm.controls['geoRegionCustomValue'].touched &&
                  metaDataForm.controls['geoRegionCustomValue'].errors?.[
                    'maxlength'
                  ]
                "
              >
                Max 100 characters allowed</mat-error
              >
              <div
                class="mpr-form-block-field-wordcount"
                [ngClass]="
                  metaDataForm.controls['geoRegionCustomValue'].value.length ===
                  100
                    ? 'mpr-error-color'
                    : ''
                "
              >
                {{
                  metaDataForm.controls['geoRegionCustomValue'].value.length
                }}/100 characters
              </div>
            </div>
          </div>
        </div>
        <div class="mpr-form-block-field">
          <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
            <div class="mpr-form-fields-row-items">
              <label for="dateCreated" class="mpr-form-block-field-label"
                >Date Created
                <button
                  class="mpr-btn-transparency"
                  [mtxTooltip]="dateCreatedInfo"
                  [mprMtxTooltipAriaDescribedBy]="dateCreatedInfo"
                  #tooltip="mtxTooltip"
                  aria-label="Date Created Information"
                >
                  <mat-icon class="mpr-info-icon">info </mat-icon>
                </button>
              </label>
              <mat-form-field
                class="mpr-calender"
                floatLabel="never"
                [hideRequiredMarker]="hideRequiredMarker"
                appearance="outline"
              >
                <input
                  id="dateCreated"
                  formControlName="dateCreated"
                  class="mpr-form-block-field-input mpr-calender-input"
                  matInput
                  [matDatepicker]="picker"
                  placeholder="MM/DD/YYYY"
                  [disabled]="true"
                  [max]="maxDate"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="false"></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="mpr-form-fields-row-items">
              <div class="mpr-form-block-field-label" for="mpr-date-purchased">
                Data Purchased<sup>*</sup>
                <button
                  class="mpr-btn-transparency"
                  [mtxTooltip]="dataPurchasedInfo"
                  [mprMtxTooltipAriaDescribedBy]="dataPurchasedInfo"
                  #tooltip="mtxTooltip"
                  aria-label="Data Purchased Information"
                >
                  <mat-icon class="mpr-info-icon">info </mat-icon>
                </button>
              </div>
              <mat-radio-group
                formControlName="dataPurchased"
                id="mpr-date-purchased"
              >
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mpr-form-fields-row-items">
              <div class="mpr-form-block-field-label">
                PII/PHI<sup>*</sup>
                <button
                  class="mpr-btn-transparency"
                  [mtxTooltip]="piiPhiInfo"
                  [mprMtxTooltipAriaDescribedBy]="piiPhiInfo"
                  #tooltip="mtxTooltip"
                  aria-label="PII/PHI Information"
                >
                  <mat-icon class="mpr-info-icon">info </mat-icon>
                </button>
              </div>
              <mat-radio-group formControlName="pii">
                <mat-radio-button [value]="true">Yes</mat-radio-button>
                <mat-radio-button [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="mpr-form-block">
        <div class="mpr-form-block-field">
          <div>
            <div class="mpr-form-block-field-label mpr-width-percentage-100">
              YEAR RANGE COVERED<sup>*</sup>
              <button
                class="mpr-btn-transparency"
                [mtxTooltip]="yearRangeInfo"
                [mprMtxTooltipAriaDescribedBy]="yearRangeInfo"
                #tooltip="mtxTooltip"
                aria-label="Year Range Information"
              >
                <mat-icon class="mpr-info-icon">info </mat-icon>
              </button>
            </div>
          </div>
          <div>
            <div class="d-flex mpr-form-fields-row">
              <div class="mpr-form-fields-row-items">
                <mat-checkbox
                  formControlName="noDataSourceYearsApply"
                  class="mpr-no-year-range-checkbox"
                  (change)="noSpecificYearRangeChanged()"
                  id="mpr-no-year-range"
                  >No Specific Year Range</mat-checkbox
                >
              </div>
              <div class="mpr-form-fields-row-items">
                <label for="startYear" class="mpr-form-block-field-label"
                  >START YEAR<sup
                    *ngIf="
                      !metaDataForm.controls['noDataSourceYearsApply'].value
                    "
                    >*</sup
                  ></label
                >
                <mat-form-field
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <input
                    id="startYear"
                    formControlName="dataSourceYearsStart"
                    class="mpr-form-block-field-input"
                    matInput
                    placeholder="YYYY"
                    type="number"
                    [disabled]="
                      metaDataForm.controls['noDataSourceYearsApply'].value
                    "
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    !metaDataForm.controls['noDataSourceYearsApply'].value &&
                    metaDataForm.controls['dataSourceYearsStart'].touched &&
                    metaDataForm.controls['dataSourceYearsStart'].errors?.[
                      'required'
                    ]
                  "
                >
                  Please enter start year</mat-error
                >
                <mat-error
                  *ngIf="
                    !metaDataForm.controls['noDataSourceYearsApply'].value &&
                    metaDataForm.controls['dataSourceYearsStart'].touched &&
                    (metaDataForm.controls['dataSourceYearsStart'].errors?.[
                      'min'
                    ] ||
                      metaDataForm.controls['dataSourceYearsStart'].errors?.[
                        'max'
                      ])
                  "
                >
                  Please enter valid year in the format (YYYY)</mat-error
                >
                <mat-error
                  *ngIf="
                    !metaDataForm.controls['noDataSourceYearsApply'].value &&
                    metaDataForm.controls['dataSourceYearsStart'].touched &&
                    metaDataForm.controls['dataSourceYearsEnd'].touched &&
                    metaDataForm.controls['dataSourceYearsStart'].errors?.[
                      'minEndYear'
                    ]
                  "
                >
                  Start year should be less than end year</mat-error
                >
              </div>

              <div class="mpr-form-fields-row-items">
                <label for="endYear" class="mpr-form-block-field-label"
                  >END YEAR<sup
                    *ngIf="
                      !metaDataForm.controls['noDataSourceYearsApply'].value
                    "
                    >*</sup
                  ></label
                >
                <mat-form-field
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                  <input
                    id="endYear"
                    formControlName="dataSourceYearsEnd"
                    class="mpr-form-block-field-input"
                    matInput
                    placeholder="YYYY"
                    type="number"
                    [disabled]="
                      metaDataForm.controls['noDataSourceYearsApply'].value
                    "
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    !metaDataForm.controls['noDataSourceYearsApply'].value &&
                    metaDataForm.controls['dataSourceYearsEnd'].touched &&
                    metaDataForm.controls['dataSourceYearsEnd'].errors?.[
                      'required'
                    ]
                  "
                >
                  Please enter end year</mat-error
                >
                <mat-error
                  *ngIf="
                    !metaDataForm.controls['noDataSourceYearsApply'].value &&
                    metaDataForm.controls['dataSourceYearsEnd'].touched &&
                    (metaDataForm.controls['dataSourceYearsEnd'].errors?.[
                      'min'
                    ] ||
                      metaDataForm.controls['dataSourceYearsEnd'].errors?.[
                        'max'
                      ])
                  "
                >
                  Please enter valid year in the format (YYYY)</mat-error
                >
                <mat-error
                  *ngIf="
                    !metaDataForm.controls['noDataSourceYearsApply'].value &&
                    metaDataForm.controls['dataSourceYearsEnd'].touched &&
                    metaDataForm.controls['dataSourceYearsStart'].touched &&
                    metaDataForm.controls['dataSourceYearsEnd'].errors?.[
                      'minEndYear'
                    ]
                  "
                >
                  End Year should be greater than or equal to Start
                  Year</mat-error
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
    <div>
      <button
        mat-stroked-button
        class="mpr-button mpr-blue-button mpr-cancel-button"
        [routerLink]="returnPath"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        [disabled]="!metaDataForm.valid"
        mat-button
        class="mpr-button mpr-yellow-button"
        matStepperNext
        (click)="triggerFileUpload()"
      >
        Proceed
      </button>
    </div>
  </div>
</div>
<ng-template #dataSourePopover>
  <div class="mpr-width-px-300">
    Enter the date when the data file was created.
  </div>
</ng-template>
<ng-template #dateCreatedInfo>
  <div class="mpr-width-px-300" id="mpr-date-created">
    Enter the date when the data file was created.
  </div>
</ng-template>
<ng-template #dataPurchasedInfo>
  <div class="mpr-width-px-300" id="mpr-data-purchased">
    Indicate if the data file was purchased from an outside source.
  </div>
</ng-template>
<ng-template #piiPhiInfo>
  <div class="mpr-width-px-300" id="mpr-pii-phi">
    Indicate if the data file contains any PII/PHI data elements.
  </div>
</ng-template>
<ng-template #yearRangeInfo>
  <div class="mpr-width-px-300" id="mpr-year-range">
    Time span represented in the data file in years (YYYY).
  </div>
</ng-template>
<ng-template #datasetNameInfo>
  <div class="mpr-width-px-300" id="mpr-dataset-name">
    Provide a name that represents the data file you are uploading.
  </div>
</ng-template>
<ng-template #shortDescInfo>
  <div class="mpr-width-px-300" id="mpr-short-desc">
    Brief description about the data file. Please mention the universe or
    population included in the dataset, and any additional notes or comments
    about the data file.
  </div>
</ng-template>
<ng-template #aggLevelInfo>
  <div class="mpr-width-px-300" id="mpr-aggr-level">
    How each row in the data file is uniquely identified (e.g., National, State,
    Beneficiary, Provider, Episode, District, School, etc.)
  </div>
</ng-template>
<ng-template #focusAreaInfo>
  <div class="mpr-width-px-300" id="mpr-focus-area">
    Mathematica's focus area represented in the data file.
  </div>
</ng-template>

<ng-template #dataAccessInfo>
  <div id="mpr-data-access">
    <strong>Public use data</strong> - Raw or enriched public use data.
    <br /><br />
    <strong>Public limited data</strong> - Mathematica Purchased data available
    upon approval. <br /><br />
    <strong>Project standard data</strong> - Project level standard datasets
    that may require meeting DUA/MOU restrictions, may not contain sensitive
    restricted PII/PHI information, can only be accessed by Project Team
    Members. <br /><br />
    <strong>Project restricted data</strong> - Project level sensitive
    restricted datasets that may require meeting DUA/MOU restrictions, contain
    PII/PHI information, can only be accessed by a subset of authorized
    individuals within Projects.
  </div>
</ng-template>

<ng-template #dataTagsInfo>
  <div class="mpr-width-px-300" id="mpr-data-tags">
    User entered manual tags for categorizing their data file. E.g. Health,
    Participation, Claims etc.
  </div>
</ng-template>
