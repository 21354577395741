import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  OneTimeUploadHistoryRes,
  RecurringUploadHistoryRes,
  UploadHistoryModel,
} from '@shared/interfaces';
import { UploadHistoryService } from '@shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  GetAllOneTimeUpload,
  GetAllRecurringUpload,
  ResetHistoryState,
  SetSearchTerm,
} from './history.action';

const stateDefaults: UploadHistoryModel = {
  jobOperationType: '',
  sortKey: '',
  sortOrder: '',
  searchTerm: '',
  oneTimeUploadHistory: {
    items: [],
    lastEvaluatedKey: '',
  },
  recurringUploadHistory: {
    items: [],
    lastEvaluatedKey: '',
  },
  loadingUploadHistory: false,
};

@State<UploadHistoryModel>({
  defaults: stateDefaults,
  name: 'UploadHistoryState',
})
@Injectable()
export class UploadHistoryState {
  constructor(
    private store: Store,
    private uploadHistoryService: UploadHistoryService
  ) {}

  @Selector()
  public static getOneTimeUpload(
    state: UploadHistoryModel
  ): OneTimeUploadHistoryRes {
    return state.oneTimeUploadHistory;
  }

  @Action(GetAllOneTimeUpload)
  public getOneTimeUploads(
    ctx: StateContext<UploadHistoryModel>,
    {
      searchTerm,
      startKey,
      sortOrder,
      sortKey,
      isLoadMore,
      jobOperationType,
    }: GetAllOneTimeUpload
  ): Observable<OneTimeUploadHistoryRes> {
    ctx.patchState({ loadingUploadHistory: true });
    return this.uploadHistoryService
      .getAllOneTimeUploadHistory(
        startKey,
        sortKey,
        sortOrder,
        searchTerm,
        jobOperationType
      )
      .pipe(
        tap((oneTimeUploadHistory: OneTimeUploadHistoryRes) => {
          if (isLoadMore) {
            let items = ctx.getState().oneTimeUploadHistory.items;
            items = items.concat(oneTimeUploadHistory.items);
            oneTimeUploadHistory.items = items;
          }
          ctx.patchState({
            oneTimeUploadHistory,
            loadingUploadHistory: false,
            searchTerm,
            sortOrder,
            sortKey,
            jobOperationType,
          });
        })
      );
  }

  @Selector()
  public static getRecurringUpload(
    state: UploadHistoryModel
  ): RecurringUploadHistoryRes {
    return state.recurringUploadHistory;
  }

  @Action(GetAllRecurringUpload)
  public getRecurringUploads(
    ctx: StateContext<UploadHistoryModel>,
    {
      searchTerm,
      startKey,
      sortOrder,
      sortKey,
      scheduleType,
      isLoadMore,
    }: GetAllRecurringUpload
  ): Observable<RecurringUploadHistoryRes> {
    ctx.patchState({ loadingUploadHistory: true });
    return this.uploadHistoryService
      .getAllRecurringUploadHistory(startKey, sortKey, sortOrder, searchTerm, scheduleType)
      .pipe(
        tap((recurringUploadHistory: RecurringUploadHistoryRes) => {
          if (isLoadMore) {
            let items = ctx.getState().recurringUploadHistory.items;
            items = items.concat(recurringUploadHistory.items);
            recurringUploadHistory.items = items;
          }
          ctx.patchState({
            recurringUploadHistory,
            loadingUploadHistory: false,
          });
        })
      );
  }

  @Selector()
  public static getSearchTerm(state: UploadHistoryModel): string {
    return state.searchTerm;
  }

  @Action(ResetHistoryState)
  public resetHistoryState({
    patchState,
  }: StateContext<UploadHistoryModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetSearchTerm)
  public setSearchTerm(
    ctx: StateContext<UploadHistoryModel>,
    { searchTerm }: SetSearchTerm
  ): void {
    ctx.patchState({ searchTerm });
  }
}
