import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import * as dayjs from 'dayjs';

@Pipe({ name: 'utcDate' })
export class UTCDatePipe implements PipeTransform {
  public transform(
    date: Date | string,
    format: string = 'MM/d/yy hh:mm a zzzz',
    utcString: string = 'Z'
  ): string {
    // Firefox expects a standard date time format as per spec while chrome is linient hence converts it so we convert to standard first
    return formatDate(
      new Date(`${dayjs(date).format('YYYY-MM-DDTHH:mm:ss')}${utcString}`),
      format,
      'en-US'
    );
  }
}
