import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core';
import { AuthService } from '@core/services';
import { AlertMessageService } from '@core/services/alert-message.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { SkipMainContentService } from '@shared';
import { CommonResponseModel } from '@shared/interfaces';
import { UserProject } from '@theme/interfaces';
import {
  AcceptTermsState,
  LoadAllUserProjects,
  UserProjectState,
} from 'app/state';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import { Observable, Subject, Subscription, takeUntil, throwError } from 'rxjs';
import { catchError, filter, withLatestFrom } from 'rxjs/operators';

const MOBILE_MEDIAQUERY = 'screen and (max-width: 599px)';
const TABLET_MEDIAQUERY =
  'screen and (min-width: 600px) and (max-width: 959px)';
const MONITOR_MEDIAQUERY = 'screen and (min-width: 960px)';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminLayoutComponent implements OnInit {
  // Public
  @ViewChild('content', { static: true }) public content!: MatSidenavContent;
  @ViewChild('sidenav', { static: true }) public sidenav!: MatSidenav;

  @Select(UserInfoState.getCurrentUserRole)
  public userRole!: Observable<string>;

  @Select(AcceptTermsState.getAcceptTerms)
  private acceptTerms$?: Observable<CommonResponseModel>;

  @Select(UserProjectState.getAllUserProjects)
  private allProjects$!: Observable<UserProject[]>;

  @ViewChild('changeSfRoleDialog')
  private changeSfRoleDialog!: TemplateRef<any>;

  @ViewChild('uploadHistoryDialog')
  private uploadHistoryDialog!: TemplateRef<any>;

  // Current Route
  public currentRoute = '';

  // Is the User On Dashboard then the layout should show a different topbar
  public defaultProjectValue: UserProject;
  public isDashboard = false;
  public isTermsAccepted = false;
  public numberOfProjects = 0;

  // Default and currently the only possible values
  public options = {
    navPos: 'side',
    theme: 'light',
    dir: 'ltr',
    showHeader: true,
    showUserPanel: true,
    sidenavOpened: false,
    sidenavCollapsed: true,
    language: 'en-US',
  };

  // Private
  private acceptTermsData!: CommonResponseModel;
  private allProjSubscription = new Subscription();
  private destroyed$ = new Subject<boolean>();
  private isCollapsedWidthFixed = false;
  private isContentWidthFixed = true;
  private isGeneralResearcher = false;
  private isMobileScreen = false;

  constructor(
    private alertMsgService: AlertMessageService,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private skipMainContentService: SkipMainContentService,
    private router: Router,
    public store: Store,
    @Optional() @Inject(DOCUMENT) private document: Document
  ) {
    this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY, MONITOR_MEDIAQUERY])
      .subscribe((state) => {
        // SidenavOpened must be reset true when layout changes
        this.options.sidenavOpened = true;

        this.isMobileScreen = state.breakpoints[MOBILE_MEDIAQUERY];
        this.options.sidenavCollapsed = state.breakpoints[TABLET_MEDIAQUERY];
        this.isContentWidthFixed = state.breakpoints[MONITOR_MEDIAQUERY];
        this.toggleCollapsed();
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        // Close Snackbacks if any
        this.alertMsgService.dismmissAll();

        this.currentRoute = this.router.url;
        // Confirm if User is on Dashboard page then show a different header
        if (this.router.url.includes('/dashboard')) this.isDashboard = true;

        if (this.isOver) {
          this.sidenav.close();
        }
        if(!this.router.url.includes('/authenticated'))
          localStorage.removeItem('redirectURL')
        this.content.scrollTo({ top: 0 });
      });

    this.defaultProjectValue = {
      ...this.store.selectSnapshot(UserProjectState.getSelectedProject),
    };
  }

  @HostBinding('class.matero-sidenav-collapsed-fix')
  public get collapsedWidthFix(): boolean {
    return (
      this.isCollapsedWidthFixed && this.options.sidenavOpened && this.isOver
    );
  }

  @HostBinding('class.matero-content-width-fix')
  public get contentWidthFix(): boolean {
    return (
      this.isContentWidthFixed && this.options.sidenavOpened && !this.isOver
    );
  }
  public get isOver(): boolean {
    return this.isMobileScreen;
  }

  ngOnInit(): void {
    // Get latest from API when its the dashboard page or if we do not have any project list
    if (
      this.isDashboard ||
      this.store.selectSnapshot(UserProjectState.getAllUserProjects).length ===
        0
    ) {
      this.store
        .dispatch(new LoadAllUserProjects())
        .pipe(
          withLatestFrom(this.allProjects$),
          catchError((err) => {
            this.userRole.subscribe((roleName) => {
              if (roleName === UserProjectRoleEnum.DEFAULT) return;
              if (roleName === UserProjectRoleEnum.BLANK) {
                this.isGeneralResearcher = true;
                this.handleShowingOfPrivacyPolicy();
              } else {
                this.isGeneralResearcher = false;
              }
            });
            return throwError(() => new Error(''));
          })
        )
        .subscribe((projects: UserProject[]) => {
          this.numberOfProjects = projects.length;
        });
    }

    this.showAgreementPopup();

    this.skipMainContentService.uploadRoute$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.authService.check()) {
          // If user does not have access to restricted groups disable the upload to staging tile
          const selectedProject = this.store.selectSnapshot(
            UserProjectState.getSelectedProject
          );

          const projectRestrictedGroups =
            selectedProject.userRestrictedDataUseGroups;

          this.dialog.open(this.uploadHistoryDialog);
        } else {
          this.authService.authenticate(true);
        }
      });
    this.skipMainContentService.showHideChangeSFRole$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((display) => {
        if (display) {
          const isLoadingAlreadyOpen =
            this.dialog.getDialogById('sf-role-loading');
          if (!isLoadingAlreadyOpen)
            this.dialog.open(this.changeSfRoleDialog, {
              disableClose: true,
              id: 'sf-role-loading',
            });
        } else {
          this.dialog.closeAll();
        }
      });
  }

  public sidenavCloseStart(): void {
    this.isContentWidthFixed = false;
  }

  public sidenavOpenedChange(isOpened: boolean): void {
    this.isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
  }

  public toggleCollapsed(): void {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
  }

  private checkTermsAgreement(): void {
    this.allProjSubscription = this.allProjects$.subscribe(
      (projects: UserProject[]) => {
        const projectLoaded = this.store.selectSnapshot(
          UserProjectState.getAreProjectsLoaded
        );
        if (!projectLoaded) return;
        this.isGeneralResearcher = projects.length === 0;
        this.isTermsAccepted =
          projects.filter((p) => p.isTermsAccepted === true).length > 0;
        if (!this.isTermsAccepted)
          this.isTermsAccepted =
            this.store.selectSnapshot(AcceptTermsState.getAcceptTerms)
              .status_code === 200;
        this.handleShowingOfPrivacyPolicy();
        // Once done lets Unubscribe from this as internal components can also request fresh data
        // If they do and we do not have below it will trigger this again which we do not want
        this.allProjSubscription.unsubscribe();
      }
    );
  }

  private handleAcceptPopup(): void {
    this.acceptTerms$?.subscribe((acceptTerms: CommonResponseModel) => {
      if (acceptTerms.status_code === 200) {
        this.isTermsAccepted = true;
        sessionStorage.setItem('isTermsAccepted', 'yes');
        this.dialog.closeAll();
      }
    });
  }

  private handleShowingOfPrivacyPolicy(roleName?: string): void {
    if (roleName !== UserProjectRoleEnum.PLATFORM_ADMIN) {
      const getTermsAcceptedLocalStorage =
        sessionStorage.getItem('isTermsAccepted');
      // For a general researcher anyways his agreement status is not captured so we are good to rely on session storage
      if (this.isGeneralResearcher && getTermsAcceptedLocalStorage === 'yes')
        this.isTermsAccepted = true;
    }

    if (this.isTermsAccepted === false || this.isTermsAccepted === null) {
      const navigationExtras: NavigationExtras = {
        state: {
          isGeneralResearcher: this.isGeneralResearcher,
          lastRoute: this.currentRoute,
        },
      };
      this.router.navigate(['/pages/accept-terms'], navigationExtras);
    }
  }

  private showAgreementPopup(): void {
    // Agreement stuff will trigger only when this completes
    this.userRole.subscribe((roleName) => {
      if (roleName === UserProjectRoleEnum.DEFAULT) return;
      // Check if this user is a platform admin
      if (roleName === UserProjectRoleEnum.PLATFORM_ADMIN) {
        this.isTermsAccepted =
          this.store.selectSnapshot(
            UserInfoState.getPlatformAdminTermsAccepted
          ) ?? false;
        if (!this.isTermsAccepted)
          this.handleShowingOfPrivacyPolicy(UserProjectRoleEnum.PLATFORM_ADMIN);
        return;
      } else {
        this.checkTermsAgreement();
      }
    });
  }
}
