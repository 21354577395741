import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { FeatureFlagsState } from '../feature-flags';
import { LoggedinUserStateModel } from './loggedin-user-model';
import {
  IsLoggedInUserInternal,
  ResetLoggedinUserState,
  SetLoggedinUserState,
} from './loggedin-user.actions';

const stateDefaults: LoggedinUserStateModel = {
  auth0ClientId: '',
  email: '',
  tokenExpiry: 0,
  name: '',
  userId: '',
  isInternal: null,
};

@State<LoggedinUserStateModel>({
  defaults: stateDefaults,
  name: 'LoggedinUserState',
})
@Injectable()
export class LoggedinUserState {
  constructor(public store: Store) {}

  @Selector()
  public static getAuth0ClientId(state: LoggedinUserStateModel): string {
    return state.auth0ClientId;
  }

  @Selector()
  public static getIsUserInternal(state: LoggedinUserStateModel): boolean {
    return state.isInternal || false;
  }

  @Selector()
  public static getLoggedInUserEmail(state: LoggedinUserStateModel): string {
    return state.email;
  }

  @Selector()
  public static getLoggedInUserId(state: LoggedinUserStateModel): string {
    return state.userId;
  }

  @Selector()
  public static getLoggedinUserName(state: LoggedinUserStateModel): string {
    return state.name;
  }

  @Selector()
  public static getLoggedinUserTokenExpiry(
    state: LoggedinUserStateModel
  ): number {
    return state.tokenExpiry;
  }

  @Action(IsLoggedInUserInternal)
  public isTheLoggedInUserInternal(
    ctx: StateContext<LoggedinUserStateModel>
  ): void {
    // This should only be triggered from a component that is rendered after the feature flag guard.
    const isInternal =
      this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .internalDomainList.findIndex(
          (x: string) => x === ctx.getState().email.split('@')[1]
        ) > -1;
    ctx.patchState({ isInternal });
  }

  @Action(ResetLoggedinUserState)
  public resetProjectState({
    patchState,
  }: StateContext<LoggedinUserStateModel>): void {
    patchState({ ...stateDefaults });
  }

  /** Select Project */
  @Action(SetLoggedinUserState)
  public setLoggedinUserState(
    ctx: StateContext<LoggedinUserStateModel>,
    loggedinUser: SetLoggedinUserState
  ): void {
    const loggedinUserState = loggedinUser.loggedinUserState;
    ctx.patchState({ ...loggedinUserState });
  }
}
