<ng-container
  *ngIf="{
    connections: (connectionsLoading$ | async),
    connection: (connectionLoading$ | async),
    projectSurveyLoader : (projectSurveyLoader$ | async)
  } as loaders"
>
  <div class="mpr-upload-schedule-form" *ngIf= "showScheduleForm(loaders); else showPageLoader">
    <div class="mpr-form">
      <form [formGroup]="uploadScheduleForm">
        <div class="mpr-form-block">
          <div class="mpr-form-block-field">
            <div class="mpr-form-block-field-label" 
              >Select Data Source<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="selectDataSourceInfo" 
                [mprMtxTooltipAriaDescribedBy]="selectDataSourceInfo" 
                #tooltip="mtxTooltip"
                aria-label="Data Source Information"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
              <span
                class="mpr-select-source-spinner"
                *ngIf="loaders.connections && loaders.projectSurveyLoader"
              >
                <mat-spinner diameter="30" color="accent"></mat-spinner>
              </span>
            </div>
            <mat-form-field
              class="mpr-mat-select-field mpr-width-percentage-100"
              floatLabel="never"
              aria-label="Select Data Source"
              [hideRequiredMarker]="hideRequiredMarker"
              appearance="outline"
            >
              <mat-select
                formControlName="connectionId"
                panelClass="mpr-select-option-panel"
                placeholder="Select Data Source"
                #typeOfDataSource
                (selectionChange)="setSelectedConnection()"
              >
                  <mat-option [value]="dL_S3Connection.connectionId">{{dL_S3Connection.connectionName}}</mat-option>
                  <mat-option [value]="forstaConnection.connectionId" *ngIf="showForstaInDropdown">{{forstaConnection.connectionName}}</mat-option>
                  <mat-option
                  *ngFor="let connection of connectionList"
                  [value]="connection.connectionId"
                  >
                    {{ connection.connectionName }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              class="padding"
              *ngIf="uploadScheduleForm.controls['connectionId'].touched && uploadScheduleForm.controls['connectionId'].errors?.['required']"
            >
              Please select a data source.</mat-error
            >
          </div>
          <div class="mpr-survey-id-field" *ngIf="scheduleType === 'staging'&& isForstaConnectionSelected()">
            <div class="mpr-form-block-field">
              <div class="mpr-form-block-field-label" for="surveyId">Select Survey<sup>*</sup>
                <button class= "mpr-btn-transparency" 
                [mtxTooltip]="selectSurveyInfo" 
                [mprMtxTooltipAriaDescribedBy]="selectSurveyInfo" 
                #tooltip="mtxTooltip"
                aria-label="Select Survey Information"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
            </div>
              <mat-form-field class="mpr-width-percentage-100" appearance="outline">
                <mat-select 
                  formControlName="surveyId" 
                  placeholder="Select Survey" 
                  id="surveyId"
                  required
                >
                <mat-option *ngFor="let survey of projectSurveys" [value]="survey.surveyId">
                  {{ survey.surveyId }} - {{survey.surveyName}}
                </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error class="padding" *ngIf="uploadScheduleForm.get('surveyId')?.touched && uploadScheduleForm.get('surveyId')?.errors?.['required']">
                  Please select a survey.
              </mat-error>
            </div>
          </div>
          <div class="mpr-form-block-field" *ngIf="!isForstaConnectionSelected()">
            <label class="mpr-form-block-field-label" for="mpr-folder-path"
              >Folder Path<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="folderPathInfo" 
                [mprMtxTooltipAriaDescribedBy]="folderPathInfo" 
                #tooltip="mtxTooltip"
                aria-label="Folder Path Information"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
              <span
                class="mpr-select-source-spinner"
                *ngIf="loaders.connection"
              >
                <mat-spinner diameter="30" color="accent"></mat-spinner>
              </span>
            </label>
            <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
              <div class="mpr-form-fields-row-items mpr-folder-path-item">
                <mat-form-field
                  floatLabel="never"
                  class="mpr-width-percentage-100"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                >
                    <input
                      formControlName="folderPath"
                      class="mpr-form-block-field-input"
                      readonly
                      matInput
                      id="mpr-folder-path"
                  />
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['folderPath'].touched && uploadScheduleForm.controls['folderPath'].errors?.['required']"
                >
                  Please click on Add Path to select a folder path.</mat-error
                >
              </div>
              <div class="mpr-form-fields-row-items mpr-add-path-item">
                <button
                  mat-stroked-button
                  [disabled]="
                    loaders.connection ||
                    typeOfDataSource.value === ''
                  "
                  class="mpr-button mpr-green-button mpr-width-px-100 mpr-add-path-button"
                  type="button"
                  (click)="showFilePicker()"
                >
                  Add Path
                </button>
              </div>
            </div>
          </div>
          <div class="mpr-form-block-field" *ngIf = "scheduleType === 'staging'">
            <label class="mpr-form-block-field-label" for="mpr-select-destination-folder"
              >Select Destination Folder<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="destinationFolderPopover" 
                [mprMtxTooltipAriaDescribedBy]="destinationFolderPopover" 
                #tooltip="mtxTooltip"
                aria-label="Destination Folder Information"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
            </label>
            <div class="d-flex mpr-form-fields-row mpr-flex-alignitems-start">
              <div class="mpr-form-fields-row-items mpr-select-destination-item">
                <mat-form-field
                  floatLabel="never"
                  class="mpr-width-percentage-100"
                  [hideRequiredMarker]="hideRequiredMarker"
                  aria-label="Select Destination Folder"
                  appearance="outline"
                >
                    <input
                      formControlName="stagingTargetPath"
                      class="mpr-form-block-field-input"
                      readonly
                      matInput
                      id="mpr-select-destination-folder"
                  />
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['stagingTargetPath'] && uploadScheduleForm.controls['stagingTargetPath'].touched && uploadScheduleForm.controls['stagingTargetPath'].errors?.['required']"
                >
                  Please click on Select Folder to select a destination folder.</mat-error
                >
              </div>
              <div class="mpr-form-fields-row-items mpr-select-folder-item">
                <button
                  mat-stroked-button
                  class="mpr-button mpr-green-button mpr-width-px-140 mpr-select-folder-button"
                  type="button"
                  (click)="showDestinationFolderPickerDialog()"
                >
                  Select Folder
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mpr-form-block">
          <div class="mpr-form-block-field">
            <label class="mpr-form-block-field-label" for="mpr-schedule-name"
              >Schedule Name<sup>*</sup>
              <button class= "mpr-btn-transparency" 
                [mtxTooltip]="scheduleNameInfo" 
                [mprMtxTooltipAriaDescribedBy]="scheduleNameInfo" 
                #tooltip="mtxTooltip"
                aria-label="Schedule Name Information"
                >
                <mat-icon
                  class="mpr-info-icon"
                  >info
                </mat-icon>
              </button>
            </label>
            <mat-form-field
              floatLabel="never"
              class="mpr-width-percentage-100"
              [hideRequiredMarker]="hideRequiredMarker"
              appearance="outline"
            >
                <input
                  formControlName="scheduleName"
                  class="mpr-form-block-field-input"
                  matInput
                  placeholder="Enter Schedule Name"
                  maxlength="100"
                  id="mpr-schedule-name"
              />
            </mat-form-field>
            <mat-error
              class="padding"
              *ngIf="uploadScheduleForm.controls['scheduleName'].touched && uploadScheduleForm.controls['scheduleName'].errors?.['required']"
            >
              Please enter schedule name.</mat-error
            >
            <mat-error
              class="padding"
              *ngIf="uploadScheduleForm.controls['scheduleName'].touched && uploadScheduleForm.controls['scheduleName'].errors?.['maxlength']"
            >
              Max 100 characters allowed</mat-error
            >
            <div
              class="mpr-form-block-field-wordcount"
              [ngClass]="
                uploadScheduleForm.controls['scheduleName'].value.length === 100
                  ? 'mpr-error-color'
                  : ''
              "
              >{{
                uploadScheduleForm.controls['scheduleName'].value.length
              }}/100 characters</div
            >
          </div>
        </div>
        <div class="mpr-form-block">
          <div class="mpr-form-block-field">
            <div
              class="d-flex mpr-form-fields-row mpr-flex-alignitems-start mpr-form-frequency-items"
            >
              <div class="mpr-form-fields-row-items">
                <div class="mpr-form-block-field-label"
                  >Select Frequency<sup>*</sup></div
                >
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                  aria-label="frequency"
                >
                  <mat-select
                    formControlName="frequency"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select Frequency"
                    (selectionChange)="frequencyChanged()"
                  >
                    <mat-option
                      *ngFor="let frequency of frequencyList"
                      [value]="frequency"
                    >
                      {{ frequency }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['frequency'].touched && uploadScheduleForm.controls['frequency'].errors?.['required']"
                >
                  Please select frequency.</mat-error
                >
                <div
                  class="mpr-form-block-field-note-text"
                  *ngIf="
                    uploadScheduleForm.controls['frequency'].value ===
                    frequencyListEnum.HOURLY
                  "
                >
                  The Job Will Start At 00 Mins Of The Next Hour.
                  <mpr-utc-time-zone></mpr-utc-time-zone
                ></div>
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  uploadScheduleForm.controls['frequency'].value ===
                  frequencyListEnum.WEEKLY
                "
              >
                <div class="mpr-form-block-field-label"
                  >Day Of The Week<sup>*</sup></div
                >
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  aria-label="Select day of the week"
                  appearance="outline"
                >
                  <mat-select
                    formControlName="frequencyDetail"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select day of the week"
                  >
                    <mat-option
                      *ngFor="let day of daysOfTheWeek"
                      [value]="day.value"
                    >
                      {{ day.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['frequencyDetail'].touched && uploadScheduleForm.controls['frequencyDetail'].errors?.['required']"
                >
                  Please select day of the week.</mat-error
                >
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  uploadScheduleForm.controls['frequency'].value ===
                  frequencyListEnum.MONTHLY
                "
              >
                <div class="mpr-form-block-field-label"
                  >Day Of The Month<sup>*</sup></div
                >
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                  aria-label="Select day of the month"
                >
                  <mat-select
                    formControlName="frequencyDetail"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select day of the month"
                  >
                    <mat-option
                      *ngFor="let day of daysOfTheMonth"
                      [value]="day"
                    >
                      {{ day }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['frequencyDetail'].touched && uploadScheduleForm.controls['frequencyDetail'].errors?.['required']"
                >
                  Please select day of the month.</mat-error
                >
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  uploadScheduleForm.controls['frequency'].value ===
                  frequencyListEnum.YEARLY
                "
              >
                <label class="mpr-form-block-field-label" for="mpr-select-date"
                  >Select Date<sup>*</sup></label
                >
                <mat-form-field
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                  aria-label="Select Month and Date"
                >
                    <input
                      formControlName="frequencyDetail"
                      class="mpr-form-block-field-input"
                      matInput
                      placeholder="MM/DD"
                      id="mpr-select-date"
                  />
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['frequencyDetail'].touched && uploadScheduleForm.controls['frequencyDetail'].errors?.['required']"
                >
                  Please enter date of the year.</mat-error
                >
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['frequencyDetail'].touched && uploadScheduleForm.controls['frequencyDetail'].errors?.['dateValidator']"
                >
                  Please enter valid date in the format MM/DD</mat-error
                >
              </div>
              <div
                class="mpr-form-fields-row-items"
                *ngIf="
                  uploadScheduleForm.controls['frequency'].value !== '' &&
                  uploadScheduleForm.controls['frequency'].value !==
                    frequencyListEnum.HOURLY
                "
              >
                <div class="mpr-form-block-field-label"
                  >Hour Of The Day<sup>*</sup
                  ><mpr-utc-time-zone></mpr-utc-time-zone
                ></div>
                <mat-form-field
                  class="mpr-mat-select-field"
                  floatLabel="never"
                  [hideRequiredMarker]="hideRequiredMarker"
                  appearance="outline"
                  aria-label="Select hour of the day"
                >
                  <mat-select
                    formControlName="hourOfTheDay"
                    panelClass="mpr-select-option-panel"
                    placeholder="Select hour of the day"
                  >
                    <mat-option
                      *ngFor="let hr of hoursOfTheDay"
                      [value]="hr.value"
                    >
                      {{ hr.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error
                  class="padding"
                  *ngIf="uploadScheduleForm.controls['hourOfTheDay'].touched && uploadScheduleForm.controls['hourOfTheDay'].errors?.['required']"
                >
                  Please select hour of the day</mat-error
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
      <div>
        <button
          mat-stroked-button
          class="mpr-button mpr-blue-button mpr-cancel-button"
          [routerLink]="returnPath"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          mat-button
          class="mpr-button mpr-yellow-button"
          matStepperNext
          type="submit"
          [disabled]="!uploadScheduleForm.valid"
          (click)="validateAndProceedToMetaData()"
          *ngIf = "scheduleType !== 'staging'"
        >
          Save And Enter Metadata
        </button>
        <button
          mat-button
          class="mpr-button mpr-yellow-button"
          type="submit"
          [disabled]="!uploadScheduleForm.valid"
          (click)="validateAndCreateStagingSchedule()"
          *ngIf = "scheduleType === 'staging'"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #selectDataSourceInfo>
  <div class="mpr-width-px-300" id="mpr-select-class">
    Select Data Source from where you want to upload the file.
  </div>
</ng-template>
<ng-template #selectSurveyInfo>
  <div class="mpr-width-px-300" id="mpr-select-class">
    The Forsta project ID (PID) that is used to identify the survey in Forsta.
  </div>
</ng-template>
<ng-template #folderPathInfo>
  <div class="mpr-width-px-300" id="mpr-folder-class">
    Select a folder from where you want to upload files in a recurring way.
  </div>
</ng-template>
<ng-template #scheduleNameInfo>
  <div class="mpr-width-px-300" id="mpr-schedule-class">
    Brief description of the job E.g Fetch Daily Covid Data.
  </div>
</ng-template>
<ng-template #destinationFolderPopover>
  <div class="mpr-width-px-160" id="mpr-select-folder">Select destination folder</div>
</ng-template>

<ng-template #showPageLoader>
  <div class="mpr-content-loader">
      <h1 class="mpr-content-loader-text">Loading...</h1>
  </div>
</ng-template>