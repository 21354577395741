import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from '@core/auth/auth.component';
import { AuthGuard, FeatureFlagGuard } from '@core/guards';
import { RoleLabelsResolver } from '@core/resolvers/role-labels.resolver';
import { environment } from '@env/environment';
import { DashboardComponent } from 'app/dashboard/dashboard/dashboard.component';
import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [FeatureFlagGuard],
        component: DashboardComponent,
        data: { title: 'Dashboard', allowWhenFeatureFlagTrue: ['any'] },
        loadChildren: () =>
          import('app/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },
  {
    path: 'platformAdmin',
    loadChildren: () =>
      import('app/platform-admin/platform-admin.module').then(
        (m) => m.PlatformAdminModule
      ),
  },
  {
    path: 'authenticated',
    component: AuthComponent,
  },
  {
    path: 'login',
    component: AuthComponent,
  },
  {
    path: 'logout',
    component: AuthComponent,
  },
  {
    path: 'signout',
    component: AuthComponent,
  },
  {
    path: 'uploads',
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    loadChildren: () =>
      import('../uploads/uploads.module').then((m) => m.UploadsModule),
  },
  {
    path: 'staging',
    canActivate: [FeatureFlagGuard],
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    data: { allowWhenFeatureFlagTrue: ['isStagingAccessAvailable'] },
    loadChildren: () =>
      import('../staging/staging.module').then((m) => m.StagingModule),
  },
  {
    path: 'filemanager',
    canActivate: [FeatureFlagGuard],
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    data: { allowWhenFeatureFlagTrue: ['isStagingAccessAvailable'] },
    loadChildren: () =>
      import('../filemanager/filemanager.module').then(
        (m) => m.FilemanagerModule
      ),
  },
  {
    path: 'workspace',
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    loadChildren: () =>
      import('../workspace/workspace.module').then((m) => m.WorkspaceModule),
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('../pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'connections',
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    loadChildren: () =>
      import('../connections/connections.module').then(
        (m) => m.ConnectionsModule
      ),
  },
  {
    path: 'project-settings',
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    canActivate: [FeatureFlagGuard],
    data: {allowWhenFeatureFlagTrue: ['any'] },
    loadChildren: () =>
      import('../project-settings/project-settings.module').then(
        (m) => m.ProjectSettingsModule
      ),
  },
  {
    path: 'download',
    loadChildren: () =>
      import('../download/download.module').then((m) => m.DownloadModule),
  },
  {
    path: 'admin',
    canActivate: [FeatureFlagGuard],
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    data: { allowWhenFeatureFlagTrue: ['isProjectAdminConsole'] },
    loadChildren: () =>
      import('../admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'rds',
    canActivate: [FeatureFlagGuard],
    resolve: { hasDispatchFinished: RoleLabelsResolver },
    data: { allowWhenFeatureFlagTrue: ['rdsFeatureFlag'] },
    loadChildren: () =>
      import('../rds/rds.module').then((m) => m.RDSModule),
  },  
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
