/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
export class LoadDatasetCount {
  public static type = '[Userdataset] Dataset count ';
  constructor(public roleName: string) {}
}

export class GetProjectCount {
  public static type = '[Project] Get Counts ';
  constructor(public isCount: string, public pageNo?: number) {}
}

export class GetUserCount {
  public static type = '[User] Get Counts ';
  constructor(public isCount: string, public roleName: string) {}
}

export class ResetCountData {
  public static type = '[Project] reset datails ';
  constructor() {}
}
