/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
import { MprHttpHeaderModal } from '@core/interfaces';
import { RDSClusters } from '../manage-rds/manage-rds.model';

export class AddUserToRDSCluster {
  public static type = '[RDSClusterUsers] Add User to Cluster ';
  constructor(
    public projectId: string,
    public clusterId: string,
    public userId: string,
    public requestHeaders: MprHttpHeaderModal
  ) {}
}

export class DeleteUserFromRDSCluster {
  public static type = '[RDSClusterUsers] Delete User from Cluster ';
  constructor(
    public projectId: string,
    public clusterId: string,
    public userId: string,
    public requestHeaders: MprHttpHeaderModal
  ) {}
}

export class LoadRDSClusterUsers {
  public static type = '[RDSClusterUsers] Get All users for a Cluster';
  constructor(
    public clusterId: string,
    public requestHeaders?: MprHttpHeaderModal
  ) {}
}

export class ResetRDSClusterUsersState {
  public static type = '[RDSClusterUsers] Reset State ';
}

export class ResetRDSClusterCommonResponse {
  public static type = '[RDSClusterUsers] Reset Common response State ';
}

export class SetSelectedCluster {
  public static type = '[RDSClusterUsers] Set Selected Cluster';
  constructor(public cluster: RDSClusters) {}
}
