import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { UploadsService } from 'app/uploads/services';
import { CheckDatasetStatus } from './dataset-deletion-status.action';
import { DatasetStatusModel } from './dataset-status-model';

const stateDefaults: DatasetStatusModel = {
  message: {
    actionType: '',
  },
};
@State<DatasetStatusModel>({
  defaults: stateDefaults,
  name: 'DatasetStatus',
})
@Injectable()
export class DatasetStatusState {
  constructor(private uploadsService: UploadsService) {}

  @Action(CheckDatasetStatus)
  public checkDatasetStatus(
    ctx: StateContext<DatasetStatusModel>,
    { datasetId }: CheckDatasetStatus
  ): Observable<DatasetStatusModel> {
    return this.uploadsService.checkDatasetStatus(datasetId).pipe(
      tap((response: DatasetStatusModel) => {
        ctx.patchState(response);
      })
    );
  }

  @Selector()
  public static getDatasetStatus(
    state: DatasetStatusModel
  ): DatasetStatusModel {
    return state;
  }
}
