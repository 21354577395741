import { CommonResponseModel } from '@shared';

export interface CrossAccountTableDetailsModel {
  accountAttributeId: string;
  accountId: string;
  arn: string;
  crossAccountConnectionType: CrossAccountConnectionTypes;
  description: string;
  modifiedByName: string;
  modifiedDate: string;
  ownerUserId: string;
  serviceAccountArnId?: string;
}

export interface CrossAccountsEnabledListResponse {
    externalProjectArnList: CrossAccountTableDetailsModel[];
    mquiryServiceRoleArn: string;
    mquiryUserRoleArn: string;
    status_code: number;
}

export interface CrossAccountStateModel {
    commonResponse: CommonResponseModel;
    externalAccounts: CrossAccountTableDetailsModel[];
    mquiryServiceRoleArn: string;
    mquiryUserRoleArn: string;
    status_code: number;
}

export enum CrossAccountConnectionTypes {
  USER = 'user',
  SERVICE = 'service',
}
