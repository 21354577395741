import { ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';

export const duplicateValidataor =
  (ec2InstanceValue: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const duplicateEc2Instance = true;
    const newEc2InstanceValue = control.value;
    return ec2InstanceValue === newEc2InstanceValue
      ? { duplicateEc2Instance }
      : null;
  };
