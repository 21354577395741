import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericHttpResponseStatus } from '@core';
import { GenericHttpResponse, HeaderParams } from '@core/interfaces';
import { DownloadJobResponseWrapper } from '@download/interface';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient, public store: Store) {}
  public createDownloadJob(
    downloadJobRequest: any
  ): Observable<DownloadJobResponseWrapper> {
    const payload = downloadJobRequest.downloadJobRequest;
    const optionalHeaders = downloadJobRequest.requestHeaders;
    if (optionalHeaders) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, optionalHeaders[HeaderParams.PROJECTID])
        .append(HeaderParams.ROLENAME, optionalHeaders[HeaderParams.ROLENAME]);
      return this.http.post<any>(`/download`, payload, { headers });
    }
    return this.http.post<any>(`/download`, payload);
  }

  public getViewPresignedUrl(
    viewRequest: any
  ): Observable<GenericHttpResponse> {
    const fileName = viewRequest.s3Path;
    return this.http.get<any>(`/view?s3Location=${fileName}`);
  }
}
