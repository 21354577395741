import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponseModel } from '@shared/interfaces';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LaunchEc2Service {
  constructor(private http: HttpClient) { }

  public launchOrProvisionEc2(
    userId: string,
    projectId: string,
    action = 'start'
  ): Observable<CommonResponseModel> {
    // Method for post and put
    const method = action === 'reprovision' ? 'put' : 'post';
    return this.http[method]<CommonResponseModel>(`/server`, {
      userId,
      projectId,
      action,
    });
  }

  public stopEc2(
    userId: string,
    projectId: string,
    action: string,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    if(requestHeaders){
      const headers = new HttpHeaders().append(
        HeaderParams.ROLENAME,
        requestHeaders[HeaderParams.ROLENAME]
      );
      return this.http.put<CommonResponseModel>(
        `/server`,
        {
          userId,
          projectId,
          action
        },
        { headers });
    } else {
      return this.http.put<CommonResponseModel>(
        `/server`,
        {
          userId,
          projectId,
          action
        });
    }
    
  }

  public updateinstance(
    machineSize: string,
    userId: string,
    projectId: string,
    requestHeaders: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    const headers = new HttpHeaders().append(
      HeaderParams.ROLENAME,
      requestHeaders[HeaderParams.ROLENAME]
    );
    return this.http.put<CommonResponseModel>(
      `/update_instance`,
      {
        machineSize,
        userId,
        projectId,
      },
      { headers }
    );
  }
}
