import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AWSDetailsStateModel } from 'app/state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AWSDetailsService {
  constructor(private http: HttpClient) { }

  public getAWSDetails(): Observable<AWSDetailsStateModel> {
    return this.http.get<AWSDetailsStateModel>('/console');
  }
}
