import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { EXPORT_CSV_PLATFORM_ADMIN_USER_DATA_FIELDS, USER_STATUS, USER_STATUS_DISPLAY } from '@core/constants';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CommonResponseModel, MprConfirmComponent } from '@shared';
import {
  LoadAllPlatformAdminUsersData,
  ResetUserdata,
  UserState
} from 'app/state';
import { UserList } from 'app/state/project-user/user-list-model';
import { UserEnableDisableState } from 'app/state/user-enable-disable/user-enable-disabled.state';
import { EnableDisableUser } from 'app/state/user-enable-disable/user-enable-disbale.action';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import { LoggedinUserState } from 'app/state/loggedin-user/loggedin-user.state';
import UserStateModel from 'app/state/user/user-state-model';
import {
  catchError,
  Observable,
  throwError,
  timer,
  withLatestFrom
} from 'rxjs';
import { convertToCSV, saveAsCSV } from '@shared/utils';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-platform-admin-users',
  templateUrl: './platform-admin-users.component.html',
  styleUrls: ['../all-users/all-users.component.scss', './platform-admin-users.component.scss'],
})
export class PlatformAdminUsersComponent implements OnInit, OnDestroy {
  @Select(UserState.getAllPlatformAdminUsers)
  public listDetails!: Observable<UserStateModel>;
  
  @Select(LoggedinUserState.getLoggedInUserId)
  public userId!: Observable<string>;

  @Select(UserInfoState.getCurrentUserRole)
  public userRole!: Observable<string>;

  @Select(UserEnableDisableState.returnEnableDisableResponse)
  private enableDisableResponse$!: Observable<CommonResponseModel>;

  public exportButtonSpinner = false;
  public loggedInUserId = '';
  public selectedProjectId = '';
  public showDelaySpinner = false;
  public showSpinner = true;

  constructor(
    private router: Router, 
    public store: Store, 
    private dialog: MatDialog, 
    private alertService: AlertMessageService
  ) {}

  public enableDisableUser(list: UserList): void{
    const action =
      list.userStatus === 'disabled'
        ? 'enable'
        : 'disable';
    let confirmMessage = `Are you sure you want to disable the platform admin <b>${list.userName}</b>? <br/><br/>
     Once disabled, this user will no longer have access to the platform. Their access shall be restored when the account is re-enabled.`;

    if (action === 'enable') {
      confirmMessage = `Are you sure you want to enable the platform admin <b>${list.userName}</b>? <br/><br/>
       Once enabled, their access to the platform will be restored.`;
    }

    const dialogRef = this.dialog.open(MprConfirmComponent, {
      width: '650px',
      data: {
        confirmTitle: action === 'disable' ? 'Disable User' : 'Enable User',
        confirmMessage,
        useEmitterOnConfirmClick: true,
      },
      disableClose: true,
    });

    dialogRef.componentInstance.confirmAction.subscribe((data) => {
      // Dispatch the delete action when the confirmation is clicked
      const userIdEmail = list['userId'] ? list['userId']  : list.emailId;
      this.store
        .dispatch(new EnableDisableUser(action, userIdEmail, true))
        .pipe(
          withLatestFrom(this.enableDisableResponse$),
          catchError((err) => {
            // Handle error
            this.alertService.error({
              body:
                err.error.message ||
                'Failed to update user status. Please try again.',
            });
            dialogRef.close();
            return throwError(() => new Error(''));
          })
        )
        .subscribe(([_, res]) => {
          // Check the status code
          if (res.status_code > 202) {
            this.alertService.error({
              body: res.message
                ? res.message
                : `Failed to update user status. Please try again.`,
            });
          } else {
            // Success notification
            const successToast = `Platform admin&nbsp;<b>${list.userName}</b>&nbsp;${action}d.`;
            this.alertService.success({
              body: successToast,
            });
          }
          // Close the dialog regardless of success or failure
          dialogRef.close();
          // Reload the cluster list after deletion
          this.showDelaySpinner = true;
          // adding 5s delay for fetching the status of the user after 
          // enable user action to get the right status as it takes some time to enable
          // all the required permissions
          const timerMS = action === 'enable' ? 5000 : 0;
          timer(timerMS).subscribe(() => {
            this.store.dispatch(new LoadAllPlatformAdminUsersData());
          });
        });
    });
  }

  public exportToCSV(listData : UserStateModel): void {
    const platformAdminData: UserList[] = listData['platformAdminUserInfo']?? [];
    const valueMapping = { userStatus: {} };
    valueMapping['userStatus'] = {'active': USER_STATUS_DISPLAY.ENABLED, 'disabled': USER_STATUS_DISPLAY.DISABLED}
    const csv = convertToCSV(
      platformAdminData,
      EXPORT_CSV_PLATFORM_ADMIN_USER_DATA_FIELDS,
      valueMapping
    );
    saveAsCSV(csv, 'Platform_Admin_Users.csv');

  }
  
  public getUserIdOfLoggedInUser(): void {
    this.userId.subscribe((userId) => {
      if (userId === '') return;
        this.loggedInUserId = userId;
      }
    )
  }

  public isDisabled(user: UserList): boolean {
    return user.userStatus === USER_STATUS.DISABLED;
  }

  public loadUsers(): void {
    this.userRole.subscribe((roleName) => {
      if (roleName === '') return;
      this.store
        .dispatch(new LoadAllPlatformAdminUsersData())
        .subscribe((resp) => {
          this.showSpinner = false;
        });
    });
  }

  public navigateAddToProject(userDetails: any): void {
    const id = btoa(userDetails.emailId);
    const routePath = `platformAdmin/users/addtoproject/${id}`;
    this.router.navigate([`${routePath}`]);
  }

  public navigateToRoute(routePath: string, emailId?: string): void {
    if (emailId) {
      const encodedEmailId = btoa(emailId);
      const url = `${routePath}/${encodedEmailId}`;
      this.router.navigate([`${url}`]);
    } else {
      this.router.navigate([`${routePath}`]);
    }
  }


  ngOnDestroy(): void {
    this.store.dispatch(new ResetUserdata());
  }

  ngOnInit(): void {
    this.store.dispatch(new ResetUserdata());
    this.loadUsers();
    this.getUserIdOfLoggedInUser();
  }

  
}
