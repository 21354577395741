import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { GetAllRoleLabels } from 'app/state/role-labels/role-labels.actions';
import { RoleLabelsState } from 'app/state/role-labels/role-labels.state';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleLabelsResolver implements Resolve<boolean> {
  constructor(public store: Store) {}
  public resolve(): Observable<any> {
    const existingStateValues = this.store.selectSnapshot(
      RoleLabelsState.getRoleLabelsArray
    );
    // We don't want to make API calls when we already have data
    if (existingStateValues && existingStateValues.length > 0) {
      return of(true);
    }

    return this.store
      .dispatch(new GetAllRoleLabels())
      .pipe(
        map(() => this.store.select(RoleLabelsState.isRoleLabelsDataLoaded))
      );
  }
}
