import { Component, ViewEncapsulation } from '@angular/core';
import { AlertMessageService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import {
  ResetUploadsState,
  ResetUserdata,
  SelectUserProjectId,
  UserProjectState,
  ResetSelectedProjectMetadata,
  LoadUserdata,
} from 'app/state';
import { Observable } from 'rxjs';
import { UserProject } from '@theme/interfaces';
import { ResetConnectionState } from 'app/state/connection';
import { ResetHistoryState } from 'app/state/uploads/upload-history/history.action';
import { ResetScheduleState } from 'app/state/uploads/schedule/schedule.action';
import { ResetSFRoleResponseState } from 'app/state/change-sf-role/change-sf-role.action';
import { ReplaceRoleName } from '@shared/pipes/role-labels.pipe';

const selectProjectString = 'Select Project';

@Component({
  selector: 'mpr-subheader-project-select',
  templateUrl: './subheader-project-select.component.html',
  styleUrls: ['./subheader-project-select.component.scss'],
  providers: [ReplaceRoleName],
  encapsulation: ViewEncapsulation.None,
})
export class SubheaderProjectSelectComponent {
  @Select(UserProjectState.getAllUserProjects) public projects$?: Observable<
    UserProject[]
  >;

  public defaultValue: UserProject;

  public isSelectProjectValue = false;

  public selectProjectString = selectProjectString;

  constructor(
    private alertMsgService: AlertMessageService,
    private store: Store,
    private replaceRoleName: ReplaceRoleName
  ) {
    // Get current value from store
    this.defaultValue = {
      ...this.store.selectSnapshot(UserProjectState.getSelectedProject),
    };

    // Set 'Select Project' as the default value for these 2 fields as we require
    if (this.defaultValue.projectId === '') {
      this.defaultValue.projectName = this.selectProjectString;
      this.defaultValue.projectNumber = this.selectProjectString;
      this.isSelectProjectValue = true;
    }
  }

  public changeProject(selectedProject: UserProject): void {
    this.isSelectProjectValue = false;
    this.store.dispatch(new ResetUserdata());
    this.store.dispatch(new ResetSelectedProjectMetadata());
    this.store.dispatch(new ResetConnectionState());
    this.store.dispatch(new ResetUploadsState());
    this.store.dispatch(new ResetHistoryState());
    this.store.dispatch(new ResetScheduleState());
    this.store.dispatch(new ResetSFRoleResponseState());
    this.store.dispatch(new SelectUserProjectId(selectedProject.projectId));
    const projectName = `${selectedProject.projectNumber}${'_'}${
      selectedProject.projectNickname
    }`;
    const replaceRoleName = this.replaceRoleName.transform(
      selectedProject.roleName
    );
    this.alertMsgService.success({
      body: `You are now logged into &nbsp<b>${projectName}</b>&nbsp as a &nbsp<b>${replaceRoleName}</b>`,
    });
  }
}
