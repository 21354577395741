import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponseModel } from '@shared/interfaces';
import { GenericHttpResponse } from '@core/interfaces';
import { CrossAccountConnectionTypes, CrossAccountsEnabledListResponse } from 'app/state/set-up-cross-account/set-up-cross-account-model';

@Injectable({
  providedIn: 'root',
})
export class SetupCrossAccountService {
  private endpointForUserAccount = '/user/external_accounts';

  constructor(private http: HttpClient) {}

  public addNewCrossAccount(
    accountAttributeId: string,
    arn: string,
    crossAccountConnectionType: string
  ): Observable<CommonResponseModel> {
    return this.http.post<CommonResponseModel>(this.endpointForUserAccount, {
      accountAttributeId,
      arn,
      crossAccountConnectionType
    });
  }

  public deleteCrossAccount(
    accountAttributeId: string,
    crossAccountConnectionType: string,  
    ownerUserId: string,
    serviceAccountArnId: string
  ): Observable<GenericHttpResponse> {

    const body: any = {
      crossAccountConnectionType
    }    

    // Add Service Account Type only for service accounts.
    if (crossAccountConnectionType === CrossAccountConnectionTypes.SERVICE) {
      body.serviceAccountArnId = serviceAccountArnId;
    } else {
      body.ownerUserId = ownerUserId;
      body.accountAttributeId = accountAttributeId;
    }

    return this.http.delete<GenericHttpResponse>(this.endpointForUserAccount, {
      body
    });
  }  

  public editCrossAccount(
    accountAttributeId: string,
    ownerUserId: string,
    crossAccountConnectionType: string,
    serviceAccountArnId: string,
    modified: {
      accountAttributeId?: string;
      arn?: string;
      crossAccountConnectionType?: string;
    }
  ): Observable<CommonResponseModel> {
    const body: any = {
      crossAccountConnectionType,
      modified
    };
    if(crossAccountConnectionType === CrossAccountConnectionTypes.SERVICE){
      body.serviceAccountArnId = serviceAccountArnId;
    }
    else{
      body.ownerUserId = ownerUserId;
      body.accountAttributeId = accountAttributeId;
    }
 
    return this.http.put<CommonResponseModel>(this.endpointForUserAccount, {
      ...body
    });
  }

  public getExternalAccounts(
  ): Observable<CrossAccountsEnabledListResponse> { 
    return this.http.get<CrossAccountsEnabledListResponse>(this.endpointForUserAccount); 
  }

}
