import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { GlobalAttributesModel } from 'app/state/global-attributes/global-attributes.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalAttributesService {
  constructor(private http: HttpClient) { }

  public getGlobalAttributes(requestHeaders :MprHttpHeaderModal): Observable<GlobalAttributesModel[]> {
    const headers = new HttpHeaders()
        .set(HeaderParams.ROLENAME, requestHeaders[HeaderParams.ROLENAME]);

    return this.http.get<GlobalAttributesModel[]>('/projects/global_attributes', { headers });
  }
}
