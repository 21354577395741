import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'helpLinkUrl',
})
export class HelpLinkUrlPipe implements PipeTransform {
  public transform(property: string): string {
    return environment.helpPageNowUrl + property;
  }
}
