import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericStatus } from '@core';
import { GenericHttpResponse } from '@core/interfaces';
import { EditScheduleFormModel, ScheduleModel } from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(private http: HttpClient) {}

  public deleteSchedule(scheduleId: string): Observable<GenericHttpResponse> {
    return this.http.delete<any>(`/schedule/${scheduleId}`);
  }

  public editSchedule(
    scheduleId: string,
    schedule: EditScheduleFormModel
  ): Observable<GenericHttpResponse> {
    return this.http.put<GenericHttpResponse>(
      `/schedule/${scheduleId}`,
      schedule
    );
  }

  public enableDisableSchedule(
    scheduleId: string,
    status: GenericStatus
  ): Observable<GenericHttpResponse> {
    return this.updateSchedule(scheduleId, { status });
  }

  public getAllSchedule(scheduleType: string): Observable<ScheduleModel> {
    const scheduleURL = scheduleType !== '' ? `/schedule?scheduleType=${scheduleType}` : '/schedule';
    return this.http.get<ScheduleModel>(scheduleURL);
  }


  public updateSchedule(
    scheduleId: string,
    schedule: Partial<ScheduleModel>
  ): Observable<GenericHttpResponse> {
    return this.http.put<GenericHttpResponse>(
      `/schedule/${scheduleId}`,
      schedule
    );
  }

}
