import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { AuthConstants, UserProjectRoleEnum } from '@core/enums';
import {
  AlertMessageService,
  AuthService,
  MprCookieService,
} from '@core/services';
import { Select, Store } from '@ngxs/store';
import { SetRoute } from 'app/state/route';
import { LoadUserInfodata } from 'app/state/user-info/user-info.actions';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import { ResetUserProjectState } from 'app/state/user-projects';
import { catchError, Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-auth',
  template: '',
})
export class AuthComponent implements OnInit {
  @Select(UserInfoState.getCurrentUserRole)
  private userRole$?: Observable<string>;
  public userRoleName: any = '';
  private allowedRoutes: Record<string, string> = {
    '': 'handleLoginRoute', // unlikely
    authenticated: 'handleAuthenticatedRoute', // We do cookie stuff
    login: 'handleLoginRoute', // simple redirect to BE.
    logout: 'handleLogoutRoute', // logout.
    signout: 'redirectToAuth0LogoutAndBack', // logout.
  };
  private currentPath: string;

  constructor(
    private authService: AuthService,
    private alertMsgService: AlertMessageService,
    private mprCookieService: MprCookieService,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.currentPath = '';
  }

  ngOnInit(): void {
    // if cookies set correct route then handle the route
    if (this.doPrerequisitesMeet()) {
      this.handleAuthRoutes();
    } else {
      this.handleLoginRoute();
    }
  }

  private doPrerequisitesMeet(): boolean {
    // Check if the route is something we can handle
    const currentRouteSegments: UrlSegment[] = this.route?.snapshot.url;

    // Check Allowed Routes
    if (currentRouteSegments.length === 0) return false;
    this.currentPath = currentRouteSegments[0].path;

    // If current route is in the allowed routes and we have the id token contents
    return (
      (Object.keys(this.allowedRoutes).includes(this.currentPath) &&
        this.currentPath === 'signout') ||
      this.mprCookieService.isCookieSet(AuthConstants.ID_TOKEN)
    );
  }

  private handleAuthRoutes(): void {
    const methodName = this.allowedRoutes[this.currentPath];
    // Note: this line below will just call the method name passed from this class.
    this[methodName as keyof AuthComponent]();
  }

  private handleAuthenticatedRoute(): void {
    // In case of session timeout or logout forget user's earlier selection.
    this.store.dispatch(new ResetUserProjectState());
    const userId = this.authService.getUserId();
    this.store
      .dispatch(new LoadUserInfodata(userId))
      .pipe(
        catchError((err) => {
          if (err.error.message.includes('Unauthorized')) {
            const redirectURL =
              localStorage.getItem('redirectURL') || '/dashboard';
            this.store.dispatch(new SetRoute(redirectURL));
            this.mprCookieService.refreshTokenExpiry();
            sessionStorage.removeItem('long-operation-in-progress');
            this.authService.authenticate();
            return throwError(() => new Error(''));
          } else {
            this.alertMsgService.error({
              body: err.error.message,
            });
            return throwError(() => new Error(''));
          }
        }),
      )
      .subscribe((userData: any) => {
        if (userData.Userinfodata.message && userId) {
          this.userRoleName = userData.Userinfodata.message.roleName;
          let redirectURL = localStorage.getItem('redirectURL');
          if (
            this.userRoleName &&
            this.userRoleName === UserProjectRoleEnum.PLATFORM_ADMIN &&
            !redirectURL
          ) {
            redirectURL = '/platformAdmin';
          }
          if (!redirectURL) redirectURL = '/dashboard';

          this.store.dispatch(new SetRoute(redirectURL));
          this.mprCookieService.refreshTokenExpiry();
          this.authService.authenticate();
        }
      });
  }

  private handleLoginRoute(): void {
    this.authService.logoutAndRedirectToLogin();
  }

  private handleLogoutRoute(): void {
    sessionStorage.clear();
    this.authService.cleanUpTokens();
    this.authService.logout();
  }

  private redirectToAuth0LogoutAndBack(): void {
    this.authService.signout();
  }
}
