import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public parseToken(token: string): any {
    let decodedJwt = '';

    try {
      decodedJwt = jwt_decode(token);
    } catch {
      // graceful handle of the error
    }

    return decodedJwt;
  }
}
