import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  HydrateUserProjectStateByProjectId,
  LoadAllUserProjects,
  LoadAllUserProjectsForPlatFormAdmin,
  ResetUserProjectState,
  SelectUserProjectId,
} from './user-project.actions';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { UserProject } from '@theme/interfaces';
import { UserProjectService } from '@theme/services/user-project.service';
import UserProjectStateModel from './user-project-state-model';
import { catchError, map, tap } from 'rxjs/operators';
import { UserProjectRoleEnum } from '@core/enums';
import { GenericHttpResponse } from '@core/interfaces';

const stateDefaults: UserProjectStateModel = {
  projects: [],
  projectsLoaded: false,
  selectedProjectId: '',
  selectedProject: {
    projectId: '',
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    roleName: UserProjectRoleEnum.BLANK,
    userRestrictedDataUseGroups: [],
    serverUrls: { jupyter: '', rstudio: '' },
    ec2Instance: '',
    machineSize: '',
    isTermsAccepted: false,
  },
  status: '',
  message: '',
  userProjectsLoaded: false,
};

@State<UserProjectStateModel>({
  defaults: stateDefaults,
  name: 'UserProject',
})
@Injectable()
export class UserProjectState {
  constructor(private userProjectService: UserProjectService) {}

  @Selector()
  public static getAllUserProjects(
    state: UserProjectStateModel
  ): UserProject[] {
    return state.projects;
  }

  @Selector()
  public static getAreProjectsLoaded(state: UserProjectStateModel): boolean {
    return state.projectsLoaded;
  }

  @Selector()
  public static getAreUserProjectsLoaded(
    state: UserProjectStateModel
  ): boolean {
    return state.userProjectsLoaded;
  }

  @Selector()
  public static getSelectedProject(state: UserProjectStateModel): UserProject {
    return state.selectedProject;
  }

  @Selector()
  public static getSelectedProjectId(state: UserProjectStateModel): string {
    return state.selectedProjectId;
  }

  @Selector()
  public static getSelectedProjectName(state: UserProjectStateModel): string {
    return state.selectedProject.projectName;
  }

  @Selector()
  public static getSelectedProjectRole(
    state: UserProjectStateModel
  ): UserProjectRoleEnum {
    return state.selectedProject.roleName;
  }

  @Selector()
  public static getSelectedProjectUserRestrictedDataUseGroups(
    state: UserProjectStateModel
  ): string[] {
    return state.selectedProject.userRestrictedDataUseGroups;
  }

  @Action(HydrateUserProjectStateByProjectId)
  public hydrateUserProjectStateByProjectId(
    ctx: StateContext<UserProjectStateModel>,
    { projectIdFromURL } : HydrateUserProjectStateByProjectId
  ): Observable<boolean> {
    // Check if we have the projectId preserved
    let selectedProjectId = ctx.getState().selectedProjectId;
    
    // if there is nothing in the selectedProjectId
    if (!selectedProjectId && projectIdFromURL === '') return of(false);

    // Check if we also the the selectedProject already then do nothing
    if(selectedProjectId === projectIdFromURL){
      if (ctx.getState().selectedProject.projectNumber) return of(true);
    } else {
      if(projectIdFromURL !== '')
      selectedProjectId = projectIdFromURL;
    }

    // If we have id but do not have other details populate them.
    return this.userProjectService.getAllUserProjects().pipe(
      catchError((err) => {
        const projectsLoaded = true;
        ctx.patchState({ projectsLoaded });
        return throwError(() => new Error(''));
      }),
      map((projects) => {
        let selectedProject = projects.find(
          (project: UserProject) => project.projectId === selectedProjectId
        );

        const projectsLoaded = true;

        // Set the default project data if we do not have the project list or the selected project data in projects
        if (projects.length === 0 || !selectedProject){
          selectedProject = stateDefaults.selectedProject;
          selectedProjectId = stateDefaults.selectedProjectId;
        }

        ctx.patchState({ projects, selectedProject, projectsLoaded, selectedProjectId });

        return true;
      })
    );
  }

  @Action(LoadAllUserProjectsForPlatFormAdmin)
  public loadAllProjectsForPlatformAdmin(
    ctx: StateContext<UserProjectStateModel>,
    { emailId }: LoadAllUserProjectsForPlatFormAdmin
  ): Observable<UserProject[]> {
    return this.userProjectService.getAllUserProjects(emailId).pipe(
      tap((projects) => {
        const userProjectsLoaded = true;
        ctx.patchState({ projects, userProjectsLoaded });
      })
    );
  }

  @Action(LoadAllUserProjects)
  public loadAllUserProjects({
    getState,
    patchState,
  }: StateContext<UserProjectStateModel>): Observable<UserProject[]> {
    return this.userProjectService.getAllUserProjects().pipe(
      catchError((err) => {
        // Set to empty array. This is done as if not set as empty whereever we get snapshots we will get older values!
        const projects: UserProject[] = [];
        const userProjectsLoaded = true;
        patchState({ projects, userProjectsLoaded });
        return throwError(() => new Error(''));
      }),
      tap((projects) => {
        // Check if we have the projectId preserved
        const selectedProjectId = getState().selectedProjectId;

        let selectedProject = projects.find(
          (project: UserProject) => project.projectId === selectedProjectId
        );

        const projectsLoaded = true;

        // Set the default project data if we do not have the project list or the selected project data in projects
        if (projects.length === 0 || !selectedProject) {
          selectedProject = stateDefaults.selectedProject;
        }

        patchState({ projects, selectedProject, projectsLoaded });
      })
    );
  }

  @Action(ResetUserProjectState)
  public resetProjectState({
    patchState,
  }: StateContext<UserProjectStateModel>): void {
    patchState({ ...stateDefaults });
  }

  /** Select Project */
  @Action(SelectUserProjectId)
  public selectProject(
    ctx: StateContext<UserProjectStateModel>,
    { selectedProjectId }: SelectUserProjectId
  ): void {
    const selectedProject = ctx
      .getState()
      .projects.find(
        (project: UserProject) => project.projectId === selectedProjectId
      );
    ctx.patchState({ selectedProjectId, selectedProject });
  }
}
