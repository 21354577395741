/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { ContentPickerDetails } from '@shared';
import { ProjectRequestHeaders } from '@shared/interfaces';
import { UserProject } from '@theme/interfaces';

export class CheckFileManagerObjectExists {
  public static type = 'Check S3 Object';
  constructor(
    public destinationPrefix: string,
    public s3objectNames: any,
    public action: string,
    public isFile: string,
    public prefix?: string,
  ) {}
}

export class GetContentPicker {
  public static type = 'Get Content Picker Details';
  constructor(
    public contentPickerRequest?: ContentPickerDetails,
    public projectRequestHeaders?: ProjectRequestHeaders,
    public selectedProject?: UserProject,
    public showDefaultProjectBucket?: boolean,
    public pageNo?: number | undefined
  ) {}
}

export class CreateS3Folder {
  public static type = 'Create S3 Folder';
  constructor(public prefix?: string) {}
}

export class DeleteS3Folder {
  public static type = 'Delete S3 Folder';
  constructor(public prefix: string, public isFile: boolean) {}
}

export class RenameS3Folder {
  public static type = 'Rename S3 Folder';
  constructor(
    public prefix: string,
    public action: string,
    public destinationPrefix: string,
    public isFile: string,
    public isCopy?: boolean
  ) {}
}
