import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { User } from '@theme/interfaces';
import UserDatasetStateModel from 'app/state/console-stats/console-stats-state-model';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import UserInfoModel from 'app/state/user-info/user-model';
import UserStateModel from 'app/state/user/user-state-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getAllPlatformAdminUsers(
    optionalHeaders: MprHttpHeaderModal
  ): Observable<UserStateModel> {
    const headers = this.getRequestHeaders(optionalHeaders, true);
    return this.http.get<UserStateModel>(
      `/user?isUserList=True&isPlatformAdmins=True`,
      {
        headers,
      }
    );
  }

  public getAllUsers(
    getAllFields = false,
    optionalHeaders: MprHttpHeaderModal,
    pageNo?: number | undefined
  ): Observable<UserStateModel> {
    const headers = this.getRequestHeaders(optionalHeaders, true);
    const pageNoUrl = pageNo && pageNo > 0 ? `&pageNumber=${pageNo}` : '';
    if (getAllFields) {
      return this.http.get<UserStateModel>(
        `/user?isUserList=True&isExportForAllUsers=True${pageNoUrl}`,
        {
          headers,
        }
      );
    }
    return this.http.get<UserStateModel>(`/user?isUserList=True${pageNoUrl}`, {
      headers,
    });
  }

  public getDatasetCount(roleName: string): Observable<UserDatasetStateModel> {
    const headers = new HttpHeaders().set(HeaderParams.ROLENAME, roleName);
    return this.http.get<UserDatasetStateModel>('/dataset', { headers });
  }

  public getProjectCount(
    isCount: string,
    pageNo?: number
  ): Observable<Array<ProjectUserList>> {
    let pageNoStr = '';
    if (pageNo) pageNoStr = `&pageNumber=${pageNo}`;
    const url = `projects?isProjectCount=${isCount}${pageNoStr}`;
    return this.http.get<Array<ProjectUserList>>(url);
  }

  public getUser(optionalHeaders?: MprHttpHeaderModal): Observable<User> {
    if (optionalHeaders) {
      const headers = this.getRequestHeaders(optionalHeaders);
      return this.http.get<User>('/user', { headers });
    }
    return this.http.get<User>('/user');
  }

  public getUserCount(
    isCount: string,
    roleName: string
  ): Observable<Array<UserInfoModel>> {
    const headers = new HttpHeaders().set(HeaderParams.ROLENAME, roleName);
    const url = `/user?isCount=${isCount}`;
    return this.http.get<Array<UserInfoModel>>(url, { headers });
  }

  public getUserInfo(userId: string): Observable<Array<UserInfoModel>> {
    const url = `/userinfo?userId=${userId}`;
    return this.http.get<Array<UserInfoModel>>(url);
  }

  private getRequestHeaders(
    headers: MprHttpHeaderModal,
    roleOnly = false
  ): HttpHeaders {
    const requestHeaders = new HttpHeaders().set(
      HeaderParams.ROLENAME,
      headers[HeaderParams.ROLENAME]
    );

    if (!roleOnly)
      requestHeaders.append(
        HeaderParams.PROJECTID,
        headers[HeaderParams.PROJECTID]
      );
    return requestHeaders;
  }
}
