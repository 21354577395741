import { Pipe, PipeTransform } from '@angular/core';
import { UserProject } from '@theme/interfaces';
/*
 * Get the project short name
 * Takes an object of type UserProject argument.
 * Usage:
 *   userProjectObject | projectShortName
 */
@Pipe({ name: 'projectShortName' })
export class ProjectShortNamePipe implements PipeTransform {
  public transform(project: UserProject): string {
    return `${project.projectNumber}_${project.projectNickname}`;
  }
}
