import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RouteStateModel } from './route-state-model';
import { ResetRoute, SetRoute } from './route.actions';

const stateDefaults: RouteStateModel = {
  lastRoute: '',
};

@State<RouteStateModel>({
  defaults: stateDefaults,
  name: 'RouteState',
})
@Injectable()
export class RouteState {
  constructor() {}

  /** Set Route */
  @Action(ResetRoute)
  public ResetRoute(ctx: StateContext<RouteStateModel>): void {
    ctx.patchState({ lastRoute: '' });
  }

  @Selector()
  public static getRoute(state: RouteStateModel): string {
    return state.lastRoute;
  }

  /** Set Route */
  @Action(SetRoute)
  public setRoute(
    ctx: StateContext<RouteStateModel>,
    { lastRoute }: RouteStateModel
  ): void {
    ctx.patchState({ lastRoute });
  }
}
