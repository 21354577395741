import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { AlertMessageService } from '@core/services';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import {
  CommonResponseModel,
  MprConfirmComponent,
  noWhitespaceValidator,
} from '@shared';
import { LaunchEc2Enum, UserProjectRoleEnum } from '@core';
import { Observable, Subscription, catchError, throwError } from 'rxjs';
import { LaunchEc2State, ResetWorkspaceEc2, StopWorkspaceEc2 } from 'app/state';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as constants from '@core/constants';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { duplicateValidataor } from '@shared/validators/duplicate.validator';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-reconfigure-ec2',
  templateUrl: './reconfigure-ec2.component.html',
  styleUrls: ['./reconfigure-ec2.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ReconfigureEC2Component implements OnInit, OnDestroy {
  @Input() public action = 'edit';
  @Input() public data: ProjectUserList = {
    ec2Status: '',
    emailId: '',
    firstName: '',
    isPowerUser: false,
    lastName: '',
    machineSize: '',
    projectId: '',
    projectRestrictedDataUseGroups: [],
    roleName: '',
    userId: '',
    userStatus: '',
    powerUserFeatures : {
      isHLBedrockAccess : false
    }
  };
  @Output() public updateEvent = new EventEmitter<{
    data: ProjectUserList;
    requestHeaders: MprHttpHeaderModal;
  }>();
  @Select(LaunchEc2State.getLaunchEc2)
  private ec2$!: Observable<CommonResponseModel>;
  public ec2InstanceType = '';
  public ec2InstanceTypes = constants.ec2InstanceTypes;
  public isEnterInstanceType = false;
  public isSuccess = true;
  public launchEc2Enum = LaunchEc2Enum;
  public machineSize = '';
  public reconfigureForm: FormGroup;
  public userEC2Status = '';
  private allSubscription = new Subscription();
  private requestHeaders: MprHttpHeaderModal = {};
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.reconfigureForm = this.fb.group({
      ec2Status: [''],
      ec2InstanceValue: [''],
    });
  }

  public changeEc2Instanace(ec2Instance: any): void {
    this.isEnterInstanceType = ec2Instance === 'Others' ? true : false;
    this.reconfigureForm.controls['ec2InstanceValue'].setValue('');
    this.reconfigureForm.controls['ec2InstanceValue'].clearValidators();
    this.reconfigureForm.controls['ec2InstanceValue'].updateValueAndValidity();
    if (ec2Instance === 'Others') {
      this.machineSize =
        this.reconfigureForm.controls['ec2InstanceValue'].value;
      this.reconfigureForm.controls['ec2InstanceValue'].setValidators([
        Validators.required,
        noWhitespaceValidator(),
        duplicateValidataor(this.data.machineSize),
      ]);
    } else {
      this.machineSize = ec2Instance !== null ? ec2Instance : '';
    }
  }

  public checKFormValue(reconfigureForm: FormGroup): boolean {
    return !reconfigureForm.pristine ? false : true;
  }

  public checkCurrentEc2Value(currentEc2Status: string): boolean {
    if (this.data.machineSize) {
      this.isEnterInstanceType = false;
      const isExistValue = this.ec2InstanceTypes.some((element) => {
        if (element.value === currentEc2Status) {
          this.reconfigureForm.patchValue({
            ec2Status: this.data.machineSize,
          });
          return true;
        }
        return false;
      });
      if (isExistValue) return true;
      else {
        this.reconfigureForm.patchValue({
          ec2Status: 'Others',
          ec2InstanceValue: currentEc2Status,
        });
        this.reconfigureForm.controls['ec2InstanceValue'].setValidators([
          Validators.required,
          noWhitespaceValidator(),
          duplicateValidataor(this.data.machineSize),
        ]);
        this.isEnterInstanceType = true;
      }
      return false;
    }
    return false;
  }

  public displayStatusColor(userEC2Status: string): string {
    if (
      userEC2Status === 'provisioning' ||
      userEC2Status === 'reprovisioning' ||
      userEC2Status === 'initializing' ||
      userEC2Status === 'not provisioned'
    ) {
      return 'yellow';
    } else if (
      userEC2Status === 'stopped' ||
      userEC2Status === 'stopping' ||
      userEC2Status === 'failed'
    ) {
      return 'red';
    } else if (userEC2Status === 'running') {
      return 'green';
    } else if (userEC2Status === 'terminated') {
      return 'grey';
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetWorkspaceEc2());
    this.allSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.action === 'edit') {
      this.userEC2Status = this.data.ec2Status
        ? this.data.ec2Status
        : 'not provisioned';
      this.ec2InstanceType = this.data.machineSize;
      this.checkCurrentEc2Value(this.data.machineSize);
      this.reconfigureForm.controls['ec2Status'].setValidators([
        duplicateValidataor(this.data.machineSize),
      ]);
    }

    this.allSubscription = this.ec2$?.subscribe((ec2: CommonResponseModel) => {
      if (ec2?.status_code === 200) {
        if (ec2?.message.toLowerCase() === 'shutting down ec2') {
          this.userEC2Status = LaunchEc2Enum.STOPPING_STATUS.toLowerCase();
          this.alertMsgService.success({
            body: `The EC2 instance for ${this.data['firstName']} ${this.data['lastName']} is being stopped. It will take a few minutes.`,
          });
        }
      } else if (ec2?.status_code !== 0) {
        this.alertMsgService.error({
          body: `The EC2 instance for ${this.data['firstName']} ${this.data['lastName']} could not be stopped. Please try after sometime.`,
        });
      }
    });
  }

  public stopEC2InstanceConfirmPopup(): void {
    const userId =
      this.data['userStatus'] === 'invited'
        ? this.data['emailId']
        : this.data['userId'];
    const projectId = this.data['projectId'];
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'STOP EC2 INSTANCE',
        confirmMessage: `You are trying to stop the EC2 instance of <b>${this.data['firstName']} ${this.data['lastName']}</b>. Upon stopping, any unsaved data might be lost and any ongoing processes may get terminated. Are you sure you want to continue?`,
        confirmData: {
          userId,
          projectId,
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmData: any): void => {
      if (!confirmData) return;
      const requestHeaders: MprHttpHeaderModal = {};
      requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;
      this.store.dispatch(
        new StopWorkspaceEc2(
          confirmData.userId,
          confirmData.projectId,
          'stop',
          requestHeaders
        )
      );
    });
  }

  public updateEc2instance(): void {
    this.data.machineSize = this.isEnterInstanceType
      ? this.reconfigureForm.controls['ec2InstanceValue'].value.trim()
      : this.machineSize.trim();
    this.requestHeaders = {};
    this.requestHeaders[HeaderParams.ROLENAME] =
      UserProjectRoleEnum.PLATFORM_ADMIN;

    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'Update EC2 Instance',
        confirmMessage: `You are modifying the EC2 instance type for <b>${this.data['firstName']} ${this.data['lastName']}</b> to <b>${this.data['machineSize']}</b>.<br/>This will take few minutes. Are you sure you want to continue?`,
        confirmData: {
          data: this.data,
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmData: any): void => {
      if (!confirmData) return;
      this.updateEvent.emit({
        data: this.data,
        requestHeaders: this.requestHeaders,
      });
    });
  }
}
