/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class UpdateRVersion {
    public static type = '[UpdateRversion] Update R Version ';
    constructor(
      public instanceId: string,
      public rVersion: string
    ) {}
  }

  export class ResetRversionResponse {
    public static type = '[UpdateRversion] Reset to Default';
  }