import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponseModel } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ChangeSFDefaultRoleService {
  constructor(private http: HttpClient) {}

  public changeSFRole(
    projectId: string,
    roleName: string
  ): Observable<CommonResponseModel> {
    return this.http.put<CommonResponseModel>(`/project/change_default_role`, {
      projectId,
      roleName,
    });
  }

}
