<div class="d-flex mpr-justify-content-center mpr-align-items-center">
  <mat-spinner
    diameter="20"
    color="accent"
    class="m-r-4"
    [class]="spinner ? 'mpr-visibility-visible' : 'mpr-visibility-hidden'"
  ></mat-spinner>
  <button
    aria-label="Export As CSV"
    class="mpr-export-csv"
    [class.mpr-disabled]="spinner"
    mat-button
    id="mpr-export-csv"
    (click)="exportToCsv()"
  >
    <img
      src="assets/images/Export.svg"
      alt=""
      class="mpr-export-folder-image"
    />
    <span class="mpr-export-text">Export as CSV </span>
  </button>
</div>
