import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { CommonResponseModel } from '@shared/interfaces';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AWSWorkspaceService {
  constructor(private http: HttpClient) {}

  public forgotAWSWPassword(
    password: string,
    workspaceUserName: string
  ): Observable<CommonResponseModel> {
    return this.http.put<CommonResponseModel>(`/password`, {
      password,
      workspaceUserName,
    });
  }

  public launchOrProvisionAWSWorkspace(
    action = 'start',
    awsPassword?: string
  ): Observable<CommonResponseModel> {
    // Method for post and put
    const method = action === 'reprovision' ? 'put' : 'post';
    const workspaceType = 'amazon_workspace';
    const password = awsPassword;
    return password
      ? this.http[method]<CommonResponseModel>(`/server`, {
          action,
          workspaceType,
          password,
        })
      : this.http[method]<CommonResponseModel>(`/server`, {
          action,
          workspaceType,
        });
  }

  public reprovisionAWSWorkspace(
    updateWSType: string
  ): Observable<CommonResponseModel> {
    // Method for post and put
    const action = 'reprovision';
    const method = 'post';
    const workspaceType = 'amazon_workspace';
    const updatedWorkspaceType = updateWSType;
    return this.http.post<CommonResponseModel>(`/server`, {
      action,
      workspaceType,
      updatedWorkspaceType,
    });
  }

  public stopAWSWorkspace(action: string): Observable<CommonResponseModel> {
    const workspaceType = 'amazon_workspace';
    return this.http.post<CommonResponseModel>(`/server`, {
      action,
      workspaceType,
    });
  }

  public terminateUserAWSWorkspace(userDetails : ProjectUserList, requestHeaders: MprHttpHeaderModal): Observable<CommonResponseModel> {
    const workspaceType = 'amazon_workspace';
    const action = 'deprovision';
    const headers = new HttpHeaders().append(
      HeaderParams.ROLENAME,
      requestHeaders[HeaderParams.ROLENAME]
    );
    return this.http.delete<any>(`/server`, {
      body : {action,
      workspaceType,
      userId : userDetails.userId,
      projectId: userDetails.projectId,
      },
      headers
      });
  }
}
