/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetSnowflakeDetails {
    public static type = '[SnowflakeDetails] Get Snowflake Details ';
  }

export class ResetSnowflakeDetails {
    public static type = '[SnowflakeDetails] Reset Snowflake Details ';
  }
