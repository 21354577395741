/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
export class SetRecurringJobDetails {
    public static type = '[RecurringJobDetailsState] Set job details';

    constructor(public jobId: string, public jobOperationType?: string) { }
}

export class ResetRecurringJobDetails {
    public static type = '[RecurringJobDetailsState] Reset job details'
}