import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { LoggedinUserState } from 'app/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
})
export class UserComponent {
  @Select(LoggedinUserState.getLoggedinUserName)
  public userFullName$?: Observable<string>;
}
