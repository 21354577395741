import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoleLabelsModel } from 'app/state/role-labels/role-labels.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleLabelsService {
  constructor(private http: HttpClient) {}

  public getAllRoleLabels(): Observable<RoleLabelsModel[]> {
    return this.http.get<RoleLabelsModel[]>('/role_label_management');
  }
}
