<div *ngIf="jobId || datasetDetails.datasetName !== ''; else oneTimeUpload">
  <mpr-select-files
    [jobId]="jobId"
    [datasetDetails] = "datasetDetails"
    (uploadData)="startFileUpload($event)"
    
  ></mpr-select-files>
</div>
<ng-template #oneTimeUpload>
  <mat-stepper
    class="ontime-upload-screen"
    [linear]="isLinear"
    labelPosition="bottom"
    #stepper
    
  >
    <ng-template matStepperIcon="edit" let-index="index">{{
      index + 1
    }}</ng-template>
    <mat-step label="SELECT FILE(S)" [completed]="selectFilesFormStatus">
      <ng-template [ngTemplateOutlet]="noteText"></ng-template>
      <mpr-select-files
        (proceedToMetaData)="proceedToMetaDataScreen(stepper)"
      ></mpr-select-files>
    </mat-step>
    <mat-step
      label="ENTER METADATA"
      [completed]="metaDataFormStatus"
      [optional]="false"
    >
      <ng-template [ngTemplateOutlet]="noteText"></ng-template>
      <mpr-enter-metadata
        [oneTimeOrSchedule]="oneTime"
        (proceedToUploadOrSchedule)="startFileUpload()"
      >
      </mpr-enter-metadata>
    </mat-step>
  </mat-stepper>
</ng-template>

<ng-template #noteText>
  <div class="mpr-note-text">
    NOTE : You must complete the 2-step file upload process by selecting the data files, supporting documents (if any) and entering the metadata in a single session without closing the browser. If you begin this process and close the browser before the full upload is completed, your file and its associated metadata will not be saved.
  </div>
  <hr class="mpr-note-dashed-divider" />
</ng-template>
