/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
import { MprHttpHeaderModal } from '@core/interfaces';
export class LoadSelectedProjectMetadata {
  public static type = '[ProjectMetadata] Get metadata ';
  constructor(public requestHeaders?: MprHttpHeaderModal, public getProjectAdminEmailList?: string ) {}
}

export class ResetSelectedProjectMetadata {
  public static type = '[ProjectMetadata] Reset to Default';
}
