/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class RefreshSfJWTToken {
    public static type = '[SfJWTToken] Get Sf JWT Token ';
    constructor(public instanceId : string){}
  }

export class ResetSfJWTToken {
  public static type = '[SfJWTToken] Reset Sf JWT Token ';
}


