/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';
import { ConnectionModel, ConnectionRequestPayload } from '@shared';

export class GetAllConnections {
  public static type = '[Connection] Get All';
  constructor(public requestHeaders?: MprHttpHeaderModal) { }
}

export class GetActiveIngestionConnections {
  public static type = '[Connection] Get Active Ingestion Connections';
}

export class SetSelectedConnection {
  public static type = '[Connection] Set Selected ';
  constructor(public selectedConnection: ConnectionModel, public requestHeaders?: MprHttpHeaderModal) { }
}

export class AddConnection {
  public static type = '[Connection] Add ';
  constructor(public connectionDetails: ConnectionRequestPayload) { }
}

export class DeleteConnection {
  public static type = '[Connection] Delete ';
  constructor(public connectionId: string) { }
}

export class GetAddedConnection {
  public static type = '[Connection] Get Added ';
}

export class ResetConnectionState {
  public static type = '[ConnectionState] Reset to Default';
}

export class UpdateConnection {
  public static type = '[Connection] Update';
  constructor(public connectionUpdateDetails: ConnectionModel) { }
}
