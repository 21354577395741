import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserService } from '@theme/services';
import { Observable, tap } from 'rxjs';
import {
  GetProjectCount,
  GetUserCount,
  LoadDatasetCount,
  ResetCountData,
} from './console-stats.actions';
import ConsoleStatsModel, { ProjectDetails } from './console-stats-state-model';
import UserInfoStateModel from '../user-info/user-info-state-model';
import { User } from '@theme/interfaces';
import ProjectCountModel from '../project-user/project-user-state-model';

const stateDefaults: ConsoleStatsModel = {
  allProjects: [],
  message: '',
  userCount: 0,
  projectCount: 0,
  projectDetails: [],
};
@State<ConsoleStatsModel>({
  defaults: stateDefaults,
  name: 'Userdatasetdata',
})
@Injectable()
export class ConsoleStatsState {
  constructor(private userService: UserService) {}

  @Action(GetProjectCount)
  public GetProjectCount(
    ctx: StateContext<ConsoleStatsModel>,
    { isCount, pageNo }: GetProjectCount
  ): Observable<ProjectCountModel> {
    return this.userService.getProjectCount(isCount, pageNo).pipe(
      tap((result: any) => {
        if (pageNo === 1) {
          ctx.patchState({ ...result });
        } else {
          if (!pageNo) {
            const allProjects: Array<ProjectDetails> = result.projectDetails;
            ctx.patchState({ allProjects });
          } else {
            let projectDetails = ctx.getState().projectDetails;
            projectDetails = projectDetails?.concat(result.projectDetails);
            ctx.patchState({ projectDetails });
          }
        }
      })
    );
  }

  @Action(GetUserCount)
  public GetUserCount(
    { patchState }: StateContext<UserInfoStateModel>,
    { isCount, roleName }: GetUserCount
  ): Observable<User> {
    return this.userService.getUserCount(isCount, roleName).pipe(
      tap((result: any) => {
        patchState({ ...result });
      })
    );
  }

  @Action(LoadDatasetCount)
  public LoadDatasetCount(
    { patchState }: StateContext<ConsoleStatsModel>,
    { roleName }: LoadDatasetCount
  ): Observable<ConsoleStatsModel> {
    return this.userService.getDatasetCount(roleName).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Action(ResetCountData)
  public ResetCountData({ patchState }: StateContext<ConsoleStatsModel>): void {
    patchState({ ...stateDefaults });
  }

  @Selector()
  public static getAllProjects(state: ConsoleStatsModel): any {
    return state.allProjects;
  }

  @Selector()
  public static getCurrentUserCount(
    state: ConsoleStatsModel
  ): number | undefined {
    return state.userCount;
  }

  @Selector()
  public static getDatasetCount(state: ConsoleStatsModel): any {
    return state.message;
  }

  @Selector()
  public static getProjectCount(state: ProjectCountModel): number | undefined {
    return state.projectCount;
  }

  @Selector()
  public static getProjectDetails(state: ConsoleStatsModel): any | undefined {
    return state.projectDetails;
  }
}
