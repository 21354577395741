<mat-toolbar class="matero-toolbar mpr-header-green-color">
  <div
    tabindex="-1"
    aria-hidden
    class="hidden-ref"
    id="setFocusField"
  ></div>
  <p class="skipnav">
    <a
      href="javascript:void(0);"
      (keyup.enter)="skipToMainContent()"
      (click)="skipToMainContent()"
      translate
      aria-label="Skip to main content"
      >Skip to main content</a
    >
  </p>
  <div class="p-l-8">
    <mpr-app-name dataTestId="app-header-branding-text"></mpr-app-name>
  </div>

  <span fxFlex></span>
  <app-user></app-user>
  <mat-accordion class="user-menu">
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [expanded]="panelOpenState"
    >
      <mat-expansion-panel-header
        aria-label="More Options Dropdown"
        title="More Options"
      >
      </mat-expansion-panel-header>
      <p><a target="_blank" [href]="helpPageUrl">Help</a></p>
      <p>
        <a target="_blank" [href]="termsOfUse"
          >Terms Of Use And Privacy Policy</a
        >
      </p>
      <p><a routerLink="/logout">Logout</a></p>
    </mat-expansion-panel>
  </mat-accordion>
</mat-toolbar>
