import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PreloaderService } from '@core';
import { USER_IDLE_CONFIG } from '@core/constants';
import { IdleUserService, SeoService } from '@shared/services';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    //Do not remove this is not unused variable, this calls its contructor
    private idleUserService: IdleUserService,
    private preloader: PreloaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
  ) {
    localStorage.removeItem(USER_IDLE_CONFIG.localStorageKey);
  }

  ngAfterViewInit(): void {
    this.preloader.hide();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        this.seoService.updateTitle(event['title']);
      });
  }
}
