import { Injectable } from '@angular/core';
import { GenericHttpResponse } from '@core/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ScheduleService, ScheduleModel } from '@shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggedinUserState } from '../../loggedin-user';
import { UserProjectState } from '../../user-projects/user-project.state';
import { UploadsMetadataState } from '../uploads-metadata-state';
import { ScheduleFormModel } from './schedule-form.model';
import {
  DeleteSchedule,
  EditSchedule,
  EnableDisableSchedule,
  GetAllSchedule,
  ResetScheduleState,
  SetScheduleFormData,
  SetScheduleMetaData,
} from './schedule.action';
import { ScheduleStateModel } from './schedule.state.model';

const stateDefaults: ScheduleStateModel = {
  uploadScheduleForm: {
    connectionId: '',
    scheduleName: '',
    folderPath: '',
    folderId: '',
    frequency: '',
    hourOfTheDay: '',
    frequencyDetail: '',
    
  },
  loadingSchedules: false,
  emailId: '',
  projectId: '',
  schedules: [],
};

@State<ScheduleStateModel>({
  defaults: stateDefaults,
  name: 'ScheduleState',
  children: [UploadsMetadataState],
})
@Injectable()
export class ScheduleState {
  constructor(private store: Store, private scheduleService: ScheduleService) {}

  @Action(DeleteSchedule)
  public delete(
    ctx: StateContext<ScheduleModel>,
    { scheduleId }: DeleteSchedule
  ): Observable<GenericHttpResponse> {
    return this.scheduleService.deleteSchedule(scheduleId);
  }

  @Action(EditSchedule)
  public editSchedule(
    ctx: StateContext<ScheduleStateModel>,
    { scheduleId, scheduleUpdateDetails }: EditSchedule
  ): Observable<GenericHttpResponse> {
    return this.scheduleService
      .editSchedule(scheduleId, scheduleUpdateDetails)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          // Clone the array of objects.
          const scheduleList = [...state.schedules];
          const index = scheduleList.findIndex(
            (list: { scheduleId: string }) => list.scheduleId === scheduleId
          );
          // Override detuals in the existing object
          scheduleList[index] = {
            ...scheduleUpdateDetails,
            ...scheduleList[index],
          };
          ctx.patchState({
            schedules: scheduleList,
          });
        })
      );
  }

  @Action(EnableDisableSchedule)
  public enableDisable(
    ctx: StateContext<ScheduleModel>,
    { scheduleId, status }: EnableDisableSchedule
  ): Observable<GenericHttpResponse> {
    return this.scheduleService.enableDisableSchedule(scheduleId, status);
  }

  @Action(GetAllSchedule)
  public getAllSchedule({
    patchState,
  }: StateContext<ScheduleStateModel>,
  {scheduleType}: GetAllSchedule): Observable<ScheduleModel[]> {
    patchState({ loadingSchedules: true });
    return this.scheduleService.getAllSchedule(scheduleType).pipe(
      tap((schedules: any) => {
        patchState({
          schedules,
          loadingSchedules: false,
        });
      })
    );
  }

  @Selector()
  public static getAllSchedules(
    state: ScheduleStateModel
  ): ScheduleModel[] {
    return state.schedules;
  }

  @Action(ResetScheduleState)
  public resetScheduleState({
    patchState,
  }: StateContext<ScheduleStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetScheduleFormData)
  public setScheduleFormData(
    ctx: StateContext<ScheduleStateModel>,
    uploadScheduleForm: ScheduleFormModel
  ): void {
    const emailId = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserEmail
    );
    const projectId = this.store.selectSnapshot(
      UserProjectState.getSelectedProjectId
    );
    ctx.patchState({ ...uploadScheduleForm, emailId, projectId });
  }

  @Action(SetScheduleMetaData)
  public setScheduleMetaData(
    ctx: StateContext<UploadsMetadataState>,
    fileMetadata: UploadsMetadataState
  ): void {
    ctx.patchState({ ...fileMetadata });
  }

}
