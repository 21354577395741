/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';

export class LaunchWorkspaceEc2 {
  public static type = '[LaunchEc2] workspace data ';
  constructor(
    public userId: string,
    public projectId: string,
    public action = 'start'
  ) {}
}

export class ProvisionWorkspaceEc2 {
  public static type = '[ProvisionEc2] workspace data ';
  constructor(
    public userId: string,
    public projectId: string,
    public action = 'provision'
  ) {}
}

export class StopWorkspaceEc2 {
  public static type = '[StopEc2] workspace data ';
  constructor(
    public userId: string,
    public projectId: string,
    public action: string,
    public requestHeaders? :MprHttpHeaderModal
  ) {}
}

export class ResetWorkspaceEc2{
  public static type = '[ResetEc2] Reset to Default';
}

