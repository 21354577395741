import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_DATA_FIELDS, USER_STATUS } from '@core/constants';
import { UserProjectRoleEnum, userStatus } from '@core/enums';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { convertToCSV, saveAsCSV } from '@shared/utils';
import { UserProject } from '@theme/interfaces';
import {
  FeatureFlagsState,
  LoadSelectedProjectMetadata,
  LoadSelectedUserProjects,
  LoggedinUserState,
  ProjectState,
  ResetSelectedProjectMetadata,
  UserProjectState,
} from 'app/state';
import { DeleteUserList } from 'app/state/project-user/delete-user-list-model';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { DeleteUserFromProject } from 'app/state/project-user/project-user.actions';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import { RoleLabelsState } from 'app/state/role-labels/role-labels.state';
import { SelectedUserProjectInfoState } from 'app/state/selected-user-project-info/selected-user-project-info.state';
import { catchError, Observable, throwError, withLatestFrom } from 'rxjs';
import { MprConfirmComponent } from '../mpr-confirm/mpr-confirm.component';
@Component({
  selector: 'mpr-list-details',
  templateUrl: './list-details.component.html',
  styleUrls: ['./list-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListDetailsComponent implements OnInit {
  @Select(ProjectUserState.getDeleteUserList)
  public deleteUsersList$?: Observable<DeleteUserList[]>;
  @Input() public isFromListPage = false;
  @Input() public listDetails?: Observable<ProjectUserList[]>;
  @Input() public listType = '';
  @Select(SelectedUserProjectInfoState.getSelectedUserProjects)
  public projectsOfUserToBeDeleted$!: Observable<UserProject[]>;
  public isDeleteInProgress = false;
  public loggedinUserEmail = '';
  public projectUserCount = '';
  public selectedProjectId = '';
  public userProjectsCount = 0;
  public userStatusInvited = userStatus.INVITED;
  private projectId = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.projectId = this.route.snapshot.params['projectId'];
    this.projectId = this.projectId
      ? atob(this.route.snapshot.params['projectId'])
      : '';
  }

  public exportToCSV(listDetails: ProjectUserList[]): void {
    const valueMapping = { roleName: {} };
    valueMapping['roleName'] =
      this.store.selectSnapshot(RoleLabelsState.getRoleLabelsMapping) || {};
    const csv = convertToCSV(listDetails, USER_DATA_FIELDS, valueMapping);
    const projectId = this.projectId
      ? this.projectId
      : this.store.selectSnapshot(UserProjectState.getSelectedProjectId);
    saveAsCSV(csv, `${projectId}_Users.csv`);
  }

  public isDisabled(status: string): boolean {
    return USER_STATUS.DISABLED === status;
  }

  public navigateToAddusers(): void {
    let navigateRoute = 'admin/users/add';
    if (this.isFromListPage)
      navigateRoute = `/platformAdmin/user/add/${this.route.snapshot.params['projectId']}`;
    this.router.navigate([navigateRoute]);
  }

  public navigateToEditUser(user: ProjectUserList): void {
    const id =
      user.userStatus === userStatus.INVITED ? user.emailId : user.userId;
    let url = `admin/users/edit/${btoa(id)}`;
    if (this.isFromListPage)
      url = `platformAdmin/user/edit/${btoa(id)}/${
        this.route.snapshot.params['projectId']
      }`;
    this.router.navigate([url]);
  }

  ngOnInit(): void {
    this.loggedinUserEmail = this.store.selectSnapshot(
      LoggedinUserState.getLoggedInUserEmail
    );
    this.selectedProjectId = this.projectId
      ? this.projectId
      : this.store.selectSnapshot(UserProjectState.getSelectedProjectId);

    this.store.dispatch(new ResetSelectedProjectMetadata());
    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.PROJECTID] = this.selectedProjectId;
    requestHeaders[HeaderParams.ROLENAME] = this.isFromListPage
      ? UserProjectRoleEnum.PLATFORM_ADMIN
      : UserProjectRoleEnum.ADMIN;
    this.store.dispatch(new LoadSelectedProjectMetadata(requestHeaders));
  }

  public showDeleteConfirmation(user: ProjectUserList): void {
    this.isDeleteInProgress = true;
    this.store
      .dispatch(new LoadSelectedUserProjects(user.emailId))
      .pipe(
        withLatestFrom(this.projectsOfUserToBeDeleted$),
        catchError((err) => throwError(() => new Error('')))
      )
      .subscribe(([_, userProjects]) => {
        this.userProjectsCount = userProjects.length;
        const id =
          user.userStatus === userStatus.INVITED ? user.emailId : user.userId;
        const isPowerUser = user.isPowerUser;
        let confirmationMessage = '';
        const displayProjectId =
          this.projectId !== ''
            ? this.projectId
            : this.store.selectSnapshot(UserProjectState.getSelectedProjectId);

        // Check the feature flag for project account settings
        const externalProjectAccountsFeatureFlag = this.store.selectSnapshot(
          FeatureFlagsState.returnFeatureFlags
        ).externalProjectAccountsFeatureFlag;

        const projectCrossAccountFlag = this.store.selectSnapshot(
          ProjectState.getExternalProjectAccountFlag
        );

        confirmationMessage = `Are you sure you want to delete <b>${user.firstName} ${user.lastName}</b> from project <b>${displayProjectId}</b>?<br/><br/>Once removed, this user will no longer have access to the project data. Any jobs running in this user's workspace will be terminated.`;

        if (externalProjectAccountsFeatureFlag && projectCrossAccountFlag) {
          confirmationMessage += ` Any User connections to project AWS Account will be disabled.`;
        }

        this.dialog
          .open(MprConfirmComponent, {
            disableClose: true,
            data: {
              confirmTitle: 'Remove User ',
              confirmMessage: confirmationMessage,
              confirmAction: '',
              confirmData: id,
            },
          })
          .afterClosed()
          .subscribe((userId?: string): void => {
            this.isDeleteInProgress = false;
            if (!userId) return;
            this.returnDeleteUserAction(id)
              .pipe(
                catchError((err) => {
                  this.alertMsgService.error({
                    body: err.message || err.error.message,
                    autoDismiss: !(err.message)
                  });
                  return throwError(() => new Error(''));
                })
              )
              .subscribe((res: any) => {
                const deleteResponse = res.ProjectUser.deleteResponse;
                if (deleteResponse.length > 0) {
                  if (!deleteResponse[0].isSuccess) {
                    this.alertMsgService.error({
                      body: deleteResponse[0].message,
                    });
                  } else {
                    this.alertMsgService.success({
                      body: `User has been deleted successfully.`,
                    });
                  }
                }
              });
          });
      });
  }

  private returnDeleteUserAction(
    id: string
  ): Observable<DeleteUserFromProject> {
    let returnType: Observable<DeleteUserFromProject>;

    if (this.projectId) {
      const requestHeaders: MprHttpHeaderModal = {};
      requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;

      returnType = this.store.dispatch(
        new DeleteUserFromProject(id, requestHeaders, [this.projectId])
      );
    } else {
      returnType = this.store.dispatch(new DeleteUserFromProject(id));
    }

    return returnType;
  }
}
