<div>
  <div
    class="mpr-dashboard-loader"
    *ngIf="showSpinner$ | async; else showUserForm"
  >
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showUserForm>
    <div class="mpr-project">PROJECT : {{ selectedProjectId }}</div>
    <form #userForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="mpr-margin-top mpr-padding-top mat-expansion-panel mat-expanded">
        <div class="mat-expansion-panel-content">
          <div class="mat-expansion-panel-body">
            <mpr-user-form
              [data]="config"
              [group]="''"
              [projectId]="selectedProjectId"
              [action]="'edit'"
              [canChangePowerUserStatus]="canChangePowerUserStatus"
              [canChangeBedrockAccessStatus]="canChangeBedrockAccessStatus"
              [restrictedGroups]="allRestrictedGroupsForProject"
              [projectPoweruser]="isPowerUserApplicable"
              [allowedExternalDomains]="allowedExternalDomains"
              (isDataRestrictedChange)="restrictedCheckboxDataChange($event)"
            ></mpr-user-form>

            <div class="mpr-actions" *ngIf="checkRoleLable()">
              <div></div>
              <div>
                <button
                  type="submit"
                  class="mpr-submit-user"
                  [ngClass]="
                    checKFormValue(userForm) && userForm.form.valid
                      ? 'mpr-disabled'
                      : ''
                  "
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mpr-actions" *ngIf="!checkRoleLable()">
        <div></div>
        <div>
          <button type="button" (click)="navigateBack()" class="mpr-cancel">
            Cancel
          </button>
          <button
            type="submit"
            class="mpr-submit-user"
            [ngClass]="
              checKFormValue(userForm) && userForm.form.valid
                ? 'mpr-disabled'
                : ''
            "
          >
            Confirm
          </button>
        </div>
      </div>
    </form>
    <div class="mpr-margin-top mpr-padding-top mat-expansion-panel mat-expanded" *ngIf="checkRoleLable()">
      <div class="mat-expansion-panel-content">
        <div class="mat-expansion-panel-body">
          <mpr-reconfigure-ec2
            [data]="config"
            [action]="'edit'"
            (updateEvent)="updateEventHander($event)"
          >
          </mpr-reconfigure-ec2>
        </div>
      </div>
    </div>
    <div class="mpr-margin-top mpr-padding-top mat-expansion-panel mat-expanded" *ngIf="checkAWSWorkspaceSectionDisplay()">
      <div class="mat-expansion-panel-content">
        <div class="mat-expansion-panel-body">
          <mpr-reconfigure-workspace
          [data]="config"
          [action]="'edit'"
          >
          </mpr-reconfigure-workspace>
        </div>
      </div>
    </div>
    <div class="mpr-actions" *ngIf="checkRoleLable() || checkAWSWorkspaceSectionDisplay()">
      <div></div>
      <div>
        <button type="button" (click)="navigateBack()" class="mpr-cancel">
          Back
        </button>
      </div>
    </div>
  </ng-template>
</div>
