/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
export class HydrateUserProjectStateByProjectId {
  public static type =
    '[UserProject] Re-Hydrate UserProjectState by Project Id';
    constructor(public projectIdFromURL: string) {}
}

export class LoadAllUserProjects {
  public static type = '[UserProject] Get All ';
}

export class LoadAllUserProjectsForPlatFormAdmin {
  public static type = '[UserProjectsPlatFormAdmin] Get All ';
  constructor(public emailId: string) {}
}

export class SelectUserProjectId {
  public static type = '[UserProject] Select Project Id';

  constructor(public selectedProjectId: string) {}
}

export class ResetUserProjectState {
  public static type = '[UserProject] Reset to Default';
}

export class LaunchEC2Project {
  public static type = '[UserProject] Launch EC2';
}
