import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingListModel, BillingModel, MonthlyBillingModel } from 'app/state/billing';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private http: HttpClient) {}

  public getBillingList(): Observable<BillingModel[]> {
    const requestUrl = `/billing`;
    return this.http.get<BillingModel[]>(`${requestUrl}`);
  }
  
  public getMonthlyBilling(statementPeriod: string): Observable<MonthlyBillingModel> {
    const monthlyBillingUrl = `/billing?usage_month=${statementPeriod}`;
    return this.http.get<MonthlyBillingModel>(monthlyBillingUrl);
  }
}
