import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { ResetUploadsState, UploadsFilesFormModel } from 'app/state';
import { Store } from '@ngxs/store';
import { UploadsService } from '../services';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ConnectionDataStore, JobOperationTypeEnums } from '@core/enums';

@Component({
  selector: 'mpr-cloud-transfer',
  templateUrl: './cloud-transfer.component.html',
  styleUrls: ['../../../styles/custom/_mpr-dialog.scss'],
})
export class CloudTransferComponent implements OnInit, OnDestroy {
  public description: string;
  public jobOperationType = JobOperationTypeEnums.JOB_OPERATION_TYPE_STAGING;
  public resUploadDeatials = '';
  public title: string;
  public uploadPending = true;
  public uploadSuccessful = false;

  constructor(
    private store: Store,
    private uploadsService: UploadsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.title = 'Preparing Upload Request';
    this.description =
      'Please wait while we register your request for the uploads.';
  }

  ngOnDestroy(): void {
    // Ensure we have clean slate when user starts off with this.
    this.store.dispatch(new ResetUploadsState());
  }

  ngOnInit(): void {
    this.initialiseFileUploads();
  }

  public proceedToOneTimeJobDetails(jobOperationType = 'uploads'): void {
    const projectDetails = this.data.datasetDetails;
    if (projectDetails) {
      this.router.navigate([
        `/${jobOperationType}/job-details/onetime/dataset/${this.resUploadDeatials}/${projectDetails}`,
      ]);
    } else {
      this.router.navigate([
        `/${jobOperationType}/job-details/onetime/${this.resUploadDeatials}`,
      ]);
    }
  }

  // Start off upload flow : Perhaps Separate Service for file-uploads and when we revisit
  private initialiseFileUploads(): void {
    // To make Async possibly move to the State and just trigger from here.
    // Currently this info is not needed elsewhere and sync is fine.
    // take(1) last emitted value needs no unsubscribe
    this.uploadsService
      .createJob(this.data.requestHeader)
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          this.resUploadDeatials = res.message.jobId;
          this.uploadSuccessful = true;
          this.uploadPending = false;
          const uploadedFilesDetails: UploadsFilesFormModel =
            this.store.snapshot().UploadsState.uploadFilesForm;
          if (
            uploadedFilesDetails.noOfFiles === 0 &&
            uploadedFilesDetails.noOfSupportingFiles === 0 &&
            uploadedFilesDetails.connectionId !== ConnectionDataStore.FORSTA
          ) {
            this.title = 'Upload Completed';
            this.description = `${uploadedFilesDetails.noOfUrls} URL(s) are being cataloged.`;
          } else {
            this.title = 'Upload Request In-progress';
            this.description =
              'Your file upload request is being processed. You will receive an email confirmation once the request is complete.';
          }
        },
        error: (err: any) => {
          this.uploadPending = false;
          this.title = 'Upload Request Failed';
          this.description =
            'Upload failed due to technical issues. Kindly re-try uploading the files.';
        },
      });
  }
}
