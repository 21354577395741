/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AcceptTermsService } from '@shared/services';
import { Observable, tap } from 'rxjs';
import { AcceptTerms } from './accept-terms.actions';
import { CommonResponseModel } from '@shared/interfaces';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};
@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'AcceptTermsdata',
})
@Injectable()
export class AcceptTermsState {
  constructor(private AcceptTermsService: AcceptTermsService) {}

  @Action(AcceptTerms)
  public AcceptTerms(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { userId, requestHeaders }: AcceptTerms
  ): Observable<CommonResponseModel> {
    return this.AcceptTermsService.acceptTerms(userId, requestHeaders).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Selector()
  public static getAcceptTerms(state: CommonResponseModel): CommonResponseModel {
    return state;
  }
}
