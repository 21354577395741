import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SnowflakeDetailsService } from '@theme/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnowflakeDetailsStateModel } from './snowflake-details-model';
import { GetSnowflakeDetails, ResetSnowflakeDetails } from './snowflake-details.actions';


const stateDefaults: SnowflakeDetailsStateModel = {
    snowflakeAccount: '' ,
    snowflakeDatabase:  '',
    snowflakeWarehouse: '' ,
    snowflakeRoleName: '' ,
    emailId: '',
    status_code: 0,
    dsn_value:'snowflake',
};

@State<SnowflakeDetailsStateModel>({
  defaults: stateDefaults,
  name: 'snowflakedetails'
})
@Injectable()
export class SnowflakeDetailsState {
  constructor(private snowflakeDetailsService:SnowflakeDetailsService ) {}

  @Action(GetSnowflakeDetails)
  public getSnowflakeDetails({
        patchState
  }: StateContext<SnowflakeDetailsStateModel>): Observable<any> {
    return this.snowflakeDetailsService.getSnowflakeDetails().pipe(
      tap((result) => {
        patchState(result);
      })
    );
  }

  @Action(ResetSnowflakeDetails)
  public resetSnowflakeDetails({
        patchState
  }: StateContext<SnowflakeDetailsStateModel>): void {
    patchState({ ...stateDefaults });
  }
  
  @Selector()
  public static returnSnowflakeDetails(state: SnowflakeDetailsStateModel): SnowflakeDetailsStateModel {
    return state;
  }
}