import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BillingListModel } from './billing-list.model';
import { BillingService } from '@shared/services/billing.service';
import { GetBillingList, ResetBillingList, GetMonthlyBilling, ResetMonthlyBilling } from './billing.action';
import { BillingModel } from './billing.model';
import { MonthlyBillingModel } from './monthly-billing.model';
import { state } from '@angular/animations';

const stateDefaults = {
    billingList: [],
    message: '',
    monthlyBillingData: null,
    status_code: 0,
};

@State<BillingListModel>({
    name: 'BillingState',
    defaults: stateDefaults
  })
  @Injectable()
  export class BillingState {
    constructor(private billingService: BillingService) { }

    @Action(GetBillingList)
    public getBillingList({
      patchState,
    }: StateContext<BillingListModel>): Observable<BillingModel[]> {
      return this.billingService.getBillingList().pipe(
        tap((billingList: BillingModel[]): any => {
          patchState({ billingList });
        })
      );
    }

    @Action(GetMonthlyBilling)
    public getMonthlyBilling({ patchState }: StateContext<BillingListModel>, 
      { statementPeriod }: GetMonthlyBilling): Observable<MonthlyBillingModel> {
      return this.billingService.getMonthlyBilling(statementPeriod).pipe(
        tap((monthlyBillingData: MonthlyBillingModel): any => {
          patchState({ monthlyBillingData});
        })
      );
    }

    @Action(ResetBillingList)
    public resetBillingList({
          patchState
    }: StateContext<BillingListModel>): void {
      patchState({ ...stateDefaults });
    }

    @Action(ResetMonthlyBilling)
    public resetMonthlyBilling({
          patchState
    }: StateContext<BillingListModel>): void {
      patchState({ ...stateDefaults });
    }

    @Selector()
    public static returnBillingData(billingState: BillingListModel): any {
        return billingState.billingList;
    }

    @Selector()
    public static returnMonthlyBillingData(monthlyState: BillingListModel): MonthlyBillingModel | null {
      return monthlyState.monthlyBillingData;
    }
  
  }