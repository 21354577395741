import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EXPORT_CSV_ALL_USER_DATA_FIELDS, USER_STATUS } from '@core/constants';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { convertToCSV, saveAsCSV } from '@shared/utils';
import {
  LoadAllUserdata,
  ResetAllUserdata,
  ResetUserdata,
  UserState,
} from 'app/state';
import { UserList } from 'app/state/project-user/user-list-model';
import { RoleLabelsState } from 'app/state/role-labels/role-labels.state';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import UserStateModel from 'app/state/user/user-state-model';
import { catchError, Observable, throwError, withLatestFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
})
export class AllUsersComponent implements OnInit, OnDestroy {
  @Select(UserState.getAllUserData)
  public allUserData$!: Observable<UserStateModel>;

  @Select(UserState.getAllUsers)
  public listDetails!: Observable<UserStateModel>;

  @Select(UserInfoState.getCurrentUserRole)
  public userRole!: Observable<string>;
  public exportButtonSpinner = false;
  public pageNo = 1;
  public selectedProjectId = '';
  public showSpinner = true;

  constructor(private router: Router, public store: Store) {}

  public exportToCSV(): void {
    this.store.dispatch(new ResetAllUserdata());
    this.exportButtonSpinner = true;

    this.store
      .dispatch(new LoadAllUserdata(true))
      .pipe(
        withLatestFrom(this.allUserData$),
        catchError((err) => {
          this.exportButtonSpinner = false;
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, listDetails]) => {
        const allData: UserList[] = listDetails.allUserData ?? [];
        const valueMapping = { roleName: {} };
        valueMapping['roleName'] =
          this.store.selectSnapshot(RoleLabelsState.getRoleLabelsMapping) || {};
        const csv = convertToCSV(
          allData,
          EXPORT_CSV_ALL_USER_DATA_FIELDS,
          valueMapping
        );
        saveAsCSV(csv, 'All_Users.csv');
        this.exportButtonSpinner = false;
      });
  }

  public isDisabled(user: UserList): boolean {
    return user.userStatus === USER_STATUS.DISABLED;
  }

  public loadMoreUsers(): void {
    this.showSpinner = true;
    this.pageNo++;
    this.loadUsers();
  }

  public loadUsers(): void {
    this.userRole.subscribe((roleName) => {
      if (roleName === '') return;
      this.store
        .dispatch(new LoadAllUserdata(false, this.pageNo))
        .subscribe((resp) => {
          this.showSpinner = false;
        });
    });
  }

  public navigateAddToProject(userDetails: any): void {
    const id = btoa(userDetails.emailId);
    const routePath = `platformAdmin/users/addtoproject/${id}`;
    this.router.navigate([`${routePath}`]);
  }

  public navigateToRoute(routePath: string, emailId?: string): void {
    if (emailId) {
      const encodedEmailId = btoa(emailId);
      const url = `${routePath}/${encodedEmailId}`;
      this.router.navigate([`${url}`]);
    } else {
      this.router.navigate([`${routePath}`]);
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetUserdata());
  }

  ngOnInit(): void {
    this.pageNo = 1;
    this.store.dispatch(new ResetUserdata());
    this.loadUsers();
  }
}
