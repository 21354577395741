// Message Types
export enum snackBarMessageType {
  ALERT = 'alert',
  ERROR = 'error',
  MESSAGE = 'message',
  WARNING = 'warning',
  SUCCESS = 'success',
}

// ALignment type
export enum snackBarAlignmentTypes {
  DEFAULT = 'space-between',
  CENTER = 'center',
}

export interface AlertMessageModel {
  alignment?: snackBarAlignmentTypes;
  autoDismiss?: boolean;
  body: string;
  title?: string;
  type?: snackBarMessageType;
}
