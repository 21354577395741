<mat-stepper
  class="create-schedule-screen"
  [linear]="isLinear"
  labelPosition="bottom"
  #stepper
>
  <ng-template matStepperIcon="edit" let-index="index">{{
    index + 1
  }}</ng-template>
  <mat-step label="SELECT DATA SOURCE & FREQUENCY" [completed]="createScheduleFormStatus">
    <ng-template [ngTemplateOutlet]="noteText"></ng-template>
    <mpr-schedule-form
      (proceedToMetaData)="proceedToMetaDataScreen(stepper)"
    ></mpr-schedule-form>
  </mat-step>
  <mat-step
    label="ENTER METADATA"
    [completed]="metaDataFormStatus"
    [optional]="false"
  >
    <ng-template [ngTemplateOutlet]="noteText"></ng-template>
    <mpr-enter-metadata
      [oneTimeOrSchedule]="schedule"
      (proceedToUploadOrSchedule)="createSchedule()"
    >
    </mpr-enter-metadata>
  </mat-step>
</mat-stepper>

<ng-template #noteText>
  <div class="mpr-note-text">
    NOTE: You must complete the 2-step process by providing the data source,
    upload frequency, and entering the metadata.
  </div>
  <hr class="mpr-note-dashed-divider" />
</ng-template>
