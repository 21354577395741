import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mprMatChipA11y]',
})
export class mprMatChipA11yDirective implements AfterViewInit {
  private chipListElement!: HTMLElement;

  constructor(private el: ElementRef) {
    if (!this.el) return;
    this.chipListElement = this.el.nativeElement as HTMLElement;
  }

  ngAfterViewInit(): void {
    if (!this.chipListElement) return;
    setTimeout(() => {
      this.chipListElement.onchange = (): void => {
        this.setRoleA11yAttributes();
      };
      this.setRoleA11yAttributes();
    }, 0);
  }

  public setRoleA11yAttributesForId(elementId: string): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.chipListElement = document.querySelector(
      `[data-ally="${elementId}"]`,
    )!;
    this.ngAfterViewInit();
  }

  private setRoleA11yAttributes(): void {
    this.chipListElement.setAttribute('role', 'grid');
    this.chipListElement.removeAttribute('aria-required');
    this.chipListElement.removeAttribute('aria-orientation');
    if (this.chipListElement.querySelector('input') !== null) {
      this.chipListElement
        .querySelector('input')
        ?.removeAttribute('aria-expanded');
      this.chipListElement
        .querySelector('input')
        ?.removeAttribute('aria-haspopup');
      this.chipListElement
        .querySelector('input')
        ?.removeAttribute('aria-autocomplete');
      this.chipListElement
        .querySelector('input')
        ?.setAttribute('role', 'gridcell');
      this.chipListElement.onchange = (): void => {
        this.setRoleA11yAttributes();
      };
    }
  }
}
