import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericHttpResponse, HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { Store } from '@ngxs/store';
import {
  ConnectionAddResponse,
  ConnectionModel,
  ConnectionRequestPayload,
  CommonResponseModel,
} from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(private http: HttpClient, private store: Store) { }

  public addConnection(
    payload: ConnectionRequestPayload
  ): Observable<ConnectionAddResponse> {
    return this.http.post<ConnectionAddResponse>('/connection', {
      ...payload,
    });
  }

  public deleteConnection(
    connectionId: string
  ): Observable<GenericHttpResponse> {
    return this.http.delete<any>(`/connection/${connectionId}`);
  }

  public fetchConnection(
    selectedConnectionId: string,
    optionalHeaders?: MprHttpHeaderModal
  ): Observable<ConnectionModel> {
    if (optionalHeaders) {
      const headers = this.getRequestHeaders(optionalHeaders);
      return this.http.get<ConnectionModel>(`/connection/${selectedConnectionId}`, { headers });
    }
    return this.http.get<ConnectionModel>(
      `/connection/${selectedConnectionId}`
    );
  }

  public fetchConnections(
    optionalHeaders?: MprHttpHeaderModal
  ): Observable<ConnectionModel[]> {
    if (optionalHeaders) {
      const headers = this.getRequestHeaders(optionalHeaders);
      return this.http.get<ConnectionModel[]>('/connection', { headers });
    }
    return this.http.get<ConnectionModel[]>('/connection');
  }

  public updateConnection(
    payload: ConnectionModel
  ): Observable<CommonResponseModel> {
    return this.http.put<CommonResponseModel>(
      `/connection/${payload.connectionId}`,
      {
        connectionName: payload.connectionName,
      }
    );
  }

  private getRequestHeaders(headers: MprHttpHeaderModal): HttpHeaders {
    const requestHeaders = new HttpHeaders()
      .set(HeaderParams.PROJECTID, headers[HeaderParams.PROJECTID])
      .append(HeaderParams.ROLENAME, headers[HeaderParams.ROLENAME]);
    return requestHeaders;
  }
}
