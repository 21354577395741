import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CommonResponseModel } from '@shared';
import { ManageRDSService } from '@shared/services/manage-rds.service';
import { Observable, of, tap } from 'rxjs';
import { RDSClusters } from '../manage-rds/manage-rds.model';
import {
  AddUserToRDSCluster,
  DeleteUserFromRDSCluster,
  LoadRDSClusterUsers,
  ResetRDSClusterCommonResponse,
  ResetRDSClusterUsersState,
  SetSelectedCluster,
} from './rds-cluster-users.action';
import {
  RDSClusterUserKeyValue,
  RDSClusterUsersResponse,
  RDSClusterUsersStateModel,
} from './rds-cluster-users.model';

const stateDefaults: RDSClusterUsersStateModel = {
  users: {},
  commonResponse: { message: '', status_code: 0 },
  cluster: null,
};

@State<RDSClusterUsersStateModel>({
  defaults: stateDefaults,
  name: 'RDSClusterUsersState',
})
@Injectable()
export class RDSClusterUsersState {
  constructor(private manageRDSService: ManageRDSService) {}

  @Action(AddUserToRDSCluster)
  public addUserToRDSCluster(
    ctx: StateContext<RDSClusterUsersStateModel>,
    { projectId, clusterId, userId, requestHeaders }: AddUserToRDSCluster
  ): Observable<CommonResponseModel> {
    return this.manageRDSService
      .addUserToRDSCluster(projectId, clusterId, userId, requestHeaders)
      .pipe(
        tap((results) => {
          const commonResponse: CommonResponseModel = results;
          ctx.patchState({ commonResponse });
        })
      );
  }

  @Action(DeleteUserFromRDSCluster)
  public deleteUserFromRDSCluster(
    ctx: StateContext<RDSClusterUsersStateModel>,
    { projectId, clusterId, userId, requestHeaders }: AddUserToRDSCluster
  ): Observable<CommonResponseModel> {
    return this.manageRDSService
      .deleteUserFromRDSCluster(projectId, clusterId, userId, requestHeaders)
      .pipe(
        tap((results) => {
          const commonResponse: CommonResponseModel = results;
          ctx.patchState({ commonResponse });
        })
      );
  }

  @Selector()
  public static getListAddDeleteUserResponse(
    state: RDSClusterUsersStateModel
  ): CommonResponseModel {
    return state.commonResponse;
  }

  @Selector()
  public static getRDSCluster(
    state: RDSClusterUsersStateModel
  ): RDSClusters | null {
    return state.cluster;
  }

  @Selector()
  public static getRDSClusterUsers(
    state: RDSClusterUsersStateModel
  ): RDSClusterUserKeyValue {
    return state.users;
  }

  @Action(LoadRDSClusterUsers)
  public loadRDSClusterUsers(
    { patchState }: StateContext<RDSClusterUsersStateModel>,
    { clusterId, requestHeaders }: LoadRDSClusterUsers
  ): Observable<RDSClusterUsersResponse> {
    return this.manageRDSService
      .getRDSClusterUsers(clusterId, requestHeaders)
      .pipe(
        tap((results: any) => {
          const users: RDSClusterUserKeyValue = {};
          let commonResponse: CommonResponseModel =
            stateDefaults.commonResponse;
          if (results.status_code && results.status_code > 202) {
            commonResponse = { ...results };
          } else {
            results.addedUsers?.forEach((user: any) => {
              users[user.userId] = user;
            });
          }
          patchState({ users, commonResponse });
        })
      );
  }

  @Action(ResetRDSClusterUsersState)
  public resetAWSDetails({
    patchState,
  }: StateContext<RDSClusterUsersStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(ResetRDSClusterCommonResponse)
  public resetRDSClusterUserCommonResponse({
    patchState,
  }: StateContext<RDSClusterUsersStateModel>): void {
    const commonResponse = stateDefaults.commonResponse;
    patchState({ commonResponse });
  }

  @Action(SetSelectedCluster)
  public setSelectedCluster(
    { patchState }: StateContext<RDSClusterUsersStateModel>,
    { cluster }: SetSelectedCluster
  ): Observable<RDSClusters> {
    patchState({ cluster });
    return of(cluster);
  }
}
