<div
  class="mpr-dashboard-loader"
  *ngIf="projectData$ | async as projectData; else showSpinner"
>
  <div
    class="container-with-border"
    *ngIf="projectData.projectId; else showSpinner"
  >
    <div>
      <div>
        <div class="d-flex mpr-justify-content-space-between">
          <div class="page-header">
            {{ projectData.projectId }}
          </div>
          <div>
            <button
              aria-label="Edit"
              class="mpr-edit-button"
              mat-button
              (click)="navigateToRoute()"
              #skipper
            >
              <img
                src="assets/images/PencilIcon.svg"
                alt=""
                class="mpr-edit-icon"
              />
              <span class="mpr-export-text">Edit</span>
            </button>
          </div>
        </div>
        <ng-container *ngIf = "showAccountingDetails()">
          <ng-container *ngIf="globalAttributes$ | async as globalAttributes;">
            <div class ="mpr-accounting-details d-flex" *ngIf="globalAttributes.length > 0">
              <div class= "mpr-medium-bold" *ngFor ="let attribute of globalAttributes; index as index">
                {{attribute.attributeName}} : {{getAttributeValue(projectData, attribute)}}
                <ng-container *ngIf = "index !== globalAttributes.length-1">&nbsp;&nbsp;|&nbsp;&nbsp;</ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div
          class="d-flex"
          *ngIf="projectData['dwAccess'] || projectData['hasPowerUsers'] || projectData['externalProjectAccountsEnabled'] || projectData['rdsEnabled']"
        >
          <div
            class="mpr-dw-tag p-4 d-flex mpr-align-items-center m-r-8"
            *ngIf="projectData['dwAccess']"
          >
            <img
              src="assets/images/Data_Warehouse_Blue.svg"
              class="mpr-dw-tag-icon m-l-4"
              alt="Datawarehouse Enabled"
            />
            <div class="m-l-4 mpr-dw-tag-text">Data Warehouse Access</div>
          </div>
          <div
            class="mpr-hl-tag p-4 d-flex mpr-align-items-center m-r-8"
            *ngIf="projectData['hasPowerUsers']"
          >
            <img
              src="assets/images/Heavy_Lifting_Green.svg"
              class="mpr-tile-icon mpr-hl-tag-icon m-l-8"
              alt="Heavylifting Enabled"
            />
            <span class="m-l-4 mpr-hl-tag-text">Heavy Lifting</span>
          </div>
          <div
            class="mpr-pa-tag p-4 d-flex mpr-align-items-center m-r-8"
            *ngIf="projectData['externalProjectAccountsEnabled']"
          >
            <img
              src="assets/images/powered_by_mquiry.svg"
              class="mpr-tile-icon mpr-pa-tag-icon m-l-8"
              alt="ProjectAccount Enabled"
            />
            <span class="m-l-4 mpr-pa-tag-text">Powered by Mquiry</span>
          </div>
          <div
            class="mpr-rds-tag p-4 d-flex mpr-align-items-center"
            *ngIf="projectData['rdsEnabled']"
          >
            <img
              src="assets/images/RDS_Blue.svg"
              class="mpr-tile-icon mpr-rds-tag-icon m-l-8"
              alt="RDS Enabled"
            />
            <span class="m-l-4 mpr-rds-tag-text">Relational Database Service</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mpr-project-title m-t-16">
      {{ projectData.projectName }}
    </div>
    <div class="mpr-medium-bold m-t-24 d-flex" *ngIf="projectData['dwAccess']">
      <div class="m-b-8">
        Data Warehouse Size: {{ projectData['projectSize'] }}
      </div>
      <div class="m-l-16">
        Onboarded On: {{ projectData['createdDate'] | utcDate: 'MM/dd/yyyy' }}
      </div>
    </div>
    <div class="mpr-divider"></div>
    <div class="mpr-no-padding">
      <mat-expansion-panel
        expanded="true"
        *ngIf="(projectData['userCountsByRole'] | json) !== '{}'"
      >
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">Project Users and Roles</div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex m-t-16">
          <mat-chip-list aria-label="User roles and count">
            <mat-chip
              class="mpr-role-tags"
              *ngFor="let role of projectData['userCountsByRole'] | keyvalue"
            >
              {{ role.key + '' | replaceRoleName }} : {{ role.value }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="mpr-button-inside-expansion-panel">
          <button
            (click)="navigateToRoute('view-all')"
            class="mpr-btn-transparency"
          >
            <span class="mpr-view-all mpr-upppercase-transform"
              >View All Users</span
            >
          </button>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">Email Notification List</div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex m-t-16">
          <mat-chip-list aria-label="Email Notification List">
            <mat-chip
              class="mpr-other-tags"
              *ngFor="let email of projectData['emailDistributionList']"
            >
              {{ email }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded="true" class="m-t-20">
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">
                Restricted Data Use Groups
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex m-t-16">
          <mat-chip-list aria-label="Restricted Data Use Groups">
            <mat-chip
              class="mpr-other-tags"
              *ngFor="
                let group of projectData['projectRestrictedDataUseGroups']
              "
            >
              {{ group }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        expanded="true"
        class="m-t-20"
        *ngIf="showProjectSurveys()"
      >
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">Forsta Survey Mappings</div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="d-flex m-t-16"
          *ngIf="projectSurveys$ | async as projectSurveys"
        >
          <div class="mpr-center-children" *ngIf="projectSurveyLoader$ | async">
            <mat-spinner diameter="30" color="accent"></mat-spinner>
          </div>
          <div *ngIf="projectSurveys.length === 0">
            You do not have any mappings for the current project
          </div>
          <mat-chip-list aria-label="Forsta Survey Mappings" *ngIf="projectSurveys.length > 0">
            <mat-chip
              class="mpr-other-tags"
              *ngFor="let projectSurvey of projectSurveys"
            >
              {{ projectSurvey.surveyId }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <div *ngIf="isPlatformAdmin" class="mpr-button-inside-expansion-panel">
          <button
            (click)="navigateToRoute('project-survey')"
            class="mpr-btn-transparency"
          >
            <span class="mpr-view-all mpr-upppercase-transform"
              >Manage Mappings</span
            >
          </button>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        expanded="true"
        class="m-t-20"
        *ngIf="showRDS(projectData)"
      >
        <mat-expansion-panel-header class="mpr-mat-header">
          <mat-panel-title>
            <div class="d-flex mpr-flex-align-items-center mpr-flex-dir-row">
              <div class="mpr-collapsible-header">Relational Database Service (RDS)</div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mpr-center-children" *ngIf="rdsClustersLoader$ | async">
          <mat-spinner diameter="30" color="accent"></mat-spinner>
        </div>
        <ng-container *ngIf="rdsClusters$ | async as rdsClusters">
        <div class="d-flex m-t-16">
          <ng-container *ngIf = "(rdsClustersLoader$ | async) === false">
            <div *ngIf="rdsClusters.length === 0">
              No RDS clusters are created for this project.
            </div>
            <mat-chip-list aria-label="RDS Clusters" *ngIf="rdsClusters.length > 0">
              <mat-chip
                class="mpr-other-tags"
                *ngFor="let rdsCluster of rdsClusters"
              >
                {{rdsCluster.clusterName}}
              </mat-chip>
            </mat-chip-list>
          </ng-container>
        </div>
        <div *ngIf="isPlatformAdmin || rdsClusters.length > 0" class="mpr-button-inside-expansion-panel">
          <button
            (click)="navigateToRoute('manage-rds')"
            class="mpr-btn-transparency"
          >
            <span class="mpr-view-all mpr-upppercase-transform"
              >Manage Clusters</span
            >
          </button>
          <button
            class="mpr-btn-transparency"
            [mtxTooltip]="manageClusterInfo"
            [mprMtxTooltipAriaDescribedBy]="manageClusterInfo"
            #tooltip="mtxTooltip"
            aria-label="Manage Clusters Info"
          >
            <mat-icon class="mpr-info-icon mpr-manage-cluster-info">info </mat-icon>
          </button>
        </div>
      </ng-container>
      </mat-expansion-panel>
    </div>
  </div>
</div>

<ng-template #showSpinner>
  <div class="mpr-noresult-container">
    <div class="mpr-noresutl-error">
      <span>
        <mat-spinner diameter="45" color="accent"></mat-spinner>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #manageClusterInfo>
  <div class="mpr-width-px-300" id="mpr-restricted-groups">
    A cluster is a collection of database instances that serves as the main unit for management, scaling, and failover.
  </div>
</ng-template>