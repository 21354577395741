/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetFeatureFlags {
  public static type = 'Get All Feature Flags';
  constructor() {}
}

export class HydrateFeatureFlagState {
  public static type = 'Populate Feature Flags';
  constructor() {}
}
