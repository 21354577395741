/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetAllOneTimeUpload {
  public static type = '[UploadHistoryState] Get All One Time Upload History';
  constructor(
    public startKey: string,
    public sortKey: string,
    public sortOrder: string,
    public searchTerm: string,
    public jobOperationType: string,
    public isLoadMore?: boolean
  ) {}
}

export class GetAllRecurringUpload {
  public static type = '[UploadHistoryState] Get All Recurring Upload History';
  constructor(
    public startKey: string,
    public sortKey: string,
    public sortOrder: string,
    public searchTerm: string,
    public scheduleType: string,
    public isLoadMore?: boolean
  ) {}
}

export class ResetHistoryState {
  public static type = '[HistoryState] Reset to Default';
}

export class SetSearchTerm {
  public static type = '[UploadHistoryState] Set Search Term';
  constructor(public searchTerm: string) {}
}
