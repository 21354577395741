import { Injectable } from '@angular/core';
import { USER_IDLE_CONFIG } from '@core/constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleUserService {
  public userInactive: Subject<boolean> = new Subject();
  private timeoutId: any;

  constructor() {
    this.reset();
    this.initListener();
  }

  public initListener(): void {
    window.addEventListener('mousemove', () => this.reset());
    window.addEventListener('click', () => this.reset());
    window.addEventListener('keypress', () => this.reset());
    window.addEventListener('DOMMouseScroll', () => this.reset());
    window.addEventListener('mousewheel', () => this.reset());
    window.addEventListener('touchmove', () => this.reset());
    window.addEventListener('MSPointerMove', () => this.reset());
  }

  public reset(): void {
    if (localStorage.getItem(USER_IDLE_CONFIG.localStorageKey))
      localStorage.removeItem(USER_IDLE_CONFIG.localStorageKey);
    clearTimeout(this.timeoutId);
    this.startIdleTimer();
  }

  public startIdleTimer(): void {
    this.timeoutId = setTimeout(() => {
      localStorage.setItem(USER_IDLE_CONFIG.localStorageKey, '1');
    }, USER_IDLE_CONFIG.timeoutMilliseconds);
  }
}
