import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core';
import { USER_STATUS } from '@core/constants';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { CommonResponseModel, MprConfirmComponent } from '@shared';
import { customSort } from '@shared/utils';
import { UserProject } from '@theme/interfaces';
import {
  ApiInProgressState,
  FeatureFlagsState,
  LoadAllUserdata,
  LoadSelectedProjectMetadata,
  LoadSelectedUserProjects,
  ProjectState,
  ResetSelectedProjectMetadata,
  ResetUserProjectState,
  SelectedUserProjectInfoState,
  UserState,
} from 'app/state';
import { DeleteUserFromProject } from 'app/state/project-user/project-user.actions';
import { UserList } from 'app/state/project-user/user-list-model';
import { UserEnableDisableState } from 'app/state/user-enable-disable/user-enable-disabled.state';
import { EnableDisableUser } from 'app/state/user-enable-disable/user-enable-disbale.action';
import UserStateModel from 'app/state/user/user-state-model';
import {
  catchError,
  Observable,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @Select(SelectedUserProjectInfoState.getSelectedUserProjects)
  public allProjectsForPlatformAdmin$?: Observable<UserProject[]>;
  @Select(ApiInProgressState.getApiInProgressStateAction('GetProjectsMetadata'))
  public fetchProjectMetadataInProgress$?: Observable<boolean>;
  @Select(ApiInProgressState.getApiInProgressStateAction('GetUser'))
  public fetchUserDetailsInprogress$?: Observable<boolean>;
  @Select(UserState.getAllUsers)
  public listDetails!: Observable<UserStateModel>;
  @Select(UserEnableDisableState.returnEnableDisableResponse)
  private enableDisableResponse$!: Observable<CommonResponseModel>;
  public dataSource!: MatTableDataSource<any>;
  public deleteForProject = '';
  public displayedColumns: string[] = [
    'projectName',
    'roleName',
    'edit',
    'delete',
  ];
  public selectedUserDetails: any = {};
  public selectedUserHasOnlyOneProject = false;
  public selectedUserId = '';
  public selectedUserProjects: any = [];
  public showDelaySpinner = false;
  public showSpinner = true;
  private requestHeaders?: MprHttpHeaderModal = {};

  constructor(
    public actions$: Actions,
    public store: Store,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private location :Location,
  ) {
    this.selectedUserId = atob(route.snapshot.params['emailId']);
    const state = location.getState() as { message: any };
    if (state?.message) {
      timer(1000).subscribe(() => {
        this.alertService.success({
          body: state.message.message,
        });
      });
    }
  }

  public editUser(user: UserList, projectData: UserProject): void {
    const userStr =
      projectData['userStatus'] === 'invited'
        ? user['emailId']
        : user['userId'];

    const encodedUserStr = btoa(userStr);
    const projectId = btoa(projectData.projectId);
    this.router.navigate([
      `/platformAdmin/users/edit/${encodedUserStr}/${projectId}`,
    ]);
  }

  public enableDisableUser(): void {
    const action =
      this.selectedUserDetails[0].userStatus === 'disabled'
        ? 'enable'
        : 'disable';
    let confirmMessage = `Are you sure you want to disable the user <b>${this.selectedUserDetails[0].userName}</b>? <br/><br/>
     Once disabled, this user will no longer have access to the platform. Their access shall be restored when the account is re-enabled.`;

    if (action === 'enable') {
      confirmMessage = `Are you sure you want to enable the user <b>${this.selectedUserDetails[0].userName}</b>? <br/><br/>
       Once enabled, their access to any existing projects will be restored.`;
    }

    // Open the confirmation dialog
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      width: '650px',
      data: {
        confirmTitle: action === 'disable' ? 'Disable User' : 'Enable User',
        confirmMessage,
        useEmitterOnConfirmClick: true,
      },
      disableClose: true,
    });
    // Subscribe to the confirmAction emitter in the dialog component
    dialogRef.componentInstance.confirmAction.subscribe((data) => {
      // Dispatch the delete action when the confirmation is clicked
      const userIdEmail = this.selectedUserDetails[0].userId ? this.selectedUserDetails[0].userId : this.selectedUserDetails[0].emailId;
      this.store
        .dispatch(new EnableDisableUser(action, userIdEmail, false))
        .pipe(
          withLatestFrom(this.enableDisableResponse$),
          catchError((err) => {
            // Handle error
            this.alertService.error({
              body:
                err.error.message ||
                'Failed to update user status. Please try again.',
            });
            return throwError(() => new Error(''));
          })
        )
        .subscribe(([_, res]) => {
          // Check the status code
          if (res.status_code > 202) {
            this.alertService.error({
              body: res.message
                ? res.message
                : `Failed to update user status. Please try again.`,
            });
          } else {
            // Success notification
            const successToast = `User&nbsp;<b>${this.selectedUserDetails[0].userName}</b>&nbsp;${action}d.`;
            this.alertService.success({
              body: successToast,
            });
          }

          // Close the dialog regardless of success or failure
          dialogRef.close();
          // Reload the cluster list after deletion
          this.showDelaySpinner = true;
          /* adding 5s delay for fetching the status of the user after 
           enable user action to get the right status as it takes some time to enable
            all the required permissions */
          const timerMS = action === 'enable' ? 5000 : 0;
          timer(timerMS).subscribe(() => {
            this.store.dispatch(new LoadAllUserdata());
          });
        });
    });
  }

  public isDisabled(status: string): boolean {
    return USER_STATUS.DISABLED === status;
  }

  public loadUserData(): void {
    this.store.dispatch(new ResetUserProjectState());
    this.showSpinner = true;
    this.selectedUserProjects = [];
    this.dataSource = new MatTableDataSource(this.selectedUserProjects);

    this.store.dispatch([
      new LoadSelectedUserProjects(this.selectedUserId),
      new LoadAllUserdata(),
    ]);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetUserProjectState());
  }

  ngOnInit(): void {
    this.deleteForProject = '';
    this.store.dispatch(new ResetUserProjectState());
    this.loadUserData();
    this.listDetails.subscribe((userDetail: UserStateModel) => {
      this.showDelaySpinner = false;
      if (userDetail && userDetail.userInfo) {
        this.selectedUserDetails = userDetail.userInfo.filter(
          (el: any) => el.emailId === this.selectedUserId
        );
      }
    });

    // Only when my action is successful then do this
    this.actions$
      .pipe(ofActionSuccessful(LoadSelectedUserProjects))
      .subscribe((action) => {
        const projectsForSelectedUser = this.store.selectSnapshot(
          SelectedUserProjectInfoState.getSelectedUserProjects
        );

        //Create a new array and sort it alphabetically by projectId
        const sortedProjects = customSort(projectsForSelectedUser);

        this.showSpinner = true;
        this.selectedUserProjects = [];

        if (sortedProjects && sortedProjects.length === 0)
          this.router.navigate([`/platformAdmin/users`]);
        else this.selectedUserProjects = sortedProjects;

        this.selectedUserHasOnlyOneProject =
          this.selectedUserProjects.length === 1;
        this.dataSource = new MatTableDataSource(this.selectedUserProjects);
        this.showSpinner = false;
      });
  }

  public showDeleteConfirmation(
    user: UserList,
    projectData: UserProject
  ): void {
    const userId =
      projectData['userStatus'] === 'invited'
        ? user['emailId']
        : user['userId'];
    const projectId = projectData['projectId'];
    this.deleteForProject = projectId;
    this.store.dispatch(new ResetSelectedProjectMetadata());

    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.PROJECTID] = projectId;
    requestHeaders[HeaderParams.ROLENAME] = UserProjectRoleEnum.PLATFORM_ADMIN;
    this.store
      .dispatch(new LoadSelectedProjectMetadata(requestHeaders))
      .subscribe(() => {
        let confirmMessage = `Are you sure you want to delete <b>${user.userName}</b> from project <b>${projectId}</b>?<br/><br/>Once removed, this user will no longer have access to the project data. Any jobs running in this user's workspace will be terminated.`;

        // Check the feature flag for project account settings
        const externalProjectAccountsFeatureFlag = /true/i.test(
          this.store.selectSnapshot(FeatureFlagsState.returnFeatureFlags)
            .externalProjectAccountsFeatureFlag
        );

        // Check for the project level flag from snapshot as the API request would have completed
        const projectCrossAccountFlag =
          this.store.selectSnapshot(
            ProjectState.getExternalProjectAccountFlag
          ) ?? false;

        // Add only if both flags are on
        if (externalProjectAccountsFeatureFlag && projectCrossAccountFlag) {
          confirmMessage += ` Any user connections to project AWS accounts will be disabled.`;
        }

        const dialogRef = this.dialog.open(MprConfirmComponent, {
          data: {
            confirmTitle: 'Remove User',
            confirmMessage,
            confirmData: {
              userId,
              projectId,
            },
          },
        });
        // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
        dialogRef.afterClosed().subscribe((data: any): void => {
          this.deleteForProject = '';
          if (!data) return;

          this.requestHeaders = {};
          this.requestHeaders[HeaderParams.ROLENAME] =
            UserProjectRoleEnum.PLATFORM_ADMIN;

          this.store
            .dispatch(
              new DeleteUserFromProject(data.userId, this.requestHeaders, [
                data.projectId,
              ])
            )
            .pipe(
              catchError((err) => {
                this.alertService.error({
                  body: err.message || err.error.message,
                  autoDismiss:!(err.message) 
                });
                return throwError(() => new Error(''));
              })
            )
            .subscribe((res) => {
              const deleteResponse = res.ProjectUser.deleteResponse;
              if (deleteResponse.length > 0) {
                if (!deleteResponse[0].isSuccess) {
                  this.alertService.error({
                    body: deleteResponse[0].message,
                  });
                  this.loadUserData();
                } else {
                  if (!this.selectedUserHasOnlyOneProject) {
                    this.alertService.success({
                      body: `User has been deleted successfully from ${data.projectId}.`,
                    });
                  } else {
                    setTimeout(() => {
                      this.alertService.success({
                        body: `User has been deleted successfully from ${data.projectId}.`,
                      });
                    }, 0);
                    this.router.navigate([`/platformAdmin/users`]);
                  }
                  this.selectedUserProjects = this.selectedUserProjects.filter(
                    (item: any) => item.projectId !== data.projectId
                  );
                  this.selectedUserHasOnlyOneProject =
                    this.selectedUserProjects.length === 1;
                  this.dataSource = new MatTableDataSource(
                    this.selectedUserProjects
                  );
                }
              }
            });
        });
      });
  }
}
