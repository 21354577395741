/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class GetCurrentRoute {
  public static type = '[Route] Get Current ';
}

export class ResetRoute {
  public static type = '[Route] Reset to Default';
}

export class SetRoute {
  public static type = '[Route] Set to Given String';
  constructor(public lastRoute: string) {}
}
