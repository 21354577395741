import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SfJWTTokenStateModel } from 'app/state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SfJWTTokenService {
  constructor(private http: HttpClient) { }

  public getSfJWTToken(instanceId : string): Observable<SfJWTTokenStateModel> {
    return this.http.post<SfJWTTokenStateModel>('/refresh_rsa_keys', { instanceId });
  }
}