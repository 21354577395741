/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';

export class GetGobalAttributes {
    public static type = '[GlobalAttributes] Get GobalAttributes ';
    constructor(public requestHeaders: MprHttpHeaderModal){}
  }

export class ResetGobalAttributes {
    public static type = '[GlobalAttributes] Reset GobalAttributes ';
  }
