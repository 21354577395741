/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class SetApiInProgressAction {
  public static type = '[ApiSetInProgress] Action ';
  constructor(
    public action: string,
    public value: boolean,
  ) {}
}
