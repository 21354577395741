import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CommonResponseModel } from '@shared';
import { ManageRDSService } from '@shared/services/manage-rds.service';
import { Observable, tap } from 'rxjs';
import {
  DeleteRDSCluster,
  GenerateRDSClusterToken,
  LoadRDSClusters,
  ResetGenerateRDSClusterTokenResponse,
  ResetManageRDSClusterCommonResponse,
  ResetManageRDSClusters,
  SetupOrEditCluster,
  StartOrStopCluster,
} from './manage-rds.action';
import {
  GenerateTokenResponseModel,
  ManageRDSClustersModel,
  RDSClusters,
  RDSResponse,
} from './manage-rds.model';

const stateDefaults: ManageRDSClustersModel = {
  commonResponse: { message: '', status_code: 0 },
  dbEngine: '',
  defaultCapacity: '',
  generateTokenResponse: { message: '', status_code: 0, accessToken: '' },
  loader: false,
  rdsClusters: [],
};

@State<ManageRDSClustersModel>({
  defaults: stateDefaults,
  name: 'ManageRDSClusterState',
})
@Injectable()
export class ManageRDSClusterState {
  constructor(private manageRDSService: ManageRDSService) {}

  @Selector()
  public static GetManageRDSLoader(state: ManageRDSClustersModel): boolean {
    return state.loader;
  }

  @Selector()
  public static GetRDSClusterState(
    state: ManageRDSClustersModel
  ): ManageRDSClustersModel {
    return state;
  }

  @Selector()
  public static GetRDSClusterTokenResponse(
    state: ManageRDSClustersModel
  ): GenerateTokenResponseModel {
    return state.generateTokenResponse;
  }

  @Selector()
  public static GetRDSClusters(
    state: ManageRDSClustersModel
  ): RDSClusters[] | [] {
    return state.rdsClusters;
  }

  @Action(DeleteRDSCluster)
  public deleteRDSCluster(
    { patchState, getState }: StateContext<ManageRDSClustersModel>,
    { projectId, rdsResourceId, requestHeaders }: DeleteRDSCluster
  ): Observable<CommonResponseModel> {
    return this.manageRDSService
      .deleteRDSCluster(projectId, rdsResourceId, requestHeaders)
      .pipe(
        tap((results) => {
          patchState({
            commonResponse: results,
          });
        })
      );
  }

  @Action(GenerateRDSClusterToken)
  public generateRDSToken(
    { patchState }: StateContext<ManageRDSClustersModel>,
    { rdsResourceId }: GenerateRDSClusterToken
  ): Observable<GenerateTokenResponseModel> {
    return this.manageRDSService.generateRDSClusterToken(rdsResourceId).pipe(
      tap((results) => {
        patchState({
          generateTokenResponse: results,
        });
      })
    );
  }

  // Selector to get existing cluster names
  @Selector()
  public static getExistingClusterNames(
    state: ManageRDSClustersModel
  ): string[] {
    return state.rdsClusters.map((cluster) =>
      cluster.clusterName.toLowerCase()
    );
  }

  @Action(LoadRDSClusters)
  public loadRDSClusters(
    { patchState }: StateContext<ManageRDSClustersModel>,
    { requestHeaders }: LoadRDSClusters
  ): Observable<RDSResponse> {
    let loader = true;
    patchState({ loader });
    return this.manageRDSService.getRDSClusters(requestHeaders).pipe(
      tap((results) => {
        loader = false;
        let rdsClusters: RDSClusters[] | [] = [];
        let commonResponse: CommonResponseModel = stateDefaults.commonResponse;
        if (results.data) {
          rdsClusters = results.data;
        } else {
          commonResponse = results;
        }
        const dbEngine = results.dbEngine || stateDefaults.dbEngine;
        const defaultCapacity =
          results.defaultCapacity || stateDefaults.defaultCapacity;
        patchState({
          rdsClusters,
          loader,
          commonResponse,
          defaultCapacity,
          dbEngine,
        });
      })
    );
  }

  @Action(ResetGenerateRDSClusterTokenResponse)
  public resetGenerateRDSClusterTokenResponse({
    patchState,
  }: StateContext<ManageRDSClustersModel>): void {
    const generateTokenResponse = stateDefaults.generateTokenResponse;
    patchState({ generateTokenResponse });
  }

  @Action(ResetManageRDSClusterCommonResponse)
  public resetManageRDSClusterUserCommonResponse({
    patchState,
  }: StateContext<ManageRDSClustersModel>): void {
    const commonResponse = stateDefaults.commonResponse;
    patchState({ commonResponse });
  }

  @Action(ResetManageRDSClusters)
  public resetManageRDSClusters({
    patchState,
  }: StateContext<ManageRDSClustersModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetupOrEditCluster)
  public setupCluster(
    { patchState }: StateContext<ManageRDSClustersModel>,
    { payload, isEdit, requestHeaders }: SetupOrEditCluster
  ): Observable<CommonResponseModel> {
    let loader = true;
    patchState({ loader });
    return this.manageRDSService
      .setupOrEditRDSCluster(payload, isEdit, requestHeaders)
      .pipe(
        tap((results) => {
          loader = false;
          const commonResponse: CommonResponseModel = results;
          patchState({ loader, commonResponse });
        })
      );
  }

  @Action(StartOrStopCluster)
  public startOrStopCluster(
    { patchState }: StateContext<ManageRDSClustersModel>,
    { projectId, rdsResourceId, action, requestHeaders }: StartOrStopCluster
  ): Observable<CommonResponseModel> {
    let loader = true;
    patchState({ loader });
    return this.manageRDSService
      .startOrStopCluster(projectId, rdsResourceId, action, requestHeaders)
      .pipe(
        tap((results) => {
          loader = false;
          const commonResponse: CommonResponseModel = results;
          patchState({ loader, commonResponse });
        })
      );
  }
}
