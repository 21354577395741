import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { ApiInProgressStateModel } from './api-in-progress-state-model';
import { SetApiInProgressAction } from './api-in-progress.actions';

/**
 * Each Value here is a slug of the API
 * For e.g GET /schedule/job = GetScheduleJob
 **/
export const ApiInProgressStateDefaults: ApiInProgressStateModel = {
  GetConnection: false,
  GetDw: false,
  GetScheduleJob: false,
  GetJob: false,
  GetUser: false,
  GetProjectSurvey: false,
  GetSchedule: false,
  GetProjectsMetadata: false,
  GetUserExternalAccounts: false,
  GetRds: false,
  GetRdsToken: false,
  GetRdsUsers: false,
};

@State<ApiInProgressStateModel>({
  defaults: ApiInProgressStateDefaults,
  name: 'ApiInProgressState',
})
@Injectable()
export class ApiInProgressState {
  constructor() {}

  // Dynamic Selectors to avoid defining multiple selectors for each of the States
  public static getApiInProgressStateAction(action: string): any {
    return createSelector([ApiInProgressState], (state: any) => state[action]);
  }

  // Actions take in the action name dynamically to avoid defining multiple setter action for each of the state values
  @Action(SetApiInProgressAction)
  public setInProgress(
    ctx: StateContext<ApiInProgressStateModel>,
    { action, value }: SetApiInProgressAction
  ): void {
    const state = { ...ctx.getState() };
    state[action] = value;
    ctx.patchState(state);
  }
}
