<div
  class="matero-container-wrap matero-navbar-side matero-header-fixed"
  dir="ltr"
>
  <header>
    <mat-toolbar
      class="matero-toolbar mpr-header-green-color mpr-layout-component"
      [class]="isTermsPage ? 'd-flex mpr-justify-content-center' : ''"
    >
      <div class="">
        <app-branding></app-branding>
      </div>
    </mat-toolbar>
  </header>
  <mat-sidenav-container class="matero-container mpr-no-left-nav-pages" autosize autoFocus>
    <mat-sidenav-content #content class="matero-content-wrap">
      
      <div *ngIf="showTitle">
        <div
          class="subheader app-sub-header"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="subheader-left-container">
            <mpr-subheader-page-title-nav></mpr-subheader-page-title-nav>
          </div>
        </div>
      </div>
      <main
        class="main-content"
        [ngClass]="!showTitle ? 'main-content-with-no-title' : ''"
      >
        <div class="matero-content">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer>
        <app-footer></app-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
