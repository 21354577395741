import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import {
  OneTimeUploadHistory,
  OneTimeUploadHistoryRes,
  RecurringUploadHistory,
  RecurringUploadHistoryRes,
  RecentUploadDetails,
} from '@shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadHistoryService {
  constructor(private http: HttpClient) {}

  public getAllOneTimeUploadHistory(
    startKey: string,
    sortKey: string,
    sortOrder: string,
    searchTerm: string,
    jobOperationType: string
  ): Observable<OneTimeUploadHistoryRes> {
    let queryStrings = `sortKey=${sortKey}&sortOrder=${sortOrder}&pageSize=10`;
    if (startKey) queryStrings += `&startKey=${startKey}`;
    if (searchTerm) queryStrings += `&description=${searchTerm}`;
    if (jobOperationType)
      queryStrings += `&jobOperationType=${jobOperationType}`;
    return this.http.get<OneTimeUploadHistoryRes>(`/job?${queryStrings}`);
  }

  public getAllRecurringUploadHistory(
    startKey: string,
    sortKey: string,
    sortOrder: string,
    searchTerm: string,
    scheduleType: string
  ): Observable<RecurringUploadHistoryRes> {
    let queryStrings = `sortKey=${sortKey}&sortOrder=${sortOrder}&pageSize=10`;
    if (startKey) queryStrings += `&startKey=${startKey}`;
    if (searchTerm) queryStrings += `&scheduleName=${searchTerm}`;
    if (scheduleType)
    queryStrings += `&scheduleType=${scheduleType}`;
    return this.http.get<RecurringUploadHistoryRes>(
      `/schedule/job?${queryStrings}`
    );
  }

  public getOnetimeJobDetails(
    jobId: string,
    jobOperationType?: string,
    requestHeaders?: MprHttpHeaderModal
  ): Observable<OneTimeUploadHistory> {
    if (jobOperationType) {
      const queryStrings = `jobOperationType=${jobOperationType}`;
      return this.http.get<OneTimeUploadHistory>(
        `/job/${jobId}?${queryStrings}`
      );
    }
    if (requestHeaders) {
      if (Object.keys(requestHeaders).length > 0) {
        const headers = new HttpHeaders()
          .set(HeaderParams.PROJECTID, requestHeaders[HeaderParams.PROJECTID])
          .append(HeaderParams.ROLENAME, requestHeaders[HeaderParams.ROLENAME]);
        return this.http.get<OneTimeUploadHistory>(`/job/${jobId}`, {
          headers,
        });
      }
    }
    return this.http.get<OneTimeUploadHistory>(`/job/${jobId}`);
  }
  public getRecentUploadsDeatils(): Observable<Array<RecentUploadDetails>> {
    return this.http.get<Array<RecentUploadDetails>>(`/job/upload`);
  }
  public getRecurringJobDetails(
    jobId: string,
    jobOperationType?: string,
  ): Observable<RecurringUploadHistory> {
    if (jobOperationType) {
      const queryStrings = `jobOperationType=${jobOperationType}`;
      return this.http.get<RecurringUploadHistory>(
        `/job/${jobId}?${queryStrings}`
      );
    }
    return this.http.get<RecurringUploadHistory>(`/job/${jobId}`);
  }
}
