import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GenericHttpResponse,
  HeaderParams,
  MprHttpHeaderModal,
} from '@core/interfaces';
import { CommonResponseModel } from '@shared/interfaces';
import { ProjectSurveyModel } from 'app/state';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root',
})
export class ProjectSurveyService {
  constructor(private http: HttpClient) {}

  public addNewMappings(
    requestHeaders : MprHttpHeaderModal,
    surveyId : string,
    surveyName : string,
    projectId: string
  ): Observable<CommonResponseModel> {
 
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.post<CommonResponseModel>(
      `/project_survey`,{surveyId, surveyName, projectId}, {headers}
    );
  }
 
  public deleteProjectSurvey(
    projectSurveyId: string,
    headers: MprHttpHeaderModal,
  ): Observable<GenericHttpResponse> {
    return this.http.delete<GenericHttpResponse>(`/project_survey`, {
      body: {
        projectSurveyId,
      },
      headers,
    });
  }

  public editMappings(
    requestHeaders : MprHttpHeaderModal,
    surveyId : string,
    surveyName : string,
    projectSurveyId: string
  ): Observable<CommonResponseModel> {
 
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.put<CommonResponseModel>(
      `/project_survey`,{surveyId, surveyName, projectSurveyId}, {headers}
    );
  }
 
  public getAllProjectSurveys(
    requestHeaders: MprHttpHeaderModal,
  ): Observable<ProjectSurveyModel[]> {
    const headers = this.getRequestHeaders(requestHeaders);
    return this.http.get<ProjectSurveyModel[]>(`/project_survey`, { headers });
  }
 
  private getRequestHeaders(headers: MprHttpHeaderModal): HttpHeaders {
    const requestHeaders = new HttpHeaders()
      .set(HeaderParams.PROJECTID, headers[HeaderParams.PROJECTID])
      .append(HeaderParams.ROLENAME, headers[HeaderParams.ROLENAME]);
    return requestHeaders;
  }
}