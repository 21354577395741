import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderParams } from '@core/interfaces';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DomainListService } from '@theme/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {DomainListStateModel } from './domain-list-model';
import { GetAllowedDomains } from './domain-list.actions';

const stateDefaults: DomainListStateModel = {
   externalDomainList:[],
};

@State<DomainListStateModel>({
  defaults: stateDefaults,
  name: 'domainList'
})
@Injectable()
export class DomainListState {
  constructor(private domainListService:DomainListService ) {}

  @Action(GetAllowedDomains)
  public getAllowedDomains(ctx: StateContext<DomainListStateModel>, action: GetAllowedDomains): Observable<any> {
    const headers = new HttpHeaders({
      [HeaderParams.PROJECTID]: action.projectId, // Add the projectId to headers
      [HeaderParams.ROLENAME]: action.roleName, // Add the rolename to headers
    });

    return this.domainListService.getAllowedDomains(headers).pipe(
      tap((result: DomainListStateModel) => {
        ctx.patchState({ externalDomainList: result.externalDomainList });
      })
    );
  }

  @Selector()
  public static returnAllowedDomains(state: DomainListStateModel): string[] {
    return state.externalDomainList;
  }
}