import { HTTP_RESPONSE_STRING_SUCCESS } from '@core/constants';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { MprConfirmComponent } from '@shared';
import { GenericStatus, UserProjectRoleEnum } from '@core/enums';
import { AlertMessageService } from '@core/services';
import { SkipMainContentService } from '@shared/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ApiInProgressState,
  DeleteProjectSurvey,
  LoadProjectSurveys,
  ProjectSurveyModel,
  ProjectSurveyState,
  ResetProjectSurveys,
} from 'app/state';
import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { CreateMappingComponent } from '../create-mapping/create-mapping.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-project-survey',
  templateUrl: './project-survey.component.html',
  styleUrls: ['./project-survey.component.scss'],
})
export class ProjectSurveyComponent implements OnInit, OnDestroy {
  @Select(ApiInProgressState.getApiInProgressStateAction('GetProjectSurvey'))
  public fetchProjectSurveyInProgress$?: Observable<boolean>;
  @Select(ProjectSurveyState.GetProjectSurveyLoader)
  public projectSurveyLoader$?: Observable<boolean>;
  @Select(ProjectSurveyState.GetProjectSurveys)
  public projectSurveys$!: Observable<ProjectSurveyModel[] | []>;
  @ViewChild('skipper') public skipper!: MatButton;
  public dataSource!: MatTableDataSource<ProjectSurveyModel>;
  public displayedColumns = [
    'surveyId',
    'surveyName',
    'createdDate',
    'edit',
    'delete',
  ];
  public isDeleteInProgress = false;
  public projectId: string;
  public projectSurveys: ProjectSurveyModel[] = [];

  public userRole = UserProjectRoleEnum.PLATFORM_ADMIN;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public store: Store,
    private skipMainContentService: SkipMainContentService,
    private alertMsgService: AlertMessageService,
  ) {
    this.projectId = this.route.snapshot.params['projectId'];
    this.dataSource = new MatTableDataSource(this.projectSurveys);
  }

  public loadAllProjectSurveys(): void {
    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.PROJECTID] = this.projectId;
    requestHeaders[HeaderParams.ROLENAME] = this.userRole;
    this.store.dispatch(new LoadProjectSurveys(requestHeaders));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetProjectSurveys());
  }

  ngOnInit(): void {
    this.loadAllProjectSurveys();
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.focus();
    });
    this.projectSurveys$.subscribe((surveys: ProjectSurveyModel[]) => {
      this.projectSurveys = surveys;
      this.dataSource = new MatTableDataSource(this.projectSurveys);
    });
  }

  public openCreateMappingDialog(): void {
    const dialogRef = this.dialog.open(CreateMappingComponent, {
      disableClose: true,
      data: { projectId: this.projectId, mappingAction: 'Create' },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadAllProjectSurveys();
    });
  }

  public openEditMappingDialog(survey: ProjectSurveyModel): void {
    const dialogRef = this.dialog.open(CreateMappingComponent, {
      disableClose: true,
      data: { survey, mappingAction: 'Edit' },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadAllProjectSurveys();
    });
  }

  public showDeleteSurveyDialog(survey: ProjectSurveyModel): void {
    this.isDeleteInProgress = true;
    const confirmationMessage = `Are you sure you want to delete mapping for the survey <b>${survey.surveyId}</b>?`;
    this.dialog
      .open(MprConfirmComponent, {
        data: {
          confirmTitle: 'Delete Mapping',
          confirmMessage: confirmationMessage,
          confirmAction: '',
          confirmData: survey.projectSurveyId,
        },
      })
      .afterClosed()
      .subscribe((projectSurveyId?: string): void => {
        if (!projectSurveyId) {
          this.isDeleteInProgress = false;
          return;
        }
        this.deleteProjectSurvey(projectSurveyId)
          .pipe(
            catchError((err) => {
              this.isDeleteInProgress = false;
              this.alertMsgService.error({
                body: err.error.message,
              });
              return throwError(() => new Error(''));
            }),
          )
          .subscribe((res: any) => {
            const deleteResponse = res.ProjectSurveyState.deleteResponse;
            if (deleteResponse) {
              this.isDeleteInProgress = false;
              if (deleteResponse === HTTP_RESPONSE_STRING_SUCCESS) {
                this.alertMsgService.success({
                  body: `Mapping for survey ${survey.surveyId} deleted successfully.`,
                });
                this.store.dispatch(
                  new LoadProjectSurveys(this.getRequestHeaders()),
                );
              } else {
                this.alertMsgService.error({
                  autoDismiss: false,
                  body: deleteResponse,
                });
              }
            }
          });
      });
  }

  private deleteProjectSurvey(
    projectSurveyId: string,
  ): Observable<DeleteProjectSurvey> {
    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.ROLENAME] = UserProjectRoleEnum.PLATFORM_ADMIN;

    return this.store.dispatch(
      new DeleteProjectSurvey(projectSurveyId, requestHeaders),
    );
  }

  private getRequestHeaders(): MprHttpHeaderModal {
    const requestHeaders: MprHttpHeaderModal = {};
    requestHeaders[HeaderParams.PROJECTID] = this.projectId;
    requestHeaders[HeaderParams.ROLENAME] = this.userRole;
    return requestHeaders;
  }
}
