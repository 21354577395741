import { Injectable } from '@angular/core';
import { UploadStatusesEnum } from '@core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RecurringUploadHistory, UploadHistoryService } from '@shared';
import { Observable, tap } from 'rxjs';
import { SetRecurringJobDetails, ResetRecurringJobDetails } from './recurring-job-details.action';

const stateDefaults: RecurringUploadHistory = {

    connectionName: '',
    uploadType: '',
    totalNoOfFiles: '',
    runDate: '',
    description: '',
    destinationFolder: '',
    jobStatus: UploadStatusesEnum.BLANK,
    scheduleName: '',
    filesReadyForSearch: [],
    quarantinedFiles: [],
    skippedFiles: [],
    stagedFiles: [],
    noOfSkippedFiles: '',
    noOfQuarantinedFiles: '',
    noOfFilesReadyForSearch: '',
    dataStore: '',
    frequency: '',
    scheduleId: '',
    scheduleJobId: ''
}
// const stateDefaults: RecurringJobDetailsModel = { recurringJobDetails: jobDetails };

@State<RecurringUploadHistory>({
    defaults: stateDefaults,
    name: 'RecurringJobDetailsState',
})
@Injectable()
export class RecurringJobDetailsState {
    constructor(
        private uploadService: UploadHistoryService
      ) {}
    @Selector()
    public static getRecurringJobDetails(state: RecurringUploadHistory): RecurringUploadHistory {
        return state
    }
    @Action(ResetRecurringJobDetails)
    public resetRecurringJobDetails({
        patchState,
    }: StateContext<RecurringUploadHistory>): void {
        patchState(stateDefaults);
    }
    @Action(SetRecurringJobDetails)
    public setRecurringJobDetails(ctx: StateContext<RecurringUploadHistory>, request: SetRecurringJobDetails
    ): Observable<RecurringUploadHistory> {
       return this.uploadService.getRecurringJobDetails(request.jobId,request.jobOperationType).pipe(tap((response: RecurringUploadHistory)=>{
            ctx.patchState(response)
        }));
    }
}