import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mpr-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss'],
})
export class LoadMoreComponent {
  @Output() public loadMoreMethod: EventEmitter<any> = new EventEmitter();
  @Input() public show!: boolean | null;
  @Input() public spinner!: boolean;
  constructor() {}

  public loadMore(): void {
    this.loadMoreMethod.emit();
  }
}
