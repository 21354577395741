<mat-toolbar class="footer-bottom">
  <mat-toolbar-row>
    <p>© {{ getFullYear() }} Mathematica</p>
    <div class="toolbar-bottom">
      <a href="https://mathematica.org/" target="_blank">about us</a>
      <ng-container *ngIf="!isFooterLinksHided">
        <a href="{{ contactUs }}">contact us</a>
        <a href="{{ termsOfUse }}" target="_blank"
          >terms of use and privacy policy</a
        >
      </ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
