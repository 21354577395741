import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title) {}

  public updateTitle(title: string): void {
    this.title.setTitle(title);
  }
}
