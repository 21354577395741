import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { RoleLabelsState } from 'app/state/role-labels/role-labels.state';

@Pipe({
  name: 'replaceRoleName',
})
export class ReplaceRoleName implements PipeTransform {
  constructor(private store: Store) {}

  public transform(value: string): string {
    const roleLabelMapping = this.store.selectSnapshot(
      RoleLabelsState.getRoleLabelsMapping
    );
    if (!roleLabelMapping) return value;

    return roleLabelMapping[value];
  }
}
