import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { WorkspacesInstanceStateModel } from '@shared';
import { WorkspacesInstanceService } from '@theme/services';
import { Observable, tap } from 'rxjs';
import {
  GetRDPDetails,
  ResetRDPDetails,
  SetEC2NewVersionAvailability,
} from './workspaces-instance.action';

const stateDefaults: WorkspacesInstanceStateModel = {
  public_dns_name: '',
  is_stata_ami: false,
  is_vscode_ami: false,
  new_ami_version_available: undefined,
};

@State<WorkspacesInstanceStateModel>({
  defaults: stateDefaults,
  name: 'workspacesinstance',
})
@Injectable()
export class WorkspacesInstanceState {
  constructor(private workspacesInstanceService: WorkspacesInstanceService) {}

  @Action(GetRDPDetails)
  public getRDPDetails({
    patchState,
  }: StateContext<WorkspacesInstanceStateModel>): Observable<any> {
    return this.workspacesInstanceService.getRDPDetails().pipe(
      tap((result) => {
        patchState(result);
      }),
    );
  }

  @Selector()
  public static getWorkspaceDetails(
    state: WorkspacesInstanceStateModel,
  ): WorkspacesInstanceStateModel {
    return state;
  }

  @Action(ResetRDPDetails)
  public resetRDPDetails({
    patchState,
  }: StateContext<WorkspacesInstanceStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(SetEC2NewVersionAvailability)
  public setIsNewEC2AMIVersionAvailable(
    { patchState }: StateContext<WorkspacesInstanceStateModel>,
    { isVersionAvailable }: SetEC2NewVersionAvailability,
  ): void {
    patchState({ new_ami_version_available: isVersionAvailable });
  }
}
