import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserProjectService, UserService } from '@theme/services';
import { catchError, Observable, tap, throwError } from 'rxjs';
import {
  AddUserToProject,
  DeleteUserFromProject,
  EditProjectUser,
  GetCountOfUsers,
  GetProjectUserList,
  GetUserDetails,
  ResetEditUserState,
  ResetUserListState,
} from './project-user.actions';
import ProjectCountModel from './project-user-state-model';
import { ProjectUserList } from './project-user-list-model';
import { UserList } from './user-list-model';
import AddUserListModel from './add-user-list-model';
import { userStatus } from '@core';
import { DeleteUserList } from './delete-user-list-model';

const stateDefaults: ProjectCountModel = {
  count: 0,
  deleteResponse: [],
  projectUsersList: [],
  loader: false,
  userDetails: {
    amazonWorkspace:{},
    ec2Status: '',
    emailId: '',
    firstName: '',
    lastName: '',
    isPowerUser: false,
    machineSize: '',
    projectId: '',
    projectRestrictedDataUseGroups: [],
    roleName: '',
    userId: '',
    userStatus: '',
    powerUserFeatures : {
      isHLBedrockAccess : false
    }
  },
};
const stateDefaultsForUserList: AddUserListModel = {
  usersListDetails: [],
  loader: false,
};

@State<ProjectCountModel>({
  defaults: stateDefaults,
  name: 'ProjectUser',
})
@State<AddUserListModel>({
  defaults: stateDefaultsForUserList,
  name: 'AddUser',
})
@Injectable()
export class ProjectUserState {
  constructor(
    private userProjectService: UserProjectService,
    private userService: UserService
  ) {}

  @Action(AddUserToProject)
  public AddUserToProject(
    { patchState }: StateContext<AddUserListModel>,
    { userFormDetails, roleName }: AddUserToProject
  ): Observable<Array<UserList>> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService.createUser(userFormDetails, roleName).pipe(
      tap((res: Array<UserList>) => {
        const usersListDetails = res;
        loader = false;
        patchState({ usersListDetails, loader });
      })
    );
  }

  @Action(EditProjectUser)
  public EditProjectUser(
    { patchState }: StateContext<ProjectCountModel>,
    { userFormDetails, requestHeaders }: EditProjectUser
  ): Observable<UserList> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService
      .editUser(userFormDetails, requestHeaders)
      .pipe(
        tap((res: UserList) => {
          loader = false;
          patchState({ userDetails: userFormDetails, loader });
        })
      );
  }

  @Action(GetCountOfUsers)
  public GetCountOfUsers(
    { patchState }: StateContext<ProjectCountModel>,
    { isCount }: GetCountOfUsers
  ): Observable<ProjectCountModel> {
    return this.userProjectService.getProjectUserCount(isCount).pipe(
      tap((result: any) => {
        stateDefaults.count = result;
        patchState({ ...stateDefaults });
      })
    );
  }

  @Action(GetProjectUserList)
  public GetProjectUserList(
    { patchState }: StateContext<ProjectCountModel>,
    { isCount, requestHeaders }: GetProjectUserList
  ): Observable<Array<ProjectUserList>> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService
      .getProjectUserCount(isCount, requestHeaders)
      .pipe(
        tap((res: Array<ProjectUserList>) => {
          const projectUsersList = res;
          loader = false;
          patchState({ projectUsersList, loader });
        })
      );
  }

  @Action(GetUserDetails)
  public GetUserDetails(
    { patchState }: StateContext<ProjectCountModel>,
    { userId, requestHeaders }: GetUserDetails
  ): Observable<ProjectUserList> {
    let loader = true;
    patchState({ loader });
    return this.userProjectService
      .getUserProjectDetails(userId, requestHeaders)
      .pipe(
        tap((userData: any) => {
          const userDetails = {
            amazonWorkspace: userData.amazonWorkspace,
            emailId: userData.emailId,
            ec2Status: userData.ec2Status,
            firstName: userData.userName.split(' ')[0],
            isPowerUser: userData.isPowerUser,
            lastName: userData.userName.split(' ')[1],
            machineSize: userData.machineSize,
            projectRestrictedDataUseGroups:
              userData.projectRestrictedDataUseGroups,
            roleName: userData.roleName,
            userId: userData.userId,
            userStatus: userData.userStatus,
            projectId: userData.projectId,
            powerUserFeatures :userData.powerUserFeatures
          };
          loader = false;
          patchState({ userDetails, loader });
        })
      );
  }

  /** Select Project */
  @Action(DeleteUserFromProject)
  public deleteUserFromProject(
    ctx: StateContext<ProjectCountModel>,
    { userId, requestHeaders, projectIds }: DeleteUserFromProject
  ): Observable<Array<DeleteUserList>> {
    let loader = true;
    ctx.patchState({ loader });
    return this.userProjectService
      .deleteUserFromProject(userId, requestHeaders, projectIds)
      .pipe(
        catchError((err) => {
          loader = false
          ctx.patchState({ loader });
          return throwError(() => new Error(err.error.message));
        }),
        tap((response: Array<DeleteUserList>) => {
          loader = false;
          const deleteResponse: DeleteUserList[] = response;
          let projectUsersList: ProjectUserList[] =
            ctx.getState().projectUsersList;

          if (response[0].isSuccess)
            projectUsersList = projectUsersList.filter(
              (user: ProjectUserList) =>
                user.userStatus === userStatus.INVITED
                  ? userId !== user.emailId
                  : userId !== user.userId
            );
          ctx.patchState({ loader, projectUsersList, deleteResponse });
        })
      );
  }
  @Selector()
  public static getDeleteUserList(
    state: ProjectCountModel
  ): Array<DeleteUserList> | undefined {
    return state.deleteResponse;
  }

  @Selector()
  public static getLoaderStatus(state: ProjectCountModel): boolean {
    return state.loader;
  }

  @Selector()
  public static getProjectUserCount(state: ProjectCountModel): number {
    return state.count;
  }

  @Selector()
  public static getProjectUserList(
    state: ProjectCountModel
  ): ProjectUserList[] {
    return state.projectUsersList;
  }

  @Selector()
  public static getUsersListDetails(state: AddUserListModel): UserList[] {
    return state.usersListDetails;
  }

  @Action(ResetEditUserState)
  public resetEditUserState({
    patchState,
  }: StateContext<ResetEditUserState>): void {
    patchState({ ...stateDefaults });
  }

  @Action(ResetUserListState)
  public resetUserListsState({
    patchState,
  }: StateContext<AddUserListModel>): void {
    patchState({ ...stateDefaultsForUserList });
  }

  @Selector()
  public static returnUsersDetails(
    state: ProjectCountModel
  ): ProjectUserList | undefined {
    return state.userDetails;
  }
}
