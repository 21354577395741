import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {  UpdateRVersionService } from '@shared/services';
import { Observable, tap } from 'rxjs';
import { UpdateRVersion, ResetRversionResponse} from './update-r-version.action';
import { CommonResponseModel } from '@shared/interfaces';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};

@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'UpdateRVersion',
})
@Injectable()
export class UpdateRVersionState {
  constructor(private updateRVersionService: UpdateRVersionService) {}

  @Selector()
  public static getRversionUpdateStatus(state: CommonResponseModel): CommonResponseModel {
    return state;
  }

  @Action(ResetRversionResponse)
  public resetRversionResponseState({
    patchState,
  }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(UpdateRVersion)
  public updateRversion(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { instanceId, rVersion }: UpdateRVersion
  ): Observable<CommonResponseModel> {
    return this.updateRVersionService.updateRVersion(instanceId, rVersion).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }
  
}