import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SfJWTTokenService } from '@theme/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SfJWTTokenStateModel } from './sf-jwt-token-model';
import { RefreshSfJWTToken, ResetSfJWTToken } from './sf-jwt-token.actions';

const stateDefaults: SfJWTTokenStateModel = {
   instanceId: '',
   status_code: 0,
};

@State<SfJWTTokenStateModel>({
  defaults: stateDefaults,
  name: 'sfJWTtoken'
})
@Injectable()
export class SfJWTTokenState {
  constructor(private sfJWTTokenService:SfJWTTokenService ) {}

  @Action(RefreshSfJWTToken)
  public refreshSfJWTToken(
    { patchState }: StateContext<SfJWTTokenStateModel>,
    refreshJWTToken: RefreshSfJWTToken
    ): Observable<any> {
    return this.sfJWTTokenService.getSfJWTToken(refreshJWTToken.instanceId).pipe(
      tap((result) => {
        patchState(result);
      })
    );
  }

  @Action(ResetSfJWTToken)
  public resetSfJWTToken({
        patchState
  }: StateContext<SfJWTTokenStateModel>): void {
    patchState({ ...stateDefaults });
  }

  @Selector()
  public static returnSfJWTToken(state: SfJWTTokenStateModel): SfJWTTokenStateModel {
    return state;
  }
}