import { AuthGuard, FeatureFlagGuard, ProjectSelectGuard } from '@core/guards';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { ConnectingToSnowflakeComponent } from './connecting-to-snowflake/connecting-to-snowflake.component';
import { AwsWorkspaceComponent } from './aws-workspace/aws-workspace.component';
import { Ec2WorkspaceComponent } from './ec2-workspace/ec2-workspace.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard, ProjectSelectGuard],
    children: [
      {
        path: '',
        component: WorkspaceComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          allowWhenFeatureFlagTrue: ['any'],
          back: '/home',
          backTitle: 'Dashboard',
          title: 'Workspace',
        },
      },
      {
        path: 'connecting-to-snowflake',
        component: ConnectingToSnowflakeComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          allowWhenFeatureFlagTrue: ['dwFeatureFlag'],
          back: '/workspace',
          backTitle: 'Workspace',
          title: 'Connecting to Snowflake',
        },
      },
      {
        path: 'aws',
        component: AwsWorkspaceComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          allowWhenFeatureFlagTrue: ['isAwsWorkspacesAvailable'],
          back: '/dashboard',
          backTitle: 'Dashboard',
          title: 'Workspace',
        },
      },
      { path: '', redirectTo: 'workspace', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkspaceRoutingModule {}
