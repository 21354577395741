import { Injectable } from '@angular/core';
import { GenericHttpResponse } from '@core/interfaces';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ProjectSurveyService } from '@shared/services/project-survey.service';
import { Observable, tap } from 'rxjs';
import {
  AddNewMapping,
  EditMapping,
  DeleteProjectSurvey,
  LoadProjectSurveys,
  ResetProjectSurveys,
} from './project-survey.actions';
import {
  ProjectSurveyModel,
  ProjectSurveyStateModel,
} from './project-survey.model';
import { CommonResponseModel } from '@shared';
 
const stateDefaults: ProjectSurveyStateModel = {
  deleteResponse: [],
  loader: false,
  projectSurveys: [],
  commonResponse : {message : '', status_code: 0 },
  deleteLoader: false,
};
@State<ProjectSurveyStateModel>({
  defaults: stateDefaults,
  name: 'ProjectSurveyState',
})
@Injectable()
export class ProjectSurveyState {
  constructor(private projectSurveyService: ProjectSurveyService) {}
 
  @Selector()
  public static GetProjectSurveyLoader(
    state: ProjectSurveyStateModel,
  ): boolean {
    return state.loader;
  }
 
  @Selector()
  public static GetProjectSurveys(
    state: ProjectSurveyStateModel,
  ): ProjectSurveyModel[] | [] {
    return state.projectSurveys;
  }

  @Action(AddNewMapping)
  public addNewMapping(
    { patchState }: StateContext<ProjectSurveyStateModel>,
    {headers, surveyId, surveyName, projectId }: AddNewMapping,
  ) : Observable<CommonResponseModel> {
    return this.projectSurveyService.addNewMappings(headers, surveyId, surveyName, projectId).pipe(
      tap((response)=>{
        const commonResponse = response;
        patchState({commonResponse})
      }),
      );
  }

  /** Select Project */
  @Action(DeleteProjectSurvey)
  public deleteProjectSurvey(
    ctx: StateContext<ProjectSurveyStateModel>,
    { projectSurveyId, requestHeaders }: DeleteProjectSurvey,
  ): Observable<GenericHttpResponse> {
    let deleteLoader = true;
    ctx.patchState({ deleteLoader });
    return this.projectSurveyService
      .deleteProjectSurvey(projectSurveyId, requestHeaders)
      .pipe(
        tap((response: GenericHttpResponse) => {
          deleteLoader = false;
          const deleteResponse = response.message;
          ctx.patchState({ deleteLoader, deleteResponse });
        }),
      );
  }

  @Action(EditMapping)
  public editMapping(
    { patchState }: StateContext<ProjectSurveyStateModel>,
    {headers, surveyId, surveyName, projectSurveyId }: EditMapping,
  ) : Observable<CommonResponseModel> {
    return this.projectSurveyService.editMappings(headers, surveyId, surveyName, projectSurveyId).pipe(
      tap((response)=>{
        const commonResponse = response;
        patchState({commonResponse})
      }),
      );
  }

  @Selector() 
  public static getResponseForCreateAndUpdate(state: ProjectSurveyStateModel): CommonResponseModel {
    return state.commonResponse;
  }

  @Selector() 
  public static getSurveyIds(state: ProjectSurveyStateModel): string[] {
    return state.projectSurveys.map(survey => survey.surveyId);
  }

  @Action(LoadProjectSurveys)
  public loadProjectSurveys(
    { patchState }: StateContext<ProjectSurveyStateModel>,
    { requestHeaders }: LoadProjectSurveys,
  ): Observable<ProjectSurveyModel[]> {
    let loader = true;
    patchState({ loader });
    return this.projectSurveyService.getAllProjectSurveys(requestHeaders).pipe(
      tap((results) => {
        const projectSurveys = [...results];
        loader = false;
        patchState({ projectSurveys, loader });
      }),
    );
  }
  
  @Action(ResetProjectSurveys)
  public resetProjectSurvey({
    patchState,
  }: StateContext<ProjectSurveyStateModel>): void {
    patchState({ ...stateDefaults });
  }
}
