import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
// eslint-disable-next-line import/no-internal-modules
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const scheduleDateValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const year = '2020';
    if (
      control &&
      control.value &&
      !dayjs(
        `${year}/${control.value.split('/')[0]}/${control.value.split('/')[1]}`,
        'YYYY/MM/DD',
        true
      ).isValid()
    ) {
      return { dateValidator: true };
    }
    return null;
  };
