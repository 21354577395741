import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { AwsWorkspaceComponent } from './aws-workspace/aws-workspace.component';
import { ConnectingToSnowflakeComponent } from './connecting-to-snowflake/connecting-to-snowflake.component';
import { Ec2WorkspaceComponent } from './ec2-workspace/ec2-workspace.component';
import { SetAwsWorkspacePasswordComponent } from './set-aws-workspace-password/set-aws-workspace-password.component';
import { SelectRVersionComponent } from './select-r-version/select-r-version.component';
import { WorkspaceRoutingModule } from './workspace-routing.module';
import { WorkspaceUpdateComponent } from './workspace-update/workspace-update.component';
import { WorkspaceComponent } from './workspace/workspace.component';

@NgModule({
  imports: [SharedModule, WorkspaceRoutingModule],
  declarations: [
    WorkspaceComponent,
    WorkspaceUpdateComponent,
    ConnectingToSnowflakeComponent,
    AwsWorkspaceComponent,
    Ec2WorkspaceComponent,
    SetAwsWorkspacePasswordComponent,
    SelectRVersionComponent,
  ],
})
export class WorkspaceModule {}
