import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonResponseModel, MprConfirmInputModal } from '@shared';
import { R_STUDIO_VERSIONS } from '@core/constants';
import { Select, Store } from '@ngxs/store';
import { UpdateRVersion } from 'app/state/update-r-version/update-r-version.action';
import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';
import { UpdateRVersionState } from 'app/state/update-r-version/update-r-version.state';

@Component({
  selector: 'mpr-select-r-version',
  templateUrl: './select-r-version.component.html',
  styleUrls: ['./select-r-version.component.scss'],
})
export class SelectRVersionComponent {
  @Select(UpdateRVersionState.getRversionUpdateStatus)
  private updateRVersionStatus$!: Observable<CommonResponseModel>;
  public disableProceed = false;
  public displayRversionValues: Array<string>;
  public rStudioVersions = R_STUDIO_VERSIONS;
  public selectedVersion!: string;

  constructor(
    private dialogRef: MatDialogRef<SelectRVersionComponent>,
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: MprConfirmInputModal
  ) { 
      this.displayRversionValues = this.rStudioVersions.filter((version) => version !== this.data.confirmData['currentRversion']);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public updateRVersion(selectedVersion: string): void {
    this.disableProceed = true;
    this.store.dispatch(
      new UpdateRVersion(
        this.data.confirmData['instanceId'],
        selectedVersion
      )
    ).pipe(
      withLatestFrom(this.updateRVersionStatus$),
        catchError((err) => {
          const confirmData = {error: true};
          this.dialogRef.close(confirmData);
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, res]) => {
        if(res.status_code === 200){
          const confirmData = {error: false, selectedVersion}
          this.dialogRef.close(confirmData);
        }
        else if(res.status_code !== 0){
          const confirmData = {error: true};
          this.dialogRef.close(confirmData)
        } else {
          this.dialogRef.close();
        }
      });
    
  }

  
}
