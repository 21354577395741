import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MetaDataFromControls } from '@core/enums';

export const startDateEndDateValidator = (fieldName: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const fieldToCheck = fieldName === MetaDataFromControls.DATA_SOURCE_YEARS_START ? MetaDataFromControls.DATA_SOURCE_YEARS_END : MetaDataFromControls.DATA_SOURCE_YEARS_START;
    const controlToCheck = control.parent?.get(fieldToCheck);
    const err = controlToCheck?.errors;
    if (err) {
      delete err['minEndYear'];
    }
    if (err && Object.keys(err).length) {
      controlToCheck.setErrors(err);
    } else {
      controlToCheck?.setErrors(null);
    }
    if (
      (fieldName === MetaDataFromControls.DATA_SOURCE_YEARS_END &&
        control.parent?.value[MetaDataFromControls.DATA_SOURCE_YEARS_START] > control.value) ||
      (fieldName === MetaDataFromControls.DATA_SOURCE_YEARS_START &&
        control.value > control.parent?.value[MetaDataFromControls.DATA_SOURCE_YEARS_END])
    ) {
      return { minEndYear: true };
    }
    return null;
  };