import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AWSDetailsService } from '@theme/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AWSDetailsStateModel } from './aws-details-model';
import { GetAWSDetails, ResetAWSDetails } from './aws-details.action';


const stateDefaults: AWSDetailsStateModel = {
    aws_access_key_id: '',
    aws_secret_access_key: '',
    aws_session_token: '',
    consoleUrl: '',
    validForHours: 0,
    sm_securitygroup: '',
    sm_subnet_1: '',
    sm_subnet_2: '',
    status_code: 0
};

@State<AWSDetailsStateModel>({
  defaults: stateDefaults,
  name: 'awsdetails'
})
@Injectable()
export class AWSDetailsState {
  constructor(private awsDetailsService:AWSDetailsService ) {}

  @Action(GetAWSDetails)
  public getAWSetails({
        patchState
  }: StateContext<AWSDetailsStateModel>): Observable<any> {
    return this.awsDetailsService.getAWSDetails().pipe(
      tap((result) => {
        patchState(result);
      })
    );
  }

  @Action(ResetAWSDetails)
  public resetAWSDetails({
        patchState
  }: StateContext<AWSDetailsStateModel>): void {
    patchState({ ...stateDefaults });
  }
  
  @Selector()
  public static returnAWSDetails(state: AWSDetailsStateModel): AWSDetailsStateModel {
    return state;
  }
}