import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponseModel, ProjectRequestHeaders } from '@shared/interfaces';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AcceptTermsService {
  constructor(private http: HttpClient) { }

  public acceptTerms(
    userId: string,
    optionalHeaders?: MprHttpHeaderModal
  ): Observable<CommonResponseModel> {
    if (optionalHeaders && Object.keys(optionalHeaders).length > 0) {
      const headers = new HttpHeaders()
        .set(HeaderParams.PROJECTID, optionalHeaders['projectId'])
        .append(HeaderParams.ROLENAME, optionalHeaders['roleName']);
      return this.http.put<CommonResponseModel>(`/user/${userId}`, {
        isTermsAccepted: true,
      }, { headers });
    }
    return this.http.put<CommonResponseModel>(`/user/${userId}`, {
      isTermsAccepted: true,
    });

  }
}
