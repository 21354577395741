import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { LoggedinUserState, UserProjectState } from 'app/state';
import { Store } from '@ngxs/store';
import { AuthService, MprCookieService, TokenService } from '@core/services';
import { environment } from '@env/environment';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AuthConstants } from '@core/enums';
import * as dayjs from 'dayjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store: Store, private tokenService : TokenService) {}
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Check if this is our BE URL
    const isBackendUrl = request.url.includes(environment.apiBaseUrl);

    // All Backend API calls need authentication before API calls
    return isBackendUrl
      ? this.handleBackendApiIntercepts(request, next)
      : next.handle(request);
  }

  private appendUserIdToUrl(url: string): string {
    if (!url.includes('/projects') || url.includes('userId')) return url;
    const userId = this.authService.getUserId();
    // Append the user id and ensure we do it based on if ? is already there

    url += `${url.match(/[\?]/g) ? '&' : '?'}userId=${userId}`;
    return url;
  }

  private getHeaders(
    request: HttpRequest<unknown>,
    token: string
  ): MprHttpHeaderModal {
    const headersValues: MprHttpHeaderModal = { Authorization: token };

    const roleName =
      request.headers.get(HeaderParams.ROLENAME) ??
      this.store.selectSnapshot(UserProjectState.getSelectedProjectRole);
    const projectId =
      request.headers.get(HeaderParams.PROJECTID) ??
      this.store.selectSnapshot(UserProjectState.getSelectedProjectId);

    if (roleName) headersValues[HeaderParams.ROLENAME] = roleName;
    if (projectId) headersValues[HeaderParams.PROJECTID] = projectId;

    return headersValues;
  }

  private handleBackendApiIntercepts(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Check if the token is present and valid
    const authCheck = this.authService.check();

    // So this is a BE API Call
    const token = authCheck ? this.authService.getIdToken() : '';
    const tokenData = token !== '' ? this.tokenService.parseToken(token) : {};
    const istokenExpired = Object.keys(tokenData).length !== 0 ? this.authService.isTokenExpired(tokenData[AuthConstants.EXPIRY]) : true;
    const refreshToken = this.authService.getRefreshToken();

    // No refresh token : first time or it got expired : make user login again
    if (!refreshToken) {
      this.authService.logoutAndRedirectToLogin();
    }

    // Check if this cookie
    const tokenExpiry = this.authService.getIdTokenExpiry();

    // check if the id token is expiring 5 mins from now
    const minsFromNow = dayjs()
      .add(
        AuthConstants.RENEW_ID_TOKEN_BEFORE,
        AuthConstants.RENEW_ID_TOKEN_BEFORE_UNIT
      )
      .valueOf();
    const tokenExpiringInNext5Mins =
      !token || !tokenExpiry || tokenExpiry <= minsFromNow;

    // Either token not there or expired or expiring in next x mins: get a new one.
    return tokenExpiringInNext5Mins
      ? this.authService
          .refreshToken()
          .pipe(switchMap(() => this.handleNext(request, next)))
      : this.handleNext(request, next);
  }

  private handleNext(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Get the new Id Token
    const token = this.authService.getIdToken();
    const headers = this.getHeaders(request, token);
    // Go ahead with the next
    return next.handle(
      request.clone({
        url: this.appendUserIdToUrl(request.url),
        setHeaders: headers,
      })
    );
  }
}
