/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';
import ManageProjectModel, {
  projectDetails,
} from './manage-project-state-model';

export class CreateAProject {
  public static type = '[Project] Create';
  constructor(
    public projectFormDeatils: projectDetails,
    public roleName: string
  ) {}
}

export class EditAProject {
  public static type = '[Project] Edit';
  constructor(
    public projectFormDeatils: projectDetails,
    public roleName: string,
    public projectId: string
  ) {}
}

export class GetProjectDeatils {
  public static type = '[Project] Details';
  constructor(public requestHeaders: MprHttpHeaderModal) {}
}

export class ResetProjectData {
  public static type = '[Project] Reset';
}

export class SetLoaderState {
  public static type = '[Project] Loader Set';
  constructor(public loader: boolean) {}
}
