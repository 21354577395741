/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class AddNewCrossAccountAccess {
    public static type = '[CrossAccount] Add new cross account access ';
    constructor(
      public accountAttributeId: string,
      public arn: string,
      public crossAccountConnectionType: string
    ) {}
  }

  export class DeleteCrossAccountAccess {
    public static type = '[CrossAccount] Delete existing cross account access';
    constructor(
      public accountAttributeId: string,
      public crossAccountConnectionType: string,
      public ownerUserId: string,
      public serviceAccountArnId: string
    ) {}
  }  

  export class EditCrossAccountAccess{
    public static type = '[CrossAccount] Edit existing cross account access ';
    constructor(
      public accountAttributeId: string,
      public ownerUserId: string,
      public crossAccountConnectionType: string,
      public serviceAccountArnId: string,
      public modified:{
        accountAttributeId?: string;
        arn?: string;
        crossAccountConnectionType?: string,
      }
    ){}
  }
  
  export class GetCrossAccountsEnabledList { 
    public static type = '[CrossAccount] Get Cross Accounts Enabled List'; 
    constructor() {} 
  }

  export class ResetSetupCrossAccountAccessState {
    public static type = '[CrossAccount] Reset to Default';
  }

