/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { LoggedinUserStateModel } from './loggedin-user-model';

export class GetLoggedinUserFullName {
  public static type = '[LoggedinUser] Get User Full Name ';
}

export class GetLoggedinUserEmail {
  public static type = '[LoggedinUser] Get User Email';
}

export class GetLoggedinUserId {
  public static type = '[LoggedinUser] Get User Id';
}

export class GetLoggedinUserTokenExpiry {
  public static type = '[LoggedinUser] Get Token E';
}

export class ResetLoggedinUserState {
  public static type = '[LoggedinUser] Reset to Default';
}

export class SetLoggedinUserState {
  public static type = '[LoggedinUser] Set State';
  constructor(public loggedinUserState: LoggedinUserStateModel) {}
}

export class IsLoggedInUserInternal {
  public static type = '[LoggedinUser] Is Internal';
}
