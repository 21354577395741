import { Injectable } from '@angular/core';
import {
  DownloadJobRequest,
  DownloadJobResponseModel,
  DownloadJobResponseWrapper,
} from '@download/interface';
import { DownloadService } from '@download/services';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DownloadJobStateModel } from './download-state-model';
import {
  CreateDownloadJob,
  CreateViewPresignedUrl,
  ResetDownloadJobState,
} from './download.actions';
import { GenericHttpResponse } from '@core/interfaces';

const stateDefaults = {
  downloadJobId: '',
  s3PresignedDownloadUrl: '',
  s3PresignedViewUrl: '',
};
@State<DownloadJobStateModel>({
  name: 'DownloadJobState',
  defaults: stateDefaults,
})
@Injectable()
export class DownloadState {
  constructor(private downloadService: DownloadService) {}
  @Selector()
  public static GetDownloadJobDetails(
    state: DownloadJobStateModel
  ): DownloadJobResponseModel {
    return state;
  }

  @Selector()
  public static GetViewFileUrl(state: DownloadJobStateModel): string {
    return state.s3PresignedViewUrl ?? '';
  }

  @Action(CreateDownloadJob)
  public createDownloadJob(
    ctx: StateContext<DownloadJobStateModel>,
    downloadJobRequest: DownloadJobRequest
  ): Observable<DownloadJobResponseWrapper> {
    return this.downloadService.createDownloadJob(downloadJobRequest).pipe(
      tap((response) => {
        ctx.patchState({
          ...response.message,
        });
      })
    );
  }

  @Action(CreateViewPresignedUrl)
  public createViewPresignedUrl(
    ctx: StateContext<DownloadJobStateModel>,
    s3Path: string
  ): Observable<GenericHttpResponse> {
    return this.downloadService.getViewPresignedUrl(s3Path).pipe(
      tap((response) => {
        const s3PresignedViewUrl = response.message.s3PresignedViewUrl;
        ctx.patchState({
          s3PresignedViewUrl,
        });
      })
    );
  }

  @Action(ResetDownloadJobState)
  public resetDownloadJobState({
    patchState,
  }: StateContext<DownloadJobStateModel>): void {
    patchState({ ...stateDefaults });
  }
}
