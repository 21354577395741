/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
import { MprHttpHeaderModal } from '@core/interfaces';

export class SetOneTimeJobDetails {
  public static type = '[OnetimeJobDetailsState] Set job details';

  constructor(
    public jobId: string,
    public jobOperationType?: string,
    public requestHeaders?: MprHttpHeaderModal
  ) {}
}

export class ResetOneTimeJobDetails {
  public static type = '[OnetimeJobDetailsState] Reset job details';
}
