import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import {
  BoxPickerDialogModel,
  BoxPickerTypeEnum,
  ConnectionModel,
  ContentPickerDetails,
  Contents,
  FileDetailsList,
  FolderDetailsList,
  MainContentDetails,
  SubContentDetails,
  WarningPopupComponent,
} from '@shared';
import {
  CommonResponseModel,
  ProjectRequestHeaders,
} from '@shared/interfaces';
import { UserProject } from '@theme/interfaces';
import { ConnectionState } from 'app/state/connection';
import {
  CheckFileManagerObjectExists,
  GetContentPicker,
  RenameS3Folder,
  S3PickerState,
} from 'app/state/s3-picker';
import { Observable, throwError } from 'rxjs';
import { catchError, withLatestFrom } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-s3-content-picker',
  templateUrl: './s3-content-picker.component.html',
  styleUrls: ['./s3-content-picker.component.scss'],
})
export class S3ContentPickerComponent implements OnInit {
  @Select(S3PickerState.GetContentPickerDetails)
  public getContentPickerDetails$!: Observable<any>;
  @Select(S3PickerState.GetAddedEditedDeletedFolder)
  private createEditS3FolderResponse$!: Observable<CommonResponseModel>;
  @Select(S3PickerState.s3ObjectResponse)
  private s3ObjectResponse$!: Observable<CommonResponseModel>;
  @ViewChild('selectAllCheckbox')
  private selectAllCheckbox!: MatCheckbox;
  public actionType = '';
  public boxPickerTypeEnum = BoxPickerTypeEnum;
  public breadCrumbMenuList: ContentPickerDetails[] = [];
  public contentPickerList: any = [];
  public dataSource!: MatTableDataSource<
    MainContentDetails | SubContentDetails
  >;
  public destinationPrefix = '';
  public disableAction = false;
  public displayName = '';
  public displayedColumns = ['columnName'];
  public fileDetailsList: FileDetailsList[] = [];
  public filePath = 'assets/images/s3-picker-images/';
  public folderDetailsList: FolderDetailsList[] = [];
  public folderPath = 'assets/images/s3-picker-images/folder.svg';
  public isFile = '';
  public isInternalPicker = true;
  public mainBreadCrumbTitle = 'All Files';
  public pickerType = BoxPickerTypeEnum.FILE;
  public prefix = '';
  public s3objectNames: any = [];
  public selectAllFiles = false;
  public showSelectAllCheckbox = false;
  public showSpinner = false;
  private projectRequestHeaders?: ProjectRequestHeaders;
  private selectedProject!: UserProject;
  private showDefaultProjectBucket = false;
  constructor(
    private alertMsgService: AlertMessageService,
    private dialogRef: MatDialogRef<S3ContentPickerComponent>,
    public dialog: MatDialog,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: BoxPickerDialogModel
  ) {
    this.pickerType = data.pickerType;
    if (data.projectRequestHeaders)
      this.projectRequestHeaders = data.projectRequestHeaders;

    if (data.selectedProject) this.selectedProject = data.selectedProject;
    if (data.showDefaultProjectBucket)
      this.showDefaultProjectBucket = data.showDefaultProjectBucket;
    if (data.actionType) this.actionType = data.actionType;
  }

  public checkBoxClick(event: any, contentPickerDetails: any): void {
    contentPickerDetails.checked = event.checked;
    if (event.checked) {
      const pickerPath = this.getPickerPath(contentPickerDetails);
      this.fileDetailsList.push({
        name: contentPickerDetails.name,
        size: contentPickerDetails.size,
        path: contentPickerDetails.path,
        pickerPath,
      });
    } else {
      this.fileDetailsList = this.fileDetailsList.filter(
        (e) => e.pickerPath !== contentPickerDetails.pickerPath
      );
    }
    const filesInFolder = this.contentPickerList.filter(
      (contentPicker: any) => contentPicker.type === this.boxPickerTypeEnum.FILE
    );
    const selectedFilesInFolder = filesInFolder.filter(
      (contentPicker: any) => contentPicker.checked
    );
    const allFilesSelected =
      filesInFolder.length === selectedFilesInFolder.length;
    this.selectAllFiles =
      filesInFolder.length === 1
        ? event.checked
          ? true
          : false
        : allFilesSelected;
  }

  public checkObjectExists(
    prefix: string,
    destinationPrefix: string,
    s3objectNames: any,
    action: string,
    fileType: string
  ): void {
    this.store
      .dispatch(
        new CheckFileManagerObjectExists(
          destinationPrefix,
          s3objectNames,
          action,
          fileType,
          prefix
        )
      )
      .pipe(
        withLatestFrom(this.s3ObjectResponse$),
        catchError((err) => {
          this.showError(err.error.message);
          this.showSpinner = false;
          this.dialogRef.close({ result: 'success' });
          return throwError(() => new Error(''));
        })
      )
      .subscribe((response: any) => {
        for (const i in response) {
          if (
            (response[i].status_code === 200 ||
              response[i].status_code === 0) &&
            response[i].message
          ) {
            if (
              response[i].message.length > 0 &&
              typeof response[i].message == 'object'
            ) {
              const selectedTypeMessage = this.data.isFile
                ? 'A file with this name already exists.</br> Would you like to replace the existing one or keep a copy?'
                : 'A folder with this name already exists. Would you like to keep a copy?';
              this.dialog
                .open(WarningPopupComponent, {
                  disableClose: true,
                  data: {
                    title: `1 item wasn't moved`,
                    contents: selectedTypeMessage,
                    destinationPrefix: this.destinationPrefix,
                    prefix: this.prefix,
                    isFile: this.data.isFile,
                    action: this.actionType,
                    displayName: this.displayName,
                    s3ObjectName: s3objectNames,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.showSpinner = false;
                  this.dialogRef.close({ result: 'success' });
                });
            } else {
              this.moveToTargetFolder(false);
            }
          }
        }
      });
  }

  public chooseFiles(): void {
    this.dialogRef.close(this.fileDetailsList);
  }

  public chooseFolder(): void {
    this.dialogRef.close(this.folderDetailsList[0].pickerPath);
  }

  public folderClick(contentPickerDetails: ContentPickerDetails): void {
    this.showSpinner = true;
    if (this.selectAllCheckbox) {
      this.selectAllCheckbox.checked = false;
    }
    this.store
      .dispatch(
        new GetContentPicker(contentPickerDetails, this.projectRequestHeaders)
      )
      .pipe(
        withLatestFrom(this.getContentPickerDetails$),
        catchError((err) => {
          this.showError(err.error.message);
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, resp]) => {
        const contentDetails = resp.response;
        if (contentDetails.status_code && contentDetails.status_code === 400) {
          this.showSpinner = false;
          this.showError(contentDetails.message);
        } else {
          this.contentPickerList = contentDetails.contents;
          this.checkPickerPath();
          this.dataSource = new MatTableDataSource(this.contentPickerList);
          this.showSpinner = false;
          const path = contentDetails.folder_name
            ? contentDetails.folder_name.split('/')
            : [];
          const subPath = this.getSubPath(path, contentDetails.bucket_name);
          if (
            !this.breadCrumbMenuList.some(
              (e: { name: string; path: string }) =>
                `${e.path}${e.name}` ===
                `${contentPickerDetails.path}${contentPickerDetails.name}`
            )
          ) {
            this.breadCrumbMenuList.push({
              name: contentPickerDetails.name,
              icon: 'keyboard_arrow_right',
              path: subPath ? subPath : '',
              displayName: contentPickerDetails.displayName,
            });
          }

          const filesInFolder = this.contentPickerList.filter(
            (contentPicker: any) =>
              contentPicker.type === this.boxPickerTypeEnum.FILE
          );
          const allFilesSelected =
            filesInFolder.length > 0 &&
            filesInFolder.every((contentPicker: any) => contentPicker.checked);
          this.selectAllFiles = allFilesSelected;
          this.showSelectAllCheckbox = filesInFolder.length > 0;
          if (this.selectAllFiles) {
            this.selectAllCheckbox.checked = true;
          }
        }
      });
  }

  public getFileIconAltText(s3BucketContent: ContentPickerDetails): string {
    return `${s3BucketContent.icon.replace('.png', '')} Icon`;
  }

  public getFolderFileName(filePath: string, isFile: boolean): string {
    const splittedPath = filePath.split('/');
    const formattedName = isFile
      ? splittedPath[splittedPath.length - 1]
      : splittedPath[splittedPath.length - 2];
    return formattedName.trim();
  }

  public getFormattedFolderName(name: string): string {
    if (name.includes('/')) {
      const formattedName = name.replace('/', '');
      return formattedName;
    } else {
      return name;
    }
  }
  public getFormattedPath(path: string): string {
    const splittedPath = path.split('/');
    const removeFirstElement = splittedPath.shift();
    return splittedPath.join('/');
  }

  public getImagePath(contentPickerDetails: ContentPickerDetails): string {
    return `${this.filePath}${contentPickerDetails.icon.replace(
      '.png',
      '.svg'
    )}`;
  }

  public getSelectedFileOrFolderName(path: string, isFile: string): string[] {
    this.s3objectNames = [];
    const splittedPath = path.split('/');
    const getLastElement =
      isFile === 'True'
        ? splittedPath[splittedPath.length - 1]
        : splittedPath[splittedPath.length - 2];
    const selectedFileOrFolderName =
      isFile === 'True' ? `${getLastElement}` : `${getLastElement}/`;
    this.s3objectNames.push(selectedFileOrFolderName);
    return this.s3objectNames;
  }

  public mainBreadCrumb(): void {
    this.breadCrumbMenuList = [];
    this.getDefaultPicker();
  }

  public moveToTargetFolder(isCopy?: boolean): void {
    this.store
      .dispatch(
        new RenameS3Folder(
          this.prefix,
          this.actionType,
          this.destinationPrefix,
          this.isFile,
          isCopy
        )
      )
      .pipe(
        withLatestFrom(this.createEditS3FolderResponse$),
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
            autoDismiss: true,
          });
          this.disableAction = false;
          this.dialogRef.close({ result: 'success' });
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, response]) => {
        let message = '';
        message = this.data.isFile
          ? `Moving "${this.displayName}" to the destination folder. It might take a few minutes.`
          : `Moving "${this.displayName}" to the destination folder. For folders with large files, it might take a few minutes, please wait until you see the folder is moved.`;

        this.alertMsgService.success({
          body: message,
          autoDismiss: false,
        });
        // All is well
        this.disableAction = false;
        this.showSpinner = false;
        this.dialogRef.close({ result: 'success' });
      });
  }

  public moveToTargetLocation(): void {
    this.disableAction = true;
    this.showSpinner = true;
    const path = this.folderDetailsList[0].pickerPath;
    if (this.data.prefix)
      this.displayName = this.getFolderFileName(
        this.data.prefix,
        this.data.isFile
      );
    if (this.data.isFile) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.destinationPrefix = this.getFormattedPath(path!);
      if (this.data.prefix)
        this.prefix = this.getFormattedPath(this.data.prefix);
      this.isFile = 'True';
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.destinationPrefix = this.getFormattedPath(path!);
      if (this.data.prefix)
        this.prefix = this.getFormattedPath(this.data.prefix);
      this.isFile = 'False';
    }
    this.getSelectedFileOrFolderName(this.prefix, this.isFile);
    this.checkObjectExists(
      this.prefix,
      this.destinationPrefix,
      this.s3objectNames,
      'check_object_exists',
      this.isFile
    );
  }

  ngOnInit(): void {
    this.getDefaultPicker();
  }

  public radioClick(contentPickerDetails: ContentPickerDetails): void {
    this.folderDetailsList = [];
    this.folderDetailsList.push({
      folderName: contentPickerDetails.name,
      pickerPath: contentPickerDetails.pickerPath,
    });
  }

  public subBreadCrumb(index: number): void {
    this.breadCrumbMenuList.splice(
      index + 1,
      this.breadCrumbMenuList.length - 1
    );
    this.folderClick(this.breadCrumbMenuList[index]);
  }

  public toggleSelectAllFiles(): void {
    let allFilesSelected = true;
    for (const contentPickerDetails of this.contentPickerList) {
      if (contentPickerDetails.type === this.boxPickerTypeEnum.FILE) {
        contentPickerDetails.checked = this.selectAllFiles;
        const pickerPath = this.getPickerPath(contentPickerDetails);
        if (this.selectAllFiles) {
          // Add file to the fileDetailsList
          const existingFile = this.fileDetailsList.find(
            (file) => file.pickerPath === pickerPath
          );
          if (!existingFile) {
            this.fileDetailsList.push({
              name: contentPickerDetails.name,
              size: contentPickerDetails.size,
              path: contentPickerDetails.path,
              pickerPath,
            });
          }
        } else {
          // Remove file from the fileDetailsList
          this.fileDetailsList = this.fileDetailsList.filter(
            (file) => file.pickerPath !== pickerPath
          );
        }
        if (!contentPickerDetails.checked) {
          allFilesSelected = false;
        }
      }
    }
    this.selectAllFiles = allFilesSelected;
  }

  private checkPickerPath(): void {
    const list =
      this.pickerType === this.boxPickerTypeEnum.FILE
        ? this.fileDetailsList
        : this.folderDetailsList;
    this.contentPickerList = this.contentPickerList.map((content: Contents) => {
      const pickerPath = this.getPickerPath(content);
      return {
        ...content,
        checked: list.some((element) => element.pickerPath === pickerPath),
        pickerPath,
      };
    });
  }

  private getDefaultPicker(): void {
    const connection: ConnectionModel = this.store.selectSnapshot(
      ConnectionState.getSelectedConnection
    );
    // If this is a not request to show the project default dev staging and we have a connection
    if (!this.showDefaultProjectBucket && connection.bucketName) {
      const obj = {
        icon: '',
        name: connection.bucketName,
        path: '',
      };
      this.isInternalPicker = false;
      this.folderClick(obj);
    } else {
      this.getS3ContentPicker();
    }
  }

  private getFormmatedPath(path: string): string {
    const spilitPath = path.split('/');
    const deleteFirstElement = spilitPath.shift;
    return spilitPath.join('/');
  }

  private getPickerPath(contentPickerDetails: ContentPickerDetails): string {
    let pickerPath = '';
    if (
      contentPickerDetails &&
      contentPickerDetails.name &&
      !contentPickerDetails.path
    ) {
      pickerPath += `${contentPickerDetails.name}`;
    } else if (
      contentPickerDetails &&
      contentPickerDetails.name &&
      contentPickerDetails.path
    ) {
      const lastChar = contentPickerDetails.path.charAt(
        contentPickerDetails.path.length - 1
      );
      pickerPath += `${contentPickerDetails.path}${
        lastChar === '/' ? '' : '/'
      }${contentPickerDetails.name}`;
    }
    return pickerPath;
  }

  private getS3ContentPicker(): void {
    this.showSpinner = true;
    this.store
      .dispatch(
        new GetContentPicker(
          undefined,
          this.projectRequestHeaders,
          this.selectedProject,
          this.showDefaultProjectBucket
        )
      )
      .pipe(
        withLatestFrom(this.getContentPickerDetails$),
        catchError((err) => {
          this.showError(err.error.message);
          return throwError(() => new Error(''));
        })
      )
      .subscribe(([_, resp]) => {
        if (Array.isArray(resp.response)) {
          this.contentPickerList = resp.response.map(
            ({ bucket_name }: { bucket_name: string }) => ({
              name: bucket_name,
            })
          );
        } else {
          const contentDetails = resp.response;
          if (
            contentDetails.status_code &&
            contentDetails.status_code === 400
          ) {
            this.showSpinner = false;
            this.showError(contentDetails.message);
          } else {
            this.contentPickerList = contentDetails.contents;
            // Filter out only the project folder
            if (
              this.showDefaultProjectBucket &&
              Array.isArray(this.contentPickerList)
            ) {
              const bucketName = this.contentPickerList.find(
                (path: any) =>
                  path.name === `${this.selectedProject.projectId}_staging/`
              );

              // If user does not have access to the folder show error and close the popup.
              if (!bucketName) {
                this.dialogRef.close();
                this.showError(
                  'Access Denied. You don’t have access to this location. Please contact your platform admin.'
                );
              } else {
                this.contentPickerList = [bucketName];

                this.contentPickerList = this.contentPickerList ?? [];
              }
            }
          }
        }
        this.checkPickerPath();
        this.dataSource = new MatTableDataSource(this.contentPickerList);
        this.showSpinner = false;
      });
  }

  private getSubPath(path: string, bucketName: string): string {
    let subPath = '';
    if (path.length > 2) {
      for (let i = 0; i < path.length - 2; i++) {
        subPath += `${path[i]}/`;
      }
      subPath = `${bucketName}/${subPath}`;
    } else if (path.length === 2) {
      subPath = bucketName;
    }
    return subPath;
  }

  private showError(errMsg: string): void {
    this.alertMsgService.error({
      body: errMsg,
    });
    this.showSpinner = false;
  }
}
