import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnowflakeDetailsStateModel } from 'app/state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnowflakeDetailsService {
  constructor(private http: HttpClient) { }

  public getSnowflakeDetails(): Observable<SnowflakeDetailsStateModel> {
    return this.http.get<SnowflakeDetailsStateModel>('/dw');
  }
}
