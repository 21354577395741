import { Injectable } from '@angular/core';
import { UploadStatusesEnum } from '@core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OneTimeUploadHistory, UploadHistoryService } from '@shared';
import { Observable, tap } from 'rxjs';
import {
  SetOneTimeJobDetails,
  ResetOneTimeJobDetails,
} from './onetime-job-details.actions';

const stateDefaults: OneTimeUploadHistory = {
  uploadDate: '',
  description: '',
  jobStatus: UploadStatusesEnum.BLANK,
  totalNoOfFiles: '',
  uploadType: '',
  filesReadyForSearch: [],
  stagedFiles: [],
  noOfFilesReadyForSearch: '',
  userName: '',
  dataStore: '',
  resourceNames: [],
  noOfUrls: '',
  displaySource: [],
};

@State<OneTimeUploadHistory>({
  defaults: stateDefaults,
  name: 'OnetimeJobDetailsState',
})
@Injectable()
export class OnetimeJobDetailsState {
  constructor(private uploadService: UploadHistoryService) {}
  @Selector()
  public static getOneTimeJobDetails(
    state: OneTimeUploadHistory
  ): OneTimeUploadHistory {
    return state;
  }
  @Action(ResetOneTimeJobDetails)
  public resetOneTimeJobDetails({
    patchState,
  }: StateContext<OneTimeUploadHistory>): void {
    patchState(stateDefaults);
  }
  @Action(SetOneTimeJobDetails)
  public setOneTimeJobDetails(
    ctx: StateContext<OneTimeUploadHistory>,
    request: SetOneTimeJobDetails
  ): Observable<OneTimeUploadHistory> {
    return this.uploadService
      .getOnetimeJobDetails(
        request.jobId,
        request.jobOperationType,
        request.requestHeaders
      )
      .pipe(
        tap((response: OneTimeUploadHistory) => {
          ctx.patchState(response);
        })
      );
  }
}
