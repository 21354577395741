import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserProject } from '@theme/interfaces';
import { UserProjectState } from 'app/state';
import { ConnectionState } from 'app/state/connection';
import { ConnectionModel } from '@shared';
import { environment } from '@env/environment';

@Component({
  selector: 'mpr-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent {
  @Input() public isDashboard = true;

  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;

  @Select(UserProjectState.getAllUserProjects)
  public projects$?: Observable<UserProject[]>;

  @Select(ConnectionState.getSelectedConnection)
  public selectedConnection$?: Observable<ConnectionModel>;

  public matChipTabIndex = -1;
  public prodEnvUrl = environment.prodEnvUrl;
  public showWarning = true;

  constructor(public router: Router, private route: ActivatedRoute) {}

  public getFileOrDatasetDownload(): string {
    return this.router.url.includes('/dataset') ? 'dataset' : 'file';
  }

  public hideWarning(): void {
    this.showWarning = false;
  }

  public isDownloadOrPages(): boolean {
    return (
      this.router.url.includes('/download') ||
      this.router.url.includes('/pages') ||
      this.router.url.includes('/uploads/onetime/dataset') ||
      this.router.url.includes('/uploads/job-details/onetime/dataset')
    );
  }

  public showNonProdWarning(): boolean {
    return (
      this.showWarning &&
      !environment.production &&
      (this.router.url.includes('uploads/onetime') ||
        this.router.url.includes('uploads/create-schedule') ||
        this.router.url.includes('uploads/edit-schedule/') ||
        this.router.url.includes('staging/create-schedule') ||
        this.router.url.includes('staging/edit-schedule') 
        )
    );
  }
}
