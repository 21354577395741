/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */
import { MprHttpHeaderModal } from '@core/interfaces';
export class LoadUserdata {
  public static type = '[User] Get data ';
  constructor(public requestHeaders?: MprHttpHeaderModal) {}
}

export class LaunchWorkspace {
  public static type = '[User] LaunchWorkspace data ';
}

export class ResetUserdata {
  public static type = '[User] Reset User data ';
}

export class LoadAllUserdata {
  public static type = '[Platform Admin] Get All Users';
  constructor(
    public getAllFields?: boolean | undefined,
    public pageNo?: number | undefined
  ) {}
}

export class LoadAllPlatformAdminUsersData {
  public static type = '[Platform Admin] Get All Platform Admin Users';
  constructor() {}
}

export class ResetAllUserdata {
  public static type = '[User] Reset All User Data ';
}
