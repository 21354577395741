import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, FeatureFlagGuard, ProjectSelectGuard } from '@core/guards';
import { UserInfoGuard } from '@core/guards/user-info.guard';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { ManageClusterUsersComponent } from './manage-cluster-users/manage-cluster-users.component';
import { ManageRDSComponent } from './manage-rds/manage-rds.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'platform-admin/manage-rds/:projectId',
        component: ManageRDSComponent,
        canActivate: [FeatureFlagGuard, UserInfoGuard],
        data: {
          allowWhenFeatureFlagTrue: ['rdsFeatureFlag'],
          back: 'platformAdmin/projects/view/:projectId',
          backTitle: 'Project Details',
          title: 'Manage RDS Clusters',
        },
      },
      {
        path: 'manage-rds',
        component: ManageRDSComponent,
        canActivate: [FeatureFlagGuard, ProjectSelectGuard],
        data: {
          allowWhenFeatureFlagTrue: ['rdsFeatureFlag'],
          back: 'admin/project/view',
          backTitle: 'Project Details',
          title: 'Manage RDS Clusters',
        },
      },
      {
        path: 'platform-admin/manage-cluster-users/:projectId/:clusterId',
        component: ManageClusterUsersComponent,
        data: {
          back: 'rds/platform-admin/manage-rds/:projectId',
          backTitle: 'Manage Clusters',
          title: 'Manage Cluster Users',
        },
      },
      {
        path: 'manage-cluster-users/:clusterId',
        component: ManageClusterUsersComponent,
        canActivate: [FeatureFlagGuard, ProjectSelectGuard],
        canActivateChild: [FeatureFlagGuard, ProjectSelectGuard],
        data: {
          allowWhenFeatureFlagTrue: ['rdsFeatureFlag'],
          back: 'rds/manage-rds',
          backTitle: 'Manage Clusters',
          title: 'Manage Cluster Users',
        },
      },
      {
        path: 'view-rds',
        component: ManageRDSComponent,
        canActivate: [FeatureFlagGuard, ProjectSelectGuard],
        data: {
          allowWhenFeatureFlagTrue: ['rdsFeatureFlag'],
          back: '/dashboard',
          backTitle: 'Dashboard',
          title: 'View RDS Clusters',
        },
      },
      {
        path: 'view-rds-from-workspace',
        component: ManageRDSComponent,
        canActivate: [FeatureFlagGuard, ProjectSelectGuard],
        data: {
          allowWhenFeatureFlagTrue: ['rdsFeatureFlag'],
          back: '/workspace',
          backTitle: 'Dashboard',
          title: 'View RDS Clusters',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RDSRoutingModule {}
