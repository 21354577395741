import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import {
  ConnectionState,
  GetAllConnections,
  SetSelectedConnection,
} from 'app/state/connection';
import { FeatureFlagsState , UserProjectState} from 'app/state';
import { ProjectSurveyState , ProjectSurveyModel, LoadProjectSurveys} from 'app/state/project-survey';
import {
  ConnectionDataStore,
  ConnectionType,
  FrequencyListEnum,
  UserProjectRoleEnum,
} from '@core/enums';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { BoxContentPickerComponent, InitiateCreateScheduleComponent, S3ContentPickerComponent, scheduleDateValidator } from '@shared';
import { ComponentType } from '@angular/cdk/portal';
import { 
  ConnectionModel,
  MprFolderPathCollection, 
  MPRFolderModel 
} from '@shared/interfaces';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { SetScheduleFormData } from 'app/state/uploads/schedule/schedule.action';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as frequencyConstants from './frequency-constants';
import { SkipMainContentService } from '@shared/services';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import * as constants from '@core/constants';
import { UserProject } from '@theme/interfaces';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
})
export class ScheduleFormComponent implements OnInit {
  @Select(ConnectionState.getConnectionLoading)
  public connectionLoading$?: Observable<boolean>;
  @Select(ConnectionState.getAllActiveIngestionConnections)
  public connections$?: Observable<ConnectionModel[]>;
  @Select(ConnectionState.getConnectionsLoading)
  public connectionsLoading$?: Observable<boolean>;
  @Output() public proceedToMetaData = new EventEmitter<any>();
  @Select(ProjectSurveyState.GetProjectSurveyLoader)
  public projectSurveyLoader$?: Observable<boolean>;
  @Select(ProjectSurveyState.GetProjectSurveys)
  public projectSurveys$!: Observable<ProjectSurveyModel[] | []>;
  @ViewChild('typeOfDataSource') public skipper!: MatSelect;
  public connectionList: ConnectionModel[] = [];
  public connectionType = ConnectionType;
  public dL_S3Connection = constants.DL_S3_CONNECTION;
  public daysOfTheMonth = frequencyConstants.daysOfTheMonth;
  public daysOfTheWeek = frequencyConstants.daysOfTheWeek;
  public defaultValue: ConnectionModel = {
    connectionId: '',
    connectionName: '',
    connectionStatus: '',
    createdBy: '',
    createdByName: '',
    createdDate: '',
    connectionType: ConnectionType.INGESTION,
    dataStore: ConnectionDataStore.LOCAL,
    emailId: '',
    modifiedBy: '',
    modifiedDate: '',
    projectId: '',
  };
  public displayDestinationPath = '';
 
  public forstaConnection = constants.FORSTA_SFTP_CONNECTION;
  public frequencyList = Object.values(FrequencyListEnum);
  public frequencyListEnum = FrequencyListEnum;
  public hideRequiredMarker = true;
  public hoursOfTheDay = frequencyConstants.hoursOfTheDay;
  public projectSurveys: ProjectSurveyModel[] = [];
  public returnPath: string;
  public scheduleType = '';
  public selectedConnection: ConnectionModel = this.defaultValue;
  public selectedProjectId = '';
  public showForstaInDropdown = false;

   public uploadScheduleForm: FormGroup;
  private dialogSize: MatDialogConfig = {
    minHeight: '250px ',
    width: '70%',
  };
  private selectedProject!: UserProject;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store,
    private dialog: MatDialog,
    private skipMainContentService: SkipMainContentService,
    private router: Router,

  ) {
    this.returnPath = route.snapshot.data['back'];
    this.scheduleType = this.router.url.includes('staging')? 'staging': '';
    this.uploadScheduleForm = this.fb.group({
      connectionId: ['', Validators.required],
      scheduleName: ['', Validators.required],
      folderPath: ['', Validators.required],
      folderId: [''],
      frequency: ['', Validators.required],
      hourOfTheDay: [''],
      frequencyDetail: [''],
    });
    if(this.scheduleType === 'staging') {
      this.uploadScheduleForm.addControl('stagingTargetPath', new FormControl('', Validators.required));
    }
    this.uploadScheduleForm.addControl('surveyId', new FormControl('', Validators.required));
  }

  public frequencyChanged(): void {
    const formControl = this.uploadScheduleForm.controls;
    this.clearValueAndValidators(formControl['hourOfTheDay']);
    this.clearValueAndValidators(formControl['frequencyDetail']);
    switch (formControl['frequency'].value) {
      case this.frequencyList[0]:
        formControl['hourOfTheDay'].setValue('01');
        break;
      case this.frequencyList[1]:
        formControl['hourOfTheDay'].setValidators(Validators.required);
        break;
      case this.frequencyList[2]:
      case this.frequencyList[3]:
        formControl['hourOfTheDay'].setValidators(Validators.required);
        formControl['frequencyDetail'].setValidators(Validators.required);
        break;
      case this.frequencyList[4]:
        formControl['hourOfTheDay'].setValidators(Validators.required);
        formControl['frequencyDetail'].setValidators([
          Validators.required,
          scheduleDateValidator(),
        ]);
        break;
      default:
        break;
    }
    formControl['hourOfTheDay'].updateValueAndValidity();
    formControl['frequencyDetail'].updateValueAndValidity();
  }

  public isForstaConnectionSelected(): boolean {
    const connectionId = this.uploadScheduleForm.get('connectionId')?.value;
    return connectionId === constants?.FORSTA_SFTP_CONNECTION?.connectionId;
  }

  ngOnInit(): void {
    this.selectedProjectId = this.store.selectSnapshot(UserProjectState.getSelectedProjectId);
    this.selectedProject = this.store.selectSnapshot(
      UserProjectState.getSelectedProject
    );
    if (this.showForstaConnection()) {
      const requestHeaders: MprHttpHeaderModal = {};
      requestHeaders[HeaderParams.PROJECTID] = this.selectedProjectId;
      requestHeaders[HeaderParams.ROLENAME] = this.selectedProject.roleName;
      this.store.dispatch(new LoadProjectSurveys(requestHeaders));
      this.projectSurveys$.subscribe((surveys: ProjectSurveyModel[]) => {
        this.projectSurveys = [...surveys];
        this.sortProjectsSurveysBySurveyId();
        if (this.showForstaConnection() && this.projectSurveys.length !== 0) {
          this.showForstaInDropdown = true;
        }
      });
    }
    if(this.selectedProject.roleName !== UserProjectRoleEnum.RESEARCHER && this.selectedProject.roleName !== UserProjectRoleEnum.EXTERNAL_RESEARCHER){
      this.store.dispatch(new GetAllConnections());
    }
    
    this.connections$?.subscribe((connections: ConnectionModel[]) => {
      this.connectionList = [...connections];
    });
    this.setupSurveyIdValidation();
    this.skipMainContentService.skip$.subscribe((res) =>{
      this.skipper.focus();
    })  
  }

  public setSelectedConnection(): void {
    const formControl = this.uploadScheduleForm.controls;
    const selectedConnectionId = formControl['connectionId'].value;
    if(selectedConnectionId === this.forstaConnection.connectionId ){
      this.selectedConnection = this.forstaConnection
    } else if(selectedConnectionId === this.dL_S3Connection.connectionId){
      this.selectedConnection = this.dL_S3Connection
    } else {
        const connection: ConnectionModel =
      this.connectionList.find(
        (connectionObject) =>
          connectionObject.connectionId === selectedConnectionId
      ) ?? this.defaultValue;
    this.selectedConnection = connection;
    }

    formControl['folderPath'].setValue('');
    formControl['folderId'].setValue('');
    this.store.dispatch(new SetSelectedConnection(this.selectedConnection));
  }

  public showDestinationFolderPickerDialog(): void {
    this.dialog
      .open(S3ContentPickerComponent, {
        data: {
          pickerType: 'folder',
          selectedProject: this.selectedProject,
          showDefaultProjectBucket: true,
        },
      })
      .afterClosed()
      .subscribe((selectedFolder) => {
        const formControl = this.uploadScheduleForm.controls;
        if (selectedFolder) {
           this.displayDestinationPath = selectedFolder
            .split('/')
            .slice(1)
            .join('/')
            .slice(0, -1);
            formControl['stagingTargetPath'].setValue(this.displayDestinationPath);
            formControl['stagingTargetPath'].updateValueAndValidity();
          }
      });
  }

  public showFilePicker(): void {
    switch (this.selectedConnection.dataStore) {
      case ConnectionDataStore.BOX:
        this.showPickerDialog(BoxContentPickerComponent);
        break;
      case ConnectionDataStore.S3:
      case ConnectionDataStore.DL_S3:
        this.showPickerDialog(S3ContentPickerComponent);
        break;
      default:
        break;
    }
  }

  public showForstaConnection(): boolean {
    return ( this.scheduleType === 'staging' &&
      this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .isForstaFeatureFlagEnabled.toLowerCase() === 'true'
    );
  }

  public showScheduleForm(loaders: any) : boolean {
    return this.scheduleType === 'staging' ? !loaders.connections && (this.showForstaConnection() && !loaders.projectSurveyLoader) : true;
  }

  public validateAndCreateStagingSchedule(): void {
    if (this.uploadScheduleForm.valid) {
      const scheduleFormData = this.uploadScheduleForm.getRawValue();
      scheduleFormData['scheduleType'] = 'staging';
      this.store.dispatch(
        new SetScheduleFormData(scheduleFormData)
      );
      this.dialog.open(InitiateCreateScheduleComponent, {
        ...this.dialogSize,
        disableClose: true,
      });
    } else {
      this.uploadScheduleForm.markAllAsTouched();
    }
  }

  public validateAndProceedToMetaData(): void {
    if (this.uploadScheduleForm.valid) {
      this.store.dispatch(
        new SetScheduleFormData(this.uploadScheduleForm.getRawValue())
      );
      this.proceedToMetaData.emit();
    } else {
      this.uploadScheduleForm.markAllAsTouched();
    }
  }

  private clearValueAndValidators(field: AbstractControl): void {
    field.setValue('');
    field.markAsPristine();
    field.markAsUntouched();
    field.clearValidators();
  }

  private createFolderPath(selectedItems: MPRFolderModel[]): void {
    let folderPath = '';
    selectedItems[0].path_collection.entries.forEach(
      (path: MprFolderPathCollection) => {
        folderPath = `${folderPath}/${path.name}`;
      }
    );
    folderPath = `${folderPath}/${selectedItems[0].name}`;
    this.uploadScheduleForm.controls['folderPath'].setValue(folderPath);
    this.uploadScheduleForm.controls['folderId'].setValue(selectedItems[0].id);
  }

  private createFolderPathForS3(folderPath: string): void {
    this.uploadScheduleForm.patchValue({ 'folderId': folderPath, folderPath });
  }

  private setupSurveyIdValidation(): void {
    this.uploadScheduleForm.get('connectionId')?.valueChanges.subscribe((connectionId) => {
      const isForstaConnection = (connectionId === constants.FORSTA_SFTP_CONNECTION.connectionId);
      if (this.scheduleType === 'staging' && isForstaConnection) {
        this.uploadScheduleForm.get('surveyId')?.setValidators(Validators.required);
      } else {
        this.uploadScheduleForm.get('surveyId')?.clearValidators();
      }
      this.uploadScheduleForm.get('surveyId')?.updateValueAndValidity();
      this.uploadScheduleForm.get('surveyId')?.valueChanges.subscribe((surveyId) => {
        const formControl = this.uploadScheduleForm.controls;
        formControl['folderPath'].setValue(surveyId);
        formControl['folderId'].setValue(surveyId);
      });
    });
  }

  private showPickerDialog(openerComponent: ComponentType<unknown>): void {
    const dialogRef = this.dialog.open(openerComponent, {
      data: {
        pickerType: 'folder',
        options: { maxSelectable: 1 },
      },
    });
    // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
    dialogRef.afterClosed().subscribe((selectedItems) => {
      if (selectedItems) {
        switch (openerComponent) {
          case BoxContentPickerComponent:
            this.createFolderPath(selectedItems);
            break;
          case S3ContentPickerComponent:
            this.createFolderPathForS3(selectedItems);
            break;
        }
      } else {
        this.uploadScheduleForm.controls['folderPath'].markAsUntouched();
      }
    });
  }
  private sortProjectsSurveysBySurveyId(): void {
    this.projectSurveys.sort((a,b) => {
      const surveyIdA = a.surveyId.toLowerCase();
      const surveyIdB = b.surveyId.toLowerCase();

      if (surveyIdA < surveyIdB) {
        return -1;
      }
      if (surveyIdA > surveyIdB) {
        return 1;
      }
      return 0;
    });
  }
}
