import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, FeatureFlagGuard, ProjectSelectGuard } from '@core/guards';
import { ScheduleFormComponent } from '@shared';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { EditScheduleComponent } from '../shared/components/edit-schedule/edit-schedule.component';
import { RecurringScheduleComponent } from '../shared/components/recurring-schedule/recurring-schedule.component';
import { OneTimeUploadStagingComponent } from './one-time-upload-staging/one-time-upload-staging.component';
import { StagingJobDetailsComponent } from './staging-job-details/staging-job-details.component';
import { StagingComponent } from './staging/staging.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard, ProjectSelectGuard],
    canActivateChild: [AuthGuard, ProjectSelectGuard],
    children: [
      {
        path: '',
        component: StagingComponent,
        data: {
          back: '/home',
          backTitle: 'Dashboard',
          title: 'Upload To Project Staging',
        },
      },
      {
        path: 'schedule',
        component: RecurringScheduleComponent,
        canActivate: [FeatureFlagGuard],
        data: {
          allowWhenFeatureFlagTrue: ['isRecurringStagingUploadAvailable'],
          back: '/filemanager',
          backTitle: 'File Manager',
          title: 'Manage Recurring Schedules',
        },
      },
      {
        path: 'create-schedule',
        component: ScheduleFormComponent,
        data: {
          allowWhenFeatureFlagTrue: ['isRecurringStagingUploadAvailable'],
          back: '/staging/schedule',
          backTitle: 'Manage Recurring Schedule',
          title: 'Create New Schedule',
        },
      },
      {
        path: 'edit-schedule/:scheduleId',
        component: EditScheduleComponent,
        data: {
          back: '/staging/schedule',
          backTitle: 'Schedule',
          title: 'Edit Schedule',
        },
      },
      {
        path: 'onetime',
        component: OneTimeUploadStagingComponent,
        data: {
          back: '/staging',
          backTitle: 'Upload File(s) & History',
          title: 'One Time Upload For S3 Staging',
        },
      },
      {
        path: 'staging',
        component: StagingComponent,
        data: {
          back: '/home',
          backTitle: 'Dashboard',
          title: 'Upload To Project Staging',
        },
      },
      {
        path: 'job-details/:uploadType/:stagingJobId',
        component: StagingJobDetailsComponent,
        data: {
          back: '/staging',
          backTitle: 'Uploads File(s) & History',
          title: 'Job Details',
        },
      },
      {
        path: 'onetime/:selectedDestinationFolderPath',
        component: OneTimeUploadStagingComponent,
        data: {
          back: '/filemanager',
          backTitle: 'File Manager',
          title: 'One Time Upload For S3 Staging',
        },
      },
      { path: '', redirectTo: 'staging', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StagingRoutingModule {}
