import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { UserList } from 'app/state/project-user/user-list-model';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  @Select(UserInfoState.getCurrentUserRole)
  private userRole$?: Observable<string>;
  public disabledUsers: Array<UserList> = [];
  public disabledUsersTitle = `The following user(s) are disabled and cannot be added.`;
  public errorTitle = `'Add User' operation failed for the following user(s).Please try again.`;
  public isPreConfirm = false;
  public popupTitle = 'User(s) Added';
  public returnPath: string;
  public selectedRoleName = '';
  public successTitle = 'The following user(s) were added successfully';
  public userWithError: Array<UserList> = [];
  public userWithSucess: Array<UserList> = [];
  private fromUserList = false;
  private projectId = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmationComponent>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.returnPath = route.snapshot.data['back'];
    this.fromUserList = this.data.fromUserList ?? false;
    this.projectId = this.data.projectId ?? '';
  }

  public closeModal(isConfirm: boolean): void {
    this.dialogRef.close(isConfirm);
  }

  public navigateBack(): void {
    this.dialogRef.close(this.data);
    if (
      this.selectedRoleName === UserProjectRoleEnum.PLATFORM_ADMIN &&
      !this.fromUserList
    ) {
      this.router.navigate([`/platformAdmin/users`]);
    } else {
      const routerPath = this.fromUserList
        ? `/platformAdmin/users/list/${btoa(this.projectId)}`
        : `/admin/users`;
      this.router.navigate([routerPath]);
    }
  }

  ngOnInit(): void {
    if (this.data.isPreconfirm) {
      this.isPreConfirm = true;
      this.popupTitle = this.data.confirmTitle;
      this.successTitle = this.data.confirmMessage;
    }

    this.userRole$?.subscribe((roleName: string) => {
      this.selectedRoleName = roleName;
    });
    if (this.data?.response) {
      const disabledUserMessageString = 'is disabled';

      this.userWithSucess = this.data.response.filter(
        (user: UserList) => user.isSuccess === true
      );
      this.userWithError = this.data.response.filter(
        (user: UserList) =>
          user.isSuccess === false &&
          !user.message?.includes(disabledUserMessageString)
      );
      this.disabledUsers = this.data.response.filter(
        (user: UserList) =>
          user.isSuccess === false &&
          user.message?.includes(disabledUserMessageString)
      );
    }
  }
}
