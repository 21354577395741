import {
  Validator,
  NG_VALIDATORS,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { EMAIL_REGEX } from '@core/constants';

@Directive({
  selector: '[appEmailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: emailValidatorDirective,
      multi: true,
    },
  ],
})
export class emailValidatorDirective implements Validator {
  @Input() public allowedExternalDomains: Array<string> = [];
  @Input() public allowedInternalDomains: Array<string> = [];
  @Input() public appEmailValidator = '';

  public validate(c: AbstractControl): ValidationErrors | null {
    const allFieldsValue = c.parent?.value;
    const currentValue = c.value;
    const fields = Object.keys(allFieldsValue);
    const regexForEmail = EMAIL_REGEX;
    let duplicateEmail = '';
    let isUnsupportedDomain = false;
    let returnval = null;
    fields.forEach((field) => {
      if (field.toLowerCase().includes('emailid')) {
        const otherEmailControl = c.parent?.get(field);
        if (
          otherEmailControl?.getError('duplicateEmail') ===
          this.appEmailValidator + 'emailId'
        )
          otherEmailControl.setErrors(null);
        if (
          currentValue !== '' &&
          !field.toLowerCase().includes(this.appEmailValidator.toLowerCase()) &&
          currentValue === allFieldsValue[field]
        )
          duplicateEmail = field;
      }
    });
    if (c.value && c.value !== '') {
      const regex = new RegExp(regexForEmail);
      if (regex.test(c.value)) {
        const enteredEmailDomain = c.value.toLowerCase().split('@')[1];
        if (
          this.allowedInternalDomains.findIndex(
            (x) => x.toLowerCase() === enteredEmailDomain
          ) <= -1 &&
          this.allowedExternalDomains.findIndex(
            (x) => x.toLowerCase() === enteredEmailDomain
          ) <= -1
        ) {
          isUnsupportedDomain = true;
        }
      }
    }
    if (!isUnsupportedDomain && duplicateEmail === '') {
      return null;
    } else {
      returnval = { unsupportedDomain: isUnsupportedDomain, duplicateEmail };
    }
    return returnval;
  }
}
