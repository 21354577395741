import {
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    Inject
  } from '@angular/core';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';
  import { Select, Store } from '@ngxs/store';
  import { UserProjectRoleEnum } from '@core/enums';
  import { AlertMessageService } from '@core/services';
  import { UntilDestroy } from '@ngneat/until-destroy';
  import { ProjectSurveyState, AddNewMapping, EditMapping } from 'app/state';
  import { Observable, catchError, withLatestFrom } from 'rxjs';
  import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
  import { CommonResponseModel, noWhitespaceValidator } from '@shared';


  @UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-create-mapping',
  templateUrl: './create-mapping.component.html',
  styleUrls: ['./create-mapping.component.scss'],
})
export class CreateMappingComponent implements OnInit{
    @Select(ProjectSurveyState.getSurveyIds)
    public existingSurveyIds$!: Observable<string[]>;
    @Select(ProjectSurveyState.getResponseForCreateAndUpdate)
    public getCommonResponse$!: Observable<CommonResponseModel>;
    public existingSurveyIds: string[] = [];
    public mappingForm!: FormGroup;
    public projectId = '';
    public userRole = UserProjectRoleEnum.PLATFORM_ADMIN;

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialog: MatDialog,
      private dialogRef: MatDialogRef<CreateMappingComponent>,
        public store: Store,
        private alertMessageService: AlertMessageService,
      ) {}

      public confirmEditMapping(): void {
        if (this.mappingForm.invalid) {
          return;
        }
        const mappingData = {
          surveyId: this.mappingForm.value.surveyId,
          surveyName: this.mappingForm.value.surveyName,
          projectSurveyId: this.data.survey.projectSurveyId
        };
        const requestHeaders: MprHttpHeaderModal = {};
        requestHeaders[HeaderParams.PROJECTID] = this.data.survey.projectId;
        requestHeaders[HeaderParams.ROLENAME] = this.userRole;
        this.store.dispatch(new EditMapping(
          requestHeaders,
          mappingData.surveyId,
          mappingData.surveyName,
          mappingData.projectSurveyId
          ))
        .pipe(
          withLatestFrom(this.getCommonResponse$),
          catchError(error => {
            this.alertMessageService.error({
              body: 'Failed to update mapping.'
            });
            return [];
          })
        )
        .subscribe(([_, res]) => {
          this.dialogRef.close(); 
          if(res.status_code === 200){
            this.alertMessageService.success({
              body: `Mapping for survey ${ this.mappingForm.value.surveyId} updated successfully.`
            });
          } else {
            this.alertMessageService.error({
              autoDismiss: false,
              body: res.message
            });
          }
        });
      }

      public isValueSameAsPreviousMapping() : boolean {
        return this.mappingForm.value.surveyId === this.data.survey.surveyId && this.mappingForm.value.surveyName === this.data.survey.surveyName
      }

      ngOnInit(): void {
        this.initForm();
        this.getExistingSurveyIds();
        this.mappingForm.get('surveyId')?.updateValueAndValidity();
       }

      public onCreateMappingClick(): void {
        if (this.mappingForm.invalid) {
          return;
        }
        const mappingData = {
          surveyId: this.mappingForm.value.surveyId,
          surveyName: this.mappingForm.value.surveyName,
          projectId: this.data.projectId
        };
        const requestHeaders: MprHttpHeaderModal = {};
        requestHeaders[HeaderParams.PROJECTID] = this.data.projectId;
        requestHeaders[HeaderParams.ROLENAME] = this.userRole;
        this.store.dispatch(new AddNewMapping (requestHeaders, mappingData.surveyId, mappingData.surveyName, mappingData.projectId, ))
        .pipe(
          withLatestFrom(this.getCommonResponse$),
          catchError(error => {
            this.alertMessageService.error({
              body: 'Failed to create mapping.'
            });
            return [];
          })
        )
        .subscribe(([_, res]) => {
          this.dialogRef.close(); 
          if(res.status_code === 200){
            this.alertMessageService.success({
              body: `Mapping for survey ${ this.mappingForm.value.surveyId} created successfully.`
            });
          } else {
            this.alertMessageService.error({
              autoDismiss: false,
              body: res.message
            });
          }
        });
      }

      private getExistingSurveyIds(): void {
        this.existingSurveyIds$.subscribe(ids => {
          this.existingSurveyIds = ids;
        });
      }

      private initForm(): void {
        this.mappingForm = new FormGroup({
          surveyId: new FormControl('', {
            validators: [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9]*$'),
            this.surveyIdValidator.bind(this)
          ],
          asyncValidators: [],
          updateOn: 'change'
        }),
          surveyName: new FormControl('',[
            Validators.required,
            noWhitespaceValidator(),
          ]),
        });
        if(this.data.mappingAction === 'Edit'){
          this.mappingForm.patchValue(this.data.survey);
        } 
      }

      private surveyIdValidator(control: FormControl): { [key: string]: boolean } | null {
        const enteredSurveyId = control.value;
        if(this.data.mappingAction === 'Create'){
          if (this.existingSurveyIds.includes(enteredSurveyId)) {
            return { 'surveyIdExists': true };
          }
        } else if(this.data.mappingAction === 'Edit') {
          if(this.data.survey.surveyId === enteredSurveyId){
            return null
          } else if(this.existingSurveyIds.includes(enteredSurveyId)) {
            return { 'surveyIdExists': true };
          }
        }
        return null;
      }
    }
