import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserInfoState } from 'app/state/user-info/user-info.state';
import { Observable } from 'rxjs';
import * as constants from '@core/constants';
import { TileConfig, User } from '@theme/interfaces';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import { UserProjectRoleEnum } from '@core';
import { Router } from '@angular/router';
import {
  GetProjectCount,
  GetUserCount,
  LoadDatasetCount,
} from 'app/state/console-stats/console-stats.actions';
import { ConsoleStatsState } from 'app/state/console-stats/console-stats.state';
import { ResetUserProjectState } from 'app/state';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-platform-admin',
  templateUrl: './platform-admin.component.html',
  styleUrls: ['./platform-admin.component.scss'],
})
export class PlatformAdminComponent implements OnInit {
  @Select(ConsoleStatsState.getProjectCount)
  public projectCount$?: Observable<number>;
  @Select(ConsoleStatsState.getCurrentUserCount)
  private userCount$?: Observable<number>;
  @Select(ConsoleStatsState.getDatasetCount)
  private userDatasetCount$?: Observable<any>;
  @Select(UserInfoState.getCurrentUserRole)
  private userRole$?: Observable<string>;
  public datasetCount: any;
  public projectCount = 0;
  public selectedRoleName = '';
  public selectedUser!: User;
  public showSpinner = true;
  public tileConfigs: TileConfig[] = [
    {
      img: 'assets/images/Manageusers.svg',
      title: constants.MANAGE_USERS,
      tag: '',
      desc: 'Add, edit and remove users.',
      roleSetting: [UserProjectRoleEnum.PLATFORM_ADMIN],
      isVisible: true,
      isDisabled: false,
      path: '/platformAdmin/users',
      externalUrl: false,
    },
    {
      img: 'assets/images/admin_console.svg',
      title: constants.MANAGE_PROJECTS,
      tag: '',
      desc: 'View and update project settings.',
      roleSetting: [UserProjectRoleEnum.PLATFORM_ADMIN],
      isVisible: true,
      isDisabled: false,
      path: '/platformAdmin/projects',
      externalUrl: false,
    },
  ];
  public userCount = 0;

  constructor(private router: Router, public store: Store) {}

  public navigateToRoute(routePath: string): void {
    this.router.navigate([`${routePath}`]);
  }

  ngOnInit(): void {
    localStorage.removeItem('UserProject.selectedProjectId');
    this.store.dispatch(new ResetUserProjectState());

    this.userRole$?.subscribe((roleName: string) => {
      this.selectedRoleName = roleName;
      if (this.selectedRoleName === UserProjectRoleEnum.PLATFORM_ADMIN) {
        this.store.dispatch([
          new GetProjectCount('True', 1),
          new LoadDatasetCount(this.selectedRoleName),
          new GetUserCount('True', this.selectedRoleName),
        ]);
        this.projectCount$?.subscribe((result: number) => {
          if (result) {
            this.projectCount = result;
            this.userDatasetCount$?.subscribe((datasetCount: any) => {
              if (datasetCount) {
                this.userCount$?.subscribe((count: any) => {
                  if (
                    this.projectCount &&
                    Object.keys(datasetCount).length > 0 &&
                    count !== 0
                  ) {
                    this.userCount = count;
                    this.datasetCount = datasetCount;
                    this.showSpinner = false;
                  }
                });
              }
            });
          }
        });
      }
    });
  }
}
