import { Pipe, PipeTransform } from '@angular/core';
import { INTERNAL_S3 } from '@core/constants';
import { environment } from '@env/environment';

@Pipe({
    name: 'appDetails'
})

export class AppDetailsPipe implements PipeTransform {
    public transform(property: string): string {
        switch(property) {
            case 'appName':
                return environment.appName;
            case 'logoUrl':
                return environment.appLogoUrl;
            case 'displayAppSupportEmail':
                return environment.supportEmailId;
            case 'internalS3':
            case 'Data Library S3': 
            case 'dls3':
                return INTERNAL_S3;
            default:
                return property;
        }
    }
}