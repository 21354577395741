import {
  Component,
  EventEmitter,
  Input,
  ViewChild,
  Output,
} from '@angular/core';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { MprConfirmComponent } from '@shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MprFile } from 'app/uploads/interfaces/mpr-file-model';
@Component({
  selector: 'mpr-selected-file-table',
  templateUrl: './selected-file-table.component.html',
  styleUrls: ['./selected-file-table.component.scss'],
})
export class SelectedFileTableComponent {
  @Input() public dataSource: any[] = [];
  @Output() public removeFiles = new EventEmitter<any>();
  @ViewChild('filesTable') public table!: MatTable<any>;

  public displayedColumns = ['serialNum', 'fileName', 'fileSize', 'removeFile'];

  constructor(private dialog: MatDialog) {}

  public removeFilesFromList(files: MprFile[]): void {
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'Delete File',
        confirmMessage: 'Are you sure you want to delete this file ?',
        confirmData: 'confirm',
      },
    });
    // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
    dialogRef.afterClosed().subscribe((confirmData): void => {
      if (confirmData) this.removeFiles.emit(files);
    });
  }
}
