export enum HeaderParams {
  EMAILID = 'emailId',
  PROJECTID = 'projectId',
  ROLENAME = 'roleName',
  USERID = 'userId',
  ISSKIPAUTHORIZER = 'isSkipAuthorizer',
}

export interface MprHttpHeaderModal {
  [name: string]: string | string[];
}
