import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ResetpdateEc2InstanceType,
  UpdateEc2InstanceType,
} from './update-instance.action';
import { CommonResponseModel, LaunchEc2Service } from '@shared';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};

@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'updateInstance',
})
@Injectable()
export class UpdateInstanceState {
  constructor(private launchEc2Service: LaunchEc2Service) {}

  @Action(UpdateEc2InstanceType)
  public UpdateEc2InstanceType(
    { patchState }: StateContext<CommonResponseModel>,
    { machineSize, userId, projectId, requestHeaders }: UpdateEc2InstanceType
  ): Observable<CommonResponseModel> {
    return this.launchEc2Service
      .updateinstance(machineSize, userId, projectId, requestHeaders)
      .pipe(
        tap((results) => {
          patchState({ ...results });
        })
      );
  }

  @Action(ResetpdateEc2InstanceType)
  public resetUserdata({ patchState }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }

  @Selector()
  public static returnUpdatedEc2InstanceType(
    state: CommonResponseModel
  ): CommonResponseModel {
    return state;
  }
}
