import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { isAssetsUrl, hasScheme } from '@shared/utils';

export const BASE_URL = new InjectionToken<string>('BASE_URL');

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(BASE_URL) private baseUrl?: string) {}
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return !isAssetsUrl(request.url) && hasScheme(request.url) === false
      ? next.handle(request.clone({ url: this.prependBaseUrl(request.url) }))
      : next.handle(request);
  }

  private prependBaseUrl(url: string): string {
    const completeUrl = [
      this.baseUrl?.replace(/\/$/g, ''),
      url.replace(/^\.?\//, ''),
    ]
      .filter((val) => val)
      .join('/');

    return completeUrl;
  }
}
