import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomainListStateModel } from 'app/state';
import { Observable } from 'rxjs';
import { HeaderParams } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DomainListService {
  constructor(private http: HttpClient) { }

  public getAllowedDomains(headers: HttpHeaders): Observable<DomainListStateModel> {
    return this.http.get<DomainListStateModel>(`/domain`,{ headers });
  }
}