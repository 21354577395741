import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetupCrossAccountService } from '@shared/services';
import { Observable, tap } from 'rxjs';
import {
    AddNewCrossAccountAccess,
    DeleteCrossAccountAccess,
    ResetSetupCrossAccountAccessState,
    GetCrossAccountsEnabledList,
    EditCrossAccountAccess
} from './set-up-cross-account.action';
import { CommonResponseModel } from '@shared/interfaces';
import { GenericHttpResponse } from '@core/interfaces';
import { CrossAccountStateModel, CrossAccountsEnabledListResponse } from './set-up-cross-account-model';

const stateDefaults: CrossAccountStateModel = {
  commonResponse: {
    status_code: 0,
    message: '',
  },
  externalAccounts: [],
  mquiryUserRoleArn: '',
  mquiryServiceRoleArn: '',
  status_code: 0,
}

@State<CrossAccountStateModel>({
  defaults: stateDefaults,
  name: 'SetupCrossAccount',
})
@Injectable()
export class SetUpCrossAccountAccessState {
  constructor(private setupCrossAccountService: SetupCrossAccountService) {}

  @Action(AddNewCrossAccountAccess)
  public addNewCrossAccountAccess(
    { getState, patchState }: StateContext<CrossAccountStateModel>,
    { accountAttributeId, arn, crossAccountConnectionType }: AddNewCrossAccountAccess
  ): Observable<CommonResponseModel> {
    return this.setupCrossAccountService.addNewCrossAccount(accountAttributeId, arn, crossAccountConnectionType).pipe(
      tap((results) => {
        patchState({ commonResponse : results});
      })
    );
  }

  @Action(DeleteCrossAccountAccess)
  public delete(
    ctx: StateContext<CommonResponseModel>,
    { accountAttributeId, crossAccountConnectionType, ownerUserId, serviceAccountArnId }: DeleteCrossAccountAccess
  ): Observable<GenericHttpResponse> {
    return this.setupCrossAccountService.deleteCrossAccount(accountAttributeId, crossAccountConnectionType, ownerUserId, serviceAccountArnId);
  }  

  @Action(EditCrossAccountAccess)
  public editCrossAccountAccess(
      { patchState }: StateContext<CrossAccountStateModel>,
      { accountAttributeId, ownerUserId, crossAccountConnectionType, serviceAccountArnId, modified }: EditCrossAccountAccess
  ): Observable<CommonResponseModel> {
      return this.setupCrossAccountService.editCrossAccount(accountAttributeId, ownerUserId, crossAccountConnectionType, serviceAccountArnId, modified).pipe(
          tap((results) => {
              patchState({ commonResponse: results });
          })
      );
  }
 
  @Selector()
  public static getAllCrossAccountEnabled(state: CrossAccountStateModel): CrossAccountsEnabledListResponse {
    return {
      externalProjectArnList: state.externalAccounts,
      mquiryUserRoleArn: state.mquiryUserRoleArn,
      mquiryServiceRoleArn: state.mquiryServiceRoleArn,
      status_code: state.status_code,
    };
  }
 
  @Action(GetCrossAccountsEnabledList)
  public getCrossAccountsEnabledList(
   { patchState }: StateContext<CrossAccountStateModel>
  ): Observable<CrossAccountsEnabledListResponse> {
    return this.setupCrossAccountService.getExternalAccounts().pipe(
        tap(results => {
            patchState({
                externalAccounts: results.externalProjectArnList, 
                mquiryUserRoleArn: results.mquiryUserRoleArn, 
                mquiryServiceRoleArn: results.mquiryServiceRoleArn, 
                status_code: results.status_code,
            });
        })
    );
  }

  @Selector()
  public static getSetupCrossAccntAccessState(state: CrossAccountStateModel): CommonResponseModel {
    return state.commonResponse;
  }

  @Action(ResetSetupCrossAccountAccessState)
  public resetSetupCrossAccountAccessState({
    patchState,
  }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }
    
}