import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  Contents,
  CommonResponseModel,
  DeleteFolderInputModel,
} from '@shared';
import { S3ContentPickerService } from '@shared/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { S3PickerStateModel } from './s3-picker-state-model';
import {
  CheckFileManagerObjectExists,
  CreateS3Folder,
  DeleteS3Folder,
  GetContentPicker,
  RenameS3Folder,
} from './s3-picker.actions';
import { RenameFileFolderInputModel } from '@shared/interfaces/rename-file-folder-model';
import { S3PickerResponseModel } from '@shared/interfaces/s3-picker-response-model';
import { FileManagerOperationTypeEnums } from '@core/enums';

const stateDefaults: S3PickerStateModel = {
  lastAddedEditedDeletedS3Folder: {
    status_code: 0,
    message: '',
  },
  s3ObjectResponse: {
    status_code: 0,
    message: '',
  },
};

@State<S3PickerStateModel>({
  defaults: stateDefaults,
  name: 'S3PickerState',
})
@Injectable()
export class S3PickerState {
  constructor(private s3ContentPickerService: S3ContentPickerService) {}

  @Action(CheckFileManagerObjectExists)
  public CheckFileManagerObjectExists(
    ctx: StateContext<S3PickerStateModel>,
    data: RenameFileFolderInputModel
  ): Observable<CommonResponseModel> {
    return this.s3ContentPickerService.renameS3Folder({ ...data }).pipe(
      tap((s3ObjectResponse) => {
        ctx.patchState({
          s3ObjectResponse,
        });
      })
    );
  }

  @Selector()
  public static GetAddedEditedDeletedFolder(
    state: S3PickerStateModel
  ): CommonResponseModel {
    return state.lastAddedEditedDeletedS3Folder;
  }

  @Selector()
  public static GetContentPickerDetails(state: S3PickerStateModel): any {
    return state;
  }

  @Selector()
  public static GetShowMore(state: S3PickerStateModel): any {
    return state.response?.has_more;
  }

  @Action(RenameS3Folder)
  public RenameS3Folder(
    ctx: StateContext<S3PickerStateModel>,
    data: RenameFileFolderInputModel
  ): Observable<CommonResponseModel> {
    return this.s3ContentPickerService.renameS3Folder({ ...data }).pipe(
      tap((lastAddedEditedDeletedS3Folder) => {
        this.updateState(
          lastAddedEditedDeletedS3Folder,
          ctx,
          FileManagerOperationTypeEnums.FILE_MANAGER_OPERATION_TYPE_RENAME,
          data
        );
      })
    );
  }

  @Action(CreateS3Folder)
  public addNewS3Folder(
    ctx: StateContext<S3PickerStateModel>,
    prefix: any
  ): Observable<CommonResponseModel> {
    return this.s3ContentPickerService.addS3Folder({ ...prefix }).pipe(
      tap((lastAddedEditedDeletedS3Folder) => {
        this.updateState(
          lastAddedEditedDeletedS3Folder,
          ctx,
          FileManagerOperationTypeEnums.FILE_MANAGER_OPERATION_TYPE_CREATE,
          prefix
        );
      })
    );
  }

  @Action(DeleteS3Folder)
  public deleteS3Folder(
    ctx: StateContext<S3PickerStateModel>,
    data: DeleteFolderInputModel
  ): Observable<CommonResponseModel> {
    return this.s3ContentPickerService.deleteS3Folder({ ...data }).pipe(
      tap((lastAddedEditedDeletedS3Folder) => {
        this.updateState(
          lastAddedEditedDeletedS3Folder,
          ctx,
          FileManagerOperationTypeEnums.FILE_MANAGER_OPERATION_TYPE_DELETE,
          data
        );
      })
    );
  }

  @Action(GetContentPicker)
  public getContentPicker(
    ctx: StateContext<S3PickerStateModel>,
    {
      contentPickerRequest,
      projectRequestHeaders,
      selectedProject,
      showDefaultProjectBucket,
      pageNo,
    }: GetContentPicker
  ): Observable<any> {
    return this.s3ContentPickerService
      .getS3ContentPicker(
        contentPickerRequest,
        projectRequestHeaders,
        selectedProject,
        showDefaultProjectBucket,
        pageNo
      )
      .pipe(
        tap((response) => {
          if (
            ctx.getState().response?.bucket_name === response.bucket_name &&
            ctx.getState().response?.folder_name === response.folder_name &&
            pageNo &&
            pageNo > 1
          ) {
            response.contents = ctx
              .getState()
              .response?.contents.concat(response.contents);
          }
          ctx.patchState({ response });
        })
      );
  }

  @Selector()
  public static s3ObjectResponse(
    state: S3PickerStateModel
  ): CommonResponseModel | undefined {
    return state.s3ObjectResponse;
  }

  private getFileOrFolderName(
    isFile: string | boolean,
    prefix: string
  ): string {
    isFile =
      typeof isFile === 'string'
        ? isFile.toLowerCase() === 'true'
          ? true
          : false
        : isFile;
    const fileOrFolderName = isFile
      ? prefix.split('/').pop() ?? ''
      : prefix
          .substring(0, prefix.length - 1)
          .split('/')
          .pop() ?? '';
    return fileOrFolderName;
  }

  private getIsFileOrFolder(isFile: string | boolean): string {
    return typeof isFile === 'string'
      ? isFile.toLowerCase() === 'true'
        ? 'file'
        : 'folder'
      : isFile
      ? 'file'
      : 'folder';
  }

  private handleStateEntry(
    action: FileManagerOperationTypeEnums,
    contents: Contents[],
    data: any
  ): Contents[] {
    contents = [...contents];
    action = data.action ?? action;

    // remove the bucket name
    const prefix =
      typeof data !== 'string'
        ? data.prefix.substring(data.prefix.indexOf('/') + 1)
        : data;
    const fileFolderName =
      typeof data !== 'string'
        ? this.getFileOrFolderName(data.isFile, prefix)
        : data;
    const folderOrFile =
      typeof data !== 'string' ? this.getIsFileOrFolder(data.isFile) : 'folder';

    if (
      action ===
      FileManagerOperationTypeEnums.FILE_MANAGER_OPERATION_TYPE_DELETE
    ) {
      contents = contents.filter((item: Contents) => {
        const name = item.name?.endsWith('/')
          ? item.name.slice(0, -1)
          : item.name;
        const returnVal = !(
          name === fileFolderName && item.type === folderOrFile
        );
        return returnVal;
      });
    }

    return contents;
  }

  private updateState(
    lastAddedEditedDeletedS3Folder: CommonResponseModel,
    ctx: StateContext<S3PickerStateModel>,
    action: FileManagerOperationTypeEnums,
    data: string | DeleteFolderInputModel
  ): void {
    let contents = ctx.getState().response?.contents || [];
    contents = this.handleStateEntry(action, contents, data);
    const response: S3PickerResponseModel = {
      bucket_name: ctx.getState().response?.bucket_name || '',
      folder_name: ctx.getState().response?.folder_name || '',
      contents: contents || [],
      has_more: ctx.getState().response?.has_more,
    };

    const updatedState: S3PickerStateModel = {
      lastAddedEditedDeletedS3Folder,
    };

    if (
      action ===
      FileManagerOperationTypeEnums.FILE_MANAGER_OPERATION_TYPE_DELETE
    ) {
      updatedState.response = response;
    }

    ctx.patchState(updatedState);
  }
}
