import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProjectRoleEnum } from '@core';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { GetProjectUserList } from 'app/state/project-user/project-user.actions';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import { Observable, catchError, throwError, timer } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @Select(ProjectUserState.getProjectUserList)
  public projectUsersList$?: Observable<ProjectUserList[]>;
  @Select(ProjectUserState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  public isFromListPage = false;
  public listType = 'Users';
  public projectId = '';

  constructor(
    private route: ActivatedRoute,
    public store: Store,
    private alertMsgService: AlertMessageService,
    private router: Router,
    private location :Location,
  ) {
    this.projectId = this.route.snapshot.params['projectId']
      ? atob(this.route.snapshot.params['projectId'])
      : '';
    const state = location.getState() as { message: any };
    if (state?.message) {
      timer(1000).subscribe(() => {
        this.alertMsgService.success({
          body: state.message.message,
        });
      });
    }
    if (this.router.url.includes('/list/')) this.isFromListPage = true;
  }

  ngOnInit(): void {
    const requestHeaders: MprHttpHeaderModal = {};
    if (this.projectId) {
      requestHeaders[HeaderParams.PROJECTID] = this.projectId;
      requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;
      this.store.dispatch(new GetProjectUserList('False', requestHeaders));
    } else {
      this.store.dispatch(new GetProjectUserList('False'));
    }

    this.projectUsersList$?.pipe(
      catchError((err) => {
        this.alertMsgService.error({
          body: err.error.message,
        });
        return throwError(() => new Error(''));
      })
    );
  }
}
