<div class="mpr-tiles-container d-flex mpr-platform-admin-width">
  <div
    class="mpr-dashboard-loader"
    *ngIf="showSpinner; else showPlatformAdminDashboard"
  >
    <div class="mpr-noresult-container">
      <div class="mpr-noresutl-error">
        <span>
          <mat-spinner diameter="45" color="accent"></mat-spinner>
        </span>
      </div>
    </div>
  </div>
  <ng-template #showPlatformAdminDashboard>
    <div class="dataCount d-flex">
      <div>
        <div class="count" *ngIf="userCount !== 0">{{ userCount }}</div>
        <p class="countTitle">USERS</p>
      </div>
      <div>
        <div class="count" *ngIf="projectCount">{{ projectCount }}</div>
        <p class="countTitle">PROJECTS</p>
      </div>
      <div>
        <div class="count" *ngIf="datasetCount">
          {{ datasetCount.datasetCount }}
        </div>
        <p class="countTitle">DATASETS</p>
      </div>
      <div>
        <div class="count" *ngIf="datasetCount">
          {{ datasetCount.publicDatasetCount }}
        </div>
        <p class="countTitle">PUBLIC DATASETS</p>
      </div>
    </div>
    <ng-container *ngFor="let tile of tileConfigs">
      <a
        *ngIf="tile.isVisible"
        (click)="navigateToRoute(tile.path)"
        (keydown.enter)="navigateToRoute(tile.path)"
        #skipper
        tabindex="0"
      >
        <div class="mpr-tiles">
          <div class="mpr-tiles-img">
            <img [src]="tile.img" [alt]="tile.title + 'Icon'" role="img" />
          </div>
          <div class="mpr-tiles-title">
            {{ tile.title }}
            <span class="mpr-tiles-tag" *ngIf="tile.tag !== ''">{{
              tile.tag
            }}</span>
          </div>
          <div class="mpr-tiles-desc">{{ tile.desc }}</div>
        </div>
      </a>
    </ng-container>
  </ng-template>
</div>
