import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChangeSFDefaultRoleService } from '@shared/services';
import { Observable, tap } from 'rxjs';
import {
    ChangeSFDefaultRole,
    ResetSFRoleResponseState

} from './change-sf-role.action';
import { CommonResponseModel } from '@shared/interfaces';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};
@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'ChangeSfRole',
})
@Injectable()
export class ChangeSFRoleState {
  constructor(private changeSfRoleService: ChangeSFDefaultRoleService) {}

  @Action(ChangeSFDefaultRole, { cancelUncompleted: true })
  public changeSFDefaultRole(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { projectId, roleName }: ChangeSFDefaultRole
  ): Observable<CommonResponseModel> {
    return this.changeSfRoleService.changeSFRole(projectId, roleName).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Selector()
  public static getChangeRoleStatus(state: CommonResponseModel): CommonResponseModel {
    return state;
  }

  @Action(ResetSFRoleResponseState)
  public resetSFRoleResponseState({
    patchState,
  }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }
  
}