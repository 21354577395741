<div fxLayout="row" fxLayoutAlign="flex-start center">
  <div class="subheader-back-icon m-r-24" *ngIf="returnPath">
    <button
      class="mpr-btn-transparency mpr-cursor-pointer"
      (click)="navigateBack()"
      aria-label="Back"
      title="Click to go back to {{ backTitle }}"
    >
      <mat-icon class="menu-icon" svgIcon="arrow-left"></mat-icon>
      <span class="sr-only">Back Icon</span>
    </button>
  </div>
  <h1 class="subheader-heading text-uppercase text-mpr-md-primary-900">
    {{ routeTitle }}
  </h1>
</div>
