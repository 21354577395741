<div class="mpr-tiles-container d-flex">
  <ng-container *ngFor="let tiles of tileConfigs">
    <a
      [routerLink]="tiles?.path"
      class="flex-1"
      *ngIf="tiles.isVisible"
      #skipper
    >
      <div class="mpr-tiles">
        <div class="mpr-tiles-img">
          <img [src]="tiles.img" alt="" />
        </div>
        <div class="mpr-tiles-body">
          <div class="mpr-tiles-body-title">
            {{ tiles.title }}
            <span class="mpr-tiles-body-title-tag" *ngIf="tiles.tag !== ''">{{
              tiles.tag
            }}</span>
          </div>
          <div class="mpr-tiles-body-desc">{{ tiles.desc }}</div>
        </div>
      </div>
    </a>
  </ng-container>
</div>
<mpr-upload-history></mpr-upload-history>
