import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { UploadsMetadataModel } from './uploads-metadata-model';

// Default in state for now, will be moved to the metadata form when handling user input.
const stateDefaults: UploadsMetadataModel = {
  aggregationLevel: [],
  dataAccessClassification: '',
  dataPurchased: false,
  dataSourceYearsEnd: '',
  dataSourceYearsStart: '',
  dataTags: [],
  shortDescription: '',
  dateCreated: '',
  datasetName: '',
  focusArea: [],
  geographicRegion: '',
  noDataSourceYearsApply: true,
  pii: false,
  restrictedDataUseGroups: [],
};

@State<UploadsMetadataModel>({
  defaults: stateDefaults,
  name: 'fileMetadata',
})
@Injectable()
export class UploadsMetadataState {}
