import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  FileSizePipe,
  HelpLinkUrlPipe,
  ProjectShortNamePipe,
  UTCDatePipe,
} from '@shared/pipes';
import { ProjectFormComponent } from 'app/platform-admin/project-form/project-form.component';
import { MaterialModule } from '../material.module';
import {
  EditScheduleComponent,
  ExportToCSVComponent,
  InitiateCreateScheduleComponent,
  ListDetailsComponent,
  LoadMoreComponent,
  MprConfirmComponent,
  RecurringScheduleComponent,
  S3ContentPickerComponent,
  ScheduleFormComponent,
  SelectedFileTableComponent,
  UTCTimeZoneComponent,
  WarningPopupComponent,
} from './components';
import { SubheaderPageTitleNavComponent } from './components/subheader-page-title-nav/subheader-page-title-nav.component';
import {
  mprCopyToClipboardDirective,
  mprMatChipA11yDirective,
  mtxTooltipAriaDescribedByDirective,
} from './directives';
import { AppDetailsPipe } from './pipes/app-info-pipe';
import { ReplaceRoleName } from './pipes/role-labels.pipe';
import { emailValidatorDirective } from './validators';

const MODULES: any[] = [
  CommonModule,
  RouterModule,
  MaterialModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
];
const COMPONENTS: any[] = [
  ExportToCSVComponent,
  ListDetailsComponent,
  LoadMoreComponent,
  MprConfirmComponent,
  ProjectFormComponent,
  RecurringScheduleComponent,
  SelectedFileTableComponent,
  SubheaderPageTitleNavComponent,
  S3ContentPickerComponent,
  UTCTimeZoneComponent,
  WarningPopupComponent,
  ScheduleFormComponent,
  InitiateCreateScheduleComponent,
  EditScheduleComponent,
];
const COMPONENTS_DYNAMIC: any[] = [];
const DIRECTIVES: any[] = [
  emailValidatorDirective,
  mtxTooltipAriaDescribedByDirective,
  mprMatChipA11yDirective,
  mprCopyToClipboardDirective,
];
const PIPES: any[] = [
  AppDetailsPipe,
  HelpLinkUrlPipe,
  ProjectShortNamePipe,
  FileSizePipe,
  UTCDatePipe,
  ReplaceRoleName,
];
@NgModule({
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
})
export class SharedModule {}
