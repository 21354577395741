<div class="mpr-flexible-box" *ngIf="(fetchDWInProgress$ | async) === false; else loader">
  <div class="mpr-workspace-console">
    <p>
      <strong>1 :</strong> ODBC drivers and required packages are pre-installed
      on your processing workspace.
    </p>
    <p>
      <strong>2 :</strong> Use the connection parameters below to connect from
      your processing workspace.
    </p>
  </div>
  <div class="mpr-sf">
    <mat-tab-group class="left-aligned-tabs custon-yab-group">
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mpr-bold-label">R</span>
        </ng-template>
        <div class="mpr-tab-content">
          <div
            class="copy-container"
            (click)="copyCodeToClipboard(codeSnippetR)"
          >
            <button
              class="mpr-copy-icon-button mpr-cred-copy-to-clipboard-icon"
              matTooltip="Copy To Clipboard"
              aria-label="Copy To Clipboard"
            ></button>
            <span class="copy-text" matTooltip="Copy To Clipboard"
              >Copy Code</span
            >
          </div>
          <pre #codeSnippetR class="code-snippet">
              <p>
                  connection <- DBI::dbConnect(
                      <span class="light-blue">drv</span> = odbc::odbc(),
                      <span class="light-blue">dsn</span> = "snowflake",
                      <span class="light-blue">uid</span> = "{{ sfDetails.emailId }}",
                      <span class="light-blue">database</span> = "{{ sfDetails.snowflakeDatabase }}"
                      )
              </p>
          </pre>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mpr-bold-label">PYTHON</span>
        </ng-template>
        <div class="mpr-box-container">
          <div class="mpr-small-box">
            <img
              class="mpr-sf-info-icon"
              src="assets/images/Infoicon.png"
              alt=""
            />
            <span
              ><strong>For more information, please refer to </strong>
              <a
                class="mpr-workspace-link-service-now"
                href="{{ snowflakeHelpURL }}"
                target="_blank"
                ><span>Help</span>
                <img
                  class="mpr-sf-new-window-icon"
                  src="assets/images/new-window.svg "
                  alt="Help"
              /></a>
            </span>
          </div>
          <div class="mpr-big-box">
            <div class="mpr-title">
              CREATE CONNECTION USING SNOWPARK CONNECTOR
            </div>
            <pre #codeSnippetPython class="mpr-python-code-block">
from snowflake.snowpark.session import Session

import os
from cryptography.hazmat.backends import default_backend
from cryptography.hazmat.primitives import serialization

with open(os.environ['PRIVATE_KEY_PATH'], "rb") as key:
    <span class="light-blue">p_key</span> = serialization.load_pem_private_key(
        key.read(),
        <span class="light-blue">password</span>=os.environ['PRIVATE_KEY_PASSPHRASE'].encode(),
        <span class="light-blue">backend</span>=default_backend()
    )

<span class="light-blue">pkb</span> = p_key.private_bytes(
    <span class="light-blue">encoding</span>=serialization.Encoding.DER,
    <span class="light-blue">format</span>=serialization.PrivateFormat.PKCS8,
    <span class="light-blue">encryption_algorithm</span>=serialization.NoEncryption()
)

connection = {{ '{' }}
    <span class="light-blue">"account"</span>: "{{sfDetails.snowflakeAccount}}",
    <span class="light-blue">"user"</span>: "{{sfDetails.emailId}}",
    <span class="light-blue">"role"</span>: "{{sfDetails.snowflakeRoleName}}",
    <span class="light-blue">"private_key"</span>: pkb,
    <span class="light-blue">"warehouse"</span>: "{{sfDetails.snowflakeWarehouse}}",
    <span class="light-blue">"database"</span>: "{{sfDetails.snowflakeDatabase}}",
    <span class="light-blue">"schema"</span>: "STAGING"
{{ '}' }}
session = Session.builder.configs(connection).create()
print("session created")
session.sql('select current_warehouse(), current_database(), current_schema()').collect()
                  </pre>
          </div>
          <div class="copy-snippet">
            <div
              class="copy-container"
              (click)="copyCodeToClipboard(codeSnippetPython)"
            >
              <button
                class="mpr-icon-button mpr-copy-to-clipboard-icon"
                matTooltip="Copy To Clipboard"
                aria-label="Copy To Clipboard"
              ></button>
              <span class="mpr-copy-python-text" matTooltip="Copy To Clipboard"
                >Copy Code</span
              >
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mpr-bold-label">OTHER LANGUAGES</span>
        </ng-template>
        <div class="mpr-tab-content">
          <div class="copy-container" (click)="copySFOtherValues()">
            <button
              class="mpr-copy-icon-button mpr-cred-copy-to-clipboard-icon"
              matTooltip="Copy To Clipboard"
              aria-label="Copy To Clipboard"
            ></button>
            <span class="copy-text" matTooltip="Copy To Clipboard"
              >Copy Text</span
            >
          </div>
          <div class="mpr-code-snippet">
            <div class="sf-value">
              <span class="light-blue">DSN</span> = snowflake
            </div>
            <div class="sf-value">
              <span class="light-blue">Account</span> =
              {{ sfDetails.snowflakeAccount }}
            </div>
            <div class="sf-value">
              <span class="light-blue">Warehouse</span> =
              {{ sfDetails.snowflakeWarehouse }}
            </div>
            <div class="sf-value">
              <span class="light-blue">Database</span> =
              {{ sfDetails.snowflakeDatabase }}
            </div>
            <div class="sf-value">
              <span class="light-blue">Role</span> =
              {{ sfDetails.snowflakeRoleName }}
            </div>
            <div class="sf-value">
              <span class="light-blue">UID</span> = {{ sfDetails.emailId }}
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="mpr-refresh-container">
    <span>
      <strong
        >The JWT token configured on your processing workspace expires after 8
        hours.
        <button
          mat-button
          #skipper
          [disabled]="isJwtRefreshing"
          (click)="triggerRefresh()"
          class="mpr-button-width"
        >
          <span class="mpr-cred-refresh-img">
            <img src="assets/images/AWSRefresh.svg" alt="" role="img" />
          </span>
          <span class="mpr-cred-refresh"><strong>Refresh</strong> </span>
        </button>
        to get a new token.
      </strong>
    </span>
  </div>
</div>
<ng-template #loader>
  <div class="d-flex mpr-justify-content-center mpr-table-loader">
    <span>
      <mat-spinner diameter="45" color="accent"></mat-spinner>
    </span>
  </div>
</ng-template>