import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mpr-export-to-csv',
  templateUrl: './export-to-csv.component.html',
  styleUrls: ['./export-to-csv.component.scss'],
})
export class ExportToCSVComponent {
  @Output() public exportMethod: EventEmitter<any> = new EventEmitter();
  @Input() public spinner = false;
  constructor() {}

  public exportToCsv(): void {
    this.exportMethod.emit();
  }
}
