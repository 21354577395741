import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CommonResponseModel } from '@shared/interfaces';
import { AWSWorkspaceService } from '@shared/services';
import { Observable, tap } from 'rxjs';
import {
  ForgotAWSPassword,
  LaunchAWSWorkspace,
  ProvisionAWSWorkspace,
  ReprovisionAWSWorkspace,
  ResetAWSWorkspaceStatus,
  StopAWSWorkspace,
  TerminateUserAWSWorkspace,
} from './launch-aws-workspace.actions';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};
@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'LaunchAwsWorkspaceData',
})
@Injectable()
export class LaunchAWSWorkspaceState {
  constructor(private awsWorkspaceService: AWSWorkspaceService) {}

  @Action(ForgotAWSPassword)
  public ForgotAWSPassword(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { password, workspaceUserName }: ForgotAWSPassword
  ): Observable<CommonResponseModel> {
    return this.awsWorkspaceService
      .forgotAWSWPassword(password, workspaceUserName)
      .pipe(
        tap((results) => {
          patchState({ ...results });
        })
      );
  }

  @Action(LaunchAWSWorkspace)
  public LaunchAWSWorkspace(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { action }: LaunchAWSWorkspace
  ): Observable<CommonResponseModel> {
    return this.awsWorkspaceService.launchOrProvisionAWSWorkspace(action).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Action(ProvisionAWSWorkspace)
  public ProvisionAWSWorkspace(
    { patchState }: StateContext<CommonResponseModel>,
    { action, awsPassword }: ProvisionAWSWorkspace
  ): Observable<CommonResponseModel> {
    action = action ?? 'provision';
    return this.awsWorkspaceService
      .launchOrProvisionAWSWorkspace(action, awsPassword)
      .pipe(
        tap((results) => {
          patchState({ ...results });
        })
      );
  }

  @Action(ReprovisionAWSWorkspace)
  public ReprovisionAWSWorkspace(
    { patchState }: StateContext<CommonResponseModel>,
    { updatedWorkspaceType }: ReprovisionAWSWorkspace
  ): Observable<CommonResponseModel> {
    return this.awsWorkspaceService
      .reprovisionAWSWorkspace(updatedWorkspaceType)
      .pipe(
        tap((results) => {
          patchState({ ...results });
        })
      );
  }

  @Action(ResetAWSWorkspaceStatus)
  public ResetAWSWorkspace({
    patchState,
  }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }

  @Action(StopAWSWorkspace)
  public StopAWSWorkspace(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { action }: StopAWSWorkspace
  ): Observable<CommonResponseModel> {
    return this.awsWorkspaceService.stopAWSWorkspace(action).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Action(TerminateUserAWSWorkspace)
  public TerminateUserAWSWorkspace(
    { getState, patchState }: StateContext<CommonResponseModel>,
    { userProjectDetails, requestHeaders }: TerminateUserAWSWorkspace
  ): Observable<CommonResponseModel> {
    return this.awsWorkspaceService.terminateUserAWSWorkspace(userProjectDetails, requestHeaders).pipe(
      tap((results) => {
        patchState({ ...results });
      })
    );
  }

  @Selector()
  public static getLaunchAws(state: CommonResponseModel): CommonResponseModel {
    return state;
  }
}
